import React from 'react';
import classes from '../../../styles/AllDevices.module.css';
import Typography from '../../../components/Typography/Typography';
import ChartComponent from '../../../components/ApexCharts/Chart';
import edgeClasses from '../Edge.module.css';
import { useEffect } from 'react';
import { EdgeService } from '../../../services/EdgeService';
import { momentTimeFormater } from '../../../utils/timeHelper';
import { useState } from 'react';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import { timeOnly } from '../../../utils/dateHelper';
import { DropdownComponent } from '../../../components/Inputs/Input';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';
import AlertsTable from '../ModalComponent/AlertsTable';
import AlertsFormModal from '../ModalComponent/AlertsForm';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import TabsComponent from '../../../components/Tabs/Tabs';
import { UilImport } from '@iconscout/react-unicons';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';

const EdgeStats = ({ edgeDeviceId }) => {
    const curCetDateTime = momentTimeFormater();
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(new Date());
    const [endTime, setEndTime] = useState(curCetDateTime.add(1, 'minute').format('HH:mm'));
    const [startTime, setStartTime] = useState(curCetDateTime.subtract(30, 'minutes').format('HH:mm'));
    const [cpuData, setCpuData] = useState([]);
    const [memoryData, setMemoryData] = useState([]);
    const [diskReadWriteData, setDiskReadWriteData] = useState([]);
    const [networkIOData, setNetworkIOData] = useState([]);
    const [cpuUsage, setCpuUsage] = useState(0);
    const [memoryUsage, setMemoryUsage] = useState(0);
    const [diskRead, setDiskRead] = useState(0);
    const [diskWrite, setDiskWrite] = useState(0);
    const [networkSent, setNetworkSent] = useState(0);
    const [networkReceived, setNetworkReceived] = useState(0);
    const viewOptions = [
        { label: 'Live', value: 'live' },
        { label: 'Detailed', value: 'detailed' },
    ];
    const [intervalState, setIntervalState] = useState(null);
    const [startIntervalState, setStartIntervalState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    useEffect(() => {
        if (intervalState) clearInterval(intervalState);
        const curInterval = setInterval(getData, 10000);
        setIntervalState(curInterval);
        return () => {
            clearInterval(curInterval);
        };
    }, [startIntervalState]);

    const getData = (cdate, cstartTime, cendTime) => {
        const ncurCetDateTime = momentTimeFormater();
        const params = {
            deviceId: edgeDeviceId,
            date: momentTimeFormater(cdate ? cdate : ncurCetDateTime).format('YYYY-MM-DD'),
            endTime: cendTime ? cendTime : ncurCetDateTime.add(1, 'minute').format('HH:mm'),
            startTime: cendTime ? cstartTime : ncurCetDateTime.subtract(30, 'minutes').format('HH:mm'),
        };
        EdgeService.GetEdgeStats(params, !intervalState ? startLoader : () => setLoading(true), handleSuccess, handleError, !intervalState ? stopLoader : () => setLoading(false));
    };

    const handleSuccess = ({ data }) => {
        const {
            cpuGraph = [],
            memoryGraph = [],
            diskReadWriteGraph = [],
            networkIOGraph = [],
            cpuUsage = 0,
            memoryUsage = 0,
            diskRead = 0,
            diskWrite = 0,
            networkSent = 0,
            networkReceived = 0,
        } = data.data;
        setCpuData(cpuGraph);
        setMemoryData(memoryGraph);
        setDiskReadWriteData(diskReadWriteGraph);
        setNetworkIOData(networkIOGraph);
        setCpuUsage(cpuUsage);
        setMemoryUsage(memoryUsage);
        setDiskRead(diskRead);
        setDiskWrite(diskWrite);
        setNetworkSent(networkSent);
        setNetworkReceived(networkReceived);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const onViewChange = ({ value }) => {
        if (value === 'live') {
            getData();
            setStartIntervalState(Date.now());
        } else if (intervalState) {
            clearInterval(intervalState);
            setIntervalState(null);
        }
    };

    const handleSubmit = () => {
        getData(date, startTime, endTime);
    };

    const handleExport = () => {
        const params = {
            deviceId: edgeDeviceId,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            endTime: endTime,
            startTime: startTime,
        };
        EdgeService.GetEdgeStatsForExport(params, startLoader, handleExportSuccess, handleError, stopLoader);
    };

    const handleExportSuccess = ({ data }) => {
        if (Array.isArray(data.data) && data.data?.length) {
            const headers = ['Timestamp (CET)'];
            const firstEntry = data.data[0];
            Object.keys(firstEntry).forEach((key) => {
                if (key !== 'timestamp') headers.push(key);
            });
            const exportData = data.data.map((item) => {
                const tempTimestamp = momentTimeFormater(item.timestamp).format('YYYY-MM-DD HH:mm:ss');
                delete item.timestamp;
                return { timestamp: tempTimestamp, ...item };
            });
            DownloadAsExcel(exportData, 'Edge stats', headers);
        }
    };

    const modalTabs = [
        {
            name: 'All Alerts',
            component: (
                <>
                    <AlertsTable edgeDeviceId={edgeDeviceId} />
                </>
            ),
        },
        {
            name: 'Create Alerts',
            component: (
                <>
                    <AlertsFormModal setAlertModal={setAlertModal} edgeDeviceId={edgeDeviceId} />
                </>
            ),
        },
    ];

    return (
        <div className={classes.AllDevices}>
            <ModalComponent
                isOpen={alertModal}
                setOpen={(e) => {
                    setAlertModal(e);
                }}
                style={{ overflow: 'initial' }}
            >
                <div style={selectedTabIndex === 0 ? { width: '60vw' } : {}}>
                    <TabsComponent selectedIndex={selectedTabIndex} tabs={modalTabs} />
                </div>
            </ModalComponent>

            <div className={classes.Header}>
                <div>
                    <Typography content="Edge Stats" />
                    <button className={classes.ButtonWidth + ' btn-primary'} onClick={() => setAlertModal(true)}>
                        Alert
                    </button>
                    <div>
                        <DropdownComponent options={viewOptions} defaultValue={viewOptions[0]} onChange={onViewChange} />
                    </div>
                    {loading && 'Loading...'}
                </div>
                <div>
                    {!intervalState && (
                        <div
                            style={{
                                display: 'flex',
                                gap: '0.521vw',
                                height: '2vw',
                                alignItems: 'center',
                            }}
                        >
                            <DatePicker date={date} setDate={setDate} showArrow={true} />
                            <CustomTimePicker startTime={startTime} setStartTime={setStartTime} type="start" />
                            <CustomTimePicker endTime={endTime} setEndTime={setEndTime} type="end" />
                            <button
                                type="submit"
                                className="btn-primary"
                                style={{
                                    marginTop: '0',
                                    width: 'auto',
                                }}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                            <UilImport
                                size="1.5vw"
                                style={{
                                    color: 'var(--color-primary)',
                                    cursor: 'pointer',
                                }}
                                onClick={handleExport}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div style={{ marginTop: '1vh' }}>
                <div className={edgeClasses.GraphContainer}>
                    <div
                        className={edgeClasses.GraphWrapper}
                        style={{
                            borderRight: '1px solid green',
                            borderBottom: '1px solid green',
                        }}
                    >
                        <ChartComponent
                            series={cpuData}
                            title={`CPU usage${intervalState ? `: ${cpuUsage}%` : ' (%)'}`}
                            xFormatter={timeOnly}
                            chartProps={{ group: 'edge-stats' }}
                        />
                    </div>
                    <div className={edgeClasses.GraphWrapper}>
                        <ChartComponent
                            series={memoryData}
                            title={`Memory usage${intervalState ? `: ${memoryUsage}%` : ' (%)'}`}
                            xFormatter={timeOnly}
                            chartProps={{ group: 'edge-stats' }}
                        />
                    </div>
                </div>
                <div className={edgeClasses.GraphContainer}>
                    <div className={edgeClasses.GraphWrapper}>
                        <ChartComponent
                            series={diskReadWriteData}
                            title={`Disk read/write${intervalState ? `: ${diskRead}GB / ${diskWrite}GB` : ' (GB)'}`}
                            xFormatter={timeOnly}
                            chartProps={{ group: 'edge-stats' }}
                        />
                    </div>
                    <div
                        className={edgeClasses.GraphWrapper}
                        style={{
                            borderLeft: '1px solid green',
                            borderTop: '1px solid green',
                        }}
                    >
                        <ChartComponent
                            series={networkIOData}
                            title={`Network I/O${intervalState ? `: ${networkSent}GB / ${networkReceived}GB` : ' (GB)'}`}
                            xFormatter={timeOnly}
                            chartProps={{ group: 'edge-stats' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdgeStats;
