// Standard library imports
import React, { useEffect, useState } from 'react';
// External library import
import { FieldArray, Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { Input } from '../../../../components/Inputs/Input';
import classes from '../../../../styles/BidModal.module.css';
import bundleClasses from '../index.module.css';
import { useLoader } from '../../../../hooks';
import { InstanceBundleValidation } from '../../../../validations/BundleBids/InstanceBundleValidation';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';

const CreateInstanceBundle = ({ editData, market, customer, licenses, setOpen, getInstanceBundle }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState({
        name: editData ? editData?.label : '',
        customerId: customer.value,
        pairs: editData ? editData?.pairs.map((item) => ({ licenseId: item.licenseId, instanceId: item.instanceId })) : [{ licenseId: '', instanceId: '' }],
        market: market,
    });
    const [instanceOptions, setInstanceOption] = useState({});

    useEffect(() => {
        if (licenses.length) {
            const temp = {};
            licenses.map((e) => {
                temp[e.value] = e.instances.map((ins) => ({
                    label: ins.name,
                    value: ins._id,
                }));
            });
            setInstanceOption(temp);
        }
    }, [licenses]);

    const handleSubmit = (value) => {
        if (editData?.value) {
            BundleBiddingService.UpdateInstanceBundle(editData?.value, value, startLoader, handleSuccess, handleError, stopLoader);
        } else {
            BundleBiddingService.CreateInstanceBundle(value, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = () => {
        toast.success(`Instance Bundle ${editData ? 'updated' : 'created'} successfully`);
        setOpen({ status: false, data: null });
        getInstanceBundle();
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div className={bundleClasses.Container}>
            <Typography content={`${editData ? 'Edit' : 'Create'} Instance Bundle (${customer.label})`} />
            <div>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={InstanceBundleValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div>
                                <div className={classes.FieldControl2}>
                                    <label htmlFor="name">
                                        Name
                                        <span className="required">*</span>
                                    </label>
                                    <Input name="name" id="name" />
                                </div>
                            </div>
                            <div>
                                <FieldArray name="pairs">
                                    {({ push, remove, form }) => {
                                        const pairs = form.values.pairs;
                                        return (
                                            <div>
                                                <div>
                                                    {pairs.map((e, index) => (
                                                        <div className={classes.InputContainer} key={index}>
                                                            <div className={classes.FieldControl2}>
                                                                <label htmlFor="license">
                                                                    license <span className="required">*</span>
                                                                </label>
                                                                <Dropdown
                                                                    name={`pairs[${index}].licenseId`}
                                                                    options={licenses}
                                                                    defaultValue={{ value: pairs?.[index]?.licenseId || '' }}
                                                                />
                                                            </div>

                                                            <div className={classes.FieldControl2}>
                                                                <label htmlFor="license">
                                                                    Instance <span className="required">*</span>
                                                                </label>
                                                                <Dropdown
                                                                    name={`pairs[${index}].instanceId`}
                                                                    options={instanceOptions[pairs[index].licenseId] || []}
                                                                    defaultValue={{ value: pairs?.[index]?.instanceId || '' }}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={bundleClasses.AddIcon}>
                                                    {pairs.length > 0 && (
                                                        <span onClick={() => remove(pairs.length - 1)}>
                                                            <UilMinusCircle
                                                                size={'1.5vw'}
                                                                style={{
                                                                    color: 'var(--color-primary)',
                                                                    cursor: 'pointer',
                                                                    zIndex: '1',
                                                                }}
                                                            />
                                                        </span>
                                                    )}
                                                    <span onClick={() => push({ licenseId: '', instanceId: '' })}>
                                                        <UilPlusCircle
                                                            size={'1.5vw'}
                                                            style={{
                                                                color: 'var(--color-primary)',
                                                                cursor: 'pointer',
                                                                zIndex: '1',
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </FieldArray>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default CreateInstanceBundle;
