// Standard library imports
import React from 'react';
// Css imports
import classes from '../index.module.css';
// Internal module imports
import TabsComponent from '../../../components/Tabs/Tabs';
import { Notes } from './Notes';
import { Logs } from './Logs';

export const NotesLogsIndex = ({ data, setOpen }) => {
    let tabs = [
        {
            name: 'Logs',
            component: (
                <>
                    <Logs data={data} />
                </>
            ),
        },
        {
            name: 'Notes',
            component: (
                <>
                    <Notes data={data} setOpen={setOpen} />
                </>
            ),
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'} style={{ display: 'flex' }}>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};
