// Standard library imports
import React, { useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
// Internal module imports
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/BidModal.module.css';
import { Input, ToggleButtonWithState } from '../../../components/Inputs/Input';
import bidsClasses from '../index.module.css';
import { CreateSecondaryBidValidation } from '../../../validations/Bidding/Bids';
import { AdminBiddingService } from '../../../services/AdminBiddingService';
import { useLoader } from '../../../hooks';

const getInitialValues = (bid) => {
    const values = {
        capacity: [],
        price: [],
    };
    if (_.get(bid, ['primary', 'provider', 'bidStatus'], []).length) {
        _.get(bid, ['primary', 'provider', 'bidStatus'], []).forEach((hourData, index) => {
            if (hourData.capacity === 0) {
                values.capacity.push(_.get(bid, ['primary', 'capacity', index], 0.9));
                values.price.push(1);
            } else {
                values.capacity.push(null);
                values.price.push(null);
            }
        });
    }
    return values;
};

const getPriceCapacity = (initialValues) => {
    return { capacity: initialValues.capacity, price: initialValues.price };
};

const CreateSecondaryBid = ({ bid, market, setOpen, fetchBids }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState(getInitialValues(bid));
    const [{ capacity, price }, setPriceCapacityValues] = useState(getPriceCapacity(initialValues));
    const [isSecondaryBid, setIsSecondaryBid] = useState(false);
    const [allHours, setAllHours] = useState(false);

    const handleSubmit = (values) => {
        if (bid._id) {
            AdminBiddingService.UpdateSecondaryBid(bid._id, values, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ res }) => {
        toast.success('Bid Updated!');
        fetchBids();
        setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleDMinus2NotAvailableChange = async (e) => {
        setIsSecondaryBid(e.target.checked);
        if (e.target.checked) {
            AdminBiddingService.GetLastBid(
                { customerId: bid.customerId, groupId: bid.instanceId, market, bidType: 'secondary' },
                startLoader,
                handleGetLastBidSuccess,
                handleError,
                stopLoader
            );
        }
    };

    const handleGetLastBidSuccess = ({ data }) => {
        if (data?.data?.length) {
            const capacity = _.get(data, ['data', 'secondary', 'capacity'], []);
            const price = _.get(data, ['data', 'secondary', 'price'], []);
            setInitialValues({ ...initialValues, capacity, price });
            setPriceCapacityValues({ capacity, price });
        } else {
            const capacity = _.get(bid, ['primary', 'capacity'], []);
            const price = Array(24).fill(1);
            setInitialValues({ capacity, price });
            setPriceCapacityValues({ capacity, price });
        }
    };

    const handleAllHours = (e) => {
        setAllHours(e.target.checked);
        let tempCap = [];
        let tempPrice = [];
        if (e.target.checked) {
            _.get(bid, ['primary', 'capacity']).map((c, idx) => {
                if (_.get(capacity, [idx])) {
                    tempCap.push(_.get(capacity, [idx]));
                    tempPrice.push(_.get(price, [idx]));
                } else {
                    tempCap.push(0);
                    tempPrice.push(0);
                }
            });
        } else {
            const data = getInitialValues(bid);
            tempCap = data.capacity;
            tempPrice = data.price;
        }
        setInitialValues({ capacity: tempCap, price: tempPrice });
        setPriceCapacityValues({ capacity: tempCap, price: tempPrice });
    };

    return (
        <div>
            <div className={bidsClasses.TitleWrapper}>
                <Typography content={`${market} Secondary Bid ( ${bid.cetDate} )`} size="16" />
                {price?.filter((e) => e != null).length > 0 && !isSecondaryBid && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonWithState value={allHours} onChange={handleAllHours} label="All Hours" />
                    </div>
                )}
            </div>
            <div className={classes.FormContainer2}>
                {price?.filter((e) => e != null).length > 0 || isSecondaryBid ? (
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateSecondaryBidValidation}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div>
                                    <div className={classes.Subtitle}>Capacity(Mw)</div>
                                    <div className={bidsClasses.DMinusContainer}>
                                        {capacity?.map((item, index) =>
                                            item || item == 0 ? (
                                                <div className={classes.InputContainer} key={index}>
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label>
                                                                Slot {index + 1} / Hour {index}-{index + 1}{' '}
                                                            </label>
                                                            <Input name={`capacity[${index}]`} id={index} type="number" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.Subtitle}>Price(€)</div>
                                    <div className={bidsClasses.DMinusContainer}>
                                        {price?.map((item, index) =>
                                            item || item == 0 ? (
                                                <div className={classes.InputContainer} key={index}>
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label>
                                                                Slot {index + 1} / Hour {index}-{index + 1} Price
                                                            </label>
                                                            <Input name={`price[${index}]`} id={index} type="number" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        )}
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <span>Looks like result for primary bid are not announced yet.</span>
                        <div>OR</div>
                        <span>Result were in favour for all hours.</span>
                        <div>OR</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ToggleButtonWithState value={isSecondaryBid} onChange={handleDMinus2NotAvailableChange} label="Primary bid not available" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateSecondaryBid;
