import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const customizationValidation = () => {
    return yup.object().shape({
        domain: yup.string().required(ERRORS.required),
        theme: yup.object({
            primaryColor: yup.string().required(ERRORS.required),
        }),
    });
};
