// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getAwsIAMUser: '/admin/access-manager',
    getCustomerCredentials: '/admin/access-manager/creds',
    create: '/admin/access-manager',
    delete: '/admin/access-manager',
    update: '/admin/access-manager',
};

const GetAwsIAMUser = (customerId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAwsIAMUser}/${customerId}`).then(callback).catch(error).finally(next);
};

const GetCustomerCredentials = (cutomerId, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getCustomerCredentials}/${cutomerId}`, {
        params,
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (awsUserName, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}`, {
        params: { awsUserName: awsUserName },
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

export const AccessManagerService = {
    GetAwsIAMUser,
    GetCustomerCredentials,
    Create,
    Update,
    Delete,
};
