// Standard library imports
import React from 'react';
// Css imports
import classes from '../index.module.css';
// Internal module imports
import HourlyCost from './HourlyCost';
import PriceContent from './Price';
import CapacityContent from './Capacity';
import TabsComponent from '../../../components/Tabs/Tabs';

const BiddingInfoContent = ({ market, data, setOpen, fetchBids }) => {
    let tabs = [
        {
            name: 'Settlement',
            component: (
                <>
                    <HourlyCost market={market} data={data.hourlyData} setOpen={setOpen} fetchBids={fetchBids} />
                </>
            ),
        },
        {
            name: 'Bidding Price',
            component: (
                <>
                    <PriceContent data={data.price} />
                </>
            ),
        },
        {
            name: 'Bidding Capacity',
            component: (
                <>
                    <CapacityContent data={data.capacity} />
                </>
            ),
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'} style={{ display: 'flex' }}>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default BiddingInfoContent;
