import { useField } from 'formik';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import classes from './ColorPicker.module.css';

const ColorPicker = ({ name, title, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const [showPicker, setShowPicker] = useState(false);
    const [value, setValue] = useState(meta.value || '#0f123f');

    const handleClick = () => setShowPicker(!showPicker);
    const onChange = (color) => {
        helpers.setValue(color.hex);
        setValue(color.hex);
    };

    return (
        <div>
            <div className={classes.Wrapper}>
                <div className={classes.Swatch} onClick={handleClick}>
                    <div className={classes.Color} style={{ background: value }} />
                </div>

                {showPicker && (
                    <div className={classes.Popover}>
                        <div className={classes.Cover} onClick={handleClick} />
                        <SketchPicker color={value} onChange={onChange} />
                    </div>
                )}
                <label>{title}</label>
            </div>
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export default ColorPicker;
