import { HttpClient } from '../utils/httpClient';

const PATH = {
    read: '/admin/economics',
    readForRoot: '/root/economics',
    readLedgerForRoot: '/root/economics/ledger',
    readForEnabler: '/enabler/economics',
};

const Read = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read, { params }).then(callback).catch(error).finally(next);
};

const ReadForRoot = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readForRoot, { params }).then(callback).catch(error).finally(next);
};

const ReadledgerForRoot = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readLedgerForRoot, { params }).then(callback).catch(error).finally(next);
};

const ReadForEnabler = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readForEnabler, { params }).then(callback).catch(error).finally(next);
};

export const EconomicsService = { Read };
export const RootEconomicsService = {
    Read: ReadForRoot,
    ReadLedger: ReadledgerForRoot,
};
export const EnablerEconomicsService = { Read: ReadForEnabler };
