import classes from '../../../../styles/AllDevices.module.css';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { LoaderContext } from '../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';
import { Input } from '../../../../components/Inputs/Input';
import { addRegulationsValidation } from '../../../../validations/SystemTools/addRegulationsValidation';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { regulationsService } from '../../../../services/regulationsService';
import { areaService } from '../../../../services/areaService';
import CodeEditor from '../../../../components/CodeEditor/CodeEditor';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import signalClasses from '../index.module.css';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

const Regulations = () => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const initialData = {
        data: {
            name: '',
            area: '',
            dataSource: '',
            deltaLoadFunction: '',
            bidCollection: '',
            powerDataSource: '',
        },
    };
    const [regulationsTableData, setRegulationsTableData] = useState([]);
    const [addRegulationModal, setAddRegulationModal] = useState(false);
    const [areaTypes, setAreaTypes] = useState([]);
    const [jobType, setJobType] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        regulationsId: '',
    });
    const [createEditRegulation, setCreateEditRegulation] = useState(initialData);
    const [editRegulationModal, setEditRegulationModal] = useState({
        status: false,
        regulationsId: '',
    });
    const [dataSourceList, setDataSourceList] = useState([]);

    useEffect(() => {
        fetchRegulations();
        fetchAreas();
        // fetchJob();
        fetchDataSourceList();
    }, []);

    const fetchAreas = () => {
        areaService.ReadAll(
            () => startLoader('getAreasSignals'),
            handleAreaSuccess,
            handleError,
            () => stopLoader('getAreasSignals')
        );
    };
    const fetchRegulations = () => {
        regulationsService.ReadAll(
            () => startLoader('getRegulations'),
            handleSuccess,
            handleError,
            () => stopLoader('getRegulations')
        );
    };

    const fetchDataSourceList = () => {
        regulationsService.readDataSourceList(
            () => startLoader('fetchDataSourceList'),
            handleDataSourceListSuccess,
            handleError,
            () => stopLoader('fetchDataSourceList')
        );
    };

    const handleDataSourceListSuccess = ({ data }) => {
        setDataSourceList(
            data.data.map((item) => ({
                label: item,
                value: item,
            }))
        );
    };

    const handleAreaSuccess = ({ data }) => {
        setAreaTypes(
            data.data.map((item) => ({
                label: item.name,
                value: item.name,
            }))
        );
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            ...key,
            's.no': i + 1,
            title: key.name,
            deltaFunctionAvailable: (
                <div className={signalClasses.DataMonitoringContainer}>
                    {!!key.deltaLoadFunction ? <div className={signalClasses.AccessNotBlocked}></div> : <div className={signalClasses.AccessBlocked}></div>}
                </div>
            ),
            action: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)', marginRight: '0.5vw' }}
                            onClick={() => {
                                setCreateEditRegulation({
                                    data: {
                                        name: key.name,
                                        area: key.area,
                                        dataSource: key?.dataSource,
                                        deltaLoadFunction: key?.deltaLoadFunction,
                                        bidCollection: key?.bidCollection,
                                        powerDataSource: key?.powerDataSource,
                                    },
                                });
                                setEditRegulationModal({
                                    status: true,
                                    regulationsId: key._id,
                                });
                            }}
                        />
                    </CustomTooltip>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, regulationsId: key._id })} />
                    </CustomTooltip>
                </div>
            ),
        }));
        setRegulationsTableData(processedData);
    };

    const handleSubmit = (values) => {
        setCreateEditRegulation(initialData);
        if (typeof values.dataSource === 'object') values.dataSource = values.dataSource.value;
        if (typeof values.bidCollection === 'object') values.bidCollection = values.bidCollection.value;
        if (typeof values.powerDataSource === 'object') values.powerDataSource = values.powerDataSource.value;
        const payload = { ...values };
        if (editRegulationModal.status) {
            regulationsService.updateRegulation(
                editRegulationModal.regulationsId,
                payload,
                () => startLoader('updateRegulations'),
                handleEditSuccess,
                handleError,
                () => stopLoader('updateRegulations')
            );
            return;
        }
        let params = {};
        regulationsService.createRegulations(
            payload,
            params,
            () => startLoader('createRegulations'),
            handleCreateSuccess,
            handleError,
            () => stopLoader('createRegulations')
        );
    };

    const handleCreateSuccess = (res) => {
        fetchRegulations();
        toast.success('regulation created sucessfully');
        setAddRegulationModal(false);
    };
    const handleEditSuccess = (res) => {
        fetchRegulations();
        toast.success('regulation updated sucessfully');
        setEditRegulationModal({ status: false, regulationsId: '' });
    };

    const handleDelete = () => {
        if (deleteModal.regulationsId) {
            regulationsService.deleteRegulations(
                deleteModal.regulationsId,
                startLoader,
                () => {
                    fetchRegulations();
                    toast.success('regulation deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div className={classes.AllDevices}>
            <ModalComponent
                isOpen={addRegulationModal || editRegulationModal.status}
                setOpen={addRegulationModal ? setAddRegulationModal : setEditRegulationModal}
                style={{ overflow: 'initial' }}
            >
                <Typography content={addRegulationModal ? 'Add Regulations' : 'Edit Regulations'} />
                <div className={classes.SupportNoteForm} style={{ minWidth: '28vw' }}>
                    <Formik
                        initialValues={createEditRegulation.data}
                        enableReinitialize
                        validationSchema={addRegulationsValidation}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        <div>
                                            <div>
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl2}>
                                                        <label for="name">
                                                            Name <span className="required">*</span>
                                                        </label>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            disabled={editRegulationModal.status}
                                                            setFieldValue={props.setFieldValue}
                                                            setFieldTouched={props.setFieldTouched}
                                                            getFieldMeta={props.getFieldMeta}
                                                            style={{ marginTop: '0' }}
                                                        />
                                                    </div>
                                                    <div className={classes.FieldControl2}>
                                                        <label for="area">
                                                            Area <span className="required">*</span>
                                                        </label>
                                                        <div>
                                                            <Dropdown
                                                                name="area"
                                                                id="area"
                                                                options={areaTypes}
                                                                defaultValue={
                                                                    createEditRegulation?.data?.area
                                                                        ? {
                                                                              label: createEditRegulation.data.area,
                                                                              value: createEditRegulation.data.area,
                                                                          }
                                                                        : areaTypes[0]
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl2}>
                                                        <label for="dataSource">Data Source (Meta)</label>
                                                        <div>
                                                            <Dropdown
                                                                name="dataSource"
                                                                id="dataSource"
                                                                defaultValue={{
                                                                    label: createEditRegulation.data.dataSource,
                                                                    value: createEditRegulation.data.dataSource,
                                                                }}
                                                                options={dataSourceList}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={classes.FieldControl2}>
                                                        <label for="dataSource">Bid Collection</label>
                                                        <div>
                                                            <Dropdown
                                                                name="bidCollection"
                                                                id="bidCollection"
                                                                defaultValue={{
                                                                    label: createEditRegulation.data.bidCollection,
                                                                    value: createEditRegulation.data.bidCollection,
                                                                }}
                                                                options={dataSourceList}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classes.FieldControl2}>
                                                    <label for="dataSource">Data Source (Power)</label>
                                                    <div>
                                                        <Dropdown
                                                            name="powerDataSource"
                                                            id="powerDataSource"
                                                            defaultValue={{
                                                                label: createEditRegulation.data.powerDataSource,
                                                                value: createEditRegulation.data.powerDataSource,
                                                            }}
                                                            options={dataSourceList}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="deltaLoadFunction">Delta load function</label>
                                                    <div>
                                                        <CodeEditor
                                                            defaultValue={createEditRegulation?.data?.deltaLoadFunction || ''}
                                                            value={values?.deltaLoadFunction || ''}
                                                            setFieldValue={props.setFieldValue}
                                                            getFieldMeta={props.getFieldMeta}
                                                            name="deltaLoadFunction"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classes.ButtonContainer}>
                                                <div>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            addRegulationModal
                                                                ? setAddRegulationModal(false)
                                                                : setEditRegulationModal({
                                                                      status: false,
                                                                      regulationsId: '',
                                                                  });
                                                            setCreateEditRegulation(initialData);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                <div>
                    <Typography content="Regulations" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{regulationsTableData.length}</span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddRegulationModal(true)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Name', 'Area', 'Data Sorurce (Power)', 'Data Source (Meta)', 'Bid Collection', 'Delta Function Available', 'Action']}
                    keys={['s.no', 'title', 'area', 'powerDataSource', 'dataSource', 'bidCollection', 'deltaFunctionAvailable', 'action']}
                    data={regulationsTableData}
                />
            </div>
        </div>
    );
};

export default Regulations;
