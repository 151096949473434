import React, { useContext, useEffect, useState } from 'react';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';
import { UilTrash } from '@iconscout/react-unicons';
import { CronService } from '../../../../../services/cronServices';
import { instancePowerRuleValidation } from '../../../../../validations/Root/cronJobsValidation';
import DropDown from '../../../../../components/Inputs/Dropdown';
import { Form, Formik } from 'formik';
import { LoaderContext } from '../../../../../context/LoaderContext';
import { toast } from 'react-toastify';
import cronClasses from '../CronJobs.module.css';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import Table from '../../../../../components/Table/Table';
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import { START_LOADER, STOP_LOADER } from '../../../../../constants';
import { ToggleButton } from '../../../../../components/Inputs/Input';
import { AdminService } from '../../../../../services/AdminService';

const InstancePowerCheckRule = ({ config }) => {
    const [addFormModal, setAddFormModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [adminOption, setAdminOption] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [customerOption, setCustomerOption] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [licenseOption, setLicenseOption] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState({});
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [ruleData, setRuleData] = useState(config);

    const initialValues = {
        adminId: '',
        customerId: '',
        licenseId: '',
        instanceId: '',
        deviceControl: false,
    };

    useEffect(() => {
        getAdmin();
    }, []);

    useEffect(() => {
        if (selectedAdmin?.value) {
            getCustomers();
        }
    }, [selectedAdmin]);

    useEffect(() => {
        if (selectedCustomer?.license) {
            setLicenseOption(selectedCustomer?.license);
        } else {
            setLicenseOption([]);
        }
    }, [selectedCustomer]);

    const getAdmin = () => {
        AdminService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const getCustomers = () => {
        AdminService.GetCustomers(selectedAdmin.value, startLoader, handleGetCustomersSuccess, handleError, stopLoader);
    };

    const handleGetCustomersSuccess = ({ data }) => {
        let temp = [];
        data?.data.map((item) => {
            let license = [];
            for (const key in item.license) {
                if (item.license[key].enabled) {
                    license.push({
                        label: key,
                        value: item.license[key].id,
                    });
                }
            }
            temp.push({
                label: item.name,
                value: item._id,
                license: license,
            });
        });
        setCustomerOption(temp);
    };

    const handleSuccess = ({ data }) => {
        let temp = [];
        data?.data.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setAdminOption(temp);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    useEffect(() => {
        if (ruleData?._id) {
            getRules();
        }
    }, []);

    const getRules = () => {
        CronService.GetRule(
            { id: ruleData?._id, type: 'instancePowerCheckRules' },
            () => startLoader('getRule'),
            handleGetRuleSuccess,
            handleError,
            () => stopLoader('getRule')
        );
    };

    const handleGetRuleSuccess = ({ data }) => {
        const processedData =
            data?.data &&
            data?.data?.map((item, index) => ({
                's.no': index + 1,
                admin: item?.properties?.rules?.admin[0],
                customer: item?.properties?.rules?.customer[0],
                license: item?.properties?.rules?.license[0],
                deviceControl: (
                    <div className={classes.ProfileIconContainer}>
                        {!item?.properties?.rules?.deviceControl ? <div className={classes.AccessBlocked}></div> : <div className={classes.AccessNotBlocked}></div>}
                    </div>
                ),
                action: (
                    <div className="cronActionConatiner">
                        <UilTrash
                            size={'1.2vw'}
                            onClick={() =>
                                setDeleteModal({
                                    status: true,
                                    id: item?.properties?.rules?.id,
                                })
                            }
                            style={{ color: 'var(--color-primary)' }}
                        />
                    </div>
                ),
            }));
        processedData ? setTableData(processedData) : setTableData([]);
    };

    const handleSubmit = (value) => {
        value['_id'] = config._id;
        CronService.AddRule(
            value,
            () => startLoader('addRule'),
            handleAddRuleSuccess,
            handleError,
            () => stopLoader('addRule')
        );
    };

    const handleAddRuleSuccess = () => {
        toast.success('Rule added!');
        setAddFormModal(false);
        if (ruleData?._id) {
            getRules();
        }
    };
    const DeleteRule = () => {
        CronService.DeleteRule(
            ruleData._id,
            deleteModal.id,
            () => startLoader('deleteRule'),
            handleDeleteRuleSuccess,
            handleError,
            () => stopLoader('deleteRule')
        );
    };

    const handleDeleteRuleSuccess = () => {
        toast.success('Rule deleted!');
        getRules();
    };
    return (
        <div>
            <DeleteModal deletefunction={DeleteRule} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}></DeleteModal>
            <div>
                <div className={classes.AllDevices}>
                    <ModalComponent isOpen={addFormModal} setOpen={(status) => setAddFormModal(status)} style={{ overflow: 'initial' }}>
                        <div style={{ height: 'auto', width: '20vw' }}>
                            <div className={cronClasses.ModalWrapper}>
                                <Typography content={'Add Rule'} />
                            </div>

                            <div className={classes.FormContent}>
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={instancePowerRuleValidation}
                                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                                >
                                    {({ errors, touched, values, isValidating, ...props }) => {
                                        return (
                                            <Form>
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl}>
                                                        <div>
                                                            <label className={cronClasses.label}>
                                                                Select Admin <span className="required">*</span>
                                                            </label>
                                                            <DropDown name="adminId" options={adminOption} onChange={setSelectedAdmin} />
                                                        </div>
                                                    </div>
                                                    <div className={classes.FieldControl}>
                                                        <div>
                                                            <label className={cronClasses.label}>
                                                                Select Customer <span className="required">*</span>
                                                            </label>
                                                            <DropDown name="customerId" options={customerOption} onChange={setSelectedCustomer} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={classes.InputContainer2}>
                                                    <div className={classes.FieldControl}>
                                                        <label className={cronClasses.label}>
                                                            Select License <span className="required">*</span>
                                                        </label>
                                                        <DropDown name="licenseId" options={licenseOption} onChange={setSelectedLicense} />
                                                    </div>
                                                    <div className={classes.FieldControl}>
                                                        <ToggleButton values={values} label={'Device Control'} name={'deviceControl'} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ display: 'flex', gap: '0.4vw' }}>
                                                        <button className="btn-secondary" onClick={() => setAddFormModal(false)}>
                                                            Cancel
                                                        </button>
                                                        <button type="submit" className="btn-primary">
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </ModalComponent>
                    <div className={classes.Header}>
                        <div>
                            <Typography content="Instance Power Check Rules" />
                            <div className={classes.TableCount}>
                                Total Count :
                                <span>
                                    <Typography size="14" content={tableData.length} />
                                </span>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddFormModal(true)}>
                                    Add Rule
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Table
                            head={['S.No', 'Admin', 'Customer', 'License', 'Device Control', 'Action']}
                            keys={['s.no', 'admin', 'customer', 'license', 'deviceControl', 'action']}
                            data={tableData}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InstancePowerCheckRule;
