import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const CreateApiValidation = yup.object({
    name: yup.string().min(3).max(70).required(),
    isActive: yup.boolean().required(),
    access: yup.object({
        edge: yup.boolean(),
    }).optional(),
    edgeId: yup.string().when(['access.edge', 'access.gateway'], {
        is: true,
        then: yup.string().required(ERRORS.required),
        otherwise: yup.string().notRequired(),
    }),

});
