import { HttpClient } from '../utils/httpClient';

const PATH = {
    error: '/root/logs/error',
    trigger: '/root/logs/trigger',
    access: '/root/logs/access',
    external: '/root/logs/external',
    edge: '/root/logs/edge'
};

const Error = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.error, { params }).then(callback).catch(error).finally(next);
};

const Trigger = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.trigger, { params }).then(callback).catch(error).finally(next);
};

const Access = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.access, { params }).then(callback).catch(error).finally(next);
};

const ExternalAPI = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.external, { params }).then(callback).catch(error).finally(next);
};

const EdgeAPI = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.edge, { params }).then(callback).catch(error).finally(next);
};

export const LogsService = {
    Error,
    Trigger,
    Access,
    ExternalAPI,
    EdgeAPI
};
