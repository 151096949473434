import React, { useEffect, useState } from 'react';

import Typography from '../../../../../components/Typography/Typography';
import { RootGlobalActivationService } from '../../../../../services/RootGlobalActivationService';
import Dropdown from '../../../../../components/Inputs/Dropdown';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

// css module imports
import classes from '../../../../../styles/AllDevices.module.css';
import forceActivationClasses from '../forceActivation.module.css';
import { useLoader } from '../../../../../hooks';
import { ForceActivationValidation } from '../../../../../validations/Root/globalForceActivation';
import DatePickerWithFormik from '../../../../../components/Inputs/DatePicker/DatePickerWithFormik';
import { momentTimeFormater } from '../../../../../utils/timeHelper';

const statusOptions = [
    {
        label: 'Default',
        value: 'DEFAULT',
    },
    {
        label: 'Enabled',
        value: 'ACTIVATE',
    },

    {
        label: 'Disabled',
        value: 'DEACTIVATE',
    },
];

export const UpdateStatus = ({ setOpen, getData }) => {
    const [startLoader, stopLoader] = useLoader();

    const initialValues = {
        status: '',
        bidDate: null,
        startDate: null,
        endDate: null,
    };

    const handleSubmit = (val) => {
        const payload = {
            bidDate: val.bidDate && val.status === 'ACTIVATE' ? momentTimeFormater(val.bidDate).format('YYYY-MM-DD') : null,
            startDate: val.startDate && val.status !== 'DEFAULT' ? momentTimeFormater(val.startDate).format('YYYY-MM-DD') : null,
            endDate: val.endDate && val.status !== 'DEFAULT' ? momentTimeFormater(val.endDate).format('YYYY-MM-DD') : null,
            status: val.status,
        };
        RootGlobalActivationService.ForceActivation(payload, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = () => {
        toast.success('Updated.');
        setOpen(false);
        getData();
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <div style={{ width: '30vw' }}>
                <Typography content={'Update Activation Status'} />
                <div className={classes.SupportNoteForm}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={ForceActivationValidation}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        <div>
                                            <div>
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl2}>
                                                        <label>
                                                            Status <span className="required">*</span>
                                                        </label>
                                                        <div>
                                                            <Dropdown name="status" id="status" defaultValue={statusOptions[0]} options={statusOptions} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {values.status === 'ACTIVATE' ? (
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl2}>
                                                        <label>
                                                            Bid Date <span className="required">*</span>
                                                        </label>
                                                        <div className="modal-date-picker">
                                                            <DatePickerWithFormik name="bidDate" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                ''
                                            )}

                                            {values.status === 'DEFAULT' ? (
                                                ''
                                            ) : (
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl2}>
                                                        <label>
                                                            Validity (Start Date) <span className="required">*</span>
                                                        </label>
                                                        <div className="modal-date-picker">
                                                            <DatePickerWithFormik name={'startDate'} />
                                                        </div>
                                                    </div>
                                                    <div className={classes.FieldControl2}>
                                                        <label>
                                                            Validity (End Date) <span className="required">*</span>
                                                        </label>
                                                        <div className="modal-date-picker">
                                                            <DatePickerWithFormik name={'endDate'} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <div className={classes.ButtonContainer}>
                                                <div>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={(e) => {
                                                            setOpen({ status: false });
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};
