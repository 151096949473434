import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/activation-profile',
    create: '/root/activation-profile',
    update: '/root/activation-profile',
    delete: '/root/activation-profile',
    activation: '/root/activation-profile/activation-log',
    getInstance: '/root/activation-profile/instance',
    getCustomer: '/root/activation-profile/customer',
    GetOptionsFilters: '/root/activation-profile/filters',
    getSelectedProfile: '/root/activation-profile/selected',
    event: '/root/activation-profile/event',
    energinetFormat: '/root/activation-profile/energinet-format',
    intMeta: '/root/activation-profile/int-meta',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const GetActivation = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.activation, { params }).then(callback).catch(error).finally(next);
};

const GetInstance = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getInstance, { params }).then(callback).catch(error).finally(next);
};

const GetCustomers = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getCustomer).then(callback).catch(error).finally(next);
};

const GetOptionsFilters = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.GetOptionsFilters).then(callback).catch(error).finally(next);
};

const GetSelectedProfile = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getSelectedProfile, { params }).then(callback).catch(error).finally(next);
};

export const getAllEvents = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.event, { params }).then(callback).catch(error).finally(next);
};

export const getDataLog = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.event}/${id}/data-log`, { params }).then(callback).catch(error).finally(next);
};

export const getEnerginetFormatData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.energinetFormat}`, { params }).then(callback).catch(error).finally(next);
};

export const getAllIntMeta = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.intMeta, { params }).then(callback).catch(error).finally(next);
};

export const RootActivationProfileService = {
    ReadAll,
    Create,
    Update,
    Delete,
    GetActivation,
    GetInstance,
    GetCustomers,
    GetOptionsFilters,
    GetSelectedProfile,
    getAllEvents,
    getDataLog,
    getEnerginetFormatData,
    getAllIntMeta,
};
