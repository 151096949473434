// Standard library imports
import React from 'react';

// Extenal library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import { Input } from '../../../components/Inputs/Input';
import { EdgeScheduleTestSave } from '../../../validations/Edge/ScheduleTestValidator';
import { EdgeService } from '../../../services/EdgeService';
import { useLoader } from '../../../hooks';

// Css imports
import classes from '../../../styles/CreateDevice.module.css';

const SaveProfileTest = ({ profileTest, setSaveTestModal, isPower, market }) => {
    const [startLoader, stopLoader] = useLoader();

    const handleSubmit = (values) => {
        const payload = {
            name: values.testName,
            profile: profileTest,
            isPower: isPower,
            market,
        };
        EdgeService.SaveTest(payload, startLoader, handleSaveTestSuccess, handleError, stopLoader);
    };

    const handleSaveTestSuccess = () => {
        toast.success('Test saved!');
        setSaveTestModal(false);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <Formik
                enableReinitialize
                initialValues={{
                    testName: '',
                }}
                onSubmit={handleSubmit}
                validationSchema={EdgeScheduleTestSave}
            >
                {({ errors, touched, values, isValidating, ...props }) => {
                    return (
                        <Form>
                            <Typography content={`Save Test`} size="16" />

                            <div className={classes.FieldControl2}>
                                <label>
                                    Test Name <span className="required">*</span>
                                </label>
                                <Input name={`testName`} type="text" />
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setSaveTestModal(false)}>
                                        Close
                                    </button>
                                </div>

                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default SaveProfileTest;
