// Standard Library Imports
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
// External library imports
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { UilCopy } from '@iconscout/react-unicons';
// Internal module imports
import Table from '../../../components/Table/Table';
import Typography from '../../../components/Typography/Typography';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import DownloadButton from '../../../components/Buttons/DownloadButton';

// CSS Imports
import classes from '../../../styles/Bids.module.css';
import EdgeClasses from '../Edge.module.css';

const SystemInfo = ({ data, setOpen }) => {
    const [processedData, setProcessedData] = useState({});
    useEffect(() => {
        const tempData = [
            {
                name: 'Alias',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.alias || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.alias} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Host Name',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.hostName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.hostName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },

            {
                name: 'VM Name',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.vmName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.vmName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Teamviewer ID',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.teamviewer?.id || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.teamviewer?.id} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Teamviewer Password',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.teamviewer?.password || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.teamviewer?.password} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Anydesk ID',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.anydesk?.id || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.anydesk?.id} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Anydesk Password',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.anydesk?.password || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.anydesk?.password} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Device PIN',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.devicePin || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.devicePin} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Device Password',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.devicePassword || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.devicePassword} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'VM User Name',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.vmUserName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.vmUserName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'VM Password',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.vmPassword || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.vmPassword} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Laptop Serial Number',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.laptopSerialNumber || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.laptopSerialNumber} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Router Serial Number',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.routerSerialNumber || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.routerSerialNumber} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Location',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.location || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.location} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Sim Card Number',
                value: (
                    <div className={EdgeClasses.TableValueParent}>
                        <div className={EdgeClasses.TableValue}>{data?.properties?.simCardNumber || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.properties?.simCardNumber} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
        ];
        setProcessedData(tempData);
    }, [data]);

    const handleDownloadExcel = () => {
        const processedData = [
            {
                alias: data?.alias || '--',
                hostName: data?.hostName,
                vmName: data?.vmName || '--',
                teamviewerId: data?.properties?.teamviewer?.id || '--',
                teamviewerPassword: data?.properties?.teamviewer?.password || '--',
                anydeskId: data?.properties?.anydesk?.id || '--',
                anydeskPassword: data?.properties?.anydesk?.password || '--',
                devicePin: data?.properties?.devicePin || '--',
                password: data?.properties?.devicePassword || '--',
                vmUserName: data?.properties?.vmUserName || '--',
                vmPassword: data?.properties?.vmPassword || '--',
                laptopSerialNumber: data?.properties?.laptopSerialNumber || '--',
                routerSerialNumber: data?.properties?.routerSerialNumber || '--',
                location: data?.properties?.location || '--',
                simCardNumber: data?.properties?.simCardNumber || '--',
            },
        ];

        DownloadAsExcel(processedData, `System Info`, [
            'Alias',
            'Host Name',
            'VM Name',
            'Teamviewer ID',
            'Teamviewer Password',
            'Anydesk ID',
            'Anydesk Password',
            'Device PIN',
            'Device Password',
            'VM User Name',
            'VM Password',
            'Laptop Serial Number',
            'Router Serial Number',
            'Location',
            'Sim card Number',
        ]);
    };

    return (
        <div style={{ minWidth: '40vw', overflow: 'auto', height: '70vh' }}>
            <div>
                <div className={EdgeClasses.EdgeInfoModalHeader}>
                    <Typography content={'System Info'} />
                    <div>
                        {' '}
                        <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} />
                    </div>
                </div>
                <div>
                    <Table head={['Label', 'Value', 'Action']} keys={['name', 'value', 'action']} data={processedData} />
                </div>
                <div className={classes.ButtonContainer}>
                    <div>
                        <button type="submit" className="btn-primary" onClick={() => setOpen({ data: {}, status: false })}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SystemInfo;
