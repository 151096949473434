import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/metaData',
    update: '/root/metaData',
    createSignal: '/root/priceSignal',
    readAllSignal: '/root/priceSignal',
    deleteSignal: '/root/priceSignal',
};

//Patch request for updating a version history (id as a param)
const createSignal = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createSignal}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();

    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

const readAllSignal = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAllSignal, { params: params }).then(callback).catch(error).finally(next);
};

//Patch request for updating a version history (id as a param)
const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const DeleteSignal = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteSignal}/${id}`, { params }).then(callback).catch(error).finally(next);
};

export const metaDataServiceForRoot = {
    ReadAll,
    Update,
    createSignal,
    readAllSignal,
    DeleteSignal,
};
