import * as yup from 'yup';

export const FrequencyConfigValidator = yup.object({
    lowVisible: yup
        .number()
        .required()
        .test('lower-than-high-Visible-test', 'low Visible should be lower than high Visible', function (value, context) {
            if (value < context.parent.highVisible) return true;
            if (value > context.parent.highVisible) return false;
        }),
    highVisible: yup
        .number()
        .required()
        .test('higher-than-low-Visible', 'high Visible should be higher than low Visible', function (value, context) {
            if (value > context.parent.lowVisible) return true;
            if (value < context.parent.lowVisible) return false;
        }),
    enableLineMin: yup.boolean(),
    enableLineMax: yup.boolean(),
    lineMin: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when('enableLineMin', {
            is: true,
            then: yup.number().required(),
        })
        .test('lower-than-line-Max-test', 'line min should be lower than line Max', function (value, context) {
            if (context.parent.enableLineMin && context.parent.enableLineMax) {
                if (value > context.parent.lineMax) return false;
                if (value < context.parent.lineMax) return true;
            } else {
                return true;
            }
        })
        .test('lower-than-high-visible-test', 'line min should be lower than high Visible', function (value, context) {
            if (context.parent.enableLineMin) {
                if (value > context.parent.highVisible) return false;
                if (value < context.parent.highVisible) return true;
            } else {
                return true;
            }
        }),
    lineMax: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when('enableLineMax', {
            is: true,
            then: yup.number().required(),
        })
        .test('lower-than-high-Visible', 'line Max should be lower than high Visible', function (value, context) {
            if (context.parent.enableLineMax) {
                if (value < context.parent.highVisible) return true;
                if (value > context.parent.highVisible) return false;
            } else {
                return true;
            }
        })
        .test('greater-than-line-Min-test', 'line max should be greater than line Min', function (value, context) {
            if (context.parent.enableLineMax && context.parent.enableLineMin) {
                if (value < context.parent.lineMin) return false;
                if (value > context.parent.lineMin) return true;
            } else {
                return true;
            }
        }),
});
