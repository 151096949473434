export const DownloadFileFromBuffer = async (buffer, fileName, fileType) => {
    const blob = new Blob([buffer], {
        type: fileType,
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
