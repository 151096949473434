// Standard Library imports
import React from 'react';

// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import CustomerMigration from './CustomerMigration';

const Migration = ({ tabId }) => {
    let tabs = [
        {
            name: 'Customer',
            component: (
                <>
                    <CustomerMigration />
                </>
            ),
            tabId: tabId,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default Migration;
