import _ from 'lodash';

const nextChar = (c) => (c ? String.fromCharCode(c.charCodeAt(0) + 1) : 'A');

export const setCellData = (sheet, cellName, value, style = {}) => {
    const cell = sheet.getCell(cellName);
    if (value !== null && value !== undefined) cell.value = value;
    _.keys(style).forEach((key) => {
        cell[key] = style[key];
    });
};

export const nextCol = (s) => s.replace(/([^Z]?)(Z*)$/, (_, a, z) => nextChar(a) + z.replace(/Z/g, 'A'));
