// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    forceActivation: '/root/global-force-activation/force-activation',
};

const ForceActivation = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.forceActivation, payload).then(callback).catch(error).finally(next);
};

const GetForceActivation = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.forceActivation).then(callback).catch(error).finally(next);
};

export const RootGlobalActivationService = {
    ForceActivation,
    GetForceActivation,
};
