// Standard Library Imports
import React from 'react';

// External library imports
import { Form, Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';
import moment from 'moment';

// Internal library imports
import { useLoader } from '../../../hooks';
import Dropdown from '../../../components/Inputs/Dropdown';
import { Input, TextArea } from '../../../components/Inputs/Input';
import Typography from '../../../components/Typography/Typography';
import { edgeShipmentValidation } from '../../../validations/Edge/edgeShipment';
import { EdgeShipmentService } from '../../../services/EdgeShipmentService';

// Css imports
import classes from '../../../styles/AllDevices.module.css';

const ShippingAssetModal = ({ customerId, setOpen, assetOptions, packageTypeCodeOptions }) => {
    const [startLoader, stopLoader] = useLoader();

    const assetUnitOptions = [
        { label: 'Kilogram', value: 'KGM' },
        { label: 'Gram', value: 'GRM' },
        { label: 'Ton', value: 'TON' },
    ];

    const initialValues = {
        consignee: {
            consigneeName: '',
            consigneeContactName: '',
            consigneeStreet1: '',
            consigneeStreet2: '',
            consigneePostalCode: '',
            consigneeCountryCode: 'DK',
            consigneeCity: '',
            consigneeEmail: '',
            consigneePhone: '',
        },
        packageTypeCode: 'PC',
        assetWeight: '',
        assetUnit: 'KGM',
        assets: [{ assetId: '' }],
        notes: '',
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSuccess = (toastData) => {
        toast.success(toastData);
        setOpen(false, true);
    };

    const handleSubmit = (values) => {
        if (customerId) {
            EdgeShipmentService.CreateShipmentAsset(
                { customerId, ...values, date: moment() },
                () => startLoader('edgeShipmentCreate'),
                () => handleSuccess('Shipping asset created sucessfully'),
                handleError,
                () => stopLoader('edgeShipmentCreate')
            );
        }
    };

    return (
        <div className={classes.ModalContainer}>
            <Typography content={'Create Shipment'} />
            <div className={classes.SupportNoteForm}>
                <Formik initialValues={initialValues} enableReinitialize={true} validationSchema={edgeShipmentValidation} onSubmit={handleSubmit}>
                    {({ ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeName">
                                                    Consignee Name
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="consignee.consigneeName" id="consigneeName" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeContactName">
                                                    Consignee Contact Name
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="consignee.consigneeContactName" id="consigneeContactName" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeStreet1">
                                                    Consignee Street Address 1<span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="consignee.consigneeStreet1" id="consigneeStreet1" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeStreet2">Consignee Street Address 2</label>
                                                <div>
                                                    <Input name="consignee.consigneeStreet2" id="consigneeStreet2" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneePostalCode">
                                                    Consignee Postal Code
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="consignee.consigneePostalCode" id="consigneePostalCode" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeCountryCode">
                                                    Consignee Country Code
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Dropdown
                                                        name="consignee.consigneeCountryCode"
                                                        id="consigneeCountryCode"
                                                        options={[{ label: 'DK', value: 'DK' }]}
                                                        defaultValue={{ label: 'DK', value: 'DK' }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeCity">
                                                    Consignee City
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="consignee.consigneeCity" id="consigneeCity" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneeEmail">Consignee Email</label>
                                                <div>
                                                    <Input name="consignee.consigneeEmail" id="consigneeEmail" type="text" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="consigneePhone">Consignee Phone (+4685586363)</label>
                                                <div>
                                                    <Input name="consignee.consigneePhone" id="consigneePhone" type="text" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                        </div>
                                        <FieldArray name="assets">
                                            {({ push, remove, form }) => {
                                                const assets = form.values.assets;
                                                return (
                                                    <div>
                                                        <div>
                                                            <div className={classes.InputContainer}>
                                                                <div className={classes.FieldControl2}>
                                                                    <label>
                                                                        Assets
                                                                        <span className="required">*</span>
                                                                    </label>
                                                                    {assets.map((asset, index) => (
                                                                        <div className={classes.InputContainer} style={{ marginBottom: '0.5vw' }}>
                                                                            <div className={classes.FieldControl2}>
                                                                                <Dropdown
                                                                                    name={`assets[${index}].assetId`}
                                                                                    id="assetId"
                                                                                    options={assetOptions}
                                                                                    defaultValue={assetOptions[0]}
                                                                                />
                                                                            </div>
                                                                            <div className={classes.FieldControl2}>
                                                                                <Input
                                                                                    name={`assets[${index}].quantity`}
                                                                                    id="quantity"
                                                                                    type="number"
                                                                                    label="Enter Quantity"
                                                                                    style={{ marginTop: 0 }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={classes.addInputActionWrapper}
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row-reverse',
                                                                gap: '0.5vw',
                                                                paddingTop: '1%',
                                                            }}
                                                        >
                                                            <span
                                                                onClick={() => {
                                                                    push('');
                                                                }}
                                                            >
                                                                <UilPlusCircle
                                                                    size={'1.5vw'}
                                                                    style={{
                                                                        color: 'var(--color-primary)',
                                                                        cursor: 'pointer',
                                                                        zIndex: '1',
                                                                    }}
                                                                />
                                                            </span>
                                                            {assets.length > 1 && (
                                                                <span onClick={() => remove(assets.length - 1)}>
                                                                    <UilMinusCircle
                                                                        size={'1.5vw'}
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                            cursor: 'pointer',
                                                                            zIndex: '1',
                                                                        }}
                                                                    />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </FieldArray>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="assetWeight">
                                                    Asset Weight
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="assetWeight" id="assetWeight" type="number" style={{ marginTop: 0 }} />
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="assetUnit">
                                                    Asset Unit
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Dropdown name="assetUnit" id="assetUnit" options={assetUnitOptions} defaultValue={assetUnitOptions[0]} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="packageTypeCode">
                                                    Package Type
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Dropdown
                                                        name="packageTypeCode"
                                                        id="packageTypeCode"
                                                        options={packageTypeCodeOptions}
                                                        defaultValue={packageTypeCodeOptions[0]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="groupId">Notes (Won't be sent to Post Nord)</label>
                                                <TextArea
                                                    name="notes"
                                                    label="notes"
                                                    setFieldValue={props.setFieldValue}
                                                    setFieldTouched={props.setFieldTouched}
                                                    getFieldMeta={props.getFieldMeta}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button
                                                className="btn-secondary"
                                                onClick={() => {
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className="btn-primary" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default ShippingAssetModal;
