// External library imports
import * as yup from 'yup';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

export const ForceActivationValidation = () => {
    return yup.object().shape({
        status: yup.string().required(ERRORS.required),
        bidDate: yup.string().when('status', {
            is: 'ACTIVATE',
            then: yup.string().required().typeError(ERRORS.required),
            otherwise: yup.string().nullable(),
        }),
        startDate: yup
            .string()
            .when('status', {
                is: 'DEFAULT',
                then: yup.string().nullable(),
                otherwise: yup.string().required().typeError(ERRORS.required),
            })
            .test('start date', 'Start date should be less than end date', (value, c) => {
                const endDate = c.parent.endDate;
                if (endDate && new Date(endDate) < new Date(value)) {
                    return false;
                } else {
                    return true;
                }
            }),

        endDate: yup
            .string()
            .when('status', {
                is: 'DEFAULT',
                then: yup.string().nullable(),
                otherwise: yup.string().required().typeError(ERRORS.required),
            })
            .test('end date', 'End date should be greater than start date', (value, c) => {
                const startDate = c.parent.startDate;
                if (startDate && new Date(startDate) > new Date(value)) {
                    return false;
                } else {
                    return true;
                }
            }),
    });
};
