// Standard library imports
import React, { useState, useContext } from 'react';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../../components/Tabs/Tabs';
import classes from './Admin.module.css';
import Admins from './Admins';
import CreateAdmin from './CreateAdmin';
import Roots from './Roots/Roots';
import CreateRoot from './Roots/CreateRoot';
import { AuthContext } from '../../../context/AuthContext';
import Enablers from './Enablers/Enablers';
import CreateEnabler from './Enablers/CreateEnabler';
import { tabCount } from '../../../utils/tabCount';
import Migration from './Migration';
import CreateUser from './Admins/AdminUser/CreateUser';

const Admin = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
    });
    const [createEditAdmin, setCreateEditAdmin] = useState({
        status: false,
        data: {},
    });
    const [createEditRoot, setCreateEditRoot] = useState({
        status: false,
        data: {},
    });
    const [createEditEnabler, setCreateEditEnabler] = useState({
        status: false,
        data: {},
    });
    const [adminUser, setAdminUser] = useState({
        status: false,
        data: '',
        adminId: '',
    });
    const [allAdmins, setAllAdmins] = useState([]);

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    const { state } = useContext(AuthContext);

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    const tabslength = state?.user?.userRole === 'root-admin' ? 3 : state?.user?.userRole == 'root' ? 4 : tabCount(location.pathname, state);

    const tabs = [
        {
            name: 'Admins',
            component: (
                <Admins
                    setCreateEditAdmin={setCreateEditAdmin}
                    setSelectedIndex={setSelectedIndex}
                    tabslength={tabslength}
                    setAdminUser={setAdminUser}
                    setAllAdmins={setAllAdmins}
                />
            ),
            tabId: 1,
        },
        ...(state?.user?.userRole == 'root' || state?.user?.userRole === 'root-admin'
            ? [
                {
                    name: 'Enabler',
                    component: (
                        <Enablers setCreateEditEnabler={setCreateEditEnabler} setSelectedIndex={setSelectedIndex} tabslength={tabslength} updateQueryParams={updateQueryParams} />
                    ),
                    tabId: 2,
                },
            ]
            : []),
        ...(state?.user?.userRole == 'root'
            ? [
                {
                    name: 'Root',
                    component: <Roots setCreateEditRoot={setCreateEditRoot} setSelectedIndex={setSelectedIndex} tabslength={tabslength} />,
                    tabId: 3,
                },
            ]
            : []),
        {
            name: 'Migration',
            component: <Migration tabId={state?.user?.userRole === 'root-admin' ? 3 : state?.user?.userRole == 'root' ? 4 : 2} />,
            tabId: state?.user?.userRole === 'root-admin' ? 3 : state?.user?.userRole == 'root' ? 4 : 2,
        },
        ...(createEditAdmin.status
            ? [
                {
                    name: `${createEditAdmin.data?._id ? 'Edit' : 'Create'} Admin`,
                    component: (
                        <CreateAdmin
                            adminData={createEditAdmin.data}
                        // setSelectedIndex={setSelectedIndex}
                        />
                    ),
                    tabId: 1,
                },
            ]
            : []),
        ...(createEditRoot.status
            ? [
                {
                    name: `${createEditRoot.data?._id ? 'Edit' : 'Create'} Root`,
                    component: (
                        <CreateRoot
                            rootData={createEditRoot.data}
                        // setSelectedIndex={setSelectedIndex}
                        />
                    ),
                    tabId: 3,
                },
            ]
            : []),
        ...(createEditEnabler.status
            ? [
                {
                    name: `${createEditEnabler.data?._id ? 'Edit' : 'Create'} Enabler`,
                    component: (
                        <CreateEnabler
                            enablerData={createEditEnabler.data}
                        // setSelectedIndex={setSelectedIndex}
                        />
                    ),
                    tabId: 2,
                },
            ]
            : []),
        ...(adminUser.status
            ? [
                {
                    name: `${adminUser.data?._id ? 'Edit' : 'Create'} User`,
                    component: (
                        <CreateUser
                            editUser={adminUser.data}
                            allAdmins={allAdmins}
                            autoFillAdminId={adminUser?.adminId}
                        // setSelectedIndex={setSelectedIndex}
                        />
                    ),
                    tabId: 2,
                },
            ]
            : []),
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                setEditGroup={() => {
                    setCreateEditAdmin({ status: false, data: {} });
                    setCreateEditEnabler({ status: false, data: {} });
                    setAdminUser({ status: false, data: '', adminId: '' });
                }}
                setEditDevice={() => {
                    setCreateEditRoot({ status: false, data: {} });
                }}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Admin;
