import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const addDataExportValidator = yup.object().shape({
    collection: yup.string().required(ERRORS.required),
    query: yup.object().optional(),
    sort: yup.object().optional(),
    skip: yup.number().optional(),
    limit: yup.number().optional(),
});
