// Standard library imports
import React from 'react';

// External library imports
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import { UilDocumentInfo, UilInfoCircle } from '@iconscout/react-unicons';
// Css imports
import documentClasses from './Documents.module.css';

const DocumentsMenuView = ({ data, onDoubleClick, getInfoDetails = null, updateQueryParams }) => {
    const openDocumentEditor = (e, nodeDatum) => {
        e.stopPropagation();
        onDoubleClick && onDoubleClick(nodeDatum);
    };

    return (
        <div>
            <Accordion allowZeroExpanded allowMultipleExpanded={true}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <div>{data?.name}</div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className={documentClasses.AccordionPanel}>
                        {data?.children?.map((admin) => (
                            <AccordionItem className={documentClasses.AccordionItem}>
                                <AccordionItemHeading className={documentClasses.AccordionHeading}>
                                    <AccordionItemButton>
                                        <div className={documentClasses.TextContainer}>
                                            <div> {admin.name}</div>
                                            <div style={{ display: 'flex', color: 'var(--color-primary)', columnGap: '1vw' }}>
                                                <UilDocumentInfo
                                                    size={'1.2vw'}
                                                    onClick={(e) => {
                                                        openDocumentEditor(e, admin);
                                                        updateQueryParams(admin._id, 'document');
                                                    }}
                                                />
                                                <UilInfoCircle
                                                    size={'1.2vw'}
                                                    onClick={() => {
                                                        getInfoDetails({ type: 'admin', ...admin });
                                                        updateQueryParams(admin._id, 'details', 'admin');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel className={documentClasses.AccordionPanel}>
                                    {admin?.children.map((customer) => (
                                        <AccordionItem className={documentClasses.AccordionItem}>
                                            <AccordionItemHeading className={documentClasses.AccordionHeading}>
                                                <AccordionItemButton>
                                                    <div className={documentClasses.TextContainer}>
                                                        <div>{customer.name}</div>
                                                        <div style={{ display: 'flex', color: 'var(--color-primary)', columnGap: '1vw' }}>
                                                            <UilDocumentInfo
                                                                size={'1.2vw'}
                                                                onClick={(e) => {
                                                                    openDocumentEditor(e, customer);
                                                                    updateQueryParams(customer._id, 'document');
                                                                }}
                                                            />
                                                            <UilInfoCircle
                                                                size={'1.2vw'}
                                                                onClick={() => {
                                                                    getInfoDetails({ type: 'customer', ...customer });
                                                                    updateQueryParams(customer._id, 'details', 'customer');
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className={documentClasses.AccordionPanel}>
                                                {customer?.children.map((license) => (
                                                    <AccordionItem className={documentClasses.AccordionItem}>
                                                        <AccordionItemHeading className={documentClasses.AccordionHeading}>
                                                            <AccordionItemButton>
                                                                <div className={documentClasses.TextContainer}>
                                                                    <div>{license.name}</div>
                                                                    <div style={{ display: 'flex', color: 'var(--color-primary)', columnGap: '1vw' }}>
                                                                        <UilDocumentInfo
                                                                            size={'1.2vw'}
                                                                            onClick={(e) => {
                                                                                openDocumentEditor(e, license);
                                                                                updateQueryParams(license._id, 'document');
                                                                            }}
                                                                        />
                                                                        <UilInfoCircle
                                                                            size={'1.2vw'}
                                                                            onClick={() => {
                                                                                getInfoDetails({ type: 'license', ...license });
                                                                                updateQueryParams(license._id, 'details', 'license');
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={documentClasses.AccordionPanel}>
                                                            {license?.children.map((instance) => (
                                                                <AccordionItem className={documentClasses.AccordionItem}>
                                                                    <AccordionItemHeading className={documentClasses.AccordionHeading}>
                                                                        <AccordionItemButton>
                                                                            <div className={documentClasses.TextContainer}>
                                                                                <div>{instance.name}</div>
                                                                                <div style={{ display: 'flex', color: 'var(--color-primary)', columnGap: '1vw' }}>
                                                                                    <UilDocumentInfo
                                                                                        size={'1.2vw'}
                                                                                        onClick={(e) => {
                                                                                            openDocumentEditor(e, instance);
                                                                                            updateQueryParams(instance._id, 'document');
                                                                                        }}
                                                                                    />
                                                                                    <UilInfoCircle
                                                                                        size={'1.2vw'}
                                                                                        onClick={() => {
                                                                                            getInfoDetails({ type: 'instance', ...instance });
                                                                                            updateQueryParams(instance._id, 'details', 'instance');
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </AccordionItemButton>
                                                                    </AccordionItemHeading>
                                                                    <AccordionItemPanel className={documentClasses.AccordionPanel}></AccordionItemPanel>
                                                                </AccordionItem>
                                                            ))}
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    ))}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    );
};

export default DocumentsMenuView;
