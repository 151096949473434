import * as yup from 'yup';

export const addRegulationsValidation = yup.object().shape({
    name: yup.string().required(),
    area: yup.string().required(),
    dataSource: yup
        .array()
        .nullable()
        .of(
            yup.object().shape({
                label: yup.string(),
                value: yup.string(),
            })
        ),
    deltaLoadFunction: yup.string().nullable(),
});
