import classes from './Login.module.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import LoginPic from '../../assets/LoginPic.svg';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { LoaderContext } from '../../context/LoaderContext';
import { SET_USER, START_LOADER, STOP_LOADER, REACT_APP_CLIENT_DASHBOARD_BASE_URI } from '../../constants';
import { AuthContext } from '../../context/AuthContext';
import { UserService } from '../../services/UserService';
import { toast } from 'react-toastify';
import Logo from '../../assets/logo.svg';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import ForgotPassword from './ForgotPassword';
import { ExternalService } from '../../services/ExternalService';
import { LoginValidation } from '../../validations/Login/LoginValidation';
import CheckboxNoState from '../../components/Inputs/Checkbox/CheckboxNoState';
import { getUsers, saveUser } from '../../utils/localStorage';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';

const Login = (props) => {
    const { dispatch: loaderDispatch, state } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [modalstate, setModalState] = useState(false);
    const { dispatch: authDispatch } = useContext(AuthContext);
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });
    const [logoDN, setLogo] = useState('');
    const [rememberLogin, setRememberLogin] = useState(true);
    const email = useRef('');
    const savedUsers = getUsers();

    useEffect(() => {
        const params = {
            host: window.location.host,
        };
        ExternalService.LogoForHost(
            params,
            () => startLoader('getLogo'),
            handleLogoSuccess,
            handleError,
            () => stopLoader('getLogo')
        );
    }, []);

    useEffect(() => {
        const token = props?.match?.params?.id;
        if (token) {
            localStorage.setItem('GM_TOKEN', token);
            UserService.Verify(
                () => startLoader('adminVerify'),
                handleAdminLoginSuccess,
                handleError,
                () => stopLoader('adminVerify')
            );
        }
    }, []);

    const loginUsingSavedUser = (user) => {
        if (user.token) {
            localStorage.setItem('GM_TOKEN', user.token);
            window.location.reload();
        }
    };

    const handleAdminLoginSuccess = ({ data }) => {
        const user = data.data;
        const toBeSaved = {
            id: user._id,
            email: user.email,
            profileImage: user.profileImage,
            token: props.match.params.id,
        };
        saveUser(toBeSaved);
        window.location.assign('/');
    };

    const handleLogoSuccess = ({ data }) => {
        if (data.data?.logo) setLogo(data.data?.logo);
    };

    const handleError = (err) => {
        console.log(err);
    };

    const handleLogin = (values) => {
        let payload = { user: values };
        UserService.Login(
            payload,
            () => startLoader('sendotp'),
            handleLoginSuccess,
            handleLoginError,
            () => stopLoader('sendotp')
        );
    };

    const handleLoginSuccess = ({ data }) => {
        const {
            success,
            data: { token },
            data: { user },
        } = data;
        if (success) {
            if (!['admin', 'sub-admin', 'root', 'enabler', 'root-admin', 'root-developer'].includes(user?.userRole)) {
                toast.error('Redirecting To Right Portal');
                window.open(`${REACT_APP_CLIENT_DASHBOARD_BASE_URI}/login/${token}`, '_blank');
            } else {
                dispatchSetUser({ user, token });
                toast.success('Login Success');
                if (rememberLogin) {
                    const toBeSaved = {
                        id: user._id,
                        email: user.email,
                        profileImage: user.profileImage,
                        userRole: user.userRole,
                        token: token,
                    };
                    saveUser(toBeSaved);
                }
            }
        }
        window.sessionStorage.setItem('redirectTo', '/');
    };

    const handleLoginError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleOnChange = (event) => {
        if (event.target.id === 'email') email.current = event.target.value;
    };

    return (
        <>
            <ModalComponent
                isOpen={modalstate}
                setOpen={setModalState}
                onClose={() => {
                    setModalState(false);
                }}
            >
                <ForgotPassword setModalState={setModalState} initialValue={email.current} />
            </ModalComponent>
            <div className={classes.Login}>
                <div>
                    <div className={classes.TitleContainer}>
                        <div className={classes.Title}>Total Energy Management System</div>
                        <div className={classes.Subtitle}>
                            Within the European Union, Denmark has remained a front runner in the push towards developing renewable energy sources, as well as enforcing regulations
                            that support the sustainability of their environment.
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <img className={classes.LoginPic} src={LoginPic} alt="" />
                    </div>
                </div>
                <div></div>
                <div className={classes.LoginContainer}>
                    <div>{state?.loading?.includes('getLogo') || logoDN ? <img src={logoDN} alt="" height={60} /> : <img src={Logo} alt="" />}</div>
                    <div className={classes.LoginForm}>
                        <div className={classes.TitleContainer}>
                            <div className={classes.FormTitle}>Login Please</div>
                            <div className={classes.FormSubtitle}>This is a secure system and you will need to provide your login details to access the site.</div>
                        </div>
                        <div className={classes.SavedUserWrapper}>
                            {savedUsers.map((u) => (
                                <div className={classes.SavedUserPreview} onClick={() => loginUsingSavedUser(u)}>
                                    <CustomTooltip
                                        content={
                                            <div>
                                                <div>User Role: {u.userRole}</div>
                                                <div>Email: {u.email}</div>
                                            </div>
                                        }
                                    >
                                        <img src={u.profileImage} alt="profileimage" className={classes.SavedUserImage} />
                                    </CustomTooltip>
                                </div>
                            ))}
                        </div>
                        <Formik initialValues={{ email: '', password: '' }} onSubmit={(val) => handleLogin(val)} validationSchema={LoginValidation}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form onChange={handleOnChange}>
                                    <div className={classes.Form}>
                                        <div>
                                            <Field type="email" name="email" id="email" placeholder="Email" />
                                            <ErrorMessage name="email" component="div" className="error-msg" />
                                        </div>
                                        <div>
                                            <Field type="password" name="password" id="password" placeholder="Password" />
                                            <ErrorMessage name="password" component="div" className="error-msg" />
                                        </div>
                                        <div className={classes.FormForgotAndremember}>
                                            <CheckboxNoState defaultValue={rememberLogin} name="remember" label="Remember Login" onChange={setRememberLogin} />
                                            <div
                                                className={classes.ForgetPassword}
                                                onClick={() => {
                                                    setModalState(true);
                                                }}
                                            >
                                                Forgot password?
                                            </div>
                                        </div>
                                        <button className="btn-primary" style={{ fontSize: '0.8vw' }}>
                                            Sign In
                                        </button>
                                    </div>
                                    <div className={classes.FooterText}>
                                        To Request an account just{' '}
                                        <a href="https://scnordic.com/#contact" target="_blank">
                                            <span className={classes.BlueText}>Email us.</span>
                                        </a>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
