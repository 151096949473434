// Standard Library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { toast } from 'react-toastify';
import { UilBellSlash } from '@iconscout/react-unicons';
import { MenuItem, Menu, MenuDivider, SubMenu } from '@szhsin/react-menu';
import moment from 'moment';

// Internal module imports
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { useLoader } from '../../../../hooks';
import monitoringClasses from './index.module.css';
import { RootMonitoringService } from '../../../../services/RootMonitoringService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { SNOOZE_MINUTES } from '../../../../constants';
import { RootSnoozeService } from '../../../../services/RootSnoozeService';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { AdminService } from '../../../../services/AdminService';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

const LIMIT = 15;
const FILTER_OPTIONS = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Reporting',
        value: 'reporting',
    },
    {
        label: 'Not Reporting',
        value: 'notReporting',
    },
];
const EdgeMonitoring = ({ queryParamsData, updateQueryParams, admins }) => {
    const [edgeData, setEdgeData] = useState([]);
    const [startLoader, stopLoader] = useLoader();

    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(
        queryParamsData.filter
            ? JSON.parse(queryParamsData.filter)
            : {
                label: 'Not Reporting',
                value: 'notReporting',
            }
    );
    const [selectedCustomer, setSelectedCustomer] = useState(
        queryParamsData.customer
            ? JSON.parse(queryParamsData.customer)
            : {
                label: 'All',
                value: 'all',
            }
    );
    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        setSelectedAdmin(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : admins[0]);
    }, [admins]);

    useEffect(() => {
        if (selectedAdmin?.value && selectedAdmin?.value !== 'all') {
            getCustomers();
        }
    }, [selectedAdmin]);

    useEffect(() => {
        if (selectedCustomer?.value) {
            getData();
        }
    }, [selectedCustomer]);

    const getCustomers = () => {
        AdminService.GetCustomers(selectedAdmin?.value, startLoader, handleGetCustomerSuccess, handleError, stopLoader);
    };

    const handleGetCustomerSuccess = ({ data }) => {
        let temp = [
            {
                label: 'All',
                value: 'all',
            },
        ];
        data.data.map((c) => {
            temp.push({
                label: c.name,
                value: c._id,
            });
        });
        setCustomers(temp);
    };

    const getData = () => {
        RootMonitoringService.getEdge(
            {
                customerId: selectedCustomer.value,
            },
            startLoader,
            handleGetDataSuccess,
            handleError,
            stopLoader
        );
    };

    const isSnooze = (data = [], type) => {
        const typeSnoozeStat = data.find((item) => item.type === type);
        if (typeSnoozeStat) {
            const snoozedTime = moment(typeSnoozeStat.timestamp).add(typeSnoozeStat.snoozeForMinutes, 'minutes');
            const isSnoozed = snoozedTime.isAfter(moment().utc());
            if (isSnoozed) {
                return `Snoozed Till (CET): ${momentTimeFormater(snoozedTime).format('YYYY-MM-DD-HH:mm:ss')}`;
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    const snooze = (id, value, type, resource, resourceName) => {
        const payload = {
            resourceId: id,
            type: type,
            snoozeForMinutes: value.value,
            resource,
            resourceName,
        };
        RootSnoozeService.Create(
            payload,
            () => startLoader('snooze'),
            () => handleSnoozeSuccess(value.label),
            handleError,
            () => stopLoader('snooze')
        );
    };

    const handleSnoozeSuccess = (value) => {
        if (value === 'Cancel Snooze') {
            toast.success(`Snooze Canceled.`);
        } else {
            toast.success(`Snooze for ${value}.`);
        }
        getData();
    };

    const handleGetDataSuccess = ({ data }) => {
        const proccessedDataEdge = data?.data?.edgeData.map((edge, index) => ({
            ...edge,
            lastReportedTimestampFormated: edge.lastReportedTimestamp ? momentTimeFormater(edge.lastReportedTimestamp).format('YYYY-MM-DD HH:mm') : '-',
            lastBootTimestampFormated: edge.lastBootTimestamp ? momentTimeFormater(edge.lastBootTimestamp).format('YYYY-MM-DD HH:mm') : '-',
            action: (
                <div className={monitoringClasses.ActionContainer}>
                    <Menu
                        menuButton={
                            <div>
                                <CustomTooltip content={'Snooze'}>
                                    <UilBellSlash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CustomTooltip>
                            </div>
                        }
                        direction={'left'}
                        arrow={true}
                        menuClassName={monitoringClasses.Menu}
                    >
                        <MenuItem>
                            <div className={monitoringClasses.SnoozeHeader}>Snooze</div>
                        </MenuItem>
                        <MenuDivider />
                        <SubMenu
                            label={
                                <div style={{ textAlign: 'left' }}>
                                    <span style={{ fontSize: '0.8vw', fontWeight: 500 }}>Data Monitoring</span>
                                    <br />
                                    <span style={{ color: '#faa500' }}>{isSnooze(edge?.snoozeData, 'dataMonitoring') ? isSnooze(edge?.snoozeData, 'dataMonitoring') : ''}</span>
                                </div>
                            }
                        >
                            {SNOOZE_MINUTES.map((option, index) => (
                                <div>
                                    <MenuItem onClick={() => snooze(edge._id, option, 'dataMonitoring', 'edge', edge.edgeAlias)}>
                                        <div>{option.label}</div>
                                    </MenuItem>
                                    {index < SNOOZE_MINUTES.length - 1 && <MenuDivider />}
                                </div>
                            ))}
                        </SubMenu>
                    </Menu>
                </div>
            ),
            style: !edge.isReported ? { background: '#FFE3E3', color: '#E25657' } : {},
        }));
        setEdgeData(proccessedDataEdge);
        setTotalCount(proccessedDataEdge?.length);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    useEffect(() => {
        getFilterData();
    }, [edgeData, skip, selectedFilter]);

    const getFilterData = () => {
        let temp = [];
        const filterByType = edgeData.filter((e) => {
            if (selectedFilter.value === 'all') {
                return e;
            } else if (selectedFilter.value === 'reporting') {
                return e.isReported === true;
            } else if (selectedFilter.value === 'notReporting') {
                return e.isReported === false;
            }
        });
        const slicedData = filterByType.slice(skip);
        slicedData.map((item, index) => {
            if (temp.length < LIMIT) {
                temp.push(item);
            }
        });
        setFilterData(temp);
        setTotalCount(filterByType.length);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={monitoringClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={monitoringClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select Admin</label>
                                <DropdownComponent
                                    name="adminId"
                                    options={admins}
                                    defaultValue={selectedAdmin}
                                    onChange={(e) => {
                                        setSelectedAdmin(e);
                                        updateQueryParams('admin', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    defaultValue={selectedCustomer}
                                    onChange={(e) => {
                                        if (e.value !== selectedCustomer?.value) {
                                            setSelectedCustomer(e);
                                        }
                                        updateQueryParams('customer', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={monitoringClasses.FilterContainer}>
                        <div className={classes.FieldControl} style={{ minWidth: '10vw' }}>
                            <label>Filter</label>
                            <DropdownComponent
                                name="filter"
                                options={FILTER_OPTIONS}
                                defaultValue={selectedFilter}
                                onChange={(e) => {
                                    setSelectedFilter(e);
                                    updateQueryParams('filter', JSON.stringify(e));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Edge Monitoring" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={monitoringClasses.TableContainer}>
                    <div style={{ width: '100%' }}>
                        <Table
                            head={['Customer', 'Edge', 'Last Reported (CET)', 'Last Boot (CET)', 'Action']}
                            keys={['customerName', 'edgeAlias', 'lastReportedTimestampFormated', 'lastBootTimestampFormated', 'action']}
                            data={filterData}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EdgeMonitoring;
