import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import classes from '../DataHub.module.css';
import DeviceList from './DeviceList';
import Overview from './Overview';
import { DataHubServiceRoot } from '../../../../../services/DataHubService';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';

const Details = ({ groups, processedDevices, setRefresh, stopLoader, startLoader, handleOpen, setEditDevice, updateQueryParams, queryParamsData = {} }) => {
    const [device, setDevice] = useState(queryParamsData?.device ? queryParamsData.device : {});
    const [createGroupModal, setCreateGroupModal] = useState(false);
    const [editGroup, setEditGroup] = useState(false);
    const [open, setOpen] = useState(false);
    const [deleteid, setDeleteId] = useState(null);

    useEffect(() => {
        if (!queryParamsData?.device) {
            processedDevices.every((device, i) => {
                if (device.priceSignal && (device.taxValue || device.taxValue === 0)) {
                    setDevice(device);
                    return false;
                }
                return true;
            });
        }
    }, [processedDevices]);

    const handleDeviceChange = (device) => {
        if (device.priceSignal && (device.taxValue || device.taxValue === 0)) {
            setDevice(device);
            updateQueryParams('device', JSON.stringify(device));
        } else toast.error('Configuration required! Please configure the meter');
    };

    const handleDeleteGroup = (id) => {
        DataHubServiceRoot.deleteGroup(
            id,
            () => startLoader('deleteGroup'),
            handleDeleteGroupSuccess,
            handleError,
            () => stopLoader('deleteGroup')
        );
    };
    const openConfirmModal = (group) => {
        setCreateGroupModal(false);
        setOpen(true);
        setDeleteId(group.id);
    };

    const handleDeleteGroupSuccess = ({ data }) => {
        toast.success('Key Deleted!');
        setOpen(false);
        setRefresh(Math.random() * 3);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <div className={classes.DeviceDashboard}>
                <DeviceList
                    createGroupModal={createGroupModal}
                    setCreateGroupModal={setCreateGroupModal}
                    groups={groups}
                    setDevice={handleDeviceChange}
                    handleDeleteGroup={openConfirmModal}
                    setEditGroup={setEditGroup}
                    editGroup={editGroup}
                />
                <Overview setEditDevice={setEditDevice} device={device} handleOpen={handleOpen} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
            </div>
            <DeleteModal
                opendeleteModal={open}
                setOpenDeleteModal={setOpen}
                deviceId={deleteid}
                deletefunction={handleDeleteGroup}
                text={'Note: Delete key will also delete meters.'}
            />
        </div>
    );
};

export default Details;
