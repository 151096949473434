// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/admin',
    create: '/root/admin',
    update: '/root/admin',
    loginAs: '/root/admin/login-as',
    getCustomers: '/root/admin/customers',
    getMarketParticipatingCustomers: '/root/admin/market-customers',
    delete: '/root/admin',
    migrateCustomer: '/root/admin/migrate-customer',
    users: '/root/admin/users',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const LoginAs = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.loginAs}/${id}`).then(callback).catch(error).finally(next);
};

const GetCustomers = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getCustomers}/${id}`).then(callback).catch(error).finally(next);
};

const GetMarketParticipatingCustomers = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getMarketParticipatingCustomers}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (data, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${data.id}`, { params: data }).then(callback).catch(error).finally(next);
};

const MigrateCustomer = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(PATH.migrateCustomer, payload).then(callback).catch(error).finally(next);
};

const GetUsers = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.users}/${id}`).then(callback).catch(error).finally(next);
};

export const AdminService = {
    ReadAll,
    Create,
    Update,
    LoginAs,
    GetCustomers,
    Delete,
    GetMarketParticipatingCustomers,
    MigrateCustomer,
    GetUsers,
};
