// Standard library import
import React, { useEffect, useContext, useState } from 'react';

// External library import
import { toast } from 'react-toastify';

// Internal module import
import Typography from '../../../../../components/Typography/Typography';
import { dataInjestService } from '../../../../../services/dataInjestService';
import { START_LOADER, STOP_LOADER } from '../../../../../constants';
import { LoaderContext } from '../../../../../context/LoaderContext';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';

// css import
import classes from '../../../../../styles/AllDevices.module.css';

const MQTTCredModal = () => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [credentialsCount, setCredentialsCount] = useState(0);
    const [handleMqttRestartModal, setHandleMqttRestartModal] = useState(false);

    useEffect(() => {
        getAvailabelMQTTCredCount();
    }, []);

    const getAvailabelMQTTCredCount = () => {
        dataInjestService.GetAvailabelMQTTCredCount(
            () => startLoader('getAvailabelMQTTCredCount'),
            handleSuccess,
            handleError,
            () => stopLoader('getAvailabelMQTTCredCount')
        );
    };

    const generateMQTTCredentials = () => {
        dataInjestService.GenerateMQTTCredentials(
            () => startLoader('generateMQTTCredentials'),
            handleSuccessGenerateCredential,
            handleError,
            () => stopLoader('generateMQTTCredentials')
        );
    };

    const handleMqttRestart = () => {
        dataInjestService.UpdateMQTTCredentials(
            () => startLoader('updateMQTTCredentials'),
            handleSuccessMQTTRestart,
            handleError,
            () => stopLoader('updateMQTTCredentials')
        );
    };

    const successToast = (data) => {
        toast.success(data);
    };

    const handleSuccessMQTTRestart = () => {
        successToast('MQTT Restarted Successfully.');
        setHandleMqttRestartModal(false);
    };

    const handleSuccessGenerateCredential = () => {
        getAvailabelMQTTCredCount();
        successToast('Credential Generated Successfully.');
    };

    const handleSuccess = ({ data }) => {
        setCredentialsCount(data.data);
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div>
            <DeleteModal
                deletefunction={handleMqttRestart}
                opendeleteModal={handleMqttRestartModal}
                setOpenDeleteModal={setHandleMqttRestartModal}
                title={<div style={{ textAlign: 'center' }}>Do you want to apply change to MQTT Broker?</div>}
                text={'Updating credentials might cause discrepancy with the MQTT connections for few seconds.'}
            />

            <div className={classes.Header} style={{ gap: '0.51vw' }}>
                <Typography content={'MQTT Credentials'} />
                <div className={classes.TableCount}>
                    Available Credentials:
                    <span>
                        <Typography size="14" content={credentialsCount || 0} />
                    </span>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <div>
                    <button type="button" className="btn-primary" onClick={() => generateMQTTCredentials()}>
                        Generate Credentials
                    </button>
                </div>
                <div>
                    <button type="button" className="btn-primary" onClick={() => setHandleMqttRestartModal(true)}>
                        Restart MQTT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default MQTTCredModal;
