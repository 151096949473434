// Standard library imports
import React, { useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
// Internal module imports
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/BidModal.module.css';
import { Input } from '../../../components/Inputs/Input';
import { AdminBiddingService } from '../../../services/AdminBiddingService';
import { resultMessageValidator } from '../../../validations/Bidding/Bids';
import { useLoader } from '../../../hooks';

const ResultId = ({ bid, market, setModal, fetchBids }) => {
    const [startLoader, stopLoader] = useLoader();
    const initialValues = {
        primaryResultMessageId: bid?.primary?.provider?.resultMessageId ? bid.primary?.provider?.resultMessageId : '',
        ...(['FCR-D-INC', 'FCR-D-DEC'].includes(market)
            ? { secondaryResultMessageId: bid?.secondary?.provider?.resultMessageId ? bid.secondary?.provider?.resultMessageId : '' }
            : {}),
        reserveBidIdentification: bid?.reserveBidIdentification ? bid.reserveBidIdentification : '',
    };

    const handleSubmit = (values) => {
        const payload = { ...values, isPrimaryBid: bid.isPrimaryBid };
        AdminBiddingService.AddResultId(bid._id, payload, startLoader, handleSubmitSucess, handleError, stopLoader);
    };

    const handleSubmitSucess = ({ res }) => {
        setModal(false);
        toast.success('Result Message ID updated successfully');
        fetchBids();
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <Typography content="Result Message ID" size="16" />
            <div className={classes.FormContainer} style={{ width: '20vw' }}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={resultMessageValidator(bid.isPrimaryBid)}>
                    <Form>
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label>Result Message ID (Primary)</label>
                            <Input name="primaryResultMessageId" id="primaryResultMessageId" type="number" />
                        </div>
                        {['FCR-D-INC', 'FCR-D-DEC'].includes(market) && (
                            <div className={classes.FieldControl} style={{ width: '100%' }}>
                                <label>Result Message ID (Secondary)</label>
                                <Input name="secondaryResultMessageId" id="secondaryResultMessageId" type="number" />
                            </div>
                        )}
                        <div className={classes.FieldControl} style={{ width: '100%' }}>
                            <label>Reserve Bid Identification</label>
                            <Input name="reserveBidIdentification" id="reserveBidIdentification" type="text" />
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="button" className="btn-secondary" onClick={() => setModal(false)}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default ResultId;
