import React, { useEffect, useState } from 'react';

import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { useLoader, useQueryParams } from '../../../../../hooks';
import classes from '../../../../../styles/AllDevices.module.css';
import { toast } from 'react-toastify';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';
import { UserService } from '../../../../../services/UserService';
import { UilPen, UilTrash } from '@iconscout/react-unicons';
import { AdminService } from '../../../../../services/AdminService';

function AdminUser({ adminId, setUserModal, setAdminUser, setSelectedIndex }) {
    const [startLoader, stopLoader] = useLoader();
    const [users, setUsers] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        userId: '',
    });

    useEffect(() => {
        if (adminId) {
            fetchUsersById();
        }
    }, []);

    const fetchUsersById = () => {
        AdminService.GetUsers(adminId, startLoader, handleFetchSucess, handleError, stopLoader);
    };
    const handleFetchSucess = ({ data }) => {
        let newData = data.data.map((item, index) => ({
            sno: index + 1,
            email: item.email,
            role: item.userRole,
            contact: item.contact ? `${item.countryCode ? item.countryCode : ''} ${item.contact}` : '--',
            action: (
                <div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <UilPen
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() => {
                                setSelectedIndex(4);
                                setAdminUser({ status: true, data: item, adminId: '' });
                            }}
                        />
                    </div>
                    <div>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, userId: item._id })} />
                    </div>
                </div>
            ),
        }));
        setUsers(newData);
    };

    const handleDelete = () => {
        UserService.Delete(deleteModal.userId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('User deleted successfully!');
            setDeleteModal({ status: false, userId: '' });
            fetchUsersById();
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices} style={{ padding: '0' }}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Users" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={users.length} />
                        </span>
                    </div>
                </div>
                <div className={classes.Button}>
                    <button
                        style={{ marginTop: '0' }}
                        className="btn-primary"
                        onClick={() => {
                            setAdminUser({ status: true, adminId, data: '' });
                            setUserModal({
                                status: false,
                                adminId: '',
                            });
                            setSelectedIndex(4);
                        }}
                    >
                        Create User
                    </button>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Email', 'Contact', 'Role', 'Action']} keys={['sno', 'email', 'contact', 'role', 'action']} data={users} />
            </div>
        </div>
    );
}

export default AdminUser;
