export const Capitalize = (string) => {
    if (!string) return;
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const CamelCaseToTextCase = (text) => {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
};

export const getJustNumbers = (string) => {
    const numsStr = string.replace(/[^0-9]/g, '');
    return parseInt(numsStr);
};

export const splitAt = (index, xs) => [xs.slice(0, index), xs.slice(index)];
