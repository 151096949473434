// Standard library imports
import React, { useState, useEffect } from 'react';

// External module imports
import { toast } from 'react-toastify';
import { UilImport } from '@iconscout/react-unicons';

// Internal module imports
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { useLoader } from '../../../../../hooks';
import { RootSummaryService } from '../../../../../services/RootSummaryService';
import { momentTimeFormater } from '../../../../../utils/timeHelper';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import { AdminService } from '../../../../../services/AdminService';
import adminClasses from '../../Monitoring.module.css';
import { DownloadAsExcel } from '../../../../../utils/downloadAsExcel';

const LIMIT = 15;
const STATUS = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Activation Successful',
        value: 'Activation Successful',
    },
    {
        label: 'Over Delivered',
        value: 'Over Delivered',
    },
    {
        label: 'Under Delivered',
        value: 'Under Delivered',
    },
    {
        label: 'No Power',
        value: 'No Power',
    },
    {
        label: 'No Power & Command',
        value: 'No Power & Command',
    },
    {
        label: 'Power & no Command',
        value: 'Power & no Command',
    },
    {
        label: 'No Bids',
        value: 'No Bids',
    },
    {
        label: 'No Activation',
        value: 'No Activation',
    },
    {
        label: 'Edge Not Reporting',
        value: 'Edge Not Reporting',
    },
    {
        label: 'Delayed Activation',
        value: 'Delayed Activation',
    },
    {
        label: 'Abnormal Activation',
        value: 'Abnormal Activation',
    },
];
const ActivationSummary = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(momentTimeFormater().subtract(1, 'days').format()));
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [profiles, setProfiles] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [admins, setAdmins] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [selectedAdmin, setSelectedAdmin] = useState(
        queryParamsData.admin
            ? JSON.parse(queryParamsData.admin)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [selectedCustomer, setSelectedCustomer] = useState(
        queryParamsData.customer
            ? JSON.parse(queryParamsData.customer)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [selectedProfile, setSelectedProfile] = useState(
        queryParamsData.profile
            ? JSON.parse(queryParamsData.profile)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [selectedStatus, setSelectedStatus] = useState(
        queryParamsData.status
            ? JSON.parse(queryParamsData.status)
            : {
                  label: 'All',
                  value: 'all',
              }
    );

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        getAdmins();
    }, []);

    useEffect(() => {
        getCustomers();
    }, [selectedAdmin]);

    useEffect(() => {
        getProfiles();
    }, [selectedCustomer]);

    useEffect(() => {
        if (selectedProfile?.value) {
            getData();
        }
    }, [selectedProfile, date, skip, selectedStatus]);

    const getAdmins = () => {
        AdminService.ReadAll(startLoader, handleAdminSuccess, handleError, stopLoader);
    };

    const handleAdminSuccess = ({ data }) => {
        const admin = data.data.map((item) => ({
            label: item.name,
            value: item._id,
        }));
        setAdmins([{ label: 'All', value: 'all' }, ...admin]);
    };

    const getCustomers = () => {
        RootSummaryService.GetCustomer(selectedAdmin.value, startLoader, handleCustomerSuccess, handleError, stopLoader);
    };

    const handleCustomerSuccess = ({ data }) => {
        const customer = data.data.customers.map((customer) => ({
            label: customer.name,
            value: customer._id,
        }));
        setCustomers([{ label: 'All', value: 'all' }, ...customer]);
    };

    const getProfiles = () => {
        RootSummaryService.GetProfile(selectedCustomer.value, startLoader, handleProfileSuccess, handleError, stopLoader);
    };

    const handleProfileSuccess = ({ data }) => {
        let profiles = [];
        data.data.profiles.map((profile) => {
            if (profile?.properties?.profile?.name) {
                if (profile?.groupId) {
                    profiles.push({
                        label: `${profile?.groupId?.name} ${profile?.market}`,
                        value: profile._id,
                    });
                }
            }
        });
        setProfiles([{ label: 'All', value: 'all' }, ...profiles]);
    };

    const getData = () => {
        RootSummaryService.GetActivationSummary(
            {
                date: date,
                limit: LIMIT,
                skip: skip,
                edgeId: selectedProfile?.value,
                customerId: selectedCustomer?.value,
                status: selectedStatus?.value,
            },
            startLoader,
            handleSuccess,
            handleError,
            stopLoader
        );
    };

    const handleSuccess = ({ data }) => {
        const proccessedData = data?.data?.data?.map((item, index) => ({
            profileName: item.profileName,
            startTime: item.startTime ? momentTimeFormater(item.startTime).format('YYYY-MM-DD HH:mm') : '--',
            duration: item.duration || '--',
            status: item.status,
            maxFrequency: item?.decreased ? item.minFrequency : item.maxFrequency,
            maxPower: (
                <div className={adminClasses.AmountContainer}>
                    {item.status === 'Under Delivered' || item.status === 'Over Delivered' ? (
                        <span className={adminClasses.BoldFont + ' ' + adminClasses.RedColor}>{item.maxPower}</span>
                    ) : item.status === 'Activation Successful' ? (
                        <span className={adminClasses.BoldFont + ' ' + adminClasses.GreenColor}>{item.maxPower}</span>
                    ) : (
                        <span>{item.maxPower || item.maxPower == 0 ? item.maxPower : '--'}</span>
                    )}
                </div>
            ),
            targetPower: item.targetPower || '--',
            secToReachNonZeroPower: item.secToReachNonZeroPower || '--',
        }));
        setData(proccessedData);
        setTotalCount(data.data.count);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const downloadFile = () => {
        RootSummaryService.DownloadSummary(
            {
                date: date,
                edgeId: selectedProfile?.value,
                customerId: selectedCustomer?.value,
                status: selectedStatus?.value,
            },
            startLoader,
            handleDownloadSuccess,
            handleError,
            stopLoader
        );
    };

    const handleDownloadSuccess = ({ data }) => {
        const proccessedData = data.data.map((item) => ({
            profileName: item.profileName,
            startTime: item.startTime ? momentTimeFormater(item.startTime).format('YYYY-MM-DD HH:mm') : '--',
            duration: item.duration || '--',
            status: item.status,
            maxFrequency: item?.decreased ? item.minFrequency : item.maxFrequency,
            maxPower: item.maxPower || item.maxPower == 0 ? item.maxPower : '--',
            targetPower: item.targetPower || '--',
            secToReachNonZeroPower: Number(item.secToReachNonZeroPower) || '--',
        }));

        proccessedData.length &&
            DownloadAsExcel(proccessedData, `Activation summary ${momentTimeFormater(date).format('YYYY-MM-DD')}`, [
                'Profile Name',
                'Start Time',
                'Duration',
                'Status',
                'Max Frequency',
                'Max Power',
                'Target Power',
                'Seconds to Reach Non Zero Power ',
            ]);
    };

    return (
        <div>
            <div className={adminClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={adminClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Admin</label>
                                <DropdownComponent
                                    name="admin"
                                    options={admins}
                                    onChange={(e) => {
                                        setSelectedAdmin(e);
                                        updateQueryParams('admin', JSON.stringify(e));
                                        handlePageChange(0);
                                    }}
                                    defaultValue={selectedAdmin}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    onChange={(e) => {
                                        setSelectedCustomer(e);
                                        updateQueryParams('customer', JSON.stringify(e));
                                        handlePageChange(0);
                                    }}
                                    defaultValue={selectedCustomer}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Profile</label>
                                <DropdownComponent
                                    name="profileId"
                                    options={profiles}
                                    onChange={(e) => {
                                        setSelectedProfile(e);
                                        updateQueryParams('profile', JSON.stringify(e));
                                        handlePageChange(0);
                                    }}
                                    defaultValue={selectedProfile}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={adminClasses.FilterContainer} style={{ alignItems: 'center' }}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Status</label>
                                <DropdownComponent
                                    name="status"
                                    options={STATUS}
                                    onChange={(e) => {
                                        setSelectedStatus(e);
                                        updateQueryParams('status', JSON.stringify(e));
                                        handlePageChange(0);
                                    }}
                                    defaultValue={selectedStatus}
                                />
                            </div>
                        </div>
                        <div className={classes.FieldControl2}>
                            <label>Date</label>
                            <DatePicker date={date} setDate={setDate} showArrow={true} />
                        </div>
                        <div onClick={() => downloadFile()} style={{ marginTop: '4vh' }}>
                            <UilImport
                                size="1.5vw"
                                style={{
                                    cursor: 'pointer',
                                    color: 'var(--color-primary)',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Activation Summary" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={['Profile Name', 'Start Time', 'Duration', 'Status', 'Max Frequency', 'Max Power', 'Target Power', 'Seconds to Reach Non Zero Power']}
                            keys={['profileName', 'startTime', 'duration', 'status', 'maxFrequency', 'maxPower', 'targetPower', 'secToReachNonZeroPower']}
                            data={data}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivationSummary;
