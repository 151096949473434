import { jwtDecode } from 'jwt-decode';

export const jwtDecoder = () => {
    try {
        const jwtToken = localStorage.getItem('GM_TOKEN');

        let decodedToken = '';
        if (jwtToken) {
            decodedToken = jwtDecode(jwtToken);
        }
        return decodedToken;
    } catch (error) {
        console.log(error);
    }
};
