// Standard library imports
import { useState } from 'react';

// External library import
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal module import
import Dropdown from '../../../../components/Inputs/Dropdown';
import { addDataExportValidator } from '../../../../validations/SystemTools/addDataExporterValidation';
import { dataExportServiceForRoot } from '../../../../services/dataExportServicesForRoot';
import { useLoader } from '../../../../hooks';
import { Input } from '../../../../components/Inputs/Input';
import NewJSONEditor from '../../../../components/JSONEditor/NewJSONEditor';

// Css imports
import dataExportClasses from '../index.module.css';
import classes from '../../../../styles/AllDevices.module.css';

const DataExporterForm = ({ fetchAlldataExportRequest, setDataExportModal, dataSourceList }) => {
    const [startLoader, stopLoader] = useLoader();

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSuccess = (message) => {
        toast.success(message);
        fetchAlldataExportRequest();
        setDataExportModal(false);
    };

    const handleSubmit = (data) => {
        dataExportServiceForRoot.createRequest(
            data,
            () => startLoader('createDataExportRequest'),
            () => handleSuccess('Data Export Request created successfully'),
            handleError,
            () => stopLoader('createDataExportRequest')
        );
    };

    return (
        <div>
            <Formik
                initialValues={{
                    collection: '',
                    query: {},
                    sort: {},
                    skip: 0,
                    limit: 0,
                }}
                enableReinitialize
                validationSchema={addDataExportValidator}
                onSubmit={(val) => {
                    handleSubmit(val);
                }}
            >
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div>
                            <div className={classes.FieldControl2}>
                                <label for="collection">
                                    Collection
                                    <span className="required"> * </span>
                                </label>
                                <Dropdown name="collection" options={dataSourceList} defaultValue={dataSourceList[0]} />
                            </div>

                            <div className={classes.FieldControl2} style={{ minHeight: '5vw' }}>
                                <label for="query">Query</label>

                                <NewJSONEditor name="query" defaultValue={values.query} height="10rem" />
                            </div>

                            <div className={classes.FieldControl2} style={{ minHeight: '5vw' }}>
                                <label for="sort">Sort</label>

                                <NewJSONEditor name="sort" defaultValue={values.sort} height="10rem" />
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl2}>
                                    <label for="skip">Skip</label>
                                    <Input name="skip" id="skip" type="number" />
                                </div>

                                <div className={classes.FieldControl2}>
                                    <label for="limit">Limit</label>
                                    <Input name="limit" id="limit" type="number" />
                                </div>
                            </div>
                        </div>

                        <div className={dataExportClasses.ButtonContainer}>
                            <div>
                                <button
                                    className="btn-secondary"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setDataExportModal(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button className="btn-primary" type="submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default DataExporterForm;
