import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import { useLoader } from '../../../../hooks';
import { AdminService } from '../../../../services/AdminService';
import classes from '../../../../styles/AllDevices.module.css';
import AdminClasses from '../Admin.module.css';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { SET_USER } from '../../../../constants';
import { AuthContext } from '../../../../context/AuthContext';

import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import DeleteAdmin from './deleteAdmin';
import { UilPen } from '@iconscout/react-unicons';
import { UilSignout } from '@iconscout/react-unicons';
import { UilTrash, UilUsersAlt } from '@iconscout/react-unicons';
import AdminUser from './AdminUser/AdminUser';

const Admins = ({ setCreateEditAdmin, setSelectedIndex, tabslength, setAdminUser, setAllAdmins }) => {
    const { state } = useContext(AuthContext);
    const userRole = state?.user?.userRole;
    const [startLoader, stopLoader] = useLoader();
    const [showModal, setShowModal] = useState(false);
    const [customers, setCustomers] = useState();
    const [selectedAdmin, setSelectedAdmin] = useState();
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        adminId: '',
    });
    const [userModal, setUserModal] = useState({
        status: false,
        adminId: '',
    });
    const [admins, setAdmins] = useState([]);
    const { dispatch: authDispatch } = useContext(AuthContext);
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setAllAdmins(admins);
    }, [admins]);

    const getData = () => {
        AdminService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const getCustomers = (item) => {
        setSelectedAdmin(item);
        AdminService.GetCustomers(item._id, startLoader, (e) => handleGetCustomersSuccess(e, item._id), handleError, stopLoader);
    };

    const handleGetCustomersSuccess = ({ data }, id) => {
        if (data?.data[0]) {
            setCustomers(data?.data);
            setShowModal(true);
        } else {
            setDeleteModal({ status: true, adminId: id });
        }
    };

    const handleDelete = () => {
        const data = {
            id: deleteModal.adminId,
            isCustomers: false,
            newAdminId: null,
        };
        AdminService.Delete(data, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };
    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Admin deleted');
            setDeleteModal({ status: false, adminId: '' });
            getData();
        }
    };

    const handleSuccess = ({ data }) => {
        if (data.data?.length) {
            setAdmins(
                data.data.map((item, index) => ({
                    ...item,
                    's.no': index + 1,
                    contact: item.contact ? `${item.countryCode ? item.countryCode : ''} ${item.contact}` : '--',
                    profile: (
                        <div className={AdminClasses.ProfileIconContainer}>
                            <img className={AdminClasses.ProfileIcon} src={item.profileImage} alt={`${item.name} profile image`} />
                        </div>
                    ),
                    hasAccess: (
                        <div className={AdminClasses.ProfileIconContainer}>
                            {item.accessBlocked ? <div className={AdminClasses.AccessBlocked}></div> : <div className={AdminClasses.AccessNotBlocked}></div>}
                        </div>
                    ),
                    action: (
                        <div className={AdminClasses.ActionContainer}>
                            {userRole === 'root' && (
                                <CustomTooltip content={'users'}>
                                    <div onClick={() => setUserModal({ status: true, adminId: item._id })}>
                                        <UilUsersAlt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </CustomTooltip>
                            )}
                            <CustomTooltip content={'Edit Admin'}>
                                <div
                                    onClick={() => {
                                        setCreateEditAdmin({ status: true, data: item });
                                        setSelectedIndex(tabslength);
                                    }}
                                >
                                    <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </CustomTooltip>
                            <CustomTooltip content={'Login As Admin'}>
                                <div onClick={() => handleLoginAs(item._id)}>
                                    <UilSignout size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </CustomTooltip>
                            <CustomTooltip content={'Delete Admin'}>
                                <div onClick={() => getCustomers(item)}>
                                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </CustomTooltip>
                        </div>
                    ),
                }))
            );
        } else {
            setAdmins([]);
        }
    };

    const handleLoginAs = (id) => {
        AdminService.LoginAs(id, startLoader, handleLoginAsSuccess, handleError, stopLoader);
    };

    const handleLoginAsSuccess = ({ data }) => {
        if (data.data) {
            dispatchSetUser({ user: {}, token: data.data });
            window.location.reload();
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={userModal.status} setOpen={(status) => setUserModal({ ...userModal, status })}>
                <div style={{ width: '40vw' }}>
                    <AdminUser adminId={userModal?.adminId} setUserModal={setUserModal} setAdminUser={setAdminUser} setSelectedIndex={setSelectedIndex} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={showModal} setOpen={setShowModal}>
                <DeleteAdmin admins={admins} customers={customers} currentAdmin={selectedAdmin} setOpen={(value) => setShowModal(value)} refresh={getData} />
            </ModalComponent>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Admins" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={admins.length} />
                        </span>
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn-primary"
                        style={{ marginTop: '0' }}
                        onClick={() => {
                            setCreateEditAdmin({ status: true, data: {} });
                            setSelectedIndex(tabslength);
                        }}
                    >
                        Create Admin
                    </button>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', '', 'Name', 'Contact', 'Email', 'Access', 'Action']}
                    keys={['s.no', 'profile', 'name', 'contact', 'email', 'hasAccess', 'action']}
                    data={admins}
                />
            </div>
        </div>
    );
};

export default Admins;
