// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    shipmentAsset: '/admin/shipment',
    getShipmentAssets: '/admin/shipment/asset',
};

const CreateShipmentAsset = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.shipmentAsset, payload).then(callback).catch(error).finally(next);
};

const GetShipmentAssets = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getShipmentAssets).then(callback).catch(error).finally(next);
};

const GetShipments = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.shipmentAsset, { params }).then(callback).catch(error).finally(next);
};

export const EdgeShipmentService = {
    CreateShipmentAsset,
    GetShipmentAssets,
    GetShipments,
};
