import classes from '../../../../../styles/CapacityContent.module.css';
import React from 'react';
import Table from '../../../../../components/Table/Table';

const Status = ({ data, setOpen }) => {
    return (
        <div className={classes.CapacityContent} style={{ height: 'auto', width: 'auto', maxHeight: '70vh', overflowY: 'scroll' }}>
            <div className={classes.Title}>Status</div>
            <div className={classes.Content}>
                <div className={classes.Container} style={{ gap: '1vw' }}>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Response Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['name', 'value']} data={data?.bidResponse}></Table>
                    </div>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Capacity Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['productName', 'status']} data={data?.bidStatus}></Table>
                    </div>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Energy Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['productName', 'status']} data={data?.energyBidStatus}></Table>
                    </div>
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div className="btn-primary" onClick={() => setOpen(false)} style={{ minWidth: 'auto' }}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default Status;
