// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import moment from 'moment';
import { toast } from 'react-toastify';
import { UilEnvelopeAlt, UilImport } from '@iconscout/react-unicons';

// Internal module imports
import classes from './SignalErrors.module.css';
import { useLoader } from '../../../../../hooks';
import Typography from '../../../../../components/Typography/Typography';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { AdminService } from '../../../../../services/AdminService';
import { RootSignalErrorService } from '../../../../../services/RootSignalErrorService';
import Table from '../../../../../components/Table/Table';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import SignalErrorEmailModal from '../ModalComponent/SignalErrorEmailModal';
import { Capitalize } from '../../../../../utils/stringHelper';
import { DownloadAsExcel } from '../../../../../utils/downloadAsExcel';

const SignalErrors = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));

    const [admins, setAdmins] = useState([
        {
            label: 'All',
            value: 'global',
        },
    ]);
    const [selectedAdmin, setSelectedAdmin] = useState(admins[0]);
    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'global',
        },
    ]);
    const [selectedCustomer, setSelectedCustomer] = useState(customers[0]);

    const [logs, setLogs] = useState([]);
    const [emailModal, setEmailModal] = useState(false);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        getAdmins();
    }, []);

    useEffect(() => {
        if (selectedAdmin?.value && selectedAdmin.value !== 'global') {
            getCustomers();
        }
        const initailValue = {
            label: 'All',
            value: 'global',
        };
        setCustomers([initailValue]);
        setSelectedCustomer(initailValue);
        getSignalErrors();
    }, [selectedAdmin.value]);

    useEffect(() => {
        getSignalErrors();
    }, [selectedCustomer.value]);

    const getAdmins = () => {
        AdminService.ReadAll(startLoader, handleGetAdminsSuccess, handleError, stopLoader);
    };

    const getCustomers = () => {
        AdminService.GetCustomers(selectedAdmin.value, startLoader, handleGetCustomersSuccess, handleError, stopLoader);
    };

    const handleGetAdminsSuccess = ({ data }) => {
        let temp = [
            {
                label: 'Global',
                value: 'global',
            },
        ];
        data?.data?.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setAdmins(temp);
    };

    const handleGetCustomersSuccess = ({ data }) => {
        let temp = [
            {
                label: 'All',
                value: 'global',
            },
        ];
        data?.data?.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setCustomers(temp);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleSignalErrorSuccess = ({ data }) => {
        const processedData = data?.data?.map((item, idx) => {
            return {
                slNo: idx + 1,
                name: item.name,
                loggingType: item?.loggingType ? Capitalize(item?.loggingType) : '--',
                totalDataPoints: item.totalDataPoints,
                missedPoints: item.missedPoints,
                threeConsecutive: item.threeConsecutive,
                fiveConsecutive: item.fiveConsecutive,
                tenConsecutive: item.tenConsecutive,
                fifteenConsecutive: item.fifteenConsecutive,
                consecutiveOneMinute: item.consecutiveOneMinute
            };
        });
        setLogs(processedData);
    };

    const getSignalErrors = () => {
        RootSignalErrorService.GetSignalErrors(selectedAdmin.value, selectedCustomer.value, { date }, startLoader, handleSignalErrorSuccess, handleError, stopLoader);
    };

    const handleDownload = () => {
        const headers = ['Sl.No', 'Name', 'Type', 'Total Data Points', 'Missing Data Points', 'Consecutive 3', 'Consecutive 5', 'Consecutive 10', 'Consecutive 15'];

        logs?.length > 0 && DownloadAsExcel(logs, `Signal-Errors-${moment(date).format('YYYY-MM-DD')}`, headers);
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={emailModal} setOpen={setEmailModal} style={{ minWidth: '35vw' }}>
                <SignalErrorEmailModal setEmailModal={setEmailModal} />
            </ModalComponent>
            <div className={classes.ParentWrapper}>
                <div>
                    <div className={classes.ComponentHeader}>
                        <div style={{ display: 'flex', gap: '0.7vw', alignItems: 'center' }}>
                            <Typography content="Signal Issues" />
                            <div>
                                <div className={classes.TableCount}>
                                    Total Count :
                                    <span>
                                        <Typography size="14" content={logs.length} />
                                    </span>
                                </div>
                            </div>
                            <div className={classes.FlexContainer}>
                                <div className={classes.FlexContainer}>
                                    <div className={classes.HeaderLeft}>
                                        <div className="filter-dropdown" style={{ margin: '0 1vw 0 0' }}>
                                            <DropdownComponent
                                                defaultValue={queryParamsData.admin ? JSON.parse(queryParamsData.admin) : admins[0]}
                                                options={admins}
                                                onChange={(e) => {
                                                    setSelectedAdmin(e);
                                                    updateQueryParams('admin', JSON.stringify(e));
                                                }}
                                                width={'9vw'}
                                            />
                                        </div>
                                    </div>

                                    <div className={classes.HeaderLeft}>
                                        <div className="filter-dropdown" style={{ margin: '0 1vw 0 0' }}>
                                            <DropdownComponent
                                                defaultValue={queryParamsData.customer ? JSON.parse(queryParamsData.customer) : customers[0]}
                                                options={customers}
                                                onChange={(e) => {
                                                    setSelectedCustomer(e);
                                                    updateQueryParams('customer', JSON.stringify(e));
                                                }}
                                                width={'9vw'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl}>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: '0.5vw',
                                    }}
                                >
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                    <button
                                        type="submit"
                                        className="btn-primary"
                                        style={{
                                            marginTop: '0',
                                            width: 'auto',
                                        }}
                                        onClick={getSignalErrors}
                                    >
                                        Submit
                                    </button>
                                    <div className={classes.SignalErrorIcon} onClick={() => handleDownload()}>
                                        <UilImport size="1.5vw" />
                                    </div>
                                    <div className={classes.SignalErrorIcon} onClick={() => setEmailModal(true)}>
                                        <UilEnvelopeAlt size={'1.5vw'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div>
                    <Table
                        head={['S.No', 'Name', 'Type', 'Total Data Points', 'Missing Data Points', 'Consecutive 3', 'Consecutive 5', 'Consecutive 10', 'Consecutive 15', 'Consecutive 1 Minute']}
                        keys={['slNo', 'name', 'loggingType', 'totalDataPoints', 'missedPoints', 'threeConsecutive', 'fiveConsecutive', 'tenConsecutive', 'fifteenConsecutive', 'consecutiveOneMinute']}
                        data={logs}
                    />
                </div>
            </div>
        </div>
    );
};

export default SignalErrors;
