// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
// Internal module imports
import { useLoader } from '../../../../../hooks';
import Typography from '../../../../../components/Typography/Typography';
import { Input } from '../../../../../components/Inputs/Input';
import NewJSONEditor from '../../../../../components/JSONEditor/NewJSONEditor';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import classes from '../../../../../styles/AllDevices.module.css';
import { EdgeProfileValidation } from '../../../../../validations/Root/edgeProfileValidator';
import { RootEdgeProfileService } from '../../../../../services/RootEdgeProfileService';

const CreateEdit = ({ data, setCreateEditModal, getData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState(
        data
            ? data
            : {
                  name: '',
                  type: '',
                  profile: {},
              }
    );

    const typeOptions = [
        {
            label: 'Control Profile',
            value: 'controlProfile',
        },
        {
            label: 'Meter Profile',
            value: 'meterProfile',
        },
        {
            label: 'Controller Profile',
            value: 'controllerProfile',
        },
    ];
    const handleSubmit = (value) => {
        if (data?._id) {
            RootEdgeProfileService.Update(data._id, value, startLoader, () => handleSuccess('Profile updated sucessfully'), handleError, stopLoader);
        } else {
            RootEdgeProfileService.Create(value, startLoader, () => handleSuccess('Profile created sucessfully'), handleError, stopLoader);
        }
    };

    const handleSuccess = (msg) => {
        toast.success(msg);
        getData();
        setCreateEditModal({ status: false, data: '' });
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div style={{ width: '75vw', maxHeight: '90vh' }}>
            <Typography content={data ? 'Edit Edge Profile' : 'Add Edge Profile'} />
            <div className={classes.SupportNoteForm}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validationSchema={EdgeProfileValidation}
                    onSubmit={(val) => {
                        handleSubmit(val);
                    }}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl2}>
                                                    <label htmlFor="name">
                                                        Name <span className="required">*</span>
                                                    </label>
                                                    <Input name="name" id="name" style={{ marginTop: '0' }} />
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label htmlFor="dataSource">
                                                        Type <span className="required">*</span>
                                                    </label>
                                                    <div>
                                                        <Dropdown name="type" id="type" defaultValue={values?.type ? { value: values.type } : {}} options={typeOptions} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classes.FieldControl2}>
                                                <label htmlFor="profile">Profile</label>
                                                <div>
                                                    <NewJSONEditor name="profile" defaultValue={values?.profile ? values.profile : {}} height="30rem" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={(e) => {
                                                        setCreateEditModal({ status: false, data: '' });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateEdit;
