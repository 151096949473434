import * as yup from 'yup';
import { ERRORS, phoneRegExp } from '../../utils/validationHelper';

export const createRoot = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        email: yup.string().email().required(ERRORS.email),
        password: yup.string().required(ERRORS.required),
        countryCode: yup.string().required(ERRORS.required),
        contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
        accessBlocked: yup.boolean().required(ERRORS.required),
        userRole: yup.string().required(ERRORS.required),
        mongoDetails: yup.object().shape({
            enable: yup.boolean(),
            password: yup.string().when('enable', {
                is: true,
                then: yup.string().required(ERRORS.required),
            }),
            permission: yup.object().when('enable', {
                is: true,
                then: yup
                    .object()
                    .shape({
                        read: yup.boolean(),
                        write: yup.boolean(),
                        dbAdmin: yup.boolean(),
                    })
                    .test('only one key true', 'Please enable one permission', (value) => {
                        const { read, write, dbAdmin } = value;
                        const trueCount = [read, write, dbAdmin].filter((val) => val === true).length;
                        if (trueCount > 1 || trueCount == 0) {
                            return false;
                        } else {
                            return true;
                        }
                    }),
            }),
        }),
        awsDetails: yup.object().shape({
            enable: yup.boolean(),
            awsAliasName: yup.string().when('enable', {
                is: true,
                then: yup
                    .string()
                    .required(ERRORS.required)
                    .test('awsAliasName', 'no space allowed', (val) => !val?.includes(' ')),
            }),
        }),
        teamviewerDetails: yup.object().shape({
            enable: yup.boolean(),
            password: yup.string().when('enable', {
                is: true,
                then: yup.string().min(6).required(ERRORS.required),
            }),
        }),
        mqttDetails: yup.object().shape({
            enable: yup.boolean(),
            mqttUserName: yup.string().when('enable', {
                is: true,
                then: yup.string().required(ERRORS.required),
            }),
            mqttPassword: yup.string().when('enable', {
                is: true,
                then: yup.string().min(6).required(ERRORS.required),
            }),
        }),
    });
};

export const updateRoot = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        email: yup.string().email().required(ERRORS.email),
        password: yup.string().optional(),
        countryCode: yup.string().required(ERRORS.required),
        contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(ERRORS.required),
        accessBlocked: yup.boolean().required(ERRORS.required),
        mqttDetails: yup.object().shape({
            enable: yup.boolean(),
            mqttUserName: yup.string().when('enable', {
                is: true,
                then: yup.string(),
            }),
            mqttPassword: yup.string().when('enable', {
                is: true,
                then: yup.string(),
            }),
        }),
    });
};
