import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const addSignalsValidation = yup.object().shape({
    name: yup.string().required(ERRORS.required),
    type: yup.string().required(ERRORS.required),
    area: yup.string().required(ERRORS.required),
    queryString: yup.mixed().test('', ERRORS.required, (val) => {
        if (Object.keys(val).length > 0) {
            return true;
        } else {
            return false;
        }
    }),
    dataSource: yup.mixed().required(ERRORS.required),
    loggingType: yup.string().when('type', {
        is: 'Frequency',
        then: yup.string().required(ERRORS.required),
    }),
});
