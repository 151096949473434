// External library imports
import * as yup from 'yup';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

export const user = () => {
    return yup.object().shape({
        customerId: yup.string().required(ERRORS.required),
        customerName: yup
            .string()
            .optional()
            .test('customerName', 'no space allowed', (val) => !val?.includes(' ')),
    });
};
