// Standard Library Imports
import React, { useState, useEffect } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal library imports
import { useLoader } from '../../../../hooks';
import { ToggleButton } from '../../../../components/Inputs/Input';
import JSONEditor from '../../../../components/JSONEditor/JSONEditor';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { addAdminSignalsValidation } from '../../../../validations/SystemTools/addAdminSignalsValidation';
import { signalsService } from '../../../../services/signalsService';
import { signalTypesService } from '../../../../services/signalTypesService';
import { areaServiceAdmin } from '../../../../services/areaService';

// Css imports
import signalClasses from '../../../Root/GlobalConfig/index.module.css';
import classes from '../../../../styles/AllDevices.module.css';

function SignalFormModal({ customerId, customerCode, signalData, setSignalModal, setSignalFormModal, setSelectedIndex }) {
    const [startLoader, stopLoader] = useLoader();

    const [signalTypes, setSignalTypes] = useState([]);

    const createEditSignal = {
        data: {
            // name: signalData?.data?.name || "",
            type: signalData?.data?.type || '',
            area: signalData?.data?.area || '',
            // queryString: signalData?.data?.queryString || {},
            dataSource: signalData?.data?.dataSource || '',
            loggingType: signalData?.data?.loggingType || '',
        },
    };

    const [areaTypes, setAreaTypes] = useState([]);
    const [dataSourceList, setDataSourceList] = useState([]);

    const loggingTypeOptions = [
        { label: 'Seconds', value: 'seconds' },
        {
            label: 'Milliseconds',
            value: 'milliseconds',
        },
    ];

    useEffect(() => {
        fetchTypes();
        fetchAreas();
        fetchDataSourceList();
    }, []);

    const handleTypeSuccess = ({ data }) => {
        setSignalTypes(
            data.data.map((item) => ({
                label: item.name,
                value: item.name,
            }))
        );
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const fetchTypes = () => {
        signalTypesService.ReadAll(
            () => startLoader('getTypesSignals'),
            handleTypeSuccess,
            handleError,
            () => stopLoader('getTypesSignals')
        );
    };

    const handleAreaSuccess = ({ data }) => {
        setAreaTypes(
            data.data.map((item) => ({
                label: item.name,
                value: item.name,
            }))
        );
    };

    const fetchAreas = () => {
        areaServiceAdmin.RealAll(
            () => startLoader('getAreasSignals'),
            handleAreaSuccess,
            handleError,
            () => stopLoader('getAreasSignals')
        );
    };

    const handleDataSourceListSuccess = ({ data }) => {
        setDataSourceList(
            data.data.map((item) => ({
                label: item,
                value: item,
            }))
        );
    };

    const fetchDataSourceList = () => {
        signalsService.readDataSourceList(
            () => startLoader('fetchDataSourceList'),
            handleDataSourceListSuccess,
            handleError,
            () => stopLoader('fetchDataSourceList')
        );
    };

    const handleSuccess = (toastData) => {
        toast.success(toastData);
        setSelectedIndex(0);
        setSignalFormModal({
            status: false,
            type: 'create',
            data: {},
        });
    };

    const handleSignalSubmit = (values) => {
        const adminFormData = {
            customerId,
            customerCode,
            signalType: 'local',
        };
        let payload = values.dataSource.label ? { ...values, ...adminFormData, dataSource: values.dataSource.label } : { ...values, ...adminFormData };
        if (signalData.type === 'edit') {
            signalsService.updateType(
                signalData?.data?.signalId,
                payload,
                () => startLoader('updatedType'),
                () => handleSuccess('Signal updated sucessfully'),
                handleError,
                () => stopLoader('updatedType')
            );
            return;
        }
        let params = {};
        signalsService.createType(
            payload,
            params,
            () => startLoader('createdType'),
            () => handleSuccess('Signal created sucessfully'),
            handleError,
            () => stopLoader('createdType')
        );
    };

    return (
        <div>
            <div className={classes.SupportNoteForm} style={{ minWidth: '28vw' }}>
                <Formik
                    initialValues={createEditSignal.data}
                    enableReinitialize={true}
                    validationSchema={addAdminSignalsValidation}
                    onSubmit={(val) => {
                        handleSignalSubmit(val);
                    }}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.InputContainer}>
                                                {/* <div className={classes.FieldControl2}>
                                                    <label for="name">
                                                        Name <span className="required">*</span>
                                                    </label>
                                                    <Input
                                                        name="name"
                                                        id="name"
                                                        // disabled={editSignalModal.status}
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                        style={{ marginTop: "0" }}
                                                    />
                                                </div> */}
                                                <div className={classes.FieldControl2}>
                                                    <label for="type">
                                                        Signal Type <span className="required">*</span>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            name="type"
                                                            id="type"
                                                            options={signalTypes.filter((signal) => signal.label === 'Frequency')}
                                                            defaultValue={
                                                                createEditSignal?.data?.type
                                                                    ? {
                                                                        label: createEditSignal?.data?.type,
                                                                        value: createEditSignal?.data?.type,
                                                                    }
                                                                    : signalTypes.filter((signal) => signal.label === 'Frequency')[0]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.InputContainer}>
                                                {['Frequency'].includes(values.type) && (
                                                    <div className={classes.FieldControl2}>
                                                        <label for="loggingType">
                                                            Logging Type <span className="required">*</span>
                                                        </label>
                                                        <div>
                                                            <Dropdown
                                                                name="loggingType"
                                                                id="loggingType"
                                                                options={loggingTypeOptions}
                                                                defaultValue={
                                                                    createEditSignal?.data?.type
                                                                        ? {
                                                                            value: createEditSignal?.data?.loggingType,
                                                                        }
                                                                        : loggingTypeOptions[0]
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={classes.FieldControl2}>
                                                    <label for="area">
                                                        Area <span className="required">*</span>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            name="area"
                                                            id="area"
                                                            options={areaTypes}
                                                            defaultValue={
                                                                createEditSignal?.data?.area
                                                                    ? {
                                                                        label: createEditSignal?.data?.area,
                                                                        value: createEditSignal?.data?.area,
                                                                    }
                                                                    : areaTypes[0]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="dataSource">
                                                    Data Source <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Dropdown
                                                        name="dataSource"
                                                        id="dataSource"
                                                        options={dataSourceList}
                                                        defaultValue={
                                                            createEditSignal.data.dataSource
                                                                ? {
                                                                    label: createEditSignal.data.dataSource,
                                                                    value: createEditSignal.data.dataSource,
                                                                }
                                                                : dataSourceList.filter((signal) => signal.label === 'frequency')[0]
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            {/* <div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="queryString">
                                                        Query String <span className="required">*</span>
                                                    </label>
                                                    <JSONEditor
                                                        name="queryString"
                                                        id="queryString"
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                    />
                                                </div>
                                            </div> */}
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        // setAddTypesModal(false);
                                                        // setEditSignalModal({
                                                        //   signalId: "",
                                                        //   status: false
                                                        // });
                                                        setSignalModal({ status: false, customerId });
                                                        setSelectedIndex(0);
                                                        setSignalFormModal({
                                                            status: false,
                                                            type: 'create',
                                                            data: {},
                                                        });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default SignalFormModal;
