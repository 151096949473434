import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLoader } from '../../../../hooks';
import { RootBidRequestService } from '../../../../services/bidRequestService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import Table from '../../../../components/Table/Table';
import { Capitalize } from '../../../../utils/stringHelper';
import FilterBarClasses from '../../../../styles/Filterbar.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import bidsClasses from '../../../../styles/Bids.module.css';
import { UilCheckCircle, UilEye, UilTrash } from '@iconscout/react-unicons';
import RequestOverview from './modalComponent/RequestOverview';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import DeleteModalComponent from '../../../../components/DeleteModal/DeleteModal';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import classes from '../../../../styles/Bids.module.css';
import Typography from '../../../../components/Typography/Typography';

export const RequestBid = ({
    date,
    setDate,
    admins,
    selectedAdmin,
    setSelectedAdmin,
    customers,
    selectedCustomer,
    setSelectedCustomer,
    regulations,
    selectedRegulation,
    setSelectedRegulation,
    updateQueryParams,
}) => {
    const [startLoader, stopLoader] = useLoader();
    const [request, setRequest] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [overviewModal, setOverviewModal] = useState({
        status: false,
        request: {},
    });

    useEffect(() => {
        fetchRequest();
    }, [selectedCustomer, selectedRegulation]);

    const fetchRequest = () => {
        if (selectedAdmin?.value && selectedCustomer?.value && selectedRegulation?.value) {
            const params = {
                market: selectedRegulation.value,
                date: moment(date).format('YYYY-MM-DD'),
                adminId: selectedAdmin.value,
                customerId: selectedCustomer.value,
            };
            RootBidRequestService.GetBidRequest(params, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ data }) => {
        if (Array.isArray(data.data?.data)) {
            const processed = data.data.data.map((i) => ({
                ...i,
                startDate: momentTimeFormater(i.startDate).format('YYYY-MM-DD'),
                endDate: momentTimeFormater(i.endDate).format('YYYY-MM-DD'),
                createdAt: momentTimeFormater(i.createdAt).format('YYYY-MM-DD'),
                updatedAt: momentTimeFormater(i.updatedAt).format('YYYY-MM-DD'),
                noBid: i.excludedDays?.length,
                license: _.get(i, ['licenseId', 'name'], ''),
                instance: i.groupId?.name,
                customer: i?.customerName,
                status: i?.status ? Capitalize(i?.status) : '--',
                action: (
                    <div className={bidsClasses.ActionContainer} style={{ color: 'black' }}>
                        <div>
                            <CustomTooltip content="Update">
                                <UilCheckCircle onClick={() => handleBidStatusUpdate(i._id)} size="1.2vw" />
                            </CustomTooltip>
                        </div>
                        <div>
                            <CustomTooltip content="View">
                                <UilEye onClick={() => setOverviewModal({ status: true, request: i })} size="1.2vw" />
                            </CustomTooltip>
                        </div>
                        <div>
                            <CustomTooltip content="Delete">
                                <UilTrash onClick={() => setDeleteModal({ status: true, id: i._id })} size="1.2vw" />
                            </CustomTooltip>
                        </div>
                    </div>
                ),
            }));
            setRequest(processed);
        } else {
            setRequest([]);
        }
    };

    const handleBidStatusUpdate = (id) => {
        RootBidRequestService.UpdateBidStatus(id, startLoader, handleStatusUpdateSuccess, handleError, stopLoader);
    };

    const handleStatusUpdateSuccess = () => {
        toast.success('Bid status updated!');
        fetchRequest();
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const handleDelete = (id) => {
        RootBidRequestService.DeleteBidRequest(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Bid request deleted successfully!');
        fetchRequest();
    };

    return (
        <div>
            <div>
                <div className={FilterBarClasses.ParentWrapper}>
                    <div className={FilterBarClasses.Left}>
                        <div className={FilterBarClasses.Filter}>
                            <label>Admin</label>
                            <div style={{ width: '10vw' }}>
                                <DropdownComponent
                                    defaultValue={selectedAdmin}
                                    options={admins}
                                    onChange={(e) => {
                                        setSelectedAdmin(e);
                                        updateQueryParams('admin', e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={FilterBarClasses.Filter}>
                            <label>Customer</label>
                            <div style={{ width: '10vw' }}>
                                <DropdownComponent
                                    defaultValue={selectedCustomer}
                                    options={customers}
                                    onChange={(e) => {
                                        setSelectedCustomer(e);
                                        updateQueryParams('customer', e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={FilterBarClasses.Filter}>
                            <label>Market</label>
                            <div style={{ width: '10vw' }}>
                                <DropdownComponent
                                    defaultValue={selectedRegulation}
                                    options={regulations}
                                    onChange={(e) => {
                                        setSelectedRegulation(e);
                                        updateQueryParams('regulation', e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={FilterBarClasses.Right}>
                        <div className={FilterBarClasses.Filter}>
                            <label>Date</label>
                            <DatePicker
                                date={date}
                                setDate={(d) => {
                                    setDate(d);
                                    updateQueryParams('bidDate', d);
                                }}
                                showArrow={true}
                            />
                        </div>
                        <button
                            className="btn-primary"
                            style={{
                                marginTop: '0',
                                width: 'auto',
                            }}
                            onClick={fetchRequest}
                        >
                            Submit
                        </button>
                    </div>
                </div>
                <div className={classes.Bids}>
                    <div className={classes.Header}>
                        <div>
                            <Typography content="Bid requests" />
                            <div className={classes.TableCount}>
                                Total Count :
                                <span>
                                    <Typography size="14" content={request?.length || 0} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <Table
                        head={['Customer', 'License', 'Instance', 'Start Date', 'End Date', 'Excluded Count', 'Created At', 'Updated At', 'Status', 'Action']}
                        keys={['customer', 'license', 'instance', 'startDate', 'endDate', 'noBid', 'createdAt', 'updatedAt', 'status', 'action']}
                        data={request}
                    />
                </div>
            </div>

            <ModalComponent isOpen={overviewModal.status} setOpen={() => setOverviewModal({ status: false, request: {} })}>
                <RequestOverview request={overviewModal.request} setOpen={setOverviewModal} />
            </ModalComponent>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};
