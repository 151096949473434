import React, { useEffect, useState } from 'react';
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import { Input } from '../../../../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import contentClasses from '../ContentManage.module.css';
import { imageValidation } from '../../../../../validations/Root/contentValidation';
import FileUpload from '../../../../../components/Inputs/FileUpload/FileUpload';
import { RootContentManageService } from '../../../../../services/RootContentService';
import { useLoader } from '../../../../../hooks';
import { UilCopy } from '@iconscout/react-unicons';
import CustomTooltip from '../../../../../components/CustomToolTip/CustomTooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';
import { UilTrash } from '@iconscout/react-unicons';
import ReactPaginate from 'react-paginate';
import debounce from 'lodash.debounce';

const Images = () => {
    const [data, setData] = useState([]);
    const [addImageModal, setImageModal] = useState(false);
    const [image, setImage] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(15);
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [initialValues, setInitialValues] = useState({
        name: '',
    });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        imageId: '',
    });
    useEffect(() => {
        getData();
    }, [skip, limit]);

    useEffect(() => {
        debouncedGetData();
    }, [search]);

    const getData = () => {
        let params = {
            limit,
            skip,
            search,
        };
        RootContentManageService.ReadAll(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        setData(data.data);
        setTotalCount(data.data?.length);
    };

    const handleSubmit = (values) => {
        const formData = new FormData();
        const isImageValid = /(jpg|png|peg|svg)$/.test(image?.name?.slice(-4));
        if (image.length && !isImageValid) {
            toast.error('Please select a valid image format');
            return;
        }

        formData.append('image', image);
        Object.keys(values).forEach((key) => {
            formData.append(key, values[key]);
        });

        RootContentManageService.Create(formData, startLoader, handleCreateSuccess, handleError, stopLoader);
    };

    const handleCreateSuccess = ({ data }) => {
        toast.success('Image added!');
        getData();
        setImageModal(false);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleDelete = () => {
        const data = {
            id: deleteModal.imageId,
        };
        RootContentManageService.Delete(data, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Image deleted');
            setDeleteModal({ status: false, imageId: '' });
            getData();
        }
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    const debouncedGetData = debounce(() => {
        getData();
    }, 800);

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent isOpen={addImageModal} setOpen={(status) => setImageModal(status)} style={{ overflow: 'initial' }}>
                <div style={{ height: 'auto', width: '20vw' }}>
                    <div className={contentClasses.ModalWrapper}>
                        <Typography content={'Add Image'} />
                    </div>
                    <div className={classes.FormContent}>
                        <Formik initialValues={initialValues} enableReinitialize validationSchema={imageValidation} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}>
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div>
                                            <label className={contentClasses.Label} style={{ position: 'relative', top: '1vw' }}>
                                                Name <span className="required">*</span>
                                            </label>
                                            <Input type={'text'} name="name" />
                                        </div>

                                        <div>
                                            <label for="image" className={contentClasses.Label}>
                                                Image <span className="required">*</span>
                                            </label>
                                            <FileUpload name="image" id="image" accept="image/jpg, image/jpeg, image/png, image/svg+xml" onChange={handleUploadClick} />
                                        </div>

                                        <div>
                                            <div style={{ display: 'flex', gap: '0.4vw' }}>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setImageModal(false);
                                                        setInitialValues({
                                                            name: '',
                                                        });
                                                    }}
                                                    type="button"
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Images" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={data.length} />
                        </span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={(e) => setImageModal(true)}>
                            Add Image
                        </button>
                    </div>
                </div>
                <div>
                    <div className={'inputStyle2'}>
                        <input
                            placeholder="Search icon by name"
                            style={{ width: '10vw !important', paddingRight: '1.4vw' }}
                            type="text"
                            name="cron"
                            id=""
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '2vw' }}>
                <div className={contentClasses.ImageContainer}>
                    {data.map((item) => (
                        <div
                            style={{
                                backgroundImage: `url(${item.location})`,
                            }}
                            className={contentClasses.MainDiv}
                        >
                            <div>
                                <div className={contentClasses.NameWrapper}>
                                    <div
                                        style={{
                                            fontSize: '0.85vw',
                                            color: 'var(--color-primary)',
                                        }}
                                    >
                                        {item.name}
                                    </div>

                                    <div className={contentClasses.IconWrapper}>
                                        <CustomTooltip content={'Copy image url'}>
                                            <CopyToClipboard text={item.location} onCopy={() => toast.success('Copied!')}>
                                                <UilCopy
                                                    size={'1.2vw'}
                                                    style={{
                                                        color: 'var(--color-primary)',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </CopyToClipboard>
                                        </CustomTooltip>

                                        <CustomTooltip content={'Delete Image'}>
                                            <div onClick={() => setDeleteModal({ status: true, imageId: item._id })}>
                                                <UilTrash
                                                    size={'1.2vw'}
                                                    style={{
                                                        color: 'var(--color-primary)',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </CustomTooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <ReactPaginate
                    containerClassName="react-pagination"
                    pageCount={Math.ceil(totalCount / limit)}
                    forcePage={page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={({ selected }) => handlePageChange(selected)}
                    nextLabel=">"
                    previousLabel="<"
                />
            </div>
        </div>
    );
};

export default Images;
