// This utility function take two parameter one data and second array and return a array of length 4 of same data
const arrayConverter = (data, array) => {
    let i = 0;
    for (i = 0; i < 4; i++) {
        array.push(data);
    }
    return array;
};

// used in create fcr bid to generate 24 length array for 6 types of slot
export const arrayConverter24 = (slot1, slot2, slot3, slot4, slot5, slot6, array) => {
    array = arrayConverter(slot1, array);
    array = arrayConverter(slot2, array);
    array = arrayConverter(slot3, array);
    array = arrayConverter(slot4, array);
    array = arrayConverter(slot5, array);
    array = arrayConverter(slot6, array);
    return array;
};
