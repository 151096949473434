import { HttpClient } from '../utils/httpClient';

const PATH = {
    adminCustomerEdge: '/root/edge-ota/admin-customer-edge',
    batches: '/root/edge-ota/batch',
    startUpdate: '/root/edge-ota/start-update',
};

const ReadAdminCustomerEdge = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.adminCustomerEdge).then(callback).catch(error).finally(next);
};

const GetBatches = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.batches, { params }).then(callback).catch(error).finally(next);
};

const CreateBatch = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.batches, payload).then(callback).catch(error).finally(next);
};

const DeleteBatch = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.batches}/${id}`).then(callback).catch(error).finally(next);
};

const StartUpdate = (id, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.startUpdate}/${id}`).then(callback).catch(error).finally(next);
};

export const EdgeOTAService = { ReadAdminCustomerEdge, GetBatches, CreateBatch, DeleteBatch, StartUpdate };
