// Standard library imports
import { useEffect, useState } from 'react';

// External library import
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// Internal module import
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/BidModal.module.css';
import { SystemServices } from '../../../services/SystemServices';
import { TextArea, Input } from '../../../components/Inputs/Input';
import { EdgeSystem } from '../../../validations/Edge/system';
import { AccessManagerService } from '../../../services/AccessManagerService';
import Dropdown from '../../../components/Inputs/Dropdown';

const SystemForm = ({ setModal, editModal, modalData, startLoader, stopLoader, getData, customer }) => {
    const initialValues = {
        alias: modalData?.alias ? modalData?.alias : '',
        notes: modalData?.notes ? modalData.notes : '',
        properties: modalData?.properties || {
            teamviewer: {
                id: '',
                password: '',
            },
            anydesk: {
                id: '',
                password: '',
            },
        },
    };

    const [awsIAMUser, setAwsIAMUser] = useState([]);

    useEffect(() => {
        if (customer.value) {
            getAwsIAMUser();
        }
    }, [customer]);

    const getAwsIAMUser = () => {
        AccessManagerService.GetAwsIAMUser(customer.value, startLoader, handleGetAwsIAMUserSuccess, handleError, stopLoader);
    };

    const handleGetAwsIAMUserSuccess = ({ data }) => {
        let temp = [];
        data?.data?.data.map((item) => {
            temp.push({
                label: item.awsUserName,
                value: item._id,
            });
        });
        setAwsIAMUser(temp);
    };

    const handleSubmitSuccess = () => {
        setModal(false);
        getData();
        toast.success(`System ${editModal ? ' update ' : ' create '} successfull`);
    };

    const handleSubmitError = (err, message) => {
        if (err) {
            if (err && err.response) toast.error(err.response.data.message);
        } else if (message) {
            toast.error(message);
        }
    };

    const handleFormSubmit = (formData) => {
        if (editModal) {
            SystemServices.Update(
                modalData._id,
                {
                    ...formData,
                },
                startLoader,
                handleSubmitSuccess,
                handleSubmitError,
                stopLoader
            );
        } else {
            SystemServices.Create(
                {
                    ...formData,
                    customerId: customer.value,
                    customerName: customer.label,
                    customerCode: customer.customerCode,
                },
                startLoader,
                handleSubmitSuccess,
                handleSubmitError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div style={{ overflow: 'auto', maxHeight: '90vh' }}>
            <div>
                <Typography content={`${editModal ? 'Update ' : 'Create '} System`} size="16" />
                <div className={classes.systemFormModal}>
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleFormSubmit} validationSchema={() => EdgeSystem(customer?.customerCode)}>
                        {({ values, ...props }) => {
                            return (
                                <Form>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="alias">Alias Name</label>
                                            <Input name="alias" id="alias" />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="awsDocId">AWS IAM User Name</label>
                                            <Dropdown
                                                name="properties.awsDocId"
                                                options={awsIAMUser}
                                                defaultValue={{
                                                    value: initialValues?.properties?.awsDocId,
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="teamviewerId">
                                                Teamviewer ID
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="properties.teamviewer.id" id="teamviewerId" type={'number'} disabled={editModal ? true : false} />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="teamviewerPassword">
                                                Teamviewer Password
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="properties.teamviewer.password" id="teamviewerPassword" disabled={editModal ? true : false} />
                                        </div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="anydeskId">
                                                Anydesk ID
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="properties.anydesk.id" id="anydeskId" disabled={editModal ? true : false} />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="anydeskPassword">
                                                Anydesk Password
                                                <span className="required">*</span>
                                            </label>
                                            <Input name="properties.anydesk.password" id="anydeskPassword" disabled={editModal ? true : false} />
                                        </div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="devicePin">Device PIN</label>
                                            <Input name="properties.devicePin" id="devicePin" />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="devicePassword">Device Password</label>
                                            <Input name="properties.devicePassword" id="devicePassword" />
                                        </div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="vmUserName">VM User Name</label>
                                            <Input name="properties.vmUserName" id="vmUserName" />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="vmPassword">VM Password</label>
                                            <Input name="properties.vmPassword" id="vmPassword" />
                                        </div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="laptopSerialNumber">Laptop Serial Number</label>
                                            <Input name="properties.laptopSerialNumber" id="laptopSerialNumber" />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="routerSerialNumber">Router Serial Number</label>
                                            <Input name="properties.routerSerialNumber" id="routerSerialNumber" />
                                        </div>
                                    </div>

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="location">Location</label>
                                            <Input name="properties.location" id="location" />
                                        </div>

                                        <div className={classes.FieldControl2}>
                                            <label for="simCardNumber">SIM Card Number</label>
                                            <Input name="properties.simCardNumber" id="simCardNumber" />
                                        </div>
                                    </div>

                                    <div className={classes.FieldControl} style={{ width: '100%' }}>
                                        <label for="notes">Notes</label>
                                        <TextArea name="notes" id="notes" type="text" />
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setModal(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default SystemForm;
