import React, { useEffect, useState } from 'react';

import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader, useQueryParams } from '../../../hooks';
import { CustomerService } from '../../../services/CustomerService';
import classes from '../../../styles/AllDevices.module.css';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { UserService } from '../../../services/UserService';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

function Users({ customerId, setUser, setUserModal, setShowUserForm, setSelectedTab }) {
    //5f44e6cc6d6f9046906c898a
    const [startLoader, stopLoader] = useLoader();
    const [users, setUsers] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        userId: '',
    });

    useEffect(() => {
        fetchUsersById(customerId);
    }, []);
    const fetchUsersById = (id) => {
        CustomerService.Read(id, startLoader, handleFetchSucess, handleError, stopLoader);
    };
    const handleFetchSucess = ({ data }) => {
        let newData = data.data.users.map((item, index) => ({
            sno: index + 1,
            email: item.email,
            role: item.userRole,
            contact: item.contact ? `${item.countryCode ? item.countryCode : ''} ${item.contact}` : '--',
            action: (
                <div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>
                        <CustomTooltip content={'Edit'}>
                            <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setUser({ ...item, customerName: data.data.name })} />
                        </CustomTooltip>
                    </div>
                    <div>
                        <CustomTooltip content={'Delete'}>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, userId: item._id })} />
                        </CustomTooltip>
                    </div>
                </div>
            ),
        }));
        setUsers(newData);
    };

    const handleDelete = () => {
        UserService.Delete(deleteModal.userId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('User deleted successfully!');
            setDeleteModal({ status: false, userId: '' });
            fetchUsersById(customerId);
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Users" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={users.length} />
                        </span>
                    </div>
                </div>
                <div>
                    <div className={classes.Button} style={{ paddingBottom: '2.6vh', paddingLeft: '1vw' }}>
                        <button
                            className="btn-primary"
                            onClick={() => {
                                setShowUserForm({ status: true, customerId });
                                setUserModal({
                                    status: false,
                                    customerId: '',
                                });
                                setSelectedTab(3);
                            }}
                        >
                            Create User
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Email', 'Contact', 'Role', 'Action']} keys={['sno', 'email', 'contact', 'role', 'action']} data={users} />
            </div>
        </div>
    );
}

export default Users;
