// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import moment from 'moment';
// Internal module imports
import { useLoader } from '../../../hooks';
import { DropdownComponent } from '../../../components/Inputs/Input';
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/Bids.module.css';
import Table from '../../../components/Table/Table';
import { momentTimeFormater } from '../../../utils/timeHelper';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import { AdminBiddingService } from '../../../services/AdminBiddingService';

const TotalCapacity = ({ market, updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState({});
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : momentTimeFormater().toDate());
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchMarketEnabledGroups();
    }, []);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        if (customer?.value) {
            fetchTotalCapacity();
        }
    }, [date, customer]);

    const handleError = (error) => {
        console.error(error);
    };

    const fetchMarketEnabledGroups = () => {
        AdminBiddingService.GetMarketEnabledGroups(market, startLoader, groupSuccess, handleError, stopLoader);
    };

    const groupSuccess = ({ data }) => {
        const res = data.data;
        const curCustomers = Object.keys(res);
        if (curCustomers.length) {
            const customerOptions = curCustomers.map((id) => ({
                label: res[id].name,
                value: id,
            }));
            setCustomer(queryParamsData.customer ? queryParamsData.customer : customerOptions[0]);
            setCustomers(customerOptions);
        }
    };

    const fetchTotalCapacity = () => {
        if (customer?.value) {
            const params = {
                customerId: customer.value,
                market: market,
                date: moment(date).format('YYYY-MM-DD'),
            };
            AdminBiddingService.BidCapacity(params, startLoader, handleFetchSuccess, handleError, stopLoader);
        }
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data?.totalCapacity)) {
            setData(data.data.totalCapacity);
        }
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header} style={{ marginTop: '1vw' }}>
                <div>
                    <Typography content="Total Capacity" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={data.length} />
                        </span>
                    </div>
                    <div className={classes.DDW}>
                        <DropdownComponent
                            name="customerId"
                            options={customers}
                            defaultValue={queryParamsData.customer ? queryParamsData.customer : customer}
                            onChange={(e) => {
                                setCustomer(e);
                                updateQueryParams('customer', JSON.stringify(e));
                            }}
                        />
                    </div>
                </div>
                <div>
                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                </div>
            </div>
            <Table head={['Slot', 'Hour', 'Capacity (MW)']} keys={['slot', 'hour', 'capacity']} data={data} />
        </div>
    );
};

export default TotalCapacity;
