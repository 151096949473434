// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';
// Internal module imports
import classes from './index.module.css';
import TabsComponent from '../../components/Tabs/Tabs';
import { useLoader } from '../../hooks';
import Bids from './Bids';
import BundleBidding from './BundleBidding';
import TotalCapacity from './TotalCapacity';
import BidRequestIndex from './BidRequests';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import ExportBid from './ModalContent/ExportBid';
import { AFRR_MARKET_TYPES } from '../../constants';

const MARKET_MAP = {
    'fcr-d-inc': 'FCR-D-INC',
    'fcr-d-minus': 'FCR-D-DEC',
    fcr: 'FCR',
    ffr: 'FFR',
    'fcr-n': 'FCR-N',
    afrr: 'aFRR',
    mfrr: 'mFRR',
    'afrr-dk1-inc': 'aFRR-DK1-INC',
    'afrr-dk1-dec': 'aFRR-DK1-DEC',
    'afrr-dk2-inc': 'aFRR-DK2-INC',
    'afrr-dk2-dec': 'aFRR-DK2-DEC',
};

const BiddingIndex = () => {
    const [exportModal, setExportModal] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [market, setMarket] = useState(location.pathname.split('/')[2] ? MARKET_MAP[location.pathname.split('/')[2]] : '');
    const [queryParamsData] = useState({
        customer: queryParams.get('customer') ? JSON.parse(queryParams.get('customer')) : '',
        license: queryParams.get('license') ? JSON.parse(queryParams.get('license')) : '',
        groups: queryParams.get('groups') ? JSON.parse(queryParams.get('groups')) : '',
        filter: queryParams.get('filter') ? JSON.parse(queryParams.get('filter')) : '',
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        instanceBundle: queryParams.get('instanceBundle') ? JSON.parse(queryParams.get('instanceBundle')) : '',
    });

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    useEffect(() => {
        setMarket(location.pathname.split('/')[2] ? MARKET_MAP[location.pathname.split('/')[2]] : '');
    }, [location]);

    let tabs = [
        {
            name: `${market} Bids`,
            component: (
                <>
                    <Bids market={market} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        ...(['FCR-D-INC', 'FCR-D-DEC', 'FFR', ...AFRR_MARKET_TYPES].includes(market)
            ? [
                  {
                      name: 'Bundle Bids',
                      component: (
                          <>
                              <BundleBidding market={market} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(['FCR-D-INC', 'FCR-D-DEC', 'FFR', 'FCR', ...AFRR_MARKET_TYPES].includes(market)
            ? [
                  {
                      name: 'Bid Requests',
                      component: (
                          <>
                              <BidRequestIndex market={market} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                          </>
                      ),
                  },
              ]
            : []),
        {
            name: 'Total Capacity',
            component: <TotalCapacity market={market} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                tabs={tabs}
                setShowExportModal={setExportModal}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    updateQueryParams('tab', index);
                }}
            />
            {['FCR-D-INC', 'FCR-D-DEC', 'FFR'].includes(market) ? (
                <ModalComponent isOpen={exportModal} setOpen={setExportModal} style={{ overflow: 'initial' }}>
                    <ExportBid market={market} setOpen={setExportModal} />
                </ModalComponent>
            ) : (
                ''
            )}
        </div>
    );
};

export default BiddingIndex;
