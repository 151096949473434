import { HttpClient } from '../utils/httpClient';

const PATH = {
    update: '/admin/self',
};

const Update = (payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(PATH.update, payload).then(callback).catch(error).finally(next);
};

export const SettingsService = { Update };
