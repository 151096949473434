import React, { useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import { MainBidding } from './Bidding';
import { useHistory, useLocation } from 'react-router-dom';
import { BundleBidding } from './BundleBidding/BundleBidding';
import BidRequestIndex from './BidRequest';

const Bidding = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const queryParamsData = {
        tab: Number(queryParams.get('tab') || 0),
        subTab: Number(queryParams.get('subTab') || 0),
        regulation: queryParams.get('regulation'),
        bidDate: queryParams.get('bidDate'),
        admin: queryParams.get('admin'),
        customer: queryParams.get('customer'),
        filter: queryParams.get('filter'),
    };

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab);

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    const tabs = [
        {
            name: 'Bidding',
            component: <MainBidding queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 1,
        },
        {
            name: 'Bundle Bidding',
            component: <BundleBidding queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 2,
        },
        {
            name: 'Bid Request',
            component: <BidRequestIndex queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 3,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Bidding;
