// Standard library imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    toogleMonitoring: '/root/summary/toggle-monitoring',
    activationSummary: '/root/summary/activation-summary',
    activationSummaryByProfile: '/root/summary/activation-summary',
    downloadSummary: '/root/summary/activation-summary-download',
    customer: '/root/summary/customer',
    profile: '/root/summary/profile',
    overview: '/root/summary/activation-summary/overview',
};

const GetToggleMonitoring = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.toogleMonitoring, { params }).then(callback).catch(error).finally(next);
};

const GetActivationSummary = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.activationSummary, { params }).then(callback).catch(error).finally(next);
};

const GetCustomer = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.customer}/${id}`).then(callback).catch(error).finally(next);
};

const GetProfile = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.profile}/${id}`).then(callback).catch(error).finally(next);
};

const GetActivationSummaryByProfile = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.activationSummaryByProfile}/${params.edgeId}`, {
        params,
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

const DownloadSummary = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.downloadSummary, { params }).then(callback).catch(error).finally(next);
};

const Overview = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.overview, { params }).then(callback).catch(error).finally(next);
};

export const RootSummaryService = {
    GetToggleMonitoring,
    GetActivationSummary,
    GetActivationSummaryByProfile,
    GetCustomer,
    GetProfile,
    DownloadSummary,
    Overview,
};
