// Standard Library Imports
import React, { useEffect, useState } from 'react';

// External library imports
import { UilInfoCircle, UilMinusSquareFull } from '@iconscout/react-unicons'

// Internal library imports
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import { signalsService } from '../../../services/signalsService';
import { momentTimeFormater } from "../../../utils/timeHelper";

// Css imports
import signalClasses from '../../Root/GlobalConfig/index.module.css';
import classes from '../../../styles/AllDevices.module.css';

function SignalReportTable({ date, startTime, endTime, signal }) {
    const [startLoader, stopLoader] = useLoader();
    const [totalReport, setTotalReport] = useState({ status: false, data: [] });
    const [missedReport, setMissedReport] = useState({ status: false, data: [] });
    const [consecutive3, setConsecutive3] = useState({ status: false, data: [] });
    const [consecutive5, setConsecutive5] = useState({ status: false, data: [] });
    const [consecutive10, setConsecutive10] = useState({ status: false, data: [] });
    const [type, setType] = useState('');

    useEffect(() => {
        fetchReport();
    }, []);

    const handleReportSuccess = ({ data }) => {
        const { millisecondsLog, secondsLog } = data.data;

        const isMilliSecondsPresent = millisecondsLog.length > 0;
        const report = isMilliSecondsPresent ? millisecondsLog : secondsLog;

        const processedDataMissed = [];
        const processedDataConsecutive3 = [];
        const processedDataConsecutive5 = [];
        const processedDataConsecutive10 = [];

        const processedDataTotal = report.map((key, i) => {
            key.pointsDetail.forEach((point, j) => {
                processedDataMissed.push({
                    's.no': j + 1,
                    timestamp: point.timestamp,
                    timeDifference: point.timeDifference,
                });
            });

            let c3idx = 1;
            let c5idx = 1;
            let c10idx = 1;

            key.consecutiveDetail.forEach((info) => {
                if (info.count === 3) {
                    processedDataConsecutive3.push({
                        's.no': c3idx,
                        fromTimestamp: info.fromTimestamp,
                        toTimestamp: info.toTimestamp,
                    });
                    c3idx += 1;
                } else if (info.count === 5) {
                    processedDataConsecutive5.push({
                        's.no': c5idx,
                        fromTimestamp: info.fromTimestamp,
                        toTimestamp: info.toTimestamp,
                    });
                    c5idx += 1;
                } else if (info.count === 10) {
                    processedDataConsecutive10.push({
                        's.no': c10idx,
                        fromTimestamp: info.fromTimestamp,
                        toTimestamp: info.toTimestamp,
                    });
                    c10idx += 1;
                }
            });

            return {
                's.no': i + 1,
                area: key.area,
                totalPoints: key.totalDataPoints,
            };
        });

        setTotalReport({ status: true, data: processedDataTotal });
        setMissedReport({ status: false, data: convertTimestamp(processedDataMissed, true) });
        setConsecutive3({ status: false, data: convertTimestamp(processedDataConsecutive3) });
        setConsecutive5({ status: false, data: convertTimestamp(processedDataConsecutive5) });
        setConsecutive10({ status: false, data: convertTimestamp(processedDataConsecutive10) });
        setType(isMilliSecondsPresent ? 'milliSeconds' : 'seconds');
    };

    const convertTimestamp = (data, hasOnlyTimestamp = false) => {
        return data.map((log) => {
            if (hasOnlyTimestamp) {
                log.timestamp = momentTimeFormater(log.timestamp).format("YYYY-MM-DD HH:mm:ss");
            } else {
                log.fromTimestamp = momentTimeFormater(log.fromTimestamp).format("YYYY-MM-DD HH:mm:ss");
                log.toTimestamp = momentTimeFormater(log.toTimestamp).format("YYYY-MM-DD HH:mm:ss");
            }
            return log;
        });
    };

    const fetchReport = () => {
        const queryParams = {
            date,
            start: startTime,
            end: endTime,
            id: signal.value,
        };
        signalsService.getReport(
            queryParams,
            () => startLoader('getSignals'),
            handleReportSuccess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const selectedTypeStyle = {
        backgroundColor: 'white',
        border: '1px solid #9da7ab',
        cursor: 'pointer',
    };

    return (
        <div className={classes.AllDevices} style={{ width: '80vw' }}>
            <div>
                <div className={classes.Header} style={{ marginBottom: '1%' }}>
                    <div>
                        <Typography content={`Signal ${signal.label} (${type === 'seconds' ? 'seconds' : 'milliseconds'})`} />
                        <div
                            className={classes.TableCount}
                            style={
                                totalReport.status
                                    ? selectedTypeStyle
                                    : {
                                        cursor: 'pointer',
                                    }
                            }
                            onClick={() => {
                                setTotalReport({ status: true, data: totalReport.data });
                                setMissedReport({ status: false, data: missedReport.data });
                                setConsecutive3({ status: false, data: consecutive3.data });
                                setConsecutive5({ status: false, data: consecutive5.data });
                                setConsecutive10({ status: false, data: consecutive10.data });
                            }}
                        >
                            Total Count :
                            <span>
                                <Typography size="14" content={totalReport.data.length} />
                            </span>
                        </div>
                        <div
                            className={classes.TableCount}
                            style={
                                missedReport.status
                                    ? selectedTypeStyle
                                    : {
                                        cursor: 'pointer',
                                    }
                            }
                            onClick={() => {
                                setTotalReport({ status: false, data: totalReport.data });
                                setMissedReport({ status: true, data: missedReport.data });
                                setConsecutive3({ status: false, data: consecutive3.data });
                                setConsecutive5({ status: false, data: consecutive5.data });
                                setConsecutive10({ status: false, data: consecutive10.data });
                            }}
                        >
                            Missed Count :
                            <span>
                                <Typography size="14" content={missedReport.data.length} />
                            </span>
                        </div>
                        <div
                            className={classes.TableCount}
                            style={
                                consecutive3.status
                                    ? selectedTypeStyle
                                    : {
                                        cursor: 'pointer',
                                    }
                            }
                            onClick={() => {
                                setTotalReport({ status: false, data: totalReport.data });
                                setMissedReport({ status: false, data: missedReport.data });
                                setConsecutive3({ status: true, data: consecutive3.data });
                                setConsecutive5({ status: false, data: consecutive5.data });
                                setConsecutive10({ status: false, data: consecutive10.data });
                            }}
                        >
                            Consecutive 3 Count :
                            <span>
                                <Typography size="14" content={consecutive3.data.length} />
                            </span>
                        </div>
                        <div
                            className={classes.TableCount}
                            style={
                                consecutive5.status
                                    ? selectedTypeStyle
                                    : {
                                        cursor: 'pointer',
                                    }
                            }
                            onClick={() => {
                                setTotalReport({ status: false, data: totalReport.data });
                                setMissedReport({ status: false, data: missedReport.data });
                                setConsecutive3({ status: false, data: consecutive3.data });
                                setConsecutive5({ status: true, data: consecutive5.data });
                                setConsecutive10({ status: false, data: consecutive10.data });
                            }}
                        >
                            Consecutive 5 Count :
                            <span>
                                <Typography size="14" content={consecutive5.data.length} />
                            </span>
                        </div>
                        <div
                            className={classes.TableCount}
                            style={
                                consecutive10.status
                                    ? selectedTypeStyle
                                    : {
                                        cursor: 'pointer',
                                    }
                            }
                            onClick={() => {
                                setTotalReport({ status: false, data: totalReport.data });
                                setMissedReport({ status: false, data: missedReport.data });
                                setConsecutive3({ status: false, data: consecutive3.data });
                                setConsecutive5({ status: false, data: consecutive5.data });
                                setConsecutive10({ status: true, data: consecutive10.data });
                            }}
                        >
                            Consecutive 10 Count :
                            <span>
                                <Typography size="14" content={consecutive10.data.length} />
                            </span>
                        </div>
                    </div>
                </div>
                {totalReport.status && (
                    <div className={classes.ConsecutiveModalContainer}>
                        <Typography content={'Total Data Points:'} />
                        <Table head={['S.No', 'Area', 'Total Points']} keys={['s.no', 'area', 'totalPoints']} data={totalReport.data} />
                    </div>
                )}
                {missedReport.status && (
                    <div className={classes.ConsecutiveModalContainer}>
                        <Typography content={'Missed Data Points:'} />
                        <Table
                            head={['S.No', 'Timestamp (CET)', `Time Difference (${type === 'seconds' ? 'S' : 'MS'})`]}
                            keys={['s.no', 'timestamp', 'timeDifference']}
                            data={missedReport.data}
                        />
                    </div>
                )}
                {consecutive3.status && (
                    <div className={classes.ConsecutiveModalContainer}>
                        <Typography content={'Consecutive Values:'} />
                        <Table head={['S.No', 'FROM Timestamp (CET)', 'TO Timestamp (CET)']} keys={['s.no', 'fromTimestamp', 'toTimestamp']} data={consecutive3.data} />
                    </div>
                )}
                {consecutive5.status && (
                    <div className={classes.ConsecutiveModalContainer}>
                        <Typography content={'Consecutive Values:'} />
                        <Table head={['S.No', 'FROM Timestamp (CET)', 'TO Timestamp (CET)']} keys={['s.no', 'fromTimestamp', 'toTimestamp']} data={consecutive5.data} />
                    </div>
                )}
                {consecutive10.status && (
                    <div className={classes.ConsecutiveModalContainer}>
                        <Typography content={'Consecutive Values:'} />
                        <Table head={['S.No', 'FROM Timestamp (CET)', 'TO Timestamp (CET)']} keys={['s.no', 'fromTimestamp', 'toTimestamp']} data={consecutive10.data} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default SignalReportTable;
