import React from 'react';
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import { Form, Formik } from 'formik';
import { Input, ToggleButton } from '../../../../components/Inputs/Input';
import { FrequencyConfigValidator } from '../../../../validations/Customer/SystemConfig';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import { CustomerService } from '../../../../services/CustomerService';

const getInitialValues = (properties = {}) => {
    return {
        lowVisible: properties.lowVisible || '',
        highVisible: properties.highVisible || '',
        lineMin: properties.lineMin || '',
        lineMax: properties.lineMax || '',
        enableLineMin: properties.enableLineMin || false,
        enableLineMax: properties.enableLineMax || false,
    };
};

const SystemConfig = ({ customer = {}, setRefresh, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const initialValues = getInitialValues(customer.properties);

    const handleSubmit = (values) => {
        if (customer._id) {
            if (values.lineMin === '') values.lineMin = null;
            if (values.lineMax === '') values.lineMax = null;
            CustomerService.UpdateProperties(customer._id, values, startLoader, handleSuccess, handleError);
        }
    };

    const handleSuccess = ({ data }) => {
        toast.success('Properties updated!');
        setRefresh && setRefresh(Date.now());
        setOpen(false);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div style={{ minWidth: '40vw' }}>
            <Typography content={'Frequency Config'} />
            <Formik initialValues={initialValues} enableReinitialize validationSchema={FrequencyConfigValidator} onSubmit={handleSubmit}>
                {({ errors, touched, values, isValidating, ...props }) => {
                    return (
                        <Form>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl2}>
                                    <label for="lowVisible">Low Visible Value</label>
                                    <Input name="lowVisible" id="lowVisible" type="number" />
                                </div>
                                <div className={classes.FieldControl2}>
                                    <label for="highVisible">High Visible Value</label>
                                    <Input name="highVisible" id="highVisible" type="number" />
                                </div>
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl2}>
                                    <ToggleButton values={values} label={'Enable Line Min'} name={'enableLineMin'} />
                                </div>
                                <div className={classes.FieldControl2}>
                                    <ToggleButton values={values} label={'Enable Line Max'} name={'enableLineMax'} />
                                </div>
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl2}>
                                    {values.enableLineMin && (
                                        <>
                                            <label className={classes.FieldControl} for="lineMin">
                                                Line Min Value
                                            </label>
                                            <Input name="lineMin" id="lineMin" type="number" />
                                        </>
                                    )}
                                </div>
                                <div className={classes.FieldControl2}>
                                    {values.enableLineMax && (
                                        <>
                                            <label className={classes.FieldControl} for="lineMax">
                                                Line Max Value
                                            </label>
                                            <Input name="lineMax" id="lineMax" type="number" />
                                        </>
                                    )}
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button className="btn-primary" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default SystemConfig;
