import classes from '../../../styles/CreateDevice.module.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import Typography from '../../../components/Typography/Typography';
import { Form, Formik, ErrorMessage } from 'formik';
import { DropdownComponent, Input, ToggleButton } from '../../../components/Inputs/Input';
import 'react-toggle/style.css'; // for ES6 modules
import { FCR_Aggrements_Days } from '../../../constants';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CreateAggrementValidator } from '../../../validations/RevenueSplit/fcrAgreementValidator';
import { CustomerService } from '../../../services/CustomerService';
import { useLoader } from '../../../hooks';
import { FcrAggrementService } from '../../../services/fcrAggrement';
import Dropdown from '../../../components/Inputs/Dropdown';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import { AdminEnablerService } from '../../../services/EnablerService';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import _ from 'lodash';
import { MenuItem, Menu, ControlledMenu } from '@szhsin/react-menu';
import { UilMinusCircle } from '@iconscout/react-unicons';
import { UilPlusCircle } from '@iconscout/react-unicons';
import { Capitalize } from '../../../utils/stringHelper';

const instanceDefaultValues = (licenseId, enablers = {}) => ({
    revenueSplit: 0,
    revenueSplitPartner: 0,
    revenueSplitCustomer: 0,
    enablers: enablers,
    activeDays: {
        sunday: {
            enabled: false,
            enabled1: false,
        },
        monday: {
            enabled: false,
            enabled1: false,
        },
        tuesday: {
            enabled: false,
            enabled1: false,
        },
        wednesday: {
            enabled: false,
            enabled1: false,
        },
        thursday: {
            enabled: false,
            enabled1: false,
        },
        friday: {
            enabled: false,
            enabled1: false,
        },
        saturday: {
            enabled: false,
            enabled1: false,
        },
    },
    licenseId,
});

let defaultEnablersValues = {};

const getInitialValues = (editAggrement = {}, prefillGroup = null, customerId) => {
    if (!editAggrement?._id) {
        return {
            startDate: moment().format('YYYY-MM-DD'),
            customer: customerId,
            // endDate: eDate,
            market: '',
            enableEndDate: false,
            instances: {},
        };
    } else {
        return {
            startDate: moment(editAggrement.startDate).format('YYYY-MM-DD'),
            customer: editAggrement.customerId,
            enableEndDate: editAggrement.enableEndDate,
            endDate: editAggrement.endDate ? (editAggrement.endDate === '--' ? null : moment(editAggrement.endDate).format('YYYY-MM-DD')) : null,
            market: editAggrement?.market,
            instances: editAggrement?.instances || {},
        };
    }
};

const CreateFCRAggrement = ({ editAggrement = null, setRefresh, prefillGroup, regulations, customerId = '', biddingMarkets }) => {
    const menuRef = useRef(null);
    const today = new Date();
    const tomorrow = new Date();
    const [eDate, setEdate] = useState(tomorrow);
    const [sDate, setSdate] = useState(today);
    const [formError, setErrors] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [enablers, setEnablers] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const [licenseOption, setLicenseOption] = useState([]);
    const [instanceOption, setInstanceOption] = useState([]);
    const [allInstance, setAllInstance] = useState([]);
    const [state, setState] = useState({
        showMenu: false,
        selectedInstance: {},
        selectedLicense: {},
        selectedMarket: {},
    });

    const [initialValues, setInitialValues] = useState(getInitialValues(editAggrement, prefillGroup, customerId));
    const [customers, setCustomers] = useState([{ label: 'None', value: '' }]);

    const markets = biddingMarkets.map((market) => ({
        label: market,
        value: market,
    }));

    const fcrStartHourSlots = [
        ...Array.from({ length: 6 }).map((item, i) => ({
            label: String(i * 4),
            value: i * 4,
        })),
    ];

    const fcrEndHourSlots = [
        ...Array.from({ length: 6 }).map((item, i) => ({
            label: String((i + 1) * 4),
            value: (i + 1) * 4,
        })),
    ];

    const ffrStartHourSlots = [
        ...Array.from({ length: 24 }).map((item, i) => ({
            label: String(i),
            value: i,
        })),
    ];

    const ffrEndHourSlots = [
        ...Array.from({ length: 24 }).map((item, i) => ({
            label: String(i + 1),
            value: i + 1,
        })),
    ];

    useEffect(() => {
        getCustomers();
    }, []);

    const getCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const handleFetchSuccess = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
                license: element.license,
            });
        });
        setCustomers(temp);
    };

    const returnValidationErrors = (days) => {
        let error = false;
        Object.keys(days).forEach((eachDay) => {
            const { enabled, startHour, endHour, enabled1, startHour1, endHour1 } = days[eachDay];
            if (enabled) {
                if (startHour > endHour) {
                    setErrors('Start Hour cannot be greater than End hour - ' + eachDay);
                    error = true;
                } else if (startHour === endHour) {
                    setErrors('Start Hour and End Hour cannot be same - ' + eachDay);
                    error = true;
                }
            }

            if (enabled1) {
                if (startHour1 > endHour1) {
                    setErrors('Start Hour1 cannot be greater than End hour - ' + eachDay);
                    error = true;
                } else if (startHour1 === endHour1) {
                    setErrors('Start Hour1 and End Hour cannot be same - ' + eachDay);
                    error = true;
                }
            }

            if (enabled && enabled1) {
                if (startHour <= startHour1 && startHour1 < endHour) {
                    setErrors('Overlapping hours not allowed - ' + eachDay);
                    error = true;
                }
                if (startHour1 <= startHour && startHour < endHour1) {
                    setErrors('Overlapping hours not allowed - ' + eachDay);
                    error = true;
                }
            }
            // if (enabled && enabled1) {
            //   if (((startHour >= startHour1) && (startHour <= endHour1)) || ((endHour > startHour1) )) {
            //     setErrors('Start Hour End Hour cannot be in between Range of Start Hour1 and End Hour1 - ' + (eachDay));
            //     error = true;
            //   }
            //   if (((startHour1 >= startHour) && (startHour1 <= endHour)) || ((endHour1 > startHour) )) {
            //     setErrors('Start Hour End Hour cannot be in between Range of Start Hour1 and End Hour1 - ' + (eachDay));
            //     error = true;
            //   }

            // }
        });
        return error;
    };

    const handleSubmit = (values, resetForm) => {
        let newPayLoad = {
            ...values,
        };

        delete newPayLoad.startDatecet;
        delete newPayLoad.endDatecet;
        delete newPayLoad?.instance;
        delete newPayLoad?.license;
        newPayLoad.startDate = new Date(newPayLoad?.startDate);
        newPayLoad.endDate = newPayLoad?.enableEndDate ? newPayLoad.endDate : null;

        for (const key in newPayLoad?.instances) {
            if (newPayLoad.instances[key]?.activeDays) {
                if (returnValidationErrors(newPayLoad.instances[key]?.activeDays)) return;
            }
        }
        if (editAggrement?._id) {
            FcrAggrementService.Update(editAggrement?._id, { customerId: editAggrement.customerId }, newPayLoad, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            FcrAggrementService.Create({ customerId: values.customer }, newPayLoad, startLoader, (data) => handleCreateSuccess(data, resetForm), handleError, stopLoader);
        }
    };

    const handleCreateSuccess = (data, resetForm) => {
        toast.success('Agreement created!');
        resetForm();
    };

    const handleUpdateSuccess = (data, resetForm) => {
        toast.success('Agreement updated!');
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    useEffect(() => {
        if (selectedCustomer?.value) {
            getEnabler();
            getCustomerInstances();
        }
    }, [selectedCustomer]);

    const getEnabler = () => {
        AdminEnablerService.GetEnabler(selectedCustomer.value, startLoader, handleEnablerSuccess, handleError, stopLoader);
    };

    const handleEnablerSuccess = ({ data }) => {
        setEnablers(data?.data?.enablers);
        let temp = {};
        data?.data?.enablers.map((item) => {
            temp[item._id] = 0;
        });
        defaultEnablersValues = temp;
    };

    const getCustomerInstances = () => {
        CustomerService.GetCustomerInstances(selectedCustomer.value, startLoader, handleCustomerInstancesSuccess, handleError, stopLoader);
    };

    const handleCustomerInstancesSuccess = ({ data }) => {
        let tempLicenseOption = [];
        let tempAllInstances = [];
        data?.data?.licenses.map((item) => {
            tempLicenseOption.push({
                label: Capitalize(item._id),
                value: item.licenseId,
                instance: item.groups,
            });
            tempAllInstances.push(...item.groups);
        });
        setLicenseOption(tempLicenseOption);
        setAllInstance(tempAllInstances);
    };

    useEffect(() => {
        if (state.selectedLicense?.value) {
            let temp = [];
            state.selectedLicense.instance.map((item) => {
                if (item?.market?.includes(state?.selectedMarket?.value) && !initialValues?.instances?.[item._id]) {
                    temp.push({
                        label: item.name,
                        value: item._id,
                    });
                }
            });
            setInstanceOption(temp);
        } else {
            setInstanceOption([]);
        }
    }, [state]);

    const menuItemClassName = (hover, active) => (hover ? classes.MenuItemHover : '');

    return (
        <div className={classes.FormContainer}>
            <Typography content={!editAggrement?._id ? 'Create Agreement' : 'Edit Agreement'} />

            <div className={classes.FormContent}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={() => CreateAggrementValidator(initialValues.instances)}
                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl3}>
                                        <label for="customerId">
                                            Customer <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="customer"
                                            disabled={editAggrement?._id ? true : customerId ? true : false}
                                            options={customers}
                                            defaultValue={
                                                editAggrement?._id
                                                    ? {
                                                          label: editAggrement.customerName,
                                                          value: editAggrement.customerId,
                                                      }
                                                    : {
                                                          value: customerId,
                                                      }
                                            }
                                            onChange={setSelectedCustomer}
                                        />
                                    </div>
                                    <div className={classes.FieldControl3} style={{ zIndex: '2' }}>
                                        <label for="market">
                                            Market <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="market"
                                            options={markets}
                                            defaultValue={editAggrement?._id ? markets.filter((i) => i.value === editAggrement.market)[0] : {}}
                                            onChange={(e) => setState({ ...state, selectedMarket: e })}
                                        />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl3}>
                                        <label for="Start Date">
                                            Start Date <span className="required">*</span>
                                        </label>
                                        <DatePicker
                                            name="startDate"
                                            date={new Date(values.startDate)}
                                            setDate={(date) => {
                                                values.startDate = moment(date).format('YYYY-MM-DD');
                                            }}
                                            disabled={editAggrement?._id ? true : false}
                                        />
                                    </div>
                                    <div className={classes.FieldControl3}>
                                        <ToggleButton values={values} label={'End Date'} name={`enableEndDate`} />
                                    </div>
                                    {values.enableEndDate && (
                                        <div className={classes.FieldControl3}>
                                            <label for="End Date">
                                                End Date <span className="required">*</span>
                                            </label>
                                            <DatePicker
                                                name="endDate"
                                                date={values.endDate ? new Date(values.endDate) : new Date()}
                                                setDate={(date) => {
                                                    values.endDate = moment(date).format('YYYY-MM-DD');
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>

                                <div style={{ marginTop: '2vh' }}>
                                    <div className={classes.FieldControl3}>
                                        <label>Agreement</label>
                                    </div>
                                    <Accordion allowZeroExpanded>
                                        {Object.keys(values.instances).map((key, index) => {
                                            return (
                                                <>
                                                    <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        width: '96%',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {licenseOption.map((e) => {
                                                                            if (e?.value == values.instances[key]?.licenseId) return e?.label;
                                                                        })}{' '}
                                                                        {'>'}{' '}
                                                                        {allInstance.map((e) => {
                                                                            if (e?._id == key) return e?.name;
                                                                        })}
                                                                    </div>

                                                                    <UilMinusCircle
                                                                        size={'1.5vw'}
                                                                        style={{ color: 'var(--color-primary)' }}
                                                                        onClick={() => {
                                                                            setInitialValues(_.omit(initialValues, [`instances.${key}`]));
                                                                            props.setFieldValue(`instances.${key}`, undefined);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <div>
                                                                <div className={classes.InputContainer}>
                                                                    <div className={classes.FieldControl3}>
                                                                        <label for="Revenue Split(%)">
                                                                            Revenue Split SC (%) <span className="required">*</span>
                                                                        </label>
                                                                        <Input name={`instances.[${key}].revenueSplit`} id="revenueSplit" type="number" />
                                                                    </div>
                                                                    <div className={classes.FieldControl3}>
                                                                        <label for="Revenue Split(%)">
                                                                            Revenue Split Partner (%) <span className="required">*</span>
                                                                        </label>
                                                                        <Input name={`instances.[${key}].revenueSplitPartner`} id="revenueSplit" type="number" />
                                                                    </div>
                                                                    <div className={classes.FieldControl3}>
                                                                        <label for="Revenue Split(%)">
                                                                            Revenue Split Customer (%) <span className="required">*</span>
                                                                        </label>
                                                                        <Input name={`instances.[${key}].revenueSplitCustomer`} id="revenueSplit" type="number" />
                                                                    </div>
                                                                </div>

                                                                <div className={classes.InputContainer}>
                                                                    {enablers.map((item, index) => (
                                                                        <div className={classes.FieldControl3}>
                                                                            <label for="Revenue Split(%)">
                                                                                Revenue Split Enabler {item.name} (%) <span className="required">*</span>
                                                                            </label>
                                                                            <Input name={`instances.[${key}].enablers.${item._id}`} id="revenueSplit" type="number" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <ErrorMessage name={`instances.[${key}].enablers`} component={'div'} className="error-msg" />
                                                                {errors ? <div className="error-msg">{formError}</div> : null}
                                                                {/* {FCR_Aggrements_Days.map((el) => (
                                  <>
                                    <div className={classes.Title}>
                                      {el.title.toUpperCase()}
                                    </div>
                                    <div className={classes.InputContainer}>
                                      <div className={classes.FieldControl3}>
                                        <ToggleButton
                                          values={values}
                                          label={"Enabled"}
                                          name={`instances.[${key}].${el.enabled}`}
                                        />
                                      </div>
                                      {values.instances[key]?.[
                                        `${el?.enabledProperty}`
                                      ]?.[el?.title]?.["enabled"] && (
                                        <>
                                          <div
                                            className={classes.FieldControl3}
                                          >
                                            <label for="userRole">
                                              Start Hour Slot1{" "}
                                              <span className="required">
                                                *
                                              </span>
                                            </label>

                                            <Dropdown
                                              name={`instances.[${key}].${el.startHour}`}
                                              options={
                                                values.market !== "FFR"
                                                  ? fcrStartHourSlots
                                                  : ffrStartHourSlots
                                              }
                                              defaultValue={
                                                editAggrement?._id && {
                                                  label: String(
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "startHour"
                                                    ]
                                                  ),
                                                  value:
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "startHour"
                                                    ],
                                                }
                                              }
                                            />
                                          </div>
                                          <div
                                            className={classes.FieldControl3}
                                          >
                                            <label for="userRole">
                                              End Hour Slot1{" "}
                                              <span className="required">
                                                *
                                              </span>
                                            </label>

                                            <Dropdown
                                              name={`instances.[${key}].${el.endHour}`}
                                              options={
                                                values.market !== "FFR"
                                                  ? fcrEndHourSlots
                                                  : ffrEndHourSlots
                                              }
                                              defaultValue={
                                                editAggrement?._id && {
                                                  label: String(
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "endHour"
                                                    ]
                                                  ),
                                                  value:
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "endHour"
                                                    ],
                                                }
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className={classes.InputContainer}>
                                      <div className={classes.FieldControl3}>
                                        <ToggleButton
                                          values={values}
                                          label={"Enabled"}
                                          name={`instances.[${key}].${el.enabled1}`}
                                        />
                                      </div>
                                      {values.instances[key]?.[
                                        el?.enabledProperty
                                      ]?.[el?.title]?.["enabled1"] && (
                                        <>
                                          <div
                                            className={classes.FieldControl3}
                                          >
                                            <label for="userRole">
                                              Start Hour Slot2{" "}
                                              <span className="required">
                                                *
                                              </span>
                                            </label>

                                            <Dropdown
                                              name={`instances.[${key}].${el.startHour1}`}
                                              options={
                                                values.market !== "FFR"
                                                  ? fcrStartHourSlots
                                                  : ffrStartHourSlots
                                              }
                                              defaultValue={
                                                editAggrement?._id && {
                                                  label: String(
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "startHour1"
                                                    ]
                                                  ),
                                                  value:
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "startHour1"
                                                    ],
                                                }
                                              }
                                            />
                                          </div>
                                          <div
                                            className={classes.FieldControl3}
                                          >
                                            <label for="userRole">
                                              End Hour Slot2{" "}
                                              <span className="required">
                                                *
                                              </span>
                                            </label>

                                            <Dropdown
                                              name={`instances.[${key}].${el.endHour1}`}
                                              options={
                                                values.market !== "FFR"
                                                  ? fcrEndHourSlots
                                                  : ffrEndHourSlots
                                              }
                                              defaultValue={
                                                editAggrement?._id && {
                                                  label: String(
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "endHour1"
                                                    ]
                                                  ),
                                                  value:
                                                    editAggrement.instances[
                                                      `${key}`
                                                    ]?.activeDays?.[el.title]?.[
                                                      "endHour1"
                                                    ],
                                                }
                                              }
                                            />
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ))} */}
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                </>
                                            );
                                        })}
                                    </Accordion>

                                    <div className={classes.addInputActionWrapper} ref={menuRef}>
                                        <UilPlusCircle
                                            size={'1.5vw'}
                                            style={{
                                                color: 'var(--color-primary)',
                                                cursor: 'pointer',
                                                zIndex: '1',
                                            }}
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    showMenu: state.showMenu ? false : true,
                                                })
                                            }
                                        ></UilPlusCircle>
                                        <ControlledMenu state={state.showMenu} align="end" menuClassName={classes.ProfileMenu} anchorRef={menuRef}>
                                            <MenuItem styles={{ hover: { backgroundColor: '#ee1' } }} className={menuItemClassName}>
                                                <div>
                                                    <div style={{ display: 'flex', gap: '1vw' }}>
                                                        <div className={classes.FieldControl3}>
                                                            <label for="license">
                                                                Select License
                                                                <span className="required">*</span>
                                                            </label>
                                                            <Dropdown
                                                                name={`license`}
                                                                options={licenseOption}
                                                                onChange={(e) => {
                                                                    setState({
                                                                        ...state,
                                                                        selectedLicense: e,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className={classes.FieldControl3}>
                                                            <label for="instance">
                                                                Select Instance
                                                                <span className="required">*</span>
                                                            </label>
                                                            <Dropdown
                                                                name={`instance`}
                                                                options={instanceOption}
                                                                onChange={(e) => {
                                                                    setState({
                                                                        ...state,
                                                                        selectedInstance: e,
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={classes.ButtonContainer}>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn-secondary"
                                                                onClick={() =>
                                                                    setState({
                                                                        ...state,
                                                                        showMenu: false,
                                                                        selectedLicense: {},
                                                                        selectedInstance: {},
                                                                    })
                                                                }
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                        <div>
                                                            <button
                                                                type="button"
                                                                className="btn-primary"
                                                                onClick={() => {
                                                                    if (state.selectedInstance?.value) {
                                                                        setInitialValues(
                                                                            _.set(
                                                                                initialValues,
                                                                                `instances.${state.selectedInstance?.value}`,
                                                                                instanceDefaultValues(state.selectedLicense?.value, defaultEnablersValues)
                                                                            )
                                                                        );

                                                                        props.setFieldValue(
                                                                            `instances.${state.selectedInstance?.value}`,
                                                                            instanceDefaultValues(state.selectedLicense?.value, defaultEnablersValues)
                                                                        );

                                                                        setState({
                                                                            ...state,
                                                                            selectedLicense: {},
                                                                            selectedInstance: {},
                                                                            showMenu: false,
                                                                        });
                                                                    } else {
                                                                        toast.error('Please select instance');
                                                                    }
                                                                }}
                                                            >
                                                                Submit
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </MenuItem>
                                        </ControlledMenu>
                                    </div>
                                </div>

                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateFCRAggrement;
