import { HttpClient } from '../utils/httpClient';

const PATH = {
    asset: '/root/shipment-asset',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.asset, payload).then(callback).catch(error).finally(next);
};

const GetAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.asset).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.asset}/${id}`).then(callback).catch(error).finally(next);
};

export const RootShipmentAssetService = {
    Create,
    GetAll,
    Delete,
};
