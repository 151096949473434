import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/license/create',
    get: '/admin/license',
    createV2: '/admin/license/create-v2',
    readV2: '/admin/license/read-v2',
    deleteV2: '/admin/license/delete-v2',
    updateV2: '/admin/license/update-v2',
    updateTabSetting: '/admin/license/update-tab-setting',
    getCustomerLicense: '/admin/license/customer',
    getInstancesByLicenseTypeId: '/admin/license/instance',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Read = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.get}/${id}`).then(callback).catch(error).finally(next);
};

const CreateV2 = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.createV2, payload).then(callback).catch(error).finally(next);
};

const ReadV2 = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readV2}/${id}`).then(callback).catch(error).finally(next);
};

const deleteV2 = (customerId, licenseId, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteV2}/${customerId}/${licenseId}`).then(callback).catch(error).finally(next);
};

const updateV2 = (customerId, licenseId, body, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateV2}/${customerId}/${licenseId}`, body).then(callback).catch(error).finally(next);
};

const updateSettings = (customerId, licenseId, body, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateTabSetting}/${customerId}/${licenseId}`, body).then(callback).catch(error).finally(next);
};

const GetCustomerLicense = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getCustomerLicense}/${id}`).then(callback).catch(error).finally(next);
};

const GetInstancesByLicenseTypeId = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getInstancesByLicenseTypeId}/${id}`, { params }).then(callback).catch(error).finally(next);
};

export const LicenseService = {
    Create,
    Read,
    CreateV2,
    ReadV2,
    deleteV2,
    updateV2,
    updateSettings,
    GetCustomerLicense,
    GetInstancesByLicenseTypeId,
};
