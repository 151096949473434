import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const create = yup.object().shape({
    name: yup
        .string()
        .required()
        .test('name', 'no space allowed', (val) => !val?.includes(' ')),
    licenseCode: yup.string().required(),
    defaultMetaKeys: yup.array().of(yup.string().required(ERRORS.required)).optional(),
});
