import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const activationProfileValidator = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        signalId: yup.string().required(ERRORS.required),
        instanceId: yup.string().required(ERRORS.required),
        customerId: yup.string().required(ERRORS.required),
        market: yup.string().required(ERRORS.required),
        totalCapacity: yup.number().required(ERRORS.required),
        upperLimit: yup.number().required(ERRORS.required),
        lowerLimit: yup.number().required(ERRORS.required),
        basePower: yup.boolean().required(ERRORS.required),
        ctFactor: yup.number().required(ERRORS.required),
        emails: yup.array().of(yup.string().email(ERRORS.email)).required(ERRORS.required),
    });
};
