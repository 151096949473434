// Standard library imports
import React, { useState } from 'react';
// External library imports
import _ from 'lodash';
// Internal module imports
import { NotesLogsIndex } from '../../../Bidding/ModalContent/NotesLogsIndex';

export const BidNotes = ({ data, setOpen }) => {
    const renderComponent = () => {
        return <NotesLogsIndex data={data} setOpen={setOpen} />;
    };
    return <div>{renderComponent()}</div>;
};
