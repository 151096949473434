// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    read: '/root/afrr/redis-data',
    logs: '/root/afrr/logs',
    powerLogs: '/root/afrr/power-logs',
};

const GetRedisData = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read).then(callback).catch(error).finally(next);
};
const GetLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.logs, { params }).then(callback).catch(error).finally(next);
};

const GetPowerLogs = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.powerLogs, { params }).then(callback).catch(error).finally(next);
};

export const RootAfrrService = {
    GetRedisData,
    GetLogs,
    GetPowerLogs,
};
