// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/snooze',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

export const SnoozeService = { Create };
