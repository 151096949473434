// Standard Library Imports
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

// External Library Imports

// Internal Module Imports
import Typography from '../../../components/Typography/Typography';
import TabsComponent from '../../../components/Tabs/Tabs';

// CSS Imports
import classes from '../../../styles/Bids.module.css';
import EdgeClasses from '../Edge.module.css';
import Table from '../../../components/Table/Table';

const ShipmentInfo = ({ data, setInfoModal, assetOptions, packageTypeCodeOptions }) => {
    const [shipmentInfoTable, setShipmentInfoTable] = useState([]);
    const [consigneeInfoTable, setConsigneeInfoTable] = useState([]);
    const [assetsInfoTable, setAssetsInfoTable] = useState([]);

    useEffect(() => {
        if (Object.keys(data).length && assetOptions) {
            const shipmentInfo = [];
            const consigneeInfo = [];
            const assetsInfo = [];

            if (data.consignee) {
                const consignee = data.consignee;
                consigneeInfo.push({ name: 'Consignee Name', value: consignee.consigneeName });
                consigneeInfo.push({ name: 'Consignee Contact Name', value: consignee.consigneeContactName });
                consignee.consigneePhone && consigneeInfo.push({ name: 'Consignee Phone', value: consignee.consigneePhone });
                consignee.consigneeEmail && consigneeInfo.push({ name: 'Consignee Email', value: consignee.consigneeEmail });
                consigneeInfo.push({ name: 'Consignee Street1', value: consignee.consigneeStreet1 });
                consignee.consigneeStreet2 && consigneeInfo.push({ name: 'Consignee Street2', value: consignee.consigneeStreet2 });
                consigneeInfo.push({ name: 'Consignee City', value: consignee.consigneeCity });
                consigneeInfo.push({ name: 'Consignee Postal Code', value: consignee.consigneePostalCode });
                consigneeInfo.push({ name: 'Consignee Country Code', value: consignee.consigneeCountryCode });
            }

            if (data.assets) {
                data.assets.forEach((asset) => {
                    assetsInfo.push({
                        name: assetOptions.find((option) => option.value === asset.assetId).label,
                        value: asset.quantity,
                    });
                });
            }

            shipmentInfo.push({ name: 'Shipment Weight', value: `${data.assetWeight}${data.assetUnit}` });
            shipmentInfo.push({
                name: 'Shipment Type',
                value: packageTypeCodeOptions.find((option) => option.value === data.packageTypeCode).label,
            });
            shipmentInfo.push({ name: 'Note', value: data.notes ? data.notes : '--' });

            setAssetsInfoTable(assetsInfo);
            setShipmentInfoTable(shipmentInfo);
            setConsigneeInfoTable(consigneeInfo);
        }
    }, [data]);

    const tabs = [
        {
            name: 'Shipment Info',
            component: (
                <div className={EdgeClasses.ShipmentInfoContainer}>
                    <div className={EdgeClasses.EdgeInfoModalHeader}>
                        <Typography content={'Assets Information'} />
                    </div>
                    <div>
                        <Table head={['Asset', 'Quantity']} keys={['name', 'value']} data={assetsInfoTable} />
                    </div>
                    <div className={EdgeClasses.EdgeInfoModalHeader}>
                        <Typography content={'Shipment Information'} />
                    </div>
                    <div>
                        <Table head={['Label', 'Value']} keys={['name', 'value']} data={shipmentInfoTable} />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="button" className="btn-primary" onClick={() => setInfoModal({ status: false, data: {} })}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            name: 'Receiver Info',
            component: (
                <div className={EdgeClasses.ShipmentInfoContainer}>
                    <div className={EdgeClasses.EdgeInfoModalHeader}>
                        <Typography content={'Receiver Information'} />
                    </div>
                    <div>
                        <Table head={['Label', 'Value']} keys={['name', 'value']} data={consigneeInfoTable} />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="button" className="btn-primary" onClick={() => setInfoModal({ status: false, data: {} })}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default ShipmentInfo;
