import React, { useEffect, useState } from 'react';
import classes from '../../../../styles/CapacityContent.module.css';
import Table from '../../../../components/Table/Table';
import { useLoader } from '../../../../hooks';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { toast } from 'react-toastify';

const Status = ({ bundleBidId, setOpen, getBundleBids }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (bundleBidId) {
            BundleBiddingService.CheckBidStatus(bundleBidId, startLoader, handleCheckSuccess, handleError, stopLoader);
        }
    }, []);

    const handleCheckSuccess = ({ data }) => {
        setData(data.data);
        getBundleBids();
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div className={classes.CapacityContent} style={{ height: '70vh', width: 'auto', overflowY: 'auto' }}>
            <div className={classes.Title}>Status</div>
            <div className={classes.Content}>
                <div className={classes.Container}>
                    <div style={{ marginRight: '1vw' }}>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Response Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['name', 'value']} data={data?.bidResponse || []}></Table>
                    </div>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Bid Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['productName', 'status']} data={data?.bidStatus || []}></Table>
                    </div>
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div className="btn-primary" onClick={() => setOpen({ status: false, id: '' })} style={{ minWidth: 'auto' }}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default Status;
