// Standard library imports
import React, { useEffect, useState } from 'react';
// Internal module imports
import Typography from '../../../../components/Typography/Typography';
// External library imports
import _ from 'lodash';
// Css module imports
import classes from '../../../../styles/CapacityContent.module.css';
import bidClasses from '../../index.module.css';

const PriceContent = ({ bids, overallPrice = [] }) => {
    const [price, setPrice] = useState({
        primary: _.get(bids, [0, 'primary', 'price']),
        secondary: _.get(bids, [0, 'secondary', 'price']),
    });

    const length = Math.floor(overallPrice.length / 3);

    return (
        <div className={`${classes.CapacityContent} ${bidClasses.InfoModalContainer}`}>
            <div>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={'Overall Bid Price'} size={14} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.ContainerStart}>
                        {[0, 1, 2].map((n) => (
                            <table>
                                <tr>
                                    <th>Slot</th>
                                    <th>Hour</th>
                                    <th>Cost</th>
                                </tr>
                                {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                    key <= 23 ? (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {key}-{key + 1}
                                            </td>
                                            <td>{_.get(overallPrice, [key], '--')}</td>
                                        </tr>
                                    ) : null
                                )}
                            </table>
                        ))}
                    </div>
                </div>
            </div>
            {_.get(price, 'primary', []).length ? (
                <div>
                    <div className={bidClasses.InfoModalHeading}>
                        <Typography content={'Primary Bid Price'} size={14} />
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.ContainerStart}>
                            {[0, 1, 2].map((n) => (
                                <table>
                                    <tr>
                                        <th>Slot</th>
                                        <th>Hour</th>
                                        <th>Cost</th>
                                    </tr>
                                    {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                        key <= 23 ? (
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>
                                                    {key}-{key + 1}
                                                </td>
                                                <td>{_.get(price, ['primary', key])}</td>
                                            </tr>
                                        ) : null
                                    )}
                                </table>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
            {_.get(price, 'secondary', []).length ? (
                <div>
                    <div className={bidClasses.InfoModalHeading}>
                        <Typography content={'Secondary Bid Price'} size={14} />
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.ContainerStart}>
                            {[0, 1, 2].map((n) => (
                                <table>
                                    <tr>
                                        <th>Slot</th>
                                        <th>Hour</th>
                                        <th>Cost</th>
                                    </tr>
                                    {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                        key <= 23 ? (
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>
                                                    {key}-{key + 1}
                                                </td>
                                                <td>{_.get(price, ['secondary', key])}</td>
                                            </tr>
                                        ) : null
                                    )}
                                </table>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default PriceContent;
