// External library imports
import * as yup from 'yup';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

export const createTransaction = () => {
    return yup.object().shape({
        type: yup.string().required(ERRORS.required),
        amount: yup.number().required(ERRORS.required),
        description: yup.string().required(ERRORS.required),
    });
};
