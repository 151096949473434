// Standard library imports
import React, { useState, useEffect } from 'react';

// External library import
import { toast } from 'react-toastify';
import { UilTrash } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import { useLoader } from '../../../../hooks';
import Table from '../../../../components/Table/Table';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import ShipmentAssetModal from '../ModalComponent/ShipmentAssetModal';
import { RootShipmentAssetService } from '../../../../services/RootShipmentAssetService';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';

// CSS Imports
import classes from '../../../Edge/EdgeError/EdgeError.module.css';
import bidClasses from '../../../../styles/Bids.module.css';

const ShipmentAssets = ({ updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [totalCount, setTotalCount] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [assetModal, setAssetModal] = useState(queryParamsData.assetModal || false);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });

    useEffect(() => {
        getShipmentAssets();
    }, []);

    useEffect(() => {
        updateQueryParams('assetModal', assetModal);
    }, [assetModal]);

    const getShipmentAssets = () => {
        RootShipmentAssetService.GetAll(
            () => startLoader('shipmentAsset'),
            handleAssetsSuccess,
            handleError,
            () => stopLoader('shipmentAsset')
        );
    };

    const handleAssetsSuccess = ({ data }) => {
        const processedData = data.data.map((item, index) => {
            return {
                's.no': index + 1,
                id: item._id,
                name:
                    item.name.length > 40 ? (
                        <CustomTooltip content={item.name}>
                            <div>{item.name.substring(0, 40) + '...'}</div>
                        </CustomTooltip>
                    ) : (
                        <div>{item.name}</div>
                    ),
                description:
                    item.description.length > 40 ? (
                        <CustomTooltip content={item.description}>
                            <div>{item.description.substring(0, 40) + '...'}</div>
                        </CustomTooltip>
                    ) : item.description ? (
                        <div>{item.description}</div>
                    ) : (
                        '--'
                    ),
                createdAt: item.createdAt,
                action: (
                    <CustomTooltip content={'Delete'}>
                        <div className={classes.Action} onClick={() => setDeleteModal({ status: true, id: item._id })}>
                            <div>
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </div>
                    </CustomTooltip>
                ),
            };
        });
        setTableData(processedData);
        setTotalCount(processedData.length);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleAssetDeleteSuccess = () => {
        toast.success('Asset deleted successfully.');
        getShipmentAssets();
    };

    const handleDelete = () => {
        if (deleteModal.id) {
            RootShipmentAssetService.Delete(
                deleteModal.id,
                () => startLoader('deleteShipmentAsset'),
                handleAssetDeleteSuccess,
                handleError,
                () => stopLoader('deleteShipmentAsset')
            );
        }
    };

    return (
        <div className={bidClasses.Bids}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent isOpen={assetModal} setOpen={setAssetModal}>
                <ShipmentAssetModal
                    setOpen={(e, isFetchAssets = false) => {
                        setAssetModal(e);
                        isFetchAssets && getShipmentAssets();
                    }}
                />
            </ModalComponent>
            <div className={classes.Header}>
                <div className={classes.ErrorDropdownContainer}>
                    <Typography content={'Assets'} />
                    <div className={bidClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount || 0} />
                        </span>
                    </div>
                </div>
                <div>
                    <button onClick={() => setAssetModal(true)} className="btn-primary" style={{ marginTop: '0' }}>
                        Add Asset
                    </button>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Asset Id', 'Name', 'Description', 'Created At', 'Action']}
                    keys={['s.no', 'id', 'name', 'description', 'createdAt', 'action']}
                    data={tableData}
                />
            </div>
        </div>
    );
};

export default ShipmentAssets;
