import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/root/version',
    read_all: '/root/version',
    read: '/root/version',
    update: '/root/version',
    delete: '/root/version',
    notify: '/root/version/notify',
};

//Post call for creating a version history
const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

// //Hit for getting a particular version history based on id
// const Read = (id, start, callback, error, next) => {
//   start();
//   return HttpClient.get(`${PATH.read}/${id}`)
//     .then(callback)
//     .catch(error)
//     .finally(next);
// };

//Hit for getting all version history info from API
const ReadAll = (application, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params: { application } }).then(callback).catch(error).finally(next);
};

//Patch request for updating a version history (id as a param)
const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

//Delete request for deleting a version history based on id
const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

// send notifications for new Version History
const Notify = (id, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.notify}/${id}`).then(callback).catch(error).finally(next);
};

export const VersionHistoryService = {
    Create,
    ReadAll,
    Update,
    Delete,
    Notify,
};
