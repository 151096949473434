// Standard library imports
import React, { useContext, useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilPen, UilTrash, UilInfoCircle } from '@iconscout/react-unicons';
// Internal module imports
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import { useLoader } from '../../../../hooks';
import { RootService } from '../../../../services/RootSevice';
import classes from '../../../../styles/AllDevices.module.css';
import AdminClasses from '../Admin.module.css';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { SET_USER } from '../../../../constants';
import { AuthContext } from '../../../../context/AuthContext';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import Info from './ModalComponent/Info';

const Roots = ({ setCreateEditRoot, setSelectedIndex, tabslength }) => {
    const [startLoader, stopLoader] = useLoader();
    const [roots, setRoots] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        rootId: '',
    });
    const { dispatch: authDispatch } = useContext(AuthContext);
    const [infoModal, setInfoModal] = useState({
        status: false,
        data: {},
    });
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        RootService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };
    const handleSuccess = ({ data }) => {
        if (data.data?.length) {
            setRoots(
                data.data.map((item, index) => ({
                    ...item,
                    's.no': index + 1,
                    contact: item.contact ? `${item.countryCode ? item.countryCode : ''} ${item.contact}` : '--',
                    hasAccess: (
                        <div className={AdminClasses.ProfileIconContainer}>
                            {item.accessBlocked ? <div className={AdminClasses.AccessBlocked}></div> : <div className={AdminClasses.AccessNotBlocked}></div>}
                        </div>
                    ),
                    mongoDbUserName: item?.mongoDb?.userName || '--',
                    mongoDbPassword: item?.mongoDb?.password || '--',
                    action: (
                        <div className={AdminClasses.ActionContainer}>
                            <CustomTooltip content={'Info'}>
                                <div>
                                    <UilInfoCircle
                                        size={'1.2vw'}
                                        style={{ color: 'var(--color-primary)' }}
                                        onClick={() =>
                                            setInfoModal({
                                                status: true,
                                                data: item,
                                            })
                                        }
                                    />
                                </div>
                            </CustomTooltip>
                            <CustomTooltip content={'Edit Root'}>
                                <div
                                    onClick={() => {
                                        setCreateEditRoot({ status: true, data: item });
                                        setSelectedIndex(tabslength);
                                    }}
                                >
                                    <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </CustomTooltip>
                            <CustomTooltip content={'Delete Root'}>
                                <div onClick={() => setDeleteModal({ status: true, rootId: item._id })}>
                                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </CustomTooltip>
                        </div>
                    ),
                }))
            );
        } else {
            setRoots([]);
        }
    };

    const handleDelete = () => {
        const data = {
            id: deleteModal.rootId,
        };
        RootService.Delete(data, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };
    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Root deleted');
            setDeleteModal({ status: false, rootId: '' });
            getData();
        }
    };
    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <div className={classes.AllDevices}>
                <ModalComponent isOpen={infoModal.status} setOpen={(status) => setInfoModal({ ...infoModal, status })}>
                    <div>
                        <Info infoModal={infoModal.data} setInfoModal={setInfoModal} />
                    </div>
                </ModalComponent>
                <DeleteModal
                    deletefunction={handleDelete}
                    opendeleteModal={deleteModal.status}
                    setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
                ></DeleteModal>
                <div className={classes.Header}>
                    <div>
                        <Typography content="All Roots" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={roots.length} />
                            </span>
                        </div>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn-primary"
                            style={{ marginTop: '0' }}
                            onClick={() => {
                                setCreateEditRoot({ status: true, data: {} });
                                setSelectedIndex(tabslength);
                            }}
                        >
                            Create Root
                        </button>
                    </div>
                </div>
                <div>
                    <Table
                        head={['S.No', '', 'Name', 'Contact', 'Email', 'Access', 'Action']}
                        keys={['s.no', 'profile', 'name', 'contact', 'email', 'hasAccess', 'action']}
                        data={roots}
                    />
                </div>
            </div>
        </div>
    );
};

export default Roots;
