// Standard library imports
import React, { useState, useEffect } from 'react';

// External module imports
import { toast } from 'react-toastify';

// Internal module imports
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { useLoader } from '../../../../../hooks';
import { RootSummaryService } from '../../../../../services/RootSummaryService';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import monitoringClasses from '../../Monitoring.module.css';

const LIMIT = 15;

const CustomerMonitoringSummary = ({ admins = [], queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [proccessedData, setProccessedData] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        setSelectedAdmin(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : admins[0]);
    }, [admins]);

    useEffect(() => {
        getData();
    }, [selectedAdmin, skip]);

    const getData = () => {
        RootSummaryService.GetToggleMonitoring(
            {
                type: 'customer',
                adminId: selectedAdmin.value,
                limit: LIMIT,
                skip: skip,
            },
            startLoader,
            handleSuccess,
            handleError,
            stopLoader
        );
    };
    const handleSuccess = ({ data }) => {
        const customer = data?.data.data.map((item, index) => ({
            adminName: item.adminId?.name || '--',
            customerName: item.name,
            status: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.dataMonitoring ? <div className={monitoringClasses.AccessNotBlocked}></div> : <div className={monitoringClasses.AccessBlocked}></div>}
                </div>
            ),
        }));

        setProccessedData(customer);
        setTotalCount(data.data.count);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={monitoringClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={monitoringClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select Admin</label>
                                <DropdownComponent
                                    name="adminId"
                                    options={admins}
                                    defaultValue={selectedAdmin}
                                    onChange={(e) => {
                                        setSelectedAdmin(e);
                                        updateQueryParams('admin', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices} style={{ marginBottom: '1.3vh' }}>
                <div className={classes.Header}>
                    <div>
                        <Typography content={'Customer Monitoring'} />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={['Admin Name', 'Customer Name', 'Status']}
                            keys={['adminName', 'customerName', 'status']}
                            data={proccessedData}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerMonitoringSummary;
