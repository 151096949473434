// Standard library imports
import React from 'react';
// Ineternal module imports
import ResultIdUpdate from '../../../Bidding/ModalContent/ResultId';

export const ResultId = ({ market, bid, fetchBids, setOpen }) => {
    const renderComponent = () => {
        return <ResultIdUpdate bid={bid} market={market} setModal={setOpen} fetchBids={fetchBids} />;
    };
    return <div>{renderComponent()}</div>;
};
