import React, { useEffect, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import { toast } from 'react-toastify';
import { BiddingService } from '../../../../services/BiddingService';
import { useLoader } from '../../../../hooks';
import classes from '../../../../styles/CapacityContent.module.css';
import bidClasses from '../index.module.css';
import _ from 'lodash';

export const OverallInfo = ({ params }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState({
        approvedCapacity: 0,
        energinetCapacity: 0,
        hourlyCapacity: [],
        energinetHourlyCapacity: [],
    });
    const [length, setLength] = useState(8);

    useEffect(() => {
        if (Object.keys(params).length) getData();
    }, [params]);

    const getData = () => {
        BiddingService.GetOverallCapacity(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        setData(data.data);
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <div className={`${classes.CapacityContent} ${bidClasses.InfoModalContainer}`}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={`Total Approved Capacity : ${_.get(data, ['approvedCapacity'], 0).toFixed(2)}`} size={14} />
                </div>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={`Total Energinet Capacity : ${_.get(data, ['energinetCapacity'], 0).toFixed(2)}`} size={14} />
                </div>
            </div>
            <div style={{ marginTop: '3vh' }}>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={'Instance Capacity'} size={14} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.ContainerStart}>
                        {[0, 1, 2].map((n) => (
                            <table>
                                <tr>
                                    <th>Slot</th>
                                    <th>Hour</th>
                                    <th>Capacity</th>
                                </tr>
                                {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                    key <= 23 ? (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {key}-{key + 1}
                                            </td>
                                            <td>{_.get(data, ['hourlyCapacity', key])}</td>
                                        </tr>
                                    ) : null
                                )}
                            </table>
                        ))}
                    </div>
                </div>
            </div>

            <div>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={'Energinet Capacity'} size={14} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.ContainerStart}>
                        {[0, 1, 2].map((n) => (
                            <table>
                                <tr>
                                    <th>Slot</th>
                                    <th>Hour</th>
                                    <th>Capacity</th>
                                </tr>
                                {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                    key <= 23 ? (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {key}-{key + 1}
                                            </td>
                                            <td>{_.get(data, ['energinetHourlyCapacity', key])}</td>
                                        </tr>
                                    ) : null
                                )}
                            </table>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
