// Standard Library imports
import React, { useEffect, useState } from 'react';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';
import OTAClasses from './index.module.css';

// External library imports
import { toast } from 'react-toastify';
import { UilPlay, UilInfoCircle } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import CreateBatch from './ModalComponent/CreateBatch';
import { EdgeOTAService } from '../../../../services/edgeOtaService';
import { useLoader } from '../../../../hooks';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import BatchInfo from './ModalComponent/BatchInfo';

const LIMIT = 15;
const statusColors = {
    CREATED: '#4d4d4d', // Dark Gray
    STARTED: '#0056b3', // Darker Blue
    SUCCESS: '#1e7e34', // Darker Green
    'PARTIALLY OKAY': '#d39e00', // Darker Orange
    FAILED: '#c82333', // Darker Red
};

const EdgeOTAUpdate = () => {
    const [startLoader, stopLoader] = useLoader();
    const [createbatchModal, setCreatebatchModal] = useState(false);
    const [batches, setBatches] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        batchId: null,
    });
    const [batchInfoModal, setBatchInfoModal] = useState({
        status: false,
        eStatus: [],
    });

    useEffect(() => {
        fetchBatches();
    }, []);

    const fetchBatches = () => {
        const params = {
            limit: LIMIT,
            skip: skip,
        };
        EdgeOTAService.GetBatches(params, startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data.batches)) {
            const processed = data.data.batches.map((m) => ({
                ...m,
                totalEdges: m.edges.length,
                startedAtCET: m.startedAt ? momentTimeFormater(m.startedAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                status: (
                    <div className={OTAClasses.StatusContainer}>
                        <div className={OTAClasses.Status} style={{ backgroundColor: statusColors[m.status] }}>
                            {m.status}
                        </div>
                    </div>
                ),
                action: (
                    <div className={OTAClasses.StatusContainer}>
                        {m.status === 'CREATED' && (
                            <UilPlay size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, batchId: m._id })} />
                        )}
                        <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setBatchInfoModal({ status: true, eStatus: m.edgesStatus })} />
                    </div>
                ),
            }));
            setBatches(processed);
        }

        if (data.data.count) setTotalCount(data.data.count);
    };

    const startUpdate = (id) => {
        EdgeOTAService.StartUpdate(id, startLoader, startUpdateSuccess, handleError, stopLoader);
    };

    const startUpdateSuccess = () => {
        fetchBatches();
        toast.success('Start update requested');
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="Edge OTA Console" />
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setCreatebatchModal(true)}>
                            Create Batch
                        </button>
                    </div>
                </div>
            </div>
            <Table
                head={['Tag Id', 'Version', 'No. of Edges', 'Started At', 'Status', 'Action']}
                keys={['tagId', 'version', 'totalEdges', 'startedAtCET', 'status', 'action']}
                data={batches}
                page={page}
                Pagination={true}
                limit={LIMIT}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
            <ModalComponent isOpen={createbatchModal} setOpen={setCreatebatchModal}>
                <CreateBatch setOpen={setCreatebatchModal} fetchBatches={fetchBatches} />
            </ModalComponent>
            <ModalComponent isOpen={batchInfoModal.status} setOpen={(status) => setBatchInfoModal({ ...deleteModal, status })}>
                <BatchInfo status={batchInfoModal.eStatus} />
            </ModalComponent>
            <DeleteModal
                deletefunction={startUpdate}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
                deviceId={deleteModal.batchId}
            ></DeleteModal>
        </div>
    );
};

export default EdgeOTAUpdate;
