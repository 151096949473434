// Standard Library Imports
import React, { useState, useEffect } from 'react';
import { useField } from 'formik';

// External Library Imports
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from '@sctech/sc-editor';
import { BASE_URL } from '../../constants';

const CkeEditor = ({ defaultValue = '', ...props }) => {
    const [value, setValue] = useState('');
    const [, meta, helpers] = useField(props);
    let token = localStorage.getItem('GM_TOKEN');

    useEffect(() => {
        onChange(defaultValue);
    }, [defaultValue]);

    const onChange = (e) => {
        setValue(e);
        helpers.setValue && helpers.setValue(e.value);
        props.onChange && props.onChange(e);
    };

    return (
        <div>
            <CKEditor
                disabled={props.disabled ? true : false}
                config={{
                    simpleUpload: {
                        uploadUrl: `${BASE_URL}/${props.apiUrl}`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                }}
                editor={Editor}
                data={value}
                onChange={(_, editor) => {
                    const data = editor.getData();
                    onChange(data);
                }}
            />
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export default CkeEditor;
