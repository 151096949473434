// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getMeters: '/admin/data-hub/meter',
    readAllData: '/root/data-hub/all',
    updateDevice: '/root/data-hub',
    updateStatus: '/root/data-hub/meterStatus',
    downloadData: '/root/data-hub/download',
};

const getMeters = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getMeters}`, { params }).then(callback).catch(error).finally(next);
};

const readAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readAllData}`).then(callback).catch(error).finally(next);
};

const updateDevice = (id, meterId, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateDevice}/${id}/${meterId}`, payload).then(callback).catch(error).finally(next);
};

const updateStatus = (id, meterId, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateStatus}/${id}/${meterId}`, payload).then(callback).catch(error).finally(next);
};

const addKey = (value, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.updateDevice}`, { dataHub: value }).then(callback).catch(error).finally(next);
};

const readGraphData = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.updateDevice}/${id}`, { params }).then(callback).catch(error).finally(next);
};

const readLastStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.updateDevice}/readLast/${id}`).then(callback).catch(error).finally(next);
};
const deleteGroup = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.updateDevice}/${id}`).then(callback).catch(error).finally(next);
};

const downloadData = (params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.downloadData}`, params).then(callback).catch(error).finally(next);
};

export const DataHubService = {
    getMeters,
};

export const DataHubServiceRoot = {
    readAll,
    updateDevice,
    updateStatus,
    addKey,
    readGraphData,
    readLastStatus,
    deleteGroup,
    downloadData,
};
