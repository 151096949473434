import { HttpClient } from '../utils/httpClient';

const PATH = {
    logoForHost: '/unp/logo-with-host',
};

const LogoForHost = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.logoForHost, { params }).then(callback).catch(error).finally(next);
};

export const ExternalService = {
    LogoForHost,
};
