// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import moment from 'moment';
import { UilInfoCircle } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { momentTimeFormater } from '../../../../../utils/timeHelper';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import MetaLogJSONModal from './metaLogJSONModal';
import CustomTooltip from '../../../../../components/CustomToolTip/CustomTooltip';
import DownloadButton from '../../../../../components/Buttons/DownloadButton';
import { DownloadAsExcel } from '../../../../../utils/downloadAsExcel';

// Css imports
import activationClasses from '../ActivationProfiler.module.css';

const IntMetaLogModal = ({ setOpen, logData }) => {
    const [tableData, setTableData] = useState([]);
    const [logJSONModal, setLogJSONModal] = useState({ status: false, data: '' });

    useEffect(() => {
        const tableData = logData
            .sort((a, b) => moment(b.timestamp).valueOf() - moment(a.timestamp).valueOf())
            .map((log) => {
                return {
                    id: log?.id ? log.id : '--',
                    activationid: log?.activationid ? log.activationid : '--',
                    command: log?.command ? log?.command : '--',
                    write: log.write ? 'OK' : '--',
                    reply: log.reply ? 'OK' : '--',
                    regulationtype: log?.regulationtype ? log?.regulationtype : '--',
                    test: String(log?.test) !== 'undefined' ? String(log?.test) : '--',
                    timestamp: log?.timestamp ? momentTimeFormater(log.timestamp).format('YYYY-MM-DD HH:mm:ss') : '--',
                    style: !log.reply ? { background: '#FFE3E3', color: '#E25657' } : { background: '#E6FFDC', color: '#52E652' },
                    action: (
                        <div className={`${(activationClasses.LogContainer, activationClasses.AmountContainer)}`}>
                            <div onClick={() => setLogJSONModal({ status: true, data: log.write })}>
                                <CustomTooltip content={'Write'}>
                                    <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CustomTooltip>
                            </div>
                            {log.reply && (
                                <div onClick={() => setLogJSONModal({ status: true, data: log.reply })}>
                                    <CustomTooltip content={'Reply'}>
                                        <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </CustomTooltip>
                                </div>
                            )}
                        </div>
                    ),
                };
            });
        setTableData(tableData);
    }, [logData]);

    const handleDownloadExcel = () => {
        if (logData?.length) {
            const data = logData.map((log) => ({
                id: log.id,
                activationId: log.activationid,
                command: log.command,
                regulationtype: log?.regulationtype,
                test: String(log?.test) !== 'undefined' ? String(log?.test) : '--',
                timestamp: log?.timestamp ? momentTimeFormater(log.timestamp).format('YYYY-MM-DD HH:mm:ss') : '--',
                write: log.write ? JSON.stringify(log.write, null, 2) : '--',
                reply: log.reply ? JSON.stringify(log.reply, null, 2) : '--',
            }));
            DownloadAsExcel(data, `INT_Meta_Logs_${logData[0].activationid}`, ['Id', 'Activation Id', 'Command', 'Regulation Type', 'Test', 'Timestamp (CET)', 'Write', 'Reply']);
        }
    };

    return (
        <div className={activationClasses.ModalContainer}>
            <ModalComponent isOpen={logJSONModal.status} setOpen={() => setLogJSONModal({ status: false, data: '' })}>
                <MetaLogJSONModal setOpen={() => setLogJSONModal({ status: false, data: '' })} log={logJSONModal.data} />
            </ModalComponent>
            <div className={activationClasses.DurationContent}>
                <Typography content={'INT META Logs'} />
                <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} />
            </div>
            <div>
                <Table
                    head={['ID', 'Activation ID', 'Command', 'Write', 'Reply', 'Regulation Type', 'Test', 'Timestamp (CET)', 'Action']}
                    keys={['id', 'activationid', 'command', 'write', 'reply', 'regulationtype', 'test', 'timestamp', 'action']}
                    data={tableData}
                />
            </div>
            <div className={activationClasses.ButtonRight}>
                <div className="btn-secondary" onClick={() => setOpen({ status: false, data: [] })}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default IntMetaLogModal;
