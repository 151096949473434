// Standard library imports
import React, { useState, useEffect } from 'react';

// External module imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { UilArrowRight } from '@iconscout/react-unicons';

// Internal module imports
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import { useLoader } from '../../../../../hooks';
import { AdminService } from '../../../../../services/AdminService';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import { CustomerService } from '../../../../../services/CustomerService';
import { MigrationApiValidation } from '../../../../../validations/Root/migrationValidator';
import MultiSelectDropdown from '../../../../../components/Inputs/MultiSelectDropdown';

import adminClasses from '../../Admin.module.css';

const MonitoringSummary = () => {
    const [startLoader, stopLoader] = useLoader();
    const [admin, setAdmin] = useState([]);
    const [toAdmin, setToAdmin] = useState([]);
    const [customer, setCustomer] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        admin.length && updateToAdmin(admin[0]);
    }, [admin]);

    const getData = () => {
        AdminService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const updateToAdmin = (data) => {
        handleGetCustomer(data);
        const toAdmin = admin.filter((item) => item.value !== data.value);
        setToAdmin(toAdmin);
    };

    const handleSuccess = ({ data }) => {
        const processedAdmin = [];

        if (data?.data && data.data.length) {
            data.data.forEach((admin) => {
                processedAdmin.push({ label: admin.name, value: admin._id });
            });
        }
        setAdmin(processedAdmin);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleMigrateSuccess = () => {
        toast.success('Migration Success!');
        getData();
    };

    const handleSubmit = (data) => {
        const formData = {
            customerId: data.customerId.map((customer) => customer.value),
            toAdmin: data.toAdmin,
        };
        AdminService.MigrateCustomer(formData, startLoader, handleMigrateSuccess, handleError, stopLoader);
    };

    const handleCustomerSucess = ({ data }) => {
        const processedCustomer = [];
        if (data?.data && data.data.length) {
            data.data.forEach((customer) => {
                processedCustomer.push({ label: customer.name, value: customer._id });
            });
        }
        setCustomer(processedCustomer);
    };

    const handleLoginError = (error) => {
        console.log(error);
    };

    const handleGetCustomer = (data) => {
        AdminService.GetCustomers(data.value, startLoader, handleCustomerSucess, handleLoginError, stopLoader);
    };

    return (
        <div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <Typography content={'Migrate Customers'} />
                </div>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={{ toAdmin: toAdmin.length ? toAdmin[0].value : '', customerId: [], fromAdmin: admin.length ? admin[0].value : '' }}
                        onSubmit={handleSubmit}
                        validationSchema={MigrationApiValidation}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div className={adminClasses.migrationContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className={classes.InputContainer}>
                                            <div className={adminClasses.FieldControl}>
                                                <label for="fromAdmin">
                                                    From Admin <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <DropdownComponent name="fromAdmin" options={admin} defaultValue={admin[0]} onChange={(data) => updateToAdmin(data)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className={classes.InputContainer}>
                                                <div className={adminClasses.FieldControl}>
                                                    <label for="customerId">
                                                        Customers <span className="required">*</span>
                                                    </label>
                                                    <MultiSelectDropdown name="customerId" options={customer} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <UilArrowRight style={{ padding: '15vh' }} size={'5vw'} />
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={adminClasses.FieldControl}>
                                            <label for="toAdmin">
                                                To Admin <span className="required">*</span>
                                            </label>
                                            <div>
                                                <Dropdown name="toAdmin" options={toAdmin} defaultValue={toAdmin[0]} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }}>
                                            Move
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default MonitoringSummary;
