// Standard Library imports
import React, { useEffect, useState } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import ActivationSummary from './Activation';
import MonitoringSummary from './ToggleMonitoring';
import SignalError from './SignalErrors';
import { AdminService } from '../../../../services/AdminService';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import ActivationIndex from './Activation/ActivationIndex';

const Summary = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        getAdmins();
    }, []);

    const getAdmins = () => {
        AdminService.ReadAll(startLoader, handleAdminSuccess, handleError, stopLoader);
    };

    const handleAdminSuccess = ({ data }) => {
        let temp = [
            {
                label: 'All',
                value: 'all',
            },
        ];
        data.data.map((e) => {
            temp.push({ label: e.name, value: e._id });
        });
        setAdmins(temp);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    let tabs = [
        {
            name: 'Monitoring Summary',
            component: (
                <>
                    <MonitoringSummary queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} admins={admins} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Activation Summary',
            component: (
                <>
                    <ActivationIndex queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Signal Issues Summary',
            component: (
                <>
                    <SignalError queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default Summary;
