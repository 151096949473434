// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { UilInfoCircle } from '@iconscout/react-unicons';
// Internal module imports
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import classes from '../../../../styles/AllDevices.module.css';
import { RootAfrrService } from '../../../../services/RootAfrrService';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import afrrClasses from './index.module.css';
import Info from './ModalComponent/Info';

const LIMIT = 15;

const AfrrLogs = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [tableData, setTableData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [infoModal, setInfoModal] = useState({ status: false, data: {} });

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        getLogs();
    }, [date, skip]);

    const getLogs = () => {
        const params = {
            limit: LIMIT,
            skip,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
        };
        RootAfrrService.GetLogs(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data[0]?.data?.map((item) => ({
            source: item.source,
            type: item.type,
            setPoint: item?.value?.setPoint || item?.value?.setPoint === 0 ? item?.value?.setPoint : '--',
            status: item?.value?.status || '--',
            activationDirection: item?.value?.activationDirection || '--',
            action: item?.action || '--',
            mode: item?.value?.mode || '--',
            response: item?.value?.response ? (
                <div className={afrrClasses.ResponseContainer}>
                    {item?.value?.response}
                    <CustomTooltip content={'Info'}>
                        <UilInfoCircle
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() =>
                                setInfoModal({
                                    status: true,
                                    data: item?.value || {},
                                })
                            }
                        />
                    </CustomTooltip>
                </div>
            ) : (
                '--'
            ),
            timestamp: item?.timestamp ? momentTimeFormater(item.timestamp).format('YYYY-MM-DD HH:mm') : '--',
        }));
        setTableData(processedData);
        setTotalCount(data?.data[0]?.count.length ? data?.data[0]?.count[0]?.count : 0);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ status: false, data: {} })}>
                <Info data={infoModal.data} setInfoModal={setInfoModal} />
            </ModalComponent>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Logs" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <DatePicker date={date} setDate={setDate} showArrow={true} />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={['Source', 'Type', 'Timestamp (CET)', 'Set Point', 'Status', 'Activation Direction', 'Action', 'Mode', 'Response']}
                            keys={['source', 'type', 'timestamp', 'setPoint', 'status', 'activationDirection', 'action', 'mode', 'response']}
                            data={tableData}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AfrrLogs;
