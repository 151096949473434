// Standard library import
import React, { useEffect, useState } from 'react';

// External library import
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import {
    UilChartLine,
    UilInfoCircle,
    UilMessage,
    UilTrash,
    UilExclamationOctagon,
    UilPen,
    UilEllipsisV,
    UilFileUpload,
} from '@iconscout/react-unicons';
import { MenuItem, Menu, MenuDivider } from '@szhsin/react-menu';

// Internal module import
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Table from '../../../components/Table/Table';
import Typography from '../../../components/Typography/Typography';
import { CustomerService } from '../../../services/CustomerService';
import { useLoader } from '../../../hooks';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { momentTimeFormater } from '../../../utils/timeHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { DropdownComponent, ToggleButtonWithState } from '../../../components/Inputs/Input';
import Dropdown from '../../../components/Inputs/Dropdown';
import { ApikeyService } from '../../../services/apiKeyService';
import { LICENSE_DETAILS_GATEWAY_URL } from '../../../constants';
import DocumentEditor from '../ModalComponent/DocumentEditor';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';

// CSS Imports
import classes from '../../../styles/Bids.module.css';
import EdgeClasses from '../Edge.module.css';
import CreateEdgeGateway from '../ModalComponent/CreateEdgeGateway';
import { EdgeGatewayService } from '../../../services/EdgeGatewayService';
import EdgeGatewayInfo from '../ModalComponent/edgeGatewayInfo';
import CommandsGateway from '../ModalComponent/commandsGateway';

const statusOptions = [
    { value: false, label: 'Enabled' },
    { value: true, label: 'Disabled' },
];

const EdgeGateway = ({
    setSelectedIndex,
    setEdgeGatewayError,
    setEdgeGatewayId,
    setEdgeGatewayStats,
    setUploadLogFilesGateway,
    updateQueryParams,
    queryParamsData = {},
    setSelectedEdgeGateway,
}) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [deviceModal, setDeviceModal] = useState({
        status: false,
        device: {}
    });
    const [commandModal, setCommandModal] = useState({
        status: false,
        device: {},
    });
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [skip, setSkip] = useState(0);
    const [limit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState({
        value: '',
        label: '',
        customerCode: '',
        license: {},
    });
    const [isOpenDocumentModal, setOpenDocumentModal] = useState({
        status: false,
        data: {},
    });
    const [infoModal, setInfoModal] = useState({
        status: false,
        data: '',
        licenseDetailUrl:
            window.location.hostname === 'tgadmin.scnordic.com'
                ? `https://api.external.truegreen.scnordic.com/${LICENSE_DETAILS_GATEWAY_URL}`
                : `https://betaserver.scnordic.com/${LICENSE_DETAILS_GATEWAY_URL}`
    });

    const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);

    useEffect(() => {
        if (selectCustomer.value) {
            getData();
        }
    }, [skip, limit, selectCustomer, selectedStatus.value]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return item;
            }
        }
        return null;
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
                customerCode: element.customerCode,
                license: element.license,
            });
        });
        setCustomers(temp);
        if (temp.length) {
            const queryMatched = matchQueryParams(temp, { value: queryParamsData.customer });
            setSelectCustomer(queryMatched ? queryMatched : temp[0]);
            !queryMatched && updateQueryParams('customer', temp[0].value);
        } else {
            setSelectCustomer({
                value: '',
                label: '',
                customerCode: '',
                license: {},
            });
        }
    };

    const getData = () => {
        const params = {
            limit,
            skip,
            customerId: selectCustomer.value,
            disabled: selectedStatus.value,
        };
        return EdgeGatewayService.ReadAll(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const alertOnChangeHandler = (deviceDetails, e) => {
        if (deviceDetails?._id) {
            const payload = {
                value: e.target.checked,
                type: 'master',
                gatewayId: deviceDetails.gatewayId,
            };
            EdgeGatewayService.UpdateNotificationStates(payload, startLoader, handleUpdateSucess, handleError, stopLoader);
        }
    };

    const handleUpdateSucess = ({ res }) => {
        getData();
        toast.success('Gateway updated successfully');
    };

    const handleGetDataSuccess = ({ data }) => {
        const processedData = data?.data[0]?.data.map((item) => {
            const apiKeyData = {
                gatewayId: item.gatewayId,
                status: true,
                data: item?.ecrRepositoryUri || '',
                customerId: selectCustomer?.value,
                userName: item?.properties?.username || '',
                password: item?.properties?.password || '',
                systemId: item?.systemId || '',
                ecrRepositoryName: item?.ecrRepositoryName || '',
                edgeId: item.edgeId,
                instanceId: item?.groupId || '',
                createdAt: item?.createdAt ? momentTimeFormater(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '',
                licenseCode: item.licenseTypeData?.length && item.instanceData?.length ? item.licenseTypeData[0].licenseCode : '--',
                edgeGatewayAlias: item.edgeGatewayAlias
            };

            return {
                ...item,
                apiKeyData,
                ecrRepositoryName: item?.ecrRepositoryName || '--',
                lastReported: item.lastReported ? momentTimeFormater(item.lastReported).format('YYYY-MM-DD HH:mm:ss') : '--',
                signal: item.signalData?.length ? item.signalData[0].name : '--',
                licenseAndInstance:
                    item.licenseTypeData?.length && item.instanceData?.length
                        ? `${CamelCaseToTextCase(item.licenseTypeData[0].name)}/${item.instanceData[0].name}`
                        : item.licenseTypeData?.length
                            ? `${CamelCaseToTextCase(item.licenseTypeData[0].name)}/--`
                            : item.instanceData?.length
                                ? `--/${item.instanceData[0].name}`
                                : '--',
                codeVersion: item.properties?.codeVersion ? item.properties.codeVersion : '--',
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CustomTooltip content={'Edit Details'}>
                            <div>
                                <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeviceModal({
                                    status: true,
                                    device: item
                                })} />
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Info'}>
                            <div>
                                <UilInfoCircle
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() => {
                                        getApiKey(apiKeyData);
                                    }}
                                />
                            </div>
                        </CustomTooltip>

                        <Menu
                            menuButton={
                                <div>
                                    <CustomTooltip content={'Menu'}>
                                        <UilEllipsisV size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </CustomTooltip>
                                </div>
                            }
                            direction={'left'}
                            arrow={true}
                            menuClassName={EdgeClasses.Menu}
                        >
                            <MenuItem onClick={() => setCommandModal({ status: true, device: item })} className={EdgeClasses.MenuItem}>
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilMessage size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Send Commands</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => setDeleteModal({ status: true, id: item._id })} className={EdgeClasses.MenuItem}>
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Delete</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />

                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setSelectedIndex(5);
                                    setEdgeGatewayStats(true);
                                    setEdgeGatewayId(item.gatewayId);
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilChartLine className={EdgeClasses.ActionIcon} size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Stats</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setSelectedIndex(5);
                                    setEdgeGatewayError(true);
                                    setEdgeGatewayId(item.gatewayId);
                                    setSelectedEdgeGateway({ customerId: selectCustomer.value, ...item });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilExclamationOctagon className={EdgeClasses.ActionIcon} size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Logs</span>
                                </div>
                            </MenuItem>
                            <MenuDivider />
                            <MenuItem
                                className={EdgeClasses.MenuItem}
                                onClick={() => {
                                    setSelectedIndex(5);
                                    setUploadLogFilesGateway({
                                        status: true,
                                        data: item.gatewayId,
                                    });
                                }}
                            >
                                <div className={EdgeClasses.MenuOptions}>
                                    <UilFileUpload pwatch size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    <span>Uploaded Logs</span>
                                </div>
                            </MenuItem>
                        </Menu>
                    </div>
                ),
                alert: (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonWithState
                            value={item.properties?.notificationConfig?.master}
                            name={`properties.notificationConfig.master`}
                            onChange={(e) => alertOnChangeHandler(item, e)}
                            defaultStyle={true}
                        />
                    </div>
                ),
            };
        });
        setData(processedData);
        setTotalCount(data?.data[0]?.count[0].count);
        return processedData;
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    const handleDelete = () => {
        EdgeGatewayService.Delete(deleteModal.id, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        getData();
        toast.success('Gateway Deleted');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const getApiKey = (modalData) => {
        ApikeyService.GetApiKeyForGatewayCustomer(selectCustomer?.value, modalData.gatewayId, startLoader, (data) => handleGetApikeySuccess(data, modalData), handleError, stopLoader);
    };

    const handleGetApikeySuccess = ({ data }, modalData) => {
        setInfoModal({
            status: true,
            gatewayId: modalData.gatewayId,
            data: modalData?.data || '',
            customerId: modalData?.customerId,
            apiKey: data?.data?.key || false,
            licenseDetailUrl: infoModal.licenseDetailUrl,
            access: {
                device: data?.data?.access?.device || false,
                license: data?.data?.access?.license || false,
                bid: data?.data?.access?.bid || false,
                edge: data?.data?.access?.edge || false,
            },
            userName: modalData.userName || '',
            password: modalData.password || '',
            systemId: modalData.systemId,
            ecrRepositoryName: modalData.ecrRepositoryName,
            edgeId: modalData.edgeId,
            createdAt: modalData?.createdAt,
            edgeGatewayAlias: modalData?.edgeGatewayAlias
        });
    };

    const handleMQTTCredRotateSuccess = async (edgeId) => {
        setInfoModal({ ...infoModal, status: false });
        toast.success('New MQTT Credentials Genrated Successfully');
        const processedData = await getData();
        const infoModalActiveData = processedData.filter((item) => item.gatewayId === edgeId);
        getApiKey(infoModalActiveData[0].apiKeyData);
    };

    const rotateMQTTPassword = (edgeId) => {
        EdgeGatewayService.RotateCredentials(edgeId, startLoader, () => handleMQTTCredRotateSuccess(edgeId), handleError, stopLoader);
    };

    const handleAPIKeyRotateSuccess = async (edgeId) => {
        setInfoModal({ ...infoModal, status: false });
        toast.success('API key rotated Successfully');
        const processedData = await getData();
        const infoModalActiveData = processedData.filter((item) => item.gatewayId === edgeId);
        getApiKey(infoModalActiveData[0].apiKeyData);
    };

    const handleAPIKeyGenerateSuccess = async (edgeId) => {
        setInfoModal({ ...infoModal, status: false });
        toast.success('API key Created Successfully');
        const processedData = await getData();
        const infoModalActiveData = processedData.filter((item) => item.gatewayId === edgeId);
        getApiKey(infoModalActiveData[0].apiKeyData);
    };

    const rotateAPIKey = (edgeId) => {
        ApikeyService.RotateAPIKey(edgeId, startLoader, () => handleAPIKeyRotateSuccess(edgeId), handleError, stopLoader);
    };

    return (
        <div className={classes.Bids}>
            <ModalComponent isOpen={isOpenDocumentModal.status} setOpen={() => { }}>
                <div style={{ overflow: 'auto' }}>
                    <DocumentEditor edgeData={isOpenDocumentModal.data} setOpen={setOpenDocumentModal} />
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Edge Gateways" />
                    <Formik initialValues={{ customerId: '' }}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                {customers.length > 0 && (
                                    <div className={EdgeClasses.DropDnContainer}>
                                        <Dropdown
                                            name="customerId"
                                            options={customers}
                                            onChange={(e) => {
                                                setSelectCustomer(e);
                                                updateQueryParams('customer', e.value);
                                            }}
                                            defaultValue={selectCustomer}
                                        />
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={classes.ButtonsContainer} style={{ alignItems: 'center' }}>
                    <div style={{ minWidth: '10vw' }}>
                        <DropdownComponent
                            defaultValue={selectedStatus}
                            options={statusOptions}
                            onChange={(e) => {
                                setSelectedStatus(e);
                            }}
                        />
                    </div>
                    <button onClick={() =>
                        setDeviceModal({
                            ...deviceModal,
                            status: true,
                        })}
                        className="btn-primary" style={{ marginTop: '0' }}>
                        Add Gateway
                    </button>
                </div>
            </div>
            <ModalComponent isOpen={infoModal.status} setOpen={(status) => setInfoModal({ ...infoModal, status })}>
                <div>
                    <EdgeGatewayInfo infoModal={infoModal} setInfoModal={setInfoModal} selectCustomer={selectCustomer} rotateMQTTPassword={rotateMQTTPassword} rotateAPIKey={rotateAPIKey} handleAPIKeyGenerateSuccess={handleAPIKeyGenerateSuccess} />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={deviceModal.status} setOpen={(status) => { }}>
                <div>
                    <CreateEdgeGateway
                        setModal={(status) => {
                            setDeviceModal({
                                status,
                                device: {},
                            });
                        }}
                        startLoader={startLoader}
                        stopLoader={stopLoader}
                        fetchBids={getData}
                        deviceDetails={deviceModal.device}
                        customerId={selectCustomer.value}
                        customerCode={selectCustomer.customerCode}
                        selectCustomer={selectCustomer}
                    />
                </div>
            </ModalComponent>
            <ModalComponent isOpen={commandModal.status} setOpen={(status) => setCommandModal({ status, device: {} })}>
                <CommandsGateway device={commandModal.device} setOpen={(status) => setCommandModal({ status, device: {} })} startLoader={startLoader} stopLoader={stopLoader} />
            </ModalComponent>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <Table
                head={['Gateway ID', 'Gateway Alias', 'ECR Repository Name', 'Last Reported', 'Code Version', 'Monitoring', 'Action']}
                keys={['gatewayId', 'edgeGatewayAlias', 'ecrRepositoryName', 'lastReported', 'codeVersion', 'alert', 'action']}
                data={data}
                page={page}
                Pagination={true}
                limit={limit}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
        </div>
    );
};

export default EdgeGateway;
