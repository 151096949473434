import React, { useState } from 'react';
import CronJobs from '.';
import TabsComponent from '../../../../components/Tabs/Tabs';

const Cron = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [cronConfigPage, setCronConfigPage] = useState();
    let tabs = [
        {
            name: 'Crons',
            component: (
                <>
                    <CronJobs setSelectedIndex={setSelectedIndex} setCronConfigPage={setCronConfigPage} />
                </>
            ),
            tabId: 4,
        },
        ...(selectedIndex === 1
            ? [
                  {
                      name: 'Cron config',
                      component: <>{cronConfigPage}</>,
                      tabId: 4,
                  },
              ]
            : []),
    ];

    return (
        <div className=" ventilation">
            <TabsComponent selectedIndex={selectedIndex} tabs={tabs} onChangeTab={setSelectedIndex} />
        </div>
    );
};

export default Cron;
