// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import moment from 'moment-timezone';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { UilTrash, UilInfoCircle, UilImport, UilSync, UilBolt, UilCapture, UilKeyboardShow, UilCopy, UilBan, UilNotes } from '@iconscout/react-unicons';
import { Form, Formik } from 'formik';
import { MenuItem, Menu, MenuDivider } from '@szhsin/react-menu';
// Css imports
import classes from '../../styles/Bids.module.css';
import mainBidClasses from './index.module.css';
// Internal module imports
import Typography from '../../components/Typography/Typography';
import DownloadButton from '../../components/Buttons/DownloadButton';
import Table from '../../components/Table/Table';
import ModalComponent from '../../components/ModalComponent/ModalComponent';
import AlertModal from '../../components/AlertModal/AlertModal';
import CreateBid from './ModalContent/CreateBid';
import DeleteModal from '../../components/DeleteModal/DeleteModal';
import { DownloadAsExcel } from '../../utils/downloadAsExcel';
import Status from './ModalContent/Status';
import { useLoader } from '../../hooks';
import { AdminBiddingService } from '../../services/AdminBiddingService';
import CustomTooltip from '../../components/CustomToolTip/CustomTooltip';
import { dkCurrencyFormat, euToUsCurrencyFormat } from '../../utils/currencyHelper';
import BiddingInfoContent from './ModalContent/BiddingInfoContent';
import ResultId from './ModalContent/ResultId';
import { Capitalize } from '../../utils/stringHelper';
import { momentTimeFormater } from '../../utils/timeHelper';
// import DMinusOne from './ModalContent/CreateSecondaryBid';
import Dropdown from '../../components/Inputs/Dropdown';
import DateRangePicker from '../../components/Inputs/DateRangePicker/DateRangePicker';
import { useHistory } from 'react-router-dom';
import CreateAgreement from './CreateAgreement';
import CreateSecondaryBid from './ModalContent/CreateSecondaryBid';
import { Panic } from './ModalContent/Panic';
import { Notes } from './ModalContent/Notes';
import { NotesLogsIndex } from './ModalContent/NotesLogsIndex';
import { AFRR_MARKET_TYPES } from '../../constants';

const Bids = ({ market, updateQueryParams = {}, queryParamsData = {} }) => {
    const date = new Date();
    const [startLoader, stopLoader] = useLoader();
    const [rawCustomers, setRawCustomers] = useState({});
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState({});
    const [customerLicenses, setCustomerLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState(queryParamsData?.license ? queryParamsData.license : {});
    const [licenseGroups, setLicenseGroups] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState({});

    const [bidsModal, setBidsModal] = useState(false);
    const [alertModal, setAlertModal] = useState(false);
    const [statusModal, setStatusModal] = useState({
        status: false,
        data: {},
    });
    const [biddingInfoModal, setBiddingInfoModal] = useState({ status: false, capacity: {}, price: {}, hourlyData: [] });

    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });

    const [bids, setBids] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState(0);

    const [deleteModal, setDeleteModal] = useState({
        status: false,
        bidId: '',
        type: 'primary',
    });

    const [addResultIdModal, setAddResultIdModal] = useState({
        status: false,
        bid: {},
    });

    const [createBidDate, setCreateBidDate] = useState('');

    const [dMinusOnemodal, setDMinusOneModal] = useState({
        status: false,
        bid: {},
    });

    const [isAggrement, setAggrement] = useState({
        isAggrement: false,
        modalStatus: false,
        link: '',
    });
    const [panicModal, setPanicModal] = useState({
        status: false,
        data: {},
    });
    const [notesModal, setNotesModal] = useState({
        status: false,
        data: {},
    });
    const [minMaxValues, setMinMaxValues] = useState({
        min: 0,
        max: 0,
    });

    useEffect(() => {
        fetchMarketEnabledGroups();
    }, [market]);

    useEffect(() => {
        if (selectCustomer.value && Object.keys(rawCustomers).length) {
            const customer = rawCustomers[selectCustomer.value];
            const licenses = customer.licenses;
            const activeLicenses = Object.keys(licenses).map((id) => ({
                label: Capitalize(licenses[id].type),
                value: id,
            }));
            if (activeLicenses.length) {
                setCustomerLicenses(activeLicenses);
                const queryMatched = matchQueryParams(activeLicenses, queryParamsData.license);
                setSelectedLicense(queryMatched ? queryParamsData.license : activeLicenses[0]);
                !queryMatched && updateQueryParams('license', JSON.stringify(activeLicenses[0]));
            } else {
                setCustomerLicenses([]);
                setSelectedLicense({});
            }
        } else {
            setCustomerLicenses([]);
            setSelectedLicense({});
        }
    }, [selectCustomer]);

    useEffect(() => {
        if (selectedLicense.value && selectCustomer.value && Object.keys(rawCustomers).length) {
            const license = rawCustomers[selectCustomer.value].licenses[selectedLicense.value];
            const groups = license.groups.map((g) => ({
                label: g.name,
                value: g._id,
                approvalValidity: g.approvalValidity,
            }));
            if (groups.length) {
                setLicenseGroups(groups);
                const queryMatched = matchQueryParams(groups, queryParamsData.groups);
                setSelectedInstance(queryMatched ? queryParamsData.groups : groups[0]);
                !queryMatched && updateQueryParams('groups', JSON.stringify(groups[0]));
            } else {
                setLicenseGroups([]);
                setSelectedInstance({});
            }
        } else {
            setLicenseGroups([]);
            setSelectedInstance({});
        }
    }, [selectedLicense]);

    useEffect(() => {
        if (selectedInstance?.value) {
            fetchBids();
        }
    }, [selectedInstance]);

    useEffect(() => {
        if (bids?.length) getMinMax();
    }, [bids]);

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const fetchMarketEnabledGroups = () => {
        AdminBiddingService.GetMarketEnabledGroups(market, startLoader, groupSuccess, handleError, stopLoader);
    };

    const groupSuccess = ({ data }) => {
        const res = data.data;
        setRawCustomers(res);
        const curCustomers = Object.keys(res);
        if (curCustomers.length) {
            const customerOptions = curCustomers.map((id) => ({
                label: res[id].name,
                value: id,
                biddingMarkets: res[id].biddingMarkets,
            }));
            setCustomers(customerOptions);
            if (customerOptions.length) {
                const queryMatched = matchQueryParams(customerOptions, queryParamsData.customer);
                customerOptions.length && setSelectCustomer(queryMatched ? queryParamsData.customer : customerOptions[0]);
                !queryMatched && updateQueryParams('customer', JSON.stringify(customerOptions[0]));
            } else {
                setCustomers([]);
                setSelectCustomer({});
            }
        } else {
            toast.error('No Instances Enabled!');
            setCustomers([]);
            setSelectCustomer({});
        }
    };

    const fetchBids = () => {
        if (selectedInstance?.value) {
            let params = {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                customerId: selectCustomer.value,
                instanceId: selectedInstance.value,
                market: market,
            };
            AdminBiddingService.GetBids(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
        }
    };

    const getEnablerRevenue = (enablers = {}, enablersData = []) => {
        let totalRevenue = 0;
        let tooltip = [];
        enablersData.map((e) => {
            for (const key in enablers) {
                if (key == e._id) {
                    totalRevenue = totalRevenue + Number(enablers[key]);
                    tooltip.push(`${e.name} : ${enablers[key].toLocaleString('da-DK')}`);
                }
            }
        });

        return (
            <CustomTooltip
                content={tooltip.map((e) => (
                    <div>{e}</div>
                ))}
            >
                {totalRevenue.toLocaleString('da-DK')}
            </CustomTooltip>
        );
    };

    const handleGetDataSuccess = (res) => {
        setAggrement({
            isAggrement: res.data.data?.aggrement ? true : false,
            modalStatus: false,
            link: '',
        });
        const { data: bidData, totalRevenue } = res.data.data;
        let processedData = bidData.map((data, index) => {
            if (data.isBid) {
                const bid = {
                    ...data,
                    sc: !isNaN(data.sc) ? dkCurrencyFormat(data?.sc) : data.sc,
                    customer: !isNaN(data.customer) ? dkCurrencyFormat(data?.customer) : data.customer,
                    partner: !isNaN(data.partner) ? dkCurrencyFormat(data?.partner) : data.partner,
                    revenue: !isNaN(data.revenue) ? dkCurrencyFormat(Number(data?.revenue)) : data.revenue,
                    enablersAmount: !isNaN(data.revenue) ? getEnablerRevenue(data?.enablersRevenue, data.enablersData) : '--',
                    avgCapacity: data.avgCapacity,
                    timestamp: data.date,
                    status: `${data.primaryStatus}/${data.secondaryStatus}`,
                    actions: getActions(data._id, data),
                };
                if (!data?.bidStatus?.length) {
                    bid.style = { background: '#E6E8FA', color: '#4C5AD9' };
                }
                if (data?.notes?.length) {
                    bid.style = { background: '#fef6e5', color: '#faa500' };
                }
                return bid;
            } else {
                return {
                    ...data,
                    style: { background: '#FFE3E3', color: '#E25657' },
                    actions: (
                        <div className={classes.ActionContainer}>
                            <CustomTooltip content={'Create Bid'}>
                                <div
                                    className={classes.Action}
                                    onClick={() => {
                                        setCreateBidDate(data.cetDate);
                                        setBidsModal(true);
                                    }}
                                >
                                    <div>
                                        <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                        </div>
                    ),
                };
            }
        });
        setTotalRevenue(Math.round(totalRevenue).toLocaleString('da-DK'));
        setBids(processedData);
    };

    const getActions = (id, data) => {
        const { capacity, price, bidStatus } = data;
        return (
            <div className={classes.ActionContainer}>
                <CustomTooltip content={'Bidding Info'}>
                    <div
                        className={classes.Action}
                        onClick={() => {
                            setBiddingInfoModal({
                                status: true,
                                capacity: {
                                    overall: capacity,
                                    primary: _.get(data, ['primary', 'capacity'], []),
                                    secondary: _.get(data, ['secondary', 'capacity'], []),
                                    panicHours: _.get(data, ['panic', 'hours'], []),
                                },
                                price: {
                                    overall: price,
                                    primary: _.get(data, ['primary', 'price'], []),
                                    secondary: _.get(data, ['secondary', 'price'], []),
                                    ...(AFRR_MARKET_TYPES.includes(market) ? { regulationPrice: _.get(data, ['primary', 'regulationPrice'], []) } : {}),
                                },
                                hourlyData: bidStatus?.length
                                    ? {
                                          bidStatus,
                                          bidId: id,
                                      }
                                    : setAlertModal('SETTLEMENTS'),
                            });
                        }}
                    >
                        <div>
                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </div>
                </CustomTooltip>
                {data.bidType === 'normal' ? (
                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <CustomTooltip content={'Download'}>
                            <div className={classes.Action} onClick={() => handleDownloadHourData(data)}>
                                <div>
                                    <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <div style={{ display: 'flex', gap: '0.5vw' }}>
                            <CustomTooltip content={'Fetch Status'}>
                                <div className={classes.Action} onClick={() => handleFetchStatus(id)}>
                                    <div>
                                        <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                            {data.market === 'FCR' && (
                                <CustomTooltip content={'Bid'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            handlePostFCRBid(data._id);
                                        }}
                                    >
                                        <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </CustomTooltip>
                            )}
                            {['FCR-D-INC', 'FCR-D-DEC', 'FFR'].includes(market) ? (
                                <>
                                    {['FCR-D-INC', 'FCR-D-DEC'].includes(market) && data.isPrimaryBid && (
                                        <CustomTooltip content={'Secondary Bid'}>
                                            <div className={classes.Action} onClick={() => setDMinusOneModal({ status: true, bid: data })}>
                                                <div>
                                                    <UilSync size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                                </div>
                                            </div>
                                        </CustomTooltip>
                                    )}

                                    <CustomTooltip content={'Result ID'}>
                                        <div className={classes.Action} onClick={() => setAddResultIdModal({ status: true, bid: data })}>
                                            <div>
                                                <UilKeyboardShow size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                            </div>
                                        </div>
                                    </CustomTooltip>
                                </>
                            ) : (
                                ''
                            )}
                            <Menu
                                menuButton={
                                    <div>
                                        <CustomTooltip content={'Delete'}>
                                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </CustomTooltip>
                                    </div>
                                }
                                direction={'left'}
                                arrow={true}
                            >
                                {_.get(data, ['primary', 'capacity'], []).length ? (
                                    <>
                                        <MenuItem
                                            onClick={() => {
                                                setDeleteModal({ status: true, bidId: id, type: 'primary' });
                                            }}
                                            disabled={_.get(data, ['primary', 'provider', 'bidStatus'], []).length ? true : false}
                                        >
                                            <div>Delete Primary Bid {_.get(data, ['primary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                        </MenuItem>
                                        {_.get(data, ['secondary', 'capacity'], []).length ? <MenuDivider /> : ''}
                                    </>
                                ) : (
                                    ''
                                )}

                                {_.get(data, ['secondary', 'capacity'], []).length ? (
                                    <MenuItem
                                        onClick={() => {
                                            setDeleteModal({ status: true, bidId: id, type: 'secondary' });
                                        }}
                                        disabled={_.get(data, ['secondary', 'provider', 'bidStatus'], []).length ? true : false}
                                    >
                                        <div>Delete Secondary Bid {_.get(data, ['secondary', 'provider', 'bidStatus'], []).length ? '(Result Fetched)' : ''}</div>
                                    </MenuItem>
                                ) : (
                                    ''
                                )}
                            </Menu>
                            {bidStatus?.length ? (
                                <CustomTooltip content={'Panic'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            setPanicModal({ status: true, data: data });
                                        }}
                                    >
                                        <div>
                                            <UilBan size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>
                            ) : (
                                ''
                            )}

                            <CustomTooltip content={'Bid Note'}>
                                <div
                                    className={classes.Action}
                                    onClick={() => {
                                        setNotesModal({ status: true, data: data });
                                    }}
                                >
                                    <div>
                                        <UilNotes size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                    </div>
                                </div>
                            </CustomTooltip>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    const handleFetchStatus = (id) => {
        AdminBiddingService.FecthBidStatus(id, startLoader, handleCheckSuccess, handleError, stopLoader);
    };

    const handleCheckSuccess = ({ data }) => {
        setStatusModal({
            data: data.data,
            status: true,
        });
        fetchBids();
    };

    const handlePostFCRBid = (id) => {
        AdminBiddingService.PostBid(id, startLoader, handlePostSuccess, handleError, stopLoader);
    };

    const handlePostSuccess = () => {
        toast.success('Bid posted');
        fetchBids();
    };

    const handleDelete = () => {
        AdminBiddingService.Delete(deleteModal.bidId, { type: deleteModal.type }, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        fetchBids();
        toast.success('Bid Deleted');
    };

    const getMinMax = () => {
        const capacity = [];
        bids.map((bid) => {
            if (bid.avgCapacity !== '--') {
                capacity.push(bid.avgCapacity);
            }
        });
        setMinMaxValues({
            min: _.min(capacity),
            max: _.max(capacity),
        });
    };

    const handleDownloadExcel = () => {
        let data = [];
        let totalRevenue = 0;
        let totalSC = 0;
        let totalCustomer = 0;
        let totalPartner = 0;
        let totalEnabler = 0;
        bids.map((item) => {
            if (item.isBid) {
                const r = euToUsCurrencyFormat(_.get(item, ['economics', 'totalRevenue', 'revenue'], 0));
                const s = euToUsCurrencyFormat(_.get(item, ['economics', 'totalRevenue', 'sc'], 0));
                const c = euToUsCurrencyFormat(_.get(item, ['economics', 'totalRevenue', 'customer'], 0));
                const p = euToUsCurrencyFormat(_.get(item, ['economics', 'totalRevenue', 'partner'], 0));
                let e = 0;
                for (const key in item?.enablers) {
                    e += _.get(item, ['economics', 'totalRevenue', 'enablers', key], 0);
                }
                if (!isNaN(r)) {
                    totalRevenue += Number(r);
                }
                if (!isNaN(s)) {
                    totalSC += Number(s);
                }
                if (!isNaN(c)) {
                    totalCustomer += Number(c);
                }
                if (!isNaN(p)) {
                    totalPartner += Number(p);
                }
                if (!isNaN(e)) {
                    totalEnabler += Number(e);
                }
                data.push({
                    date: momentTimeFormater(item?.date).format('YYYY-MM-DD'),
                    capacity: _.mean(item.capacity),
                    revenue: Number(r),
                    sc: Number(s),
                    customer: Number(c),
                    partner: Number(p),
                    enabler: Number(e),
                });
            }
        });

        data.push({
            date: null,
            capacity: null,
            status: 'TOTAL',
            revenue: totalRevenue,
            sc: totalSC,
            customer: totalCustomer,
            partner: totalPartner,
            enablers: totalEnabler,
        });

        DownloadAsExcel(data, 'bids revenue', ['Date (CET)', 'Capacity (MW)', 'Revenue (€)', 'SC (€)', 'Customer split (€)', 'Partner split (€)', 'Enablers split (€)']);
    };

    const handleDownloadHourData = (item) => {
        if (market === 'FCR-D-INC' || market === 'FCR-D-DEC') {
            let totalDMinus2Price = 0;
            let totalDMinus1Price = 0;
            let totalPrice = 0;

            let data = Array(24)
                .fill(null)
                .map((i, index) => {
                    const dMinus2price = _.get(item, ['primary', 'provider', 'bidStatus', index, 'price']);
                    const dMinus1price = _.get(item, ['secondary', 'provider', 'bidStatus', index, 'price']);
                    const hourPrice = _.get(item, ['economics', 'hourlyData', index, 'price'], 0);

                    if (dMinus2price >= 0) totalDMinus2Price += dMinus2price;
                    if (dMinus1price >= 0) totalDMinus1Price += dMinus1price;
                    if (hourPrice >= 0) totalPrice += hourPrice;

                    return {
                        hour: index,
                        capacityDMinus2: _.get(item, ['primary', 'capacity', index]) || '--',
                        priceDMinus2: _.get(item, ['primary', 'price', index]) || '--',
                        settlementDMinus2: dMinus2price >= 0 ? dMinus2price : '--',
                        capacityDMinus1: _.get(item, ['secondary', 'capacity', index]) || '--',
                        priceDMinus1: _.get(item, ['secondary', 'price', index]) || '--',
                        settlementDMinus1: dMinus1price >= 0 ? dMinus1price : '--',
                        hourcost: hourPrice >= 0 ? hourPrice : '--',
                    };
                });

            data.push({
                hour: 'Total',
                capacityDMinus2: '',
                priceDMinus2: '',
                settlementDMinus2: totalDMinus2Price,
                capacityDMinus1: '',
                priceDMinus1: '',
                settlementDMinus1: totalDMinus1Price,
                hourcost: totalPrice,
            });

            DownloadAsExcel(data, `${market}_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, [
                'Hour',
                'Capacity (MW) (D-2)',
                'Price (€) (D-2)',
                'Settlement (€) (D-2)',
                'Capacity (MW) (D-1)',
                'Pricem (€) (D-1)',
                'Selttlement (€) (D-1)',
                'Settlement (€) ( D-2 & D-1)',
            ]);
        } else {
            let data = item.capacity.map((i, index) => ({
                hour: index,
                capacity: i,
                price: _.get(item, ['price', index], '--'),
                hourcost: _.get(item, ['bidStatus', index, 'price'], '--'),
            }));
            DownloadAsExcel(data, `${market}_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
        }
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const closeAllResultIdModal = (status) => {
        setAddResultIdModal({ status, bid: {} });
    };

    const closeAllDMinusOneModal = (status) => {
        setDMinusOneModal({ status, bid: {} });
    };

    const redirectToRevenue = () => {
        const link = `/?tab=3&showRevenueSplit=${JSON.stringify({
            status: true,
            data: {
                _id: selectCustomer?.value,
                biddingMarkets: selectCustomer?.biddingMarkets || [],
            },
        })}`;
        return link;
    };

    return (
        <div>
            <div className={mainBidClasses.Filter}>
                <div className={mainBidClasses.FilterContainer}>
                    <div style={{ marginTop: '2vh' }}>
                        <Typography content={`${market} Bids`} />
                    </div>
                    <div className={classes.TableCount} style={{ marginTop: '3vh' }}>
                        Total Revenue :
                        <span>
                            <Typography size="14" content={totalRevenue + ' €'} />
                        </span>
                    </div>

                    <div className={classes.TableCount} style={{ marginTop: '3vh' }}>
                        Total Count :
                        <span>
                            <Typography size="14" content={bids.length || 0} />
                        </span>
                    </div>
                    <div>
                        <Formik initialValues={{ customerId: '' }}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form style={{ display: 'flex', gridColumnGap: '0.3vw' }}>
                                    {customers.length > 0 && (
                                        <div className={classes.FieldControl}>
                                            <label>Customer</label>
                                            <Dropdown
                                                name="customerId"
                                                options={customers}
                                                defaultValue={selectCustomer}
                                                onChange={(e) => {
                                                    setSelectCustomer(e);
                                                    updateQueryParams('customer', JSON.stringify(e));
                                                }}
                                            />
                                        </div>
                                    )}
                                    {customerLicenses.length > 0 && (
                                        <div className={classes.FieldControl}>
                                            <label>License</label>
                                            <Dropdown
                                                name="licenseId"
                                                options={customerLicenses}
                                                defaultValue={selectedLicense}
                                                onChange={(e) => {
                                                    setSelectedLicense(e);
                                                    updateQueryParams('license', JSON.stringify(e));
                                                }}
                                            />
                                        </div>
                                    )}
                                    {licenseGroups.length > 0 && (
                                        <div className={classes.FieldControl}>
                                            <label>Instance</label>
                                            <Dropdown
                                                name="instanceId"
                                                options={licenseGroups}
                                                defaultValue={selectedInstance}
                                                onChange={(e) => {
                                                    if (e.value !== selectedInstance?.value) {
                                                        setSelectedInstance(e);
                                                    }
                                                    updateQueryParams('groups', JSON.stringify(e));
                                                }}
                                            />
                                        </div>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>

                <div className={classes.ButtonsContainer} style={{ marginTop: '3vh', alignItems: 'center' }}>
                    <div>
                        <button
                            onClick={() => {
                                if (isAggrement.isAggrement) {
                                    setBidsModal(true);
                                } else {
                                    toast.error('No Agreement Found.');
                                    setAggrement({
                                        ...isAggrement,
                                        modalStatus: true,
                                        link: redirectToRevenue(),
                                    });
                                }
                            }}
                            style={{ width: '7vw', margin: 0 }}
                            className="btn-primary"
                        >
                            Create Bid
                        </button>
                    </div>
                    <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} style={{ margin: 0 }} />
                </div>
            </div>

            <div className={classes.Bids}>
                <div className={classes.SubHeader}>
                    <div className={classes.Badges}>
                        <div className={classes.BadgeContainer}>
                            <Typography content="Max Capacity" />
                            <div className={classes.TableCount}>
                                <Typography size="14" content={minMaxValues.max} />
                            </div>
                        </div>
                        <div className={classes.BadgeContainer}>
                            <Typography content="Min Capacity" />
                            <div className={classes.TableCount}>
                                <Typography size="14" content={minMaxValues.min} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.DatePickers}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={classes.DateLabels}>Start Date</div>
                                <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                    End Date
                                </div>
                            </div>
                            <DateRangePicker
                                startDate={dateRange.startDate}
                                endDate={dateRange.endDate}
                                onChange={(date) => {
                                    setDateRange({
                                        startDate: new Date(date.startDate),
                                        endDate: new Date(date.endDate),
                                    });
                                    updateQueryParams('dateRange', JSON.stringify(date));
                                }}
                            />
                        </div>
                        <div className={classes.SubmitButton} onClick={() => fetchBids()}>
                            <button className="btn-primary">Submit</button>
                        </div>
                    </div>
                </div>
                <Table
                    head={['Date', 'Avg Capacity', 'Customer', 'Partner', 'S.C', 'Enablers', 'Revenue', 'Status (Primary/Secondary)', 'Action']}
                    keys={['cetDate', 'avgCapacity', 'customer', 'partner', 'sc', 'enablersAmount', 'revenue', 'status', 'actions']}
                    data={bids}
                />
            </div>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={isAggrement.modalStatus}
                setOpen={() => {
                    setAggrement({ ...isAggrement, modalStatus: false });
                }}
            >
                <CreateAgreement link={isAggrement.link} setOpen={setAggrement} />
            </ModalComponent>
            <ModalComponent
                isOpen={biddingInfoModal.status}
                setOpen={(status) => {
                    setBiddingInfoModal({
                        status: false,
                        price: {},
                        capacity: {},
                        hourlyData: [],
                    });
                }}
            >
                <BiddingInfoContent data={biddingInfoModal} setOpen={setBiddingInfoModal} fetchBids={fetchBids} market={market} />
            </ModalComponent>
            <ModalComponent isOpen={addResultIdModal.status} setOpen={closeAllResultIdModal}>
                <ResultId bid={addResultIdModal.bid} market={market} setModal={closeAllResultIdModal} fetchBids={fetchBids} />
            </ModalComponent>
            <ModalComponent
                isOpen={bidsModal}
                setOpen={(status) => {
                    setBidsModal(status);
                    setCreateBidDate('');
                }}
                style={{ overflow: 'initial' }}
            >
                <CreateBid fetchBids={fetchBids} setOpen={setBidsModal} customerId={selectCustomer.value} bidDate={createBidDate} instance={selectedInstance} market={market} />
            </ModalComponent>
            <ModalComponent
                isOpen={statusModal.status}
                setOpen={() => {
                    setStatusModal({ status: false, data: {} });
                }}
            >
                <Status data={statusModal.data} setOpen={setStatusModal} />
            </ModalComponent>
            <ModalComponent isOpen={dMinusOnemodal.status} setOpen={closeAllDMinusOneModal}>
                <CreateSecondaryBid bid={dMinusOnemodal.bid} market={market} setOpen={closeAllDMinusOneModal} fetchBids={fetchBids} />
            </ModalComponent>
            <ModalComponent
                isOpen={panicModal.status}
                setOpen={() => {
                    setPanicModal({ status: false, data: {} });
                }}
            >
                <Panic data={panicModal.data} setOpen={setPanicModal} fetchBids={fetchBids} />
            </ModalComponent>
            <ModalComponent
                isOpen={notesModal.status}
                setOpen={() => {
                    setNotesModal({ status: false, data: null });
                }}
            >
                <NotesLogsIndex data={notesModal.data} setOpen={setNotesModal} />
            </ModalComponent>
            <AlertModal title={alertModal} isOpen={alertModal} content={'No Data Found!'} setOpen={setAlertModal} />
        </div>
    );
};

export default Bids;
