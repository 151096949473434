import React, { useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import ApprovalOverview from './ApprovalOverview';

const Approval = () => {
    let tabs = [
        {
            name: 'Approval Overview',
            component: (
                <>
                    <ApprovalOverview />
                </>
            ),
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default Approval;
