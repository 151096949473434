// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import XLSX from 'xlsx';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

// Css imports
import Classes from '../../../../styles/AllDevices.module.css';
import AuditClasses from './index.module.css';
// Intenal module imports
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import FileUpload from '../../../../components/Inputs/FileUpload/FileUpload';
import { findDiffInSeconds } from '../../../../utils/timeHelper';
import { useLoader } from '../../../../hooks';

const TimestampAnalyzer = () => {
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState('');
    const [startLoader, stopLoader] = useLoader();

    const handleUploadClick = (e) => {
        try {
            const file = e.target.files[0];
            setFileName(file.name);
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const binaryString = new Uint8Array(arrayBuffer).reduce((data, byte) => {
                    return data + String.fromCharCode(byte);
                }, '');
                const workbook = XLSX.read(binaryString, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                setFileData(excelData);
            };
            reader.readAsArrayBuffer(file);
        } catch (err) {
            toast.error(`Error while processing file`);
        }
    };

    useEffect(() => {
        if (fileData) {
            startLoader();
            checkTimestamps(fileData, 'Date (CET)', 'Time (CET)', 'HH:mm:ss');
        }
    }, [fileData]);

    const checkTimestamps = (data = [], dateKey, timekey, formatter) => {
        const newData = [];
        const headers = Object.keys(data[0]);
        if (headers.includes('Time (CET)') && headers.includes('Date (CET)')) {
            let missingCount = 0;
            data.forEach((obj, index) => {
                if (index < data.length - 1) {
                    const currentTimestamp = moment(obj[timekey], formatter);
                    const nextTimestamp = moment(data[index + 1][timekey], formatter);
                    const diff = findDiffInSeconds(currentTimestamp, nextTimestamp);
                    if (diff > 0) {
                        newData.push(obj);
                        for (let i = 1; i < diff; i++) {
                            missingCount += i;
                            newData.push({
                                [dateKey]: obj[dateKey],
                                [timekey]: currentTimestamp.add(1, 'second').format(formatter),
                            });
                        }
                    } else {
                        newData.push(obj);
                    }
                } else {
                    newData.push(obj);
                }
            });
            if (missingCount > 0) {
                downloadFile(newData, headers);
                toast.error(`File has ${missingCount} missing timestamps.`);
            } else {
                toast.success('File has no missing timestamps.');
            }
        } else {
            if (!headers.includes('Time (CET)')) {
                toast.error('Time (CET) column not found');
            } else {
                toast.error('Date (CET) column not found');
            }
        }
        setFileData(null);
        stopLoader();
    };

    const downloadFile = (data, headers) => {
        DownloadAsExcel(data, `Timestamp Analyzed ${fileName}`, headers);
    };

    return (
        <div>
            <div className={Classes.AllDevices}>
                <div className={AuditClasses.UploadFileContainer}>
                    <Formik initialValues={{}} enableReinitialize>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={AuditClasses.FileContainer}>
                                        <div className={Classes.InputContainer}>
                                            <div className={AuditClasses.DatePickerLabel} style={{ width: '35vw' }}>
                                                <label>Upload file</label>
                                                <FileUpload
                                                    name="file"
                                                    id="file"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    onChange={handleUploadClick}
                                                    showPreview={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default TimestampAnalyzer;
