// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { UilTrash, UilCog } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import MultiSelectDropdown from '../../../components/Inputs/MultiSelectDropdown';
import { LicenseService } from '../../../services/LicenseService';
import { LicenseTypeServiceAdmin } from '../../../services/licenseTypeService';
import { ToggleButton, ToggleButtonWithState } from '../../../components/Inputs/Input';

import classes from '../../../styles/AllDevices.module.css';

function Licenses({ customer, updateQueryParams, queryParamsData = {} }) {
    const [startLoader, stopLoader] = useLoader();

    const [licenseFormModal, setLicenseFormModal] = useState(
        queryParamsData.licenseFormModal
            ? queryParamsData.licenseFormModal
            : {
                  status: false,
                  data: '',
              }
    );
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: '',
    });
    const [settingsModal, setSettingsModal] = useState({
        status: false,
        data: '',
    });
    const [licenseTypes, setLicenseTypes] = useState([]);
    const [licenses, setLicenses] = useState([]);

    useEffect(() => {
        fetchLicense();
        // getLicenseTypes();
    }, []);

    useEffect(() => {
        updateQueryParams('licenseFormModal', JSON.stringify(licenseFormModal));
    }, [licenseFormModal]);

    const fetchLicense = () => {
        LicenseService.ReadV2(customer._id, startLoader, handleLicenseSuccess, handleError, stopLoader);
    };

    const handleLicenseUpdate = (licenseType) => {
        LicenseService.updateV2(
            customer._id,
            licenseType._id,
            { enabled: licenseType.data?.enabled ? false : true },
            startLoader,
            () => handleSuccess('License Updated Successfully'),
            handleError,
            stopLoader
        );
    };

    const handleLicenseSuccess = ({ data }) => {
        const enabledLicenseTypes = {};
        let tableData = [];
        if (data.data?.length) {
            tableData = data.data.map((item, index) => {
                enabledLicenseTypes[item._id] = item._id;
                return {
                    's.no': index + 1,
                    licenseType: CamelCaseToTextCase(item.name),
                    enabled: (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ToggleButtonWithState value={item?.data?.enabled} name={`properties.alert`} onChange={() => handleLicenseUpdate(item)} defaultStyle={true} />
                        </div>
                    ),
                    action: (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ marginLeft: '0.3vw' }}>
                                <UilCog size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setSettingsModal({ status: true, data: item })} />
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: item._id })} />
                            </div>
                        </div>
                    ),
                };
            });
        }
        setLicenses(tableData);
        getLicenseTypes(enabledLicenseTypes);
    };

    const getLicenseTypes = (enabledLicenseTypes) => {
        LicenseTypeServiceAdmin.RealAll(startLoader, (data) => handleLicenseTypesSuccess(data.data, enabledLicenseTypes), handleError, stopLoader);
    };

    const handleLicenseTypesSuccess = (data, enabledLicenseTypes = {}) => {
        const licenseHash = enabledLicenseTypes;

        const licenseTypes = [];
        data.data.forEach((license) => {
            if (!licenseHash[license._id]) {
                licenseTypes.push({
                    label: CamelCaseToTextCase(license.name),
                    value: license._id,
                });
            }
        });
        licenseTypes.length > 0 && setLicenseTypes(licenseTypes);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSuccess = (res) => {
        if (res) {
            toast.success(res);
            fetchLicense();
            setLicenseFormModal({ status: false, data: '' });
            setDeleteModal({ status: false, id: '' });
        }
    };

    const handleSubmit = (values, resetForm) => {
        const types = {};
        values.licenseTypes &&
            values.licenseTypes.forEach((item) => {
                types[item.value] = {
                    enabled: true,
                    settings: {
                        overview: true,
                        alerts: true,
                        events: true,
                        details: true,
                    },
                };
            });
        if (Object.keys(types).length && customer._id) {
            LicenseService.CreateV2({ customerId: customer._id, types }, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleDelete = () => {
        LicenseService.deleteV2(customer._id, deleteModal.id, startLoader, () => handleSuccess('License Deleted Successfully'), handleError, stopLoader);
    };

    const handleSettingsUpdate = (data) => {
        LicenseService.updateSettings(
            customer._id,
            settingsModal.data._id,
            { setting: data },
            startLoader,
            () => handleSuccess('Tab Settings Updated Successfully'),
            handleError,
            stopLoader
        );
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={licenseFormModal.status}
                setOpen={(e) => {
                    setLicenseFormModal({ status: false, data: '' });
                }}
                style={{ overflow: 'initial' }}
            >
                <div>
                    <Typography content={`License (${customer.name})`} />
                    <div className={classes.FormContent} style={{ padding: '0' }}>
                        <Formik enableReinitialize initialValues={{ licenseTypes: [null] }} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}>
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2} style={{ width: '20vw' }}>
                                                <label for="licenseTypes">License Type</label>
                                                <MultiSelectDropdown name="licenseTypes" options={licenseTypes} />
                                            </div>
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Create
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={settingsModal.status}
                setOpen={(e) => {
                    setSettingsModal({ status: false, data: '' });
                }}
                style={{ overflow: 'initial' }}
            >
                <>
                    <Typography content={'Settings'} />
                    <div className={classes.FormContent}>
                        <Formik initialValues={settingsModal.data?.data?.settings} onSubmit={handleSettingsUpdate}>
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div className="flex-box">
                                            <ToggleButton values={values} label={'Overview'} name={'overview'} />
                                            <ToggleButton values={values} label={'Alerts'} name={'alerts'} />
                                            <ToggleButton values={values} label={'Events'} name={'events'} />
                                            <ToggleButton values={values} label={'Details'} name={'details'} />
                                        </div>
                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Update
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Licenses" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={licenses?.length || 0} />
                        </span>
                    </div>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn-primary"
                        style={{ marginTop: '0' }}
                        onClick={() =>
                            setLicenseFormModal({
                                status: true,
                                data: '',
                            })
                        }
                    >
                        Create License
                    </button>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'License Type', 'Enabled', 'Action']} keys={['s.no', 'licenseType', 'enabled', 'action']} data={licenses} />
            </div>
        </div>
    );
}

export default Licenses;
