// Standard Library Imports
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
// External library imports
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { UilCopy } from '@iconscout/react-unicons';
// CSS Imports
import classes from '../../../../../styles/Bids.module.css';
import AdminClasses from '../../Admin.module.css';
// Internal module imports
import Table from '../../../../../components/Table/Table';
import Typography from '../../../../../components/Typography/Typography';
import { RootService } from '../../../../../services/RootSevice';
import { useLoader } from '../../../../../hooks';

const Info = ({ infoModal, setInfoModal }) => {
    const [startLoader, stopLoader] = useLoader();
    const [processedData, setProcessedData] = useState([]);

    useEffect(() => {
        if (infoModal?._id) {
            getAwsDetails();
        }
    }, []);

    const getAwsDetails = () => {
        RootService.GetAwsDetails(infoModal._id, startLoader, handleSuccess, handleError, stopLoader);
    };
    const handleSuccess = ({ data }) => {
        const temp = [
            {
                name: 'AWS Access Key',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{data?.data?.awsAccessKey || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.data?.awsAccessKey} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'AWS Secret Key',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{data?.data?.awsSecretKey || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={data?.data?.awsSecretKey} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
        ];
        setProcessedData((prevState) => [...prevState, ...temp]);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    useEffect(() => {
        const tempData = [
            {
                name: 'MongoDB User Name',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{infoModal?.mongoDetails?.userName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal?.mongoDetails?.userName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'MongoDB Password',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{infoModal?.mongoDetails?.password || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal?.mongoDetails?.password} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'MongoDB Access Permission',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>
                            {infoModal?.mongoDetails?.permission.read
                                ? 'Read'
                                : infoModal?.mongoDetails?.permission.write
                                  ? 'Read / Write'
                                  : infoModal?.mongoDetails?.permission.dbAdmin
                                    ? 'DB Admin'
                                    : '--' || '--'}
                        </div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard
                            text={
                                infoModal?.mongoDetails?.permission.read
                                    ? 'Read'
                                    : infoModal?.mongoDetails?.permission.write
                                      ? 'Read / Write'
                                      : infoModal?.mongoDetails?.permission.dbAdmin
                                        ? 'DB Admin'
                                        : '--' || '--'
                            }
                            onCopy={() => toast.success('Copied!')}
                        >
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Teamviewer Email',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{infoModal?.teamviewerDetails?.email || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal?.teamviewerDetails?.email || '--'} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Teamviewer Password',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{infoModal?.teamviewerDetails?.password || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal?.teamviewerDetails?.password || '--'} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'MQTT User Name',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{infoModal?.mqttUserName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal?.mqttUserName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'MQTT Password',
                value: (
                    <div className={AdminClasses.TableValueParent}>
                        <div className={AdminClasses.TableValue}>{infoModal?.mqttPassword || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal?.mqttPassword} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
        ];
        setProcessedData((prevState) => [...prevState, ...tempData]);
    }, [infoModal]);

    return (
        <div style={{ minWidth: '40vw' }}>
            <div className={AdminClasses.EdgeInfoModalHeader}>
                <Typography content={'Info'} />
            </div>
            <div>
                <Table head={['Label', 'Value', 'Action']} keys={['name', 'value', 'action']} data={processedData} />
            </div>
            <div className={classes.ButtonContainer}>
                <div>
                    <button type="submit" className="btn-primary" onClick={() => setInfoModal({ infoModal: {}, status: false })}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Info;
