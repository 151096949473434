import './App.css';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import PublicLayout from './layouts/PublicLayout';
import { useContext, useEffect } from 'react';
import { LoaderContext } from './context/LoaderContext';
import { REMOVE_USER, SET_USER, START_LOADER, STOP_LOADER } from './constants';
import { AuthContext } from './context/AuthContext';
import { PrivateRoute } from './routes/PrivateRoute';
import { UserService } from './services/UserService';
import { getThemeFromLC, saveUser } from './utils/localStorage';
import { get as lodashGet } from 'lodash';
import { toast } from 'react-toastify';

function App() {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const { state } = useContext(AuthContext);
    const { dispatch: authDispatch } = useContext(AuthContext);
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });
    const removeUser = (payload) => authDispatch({ type: REMOVE_USER });

    useEffect(() => {
        const defaultTheme = { primaryColor: '#098074' };
        const rootTheme = { primaryColor: '#032623' };
        const theme = lodashGet(state, 'user.userRole') === 'root' ? rootTheme : lodashGet(state, 'user.properties.theme') || getThemeFromLC() || defaultTheme;

        document.documentElement.style.setProperty('--color-primary', theme.primaryColor);
    }, [lodashGet(state, 'user')]);

    useEffect(() => {
        verifyUser();
        // eslint-disable-next-line
    }, []);
    const verifyUser = () => {
        UserService.Verify(
            () => startLoader('verifyUser'),
            handleVerifySuccess,
            handleVerifyError,
            () => stopLoader('verifyUser')
        );
    };
    useEffect(() => {
        startLoader('firstLoad');
        let timer1 = setTimeout(() => stopLoader('firstLoad'), 1000);
        return () => {
            clearTimeout(timer1);
        };
        // eslint-disable-next-line
    }, []);

    const handleVerifySuccess = ({ data }) => {
        if (data.success) {
            const user = data.data;
            dispatchSetUser({ user });
            const token = localStorage.getItem('GM_TOKEN');
            const toBeSaved = {
                id: user._id,
                email: user.email,
                profileImage: user.profileImage,
                userRole: user.userRole,
                token: token,
            };
            saveUser(toBeSaved);
        }
    };

    const handleVerifyError = (err) => {
        removeUser();
        const message = err && err.response ? err.response.data.message : '';
        if (message && message !== 'jwt malformed') {
            toast.error(message);
        }
    };

    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/auth" component={AuthLayout} />
                    <PrivateRoute path="/" component={PublicLayout} />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
