import classes from '../../../../../styles/AllDevices.module.css';
import React from 'react';
import Typography from '../../../../../components/Typography/Typography';
import CreateButton from '../../../../../components/Buttons/CreateButton';
import Table from '../../../../../components/Table/Table';

const AllDevices = ({ setAddKeyModal, processedDevices }) => {
    return (
        <div className={classes.AllDevices}>
            <div className={classes.Header}>
                <div>
                    <Typography content="All Meters" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={processedDevices.length} />
                        </span>
                    </div>
                </div>

                <div>
                    <CreateButton size="medium" text="Add Key" onClick={() => setAddKeyModal(true)} />
                </div>
            </div>
            <div>
                <Table addIndex head={['S No', 'KEY/CVR', 'Meter Name', 'Meter Id', 'Actions']} keys={['cvr', 'name', 'id', 'actions']} data={processedDevices} />
            </div>
        </div>
    );
};

export default AllDevices;
