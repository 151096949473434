import * as yup from 'yup';

export const edgeCredentials = yup.object().shape({
    username: yup.string().required(),
    password: yup
        .string()
        .required()
        .matches(/^[a-z0-9]+$/i, 'Password should consist only alpha-numeric characters')
        .min(6, 'Minimum length of password is 6'),
    mqttPort: yup.number().required(),
    mqttHost: yup.string().required(),
});
