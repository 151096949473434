import classes from './index.module.css';
import React, { useState } from 'react';
import TabsComponent from '../../../../components/Tabs/Tabs';
import MonitoringAlerts from './MonitoringAlert';
import EmailSubscriptions from './EmailSubscription';
const Notifications = ({ queryParamsData, updateQueryParams }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    let tabs = [
        {
            name: 'Control Panel',
            component: (
                <>
                    <MonitoringAlerts />
                </>
            ),
            tabId: 3,
        },
        // {
        //   name: "Email Subscription",
        //   component: (
        //     <>
        //       <EmailSubscriptions />
        //     </>
        //   ),
        // },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default Notifications;
