import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import classes from '../../../../styles/BidModal.module.css';
import { getJustNumbers } from '../../../../utils/stringHelper';
import { Input, ToggleButtonWithState } from '../../../../components/Inputs/Input';
import { toast } from 'react-toastify';
import Typography from '../../../../components/Typography/Typography';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { CreateDMinusBundleBidValidation } from '../../../../validations/BundleBids/BundleBidValidation';
import bundleBidClasses from '../index.module.css';
import _ from 'lodash';

const getInitialValues = (bid) => {
    const values = {
        capacity: [],
        price: [],
    };
    let tempCapacity = {};
    bid.bids.map((b) => {
        if (_.get(bid, ['primary', 'provider', 'bidStatus'], []).length) {
            _.get(bid, ['primary', 'provider', 'bidStatus']).forEach((hourData, index) => {
                if (hourData.capacity === 0) {
                    _.set(tempCapacity, [b.instanceId, index], _.get(b, ['primary', 'capacity', index], 0));
                } else {
                    _.set(tempCapacity, [b.instanceId, index], null);
                }
            });
        } else {
            _.set(tempCapacity, b.instanceId, b.primary.capacity);
        }
    });

    if (_.get(bid, ['primary', 'provider', 'bidStatus'], []).length) {
        _.get(bid, ['primary', 'provider', 'bidStatus'], []).map((e) => {
            if (e.capacity === 0) {
                values.price.push(1);
            } else {
                values.price.push(null);
            }
        });
    }

    values.capacity = tempCapacity;
    return values;
};

const getPriceCapacity = (initialValues) => {
    return { capacity: initialValues.capacity, price: initialValues.price };
};

const CreateSecondaryBid = ({ bid, startLoader, stopLoader, setOpen, getBundleBids }) => {
    const [instanceIds] = useState(bid.bids.map((b) => b.instanceId) || []);
    const [initialValues, setInitialValues] = useState(getInitialValues(bid));

    const [{ capacity, price }, setPriceCapacityValues] = useState(getPriceCapacity(initialValues));

    const [isSecondaryBid, setIsSecondaryBid] = useState(false);
    const [instances, setInstances] = useState([]);
    const [allHours, setAllHours] = useState(false);

    useEffect(() => {
        if (instanceIds.length) {
            getInstanceDetailsByIds();
        }
    }, []);

    const getInstanceDetailsByIds = () => {
        const params = {
            instanceIds,
        };
        BundleBiddingService.GetInstanceDetailsByIds(params, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleInstanceSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            name: item.name,
            instanceId: item._id,
        }));
        setInstances(temp);
    };

    const handleSubmit = (values) => {
        if (bid._id) {
            BundleBiddingService.UpdateDMinusOne(bid._id, values, startLoader, handleSuccess, handleError, stopLoader);
        }
    };

    const handleSuccess = ({ res }) => {
        toast.success('Bid Updated!');
        getBundleBids();
        setOpen({ status: false, bid: {} });
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleDMinus2NotAvailableChange = async (e) => {
        setIsSecondaryBid(e.target.checked);
        if (e.target.checked) {
            const tempCapacity = {};
            bid.bids.map((item) => {
                _.set(tempCapacity, [item.instanceId], _.get(item, ['primary', 'capacity']));
            });
            setInitialValues({
                ...initialValues,
                capacity: tempCapacity,
                price: Array(24).fill(1),
            });
            setPriceCapacityValues({ capacity: tempCapacity, price: Array(24).fill(1) });
        }
    };

    const handleAllHours = (e) => {
        setAllHours(e.target.checked);
        let tempCap = {};
        let tempPrice = [];
        _.get(bid, ['primary', 'price'], []).map((p, i) => {
            if (_.get(price, [i])) {
                tempPrice.push(_.get(price, [i]));
            } else {
                tempPrice.push(0);
            }
        });
        if (e.target.checked) {
            bid.bids.map((b) => {
                let tempCapacity = [];
                _.get(b, ['primary', 'capacity']).map((c, idx) => {
                    if (_.get(capacity, [b.instanceId, idx])) {
                        tempCapacity.push(_.get(capacity, [b.instanceId, idx]));
                    } else {
                        tempCapacity.push(0);
                    }
                });
                _.set(tempCap, b.instanceId, tempCapacity);
            });
        } else {
            const data = getInitialValues(bid);
            tempCap = data.capacity;
            tempPrice = data.price;
        }
        setInitialValues({ ...initialValues, capacity: tempCap, price: tempPrice });
        setPriceCapacityValues({ capacity: tempCap, price: tempPrice });
    };

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography content={`${bid?.market} Secondary Bid ( ${momentTimeFormater(bid?.date).format('DD-MM-YYYY')} )`} size="16" />
                {price?.filter((e) => e != null).length > 0 && !isSecondaryBid && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <ToggleButtonWithState value={allHours} onChange={handleAllHours} label="All Hours" />
                    </div>
                )}
            </div>
            <div className={classes.FormContainer2}>
                {price?.filter((e) => e != null).length > 0 || isSecondaryBid ? (
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateDMinusBundleBidValidation(instances)}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        {/*capacity  */}
                                        {instances?.map((el, i) => (
                                            <div key={i}>
                                                <div>
                                                    <div className={classes.Subtitle}>Capacity(Mw) - {el.name} </div>
                                                </div>

                                                <div className={bundleBidClasses.DMinusContainer}>
                                                    {_.get(capacity, [el.instanceId], []).map((item, index) =>
                                                        item || item == 0 ? (
                                                            <div className={classes.InputContainer} key={index}>
                                                                <div>
                                                                    <div className={classes.FieldControl}>
                                                                        <label>
                                                                            Slot {index + 1} / Hour {index}-{index + 1}{' '}
                                                                        </label>
                                                                        <Input name={`capacity[${el.instanceId}][${index}]`} id={index} type="number" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <></>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}

                                        {/*  */}
                                        <div>
                                            <div className={classes.Subtitle}>Price(€)</div>
                                            <div className={bundleBidClasses.DMinusContainer}>
                                                {price?.map((item, index) =>
                                                    item || item == 0 ? (
                                                        <div className={classes.InputContainer} key={index}>
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label>
                                                                        Slot {index + 1} / Hour {index}-{index + 1} Price
                                                                    </label>
                                                                    <Input name={`price[${index}]`} id={index} type="number" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setOpen({ status: false, bid: {} })}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <span>Looks like result for primary bid are not announced yet.</span>
                        <div>OR</div>
                        <span>Result were in favour for all hours.</span>
                        <div>OR</div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <ToggleButtonWithState value={isSecondaryBid} onChange={handleDMinus2NotAvailableChange} label="Primary bid not available" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateSecondaryBid;
