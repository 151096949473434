import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/business-website/client',
    create: '/root/business-website/client',
    delete: '/root/business-website/client',
    update: '/root/business-website/client',
};

const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll, { params }).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (data, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${data.id}`, { params: data }).then(callback).catch(error).finally(next);
};
export const RootBusinessWebsiteService = {
    ReadAll,
    Create,
    Update,
    Delete,
};
