import classes from '../../../../../styles/CreateDevice.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../../../components/Typography/Typography';
import { Form, Formik } from 'formik';
import { Input } from '../../../../../components/Inputs/Input';
import 'react-toggle/style.css'; // for ES6 modules
import { toast } from 'react-toastify';
import { useLoader } from '../../../../../hooks';
import { UserService } from '../../../../../services/UserService';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import PhoneNumberComponent from '../../../../../components/Inputs/PhoneNumberComponent';
import FileUpload from '../../../../../components/Inputs/FileUpload/FileUpload';
import { createAdminUserValidation, updateAdminUserValidation } from '../../../../../validations/Root/adminUserValidation';

const getInitialValues = (editUser) => {
    if (!editUser?._id) {
        return {
            name: '',
            email: '',
            password: '',
            userRole: '',
            contact: '',
            adminId: '',
            countryCode: '',
        };
    } else {
        let temp = {
            name: editUser.name,
            email: editUser.email,
            password: '',
            userRole: editUser.userRole,
            contact: editUser.contact,
            adminId: editUser.adminId,
            countryCode: editUser.countryCode,
        };
        return temp;
    }
};

const CreateUser = ({ editUser = null, allAdmins, autoFillAdminId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [admins, setAdmins] = useState([{ label: 'None', value: '' }]);
    const [userRoleSelected, setUserRole] = useState({
        value: 'sub-admin',
        label: 'Sub Admin',
    });
    const [customIntialValue, setCustomIntialValue] = useState(getInitialValues(editUser));
    const [profileImage, setProfileImage] = useState([]);

    const userRole = [{ value: 'sub-admin', label: 'Sub Admin' }];

    const handleSubmit = (values, resetForm) => {
        const formData = new FormData();
        const isProfileImageValid = /(jpg|png|peg|svg)$/.test(profileImage?.name?.slice(-4));
        if (profileImage.length && !isProfileImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('profileImage', profileImage);
        formData.append('name', values.name);
        formData.append('contact', values.contact);
        formData.append('countryCode', values.countryCode);
        formData.append('adminId', values.adminId);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('userRole', values.userRole);
        if (editUser?._id) {
            UserService.Update(editUser?._id, formData, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            UserService.Create(formData, startLoader, (data) => handleCreateSuccess(data, resetForm), handleError, stopLoader);
        }
    };

    const handleCreateSuccess = (data, resetForm) => {
        resetForm();
        toast.success('User created!');
    };

    const handleUpdateSuccess = (data, resetForm) => {
        toast.success('User updated!');
    };

    useEffect(() => {
        let temp = [];
        allAdmins.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setAdmins(temp);
    }, [JSON.stringify(allAdmins)]);

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };
    return (
        <div className={classes.FormContainer}>
            <Typography content={!editUser?._id ? 'Create User' : 'Edit User'} />
            <div className={classes.FormContent}>
                <Formik
                    enableReinitialize
                    initialValues={customIntialValue}
                    validationSchema={editUser?._id ? updateAdminUserValidation : createAdminUserValidation}
                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input name="name" id="name" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="Email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <Input name="email" id="Email" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="password">
                                            Password <span className="required">*</span>
                                        </label>
                                        <Input name="password" id="password" type="password" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="userRole">
                                            User Role <span className="required">*</span>
                                        </label>
                                        <Dropdown name="userRole" id="userRole" options={userRole} onChange={({ value }) => setUserRole(value)} defaultValue={userRoleSelected} />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="contact">
                                            Contact <span className="required">*</span>
                                        </label>
                                        <div>
                                            <PhoneNumberComponent
                                                inputName="contact"
                                                countryCodeName="countryCode"
                                                inputValues={values}
                                                defaultCountryCode={{ value: values.countryCode }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="adminId">
                                            Admin Id <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="adminId"
                                            id="adminId"
                                            options={admins}
                                            defaultValue={
                                                autoFillAdminId && admins.filter((admin) => admin.value === autoFillAdminId).length
                                                    ? admins.filter((admin) => admin.value === autoFillAdminId)[0]
                                                    : editUser?._id
                                                      ? {
                                                            label: '',
                                                            value: editUser.adminId,
                                                        }
                                                      : {}
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="profileImage">Profile Image</label>
                                        <FileUpload name="profileImage" id="profileImage" accept="image/*" onChange={handleUploadClick} />
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateUser;
