import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const cronJobsValidation = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        pattern: yup.string().required(ERRORS.required),
    });
};

export const cronJobsRulesValidation = () => {
    return yup.object().shape({
        collection: yup.string().required(ERRORS.required),
        preserveDays: yup.string().required(ERRORS.required),
        value: yup.number().required(ERRORS.required),
    });
};

export const instancePowerRuleValidation = () => {
    return yup.object().shape({
        adminId: yup.string().required(ERRORS.required),
        customerId: yup.string().required(ERRORS.required),
        licenseId: yup.string().required(ERRORS.required),
        deviceControl: yup.boolean().required(ERRORS.required),
    });
};
