// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { UilTimesCircle, UilFileDownload, UilPlusCircle } from '@iconscout/react-unicons';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
// Internal module import
import { DocumentsService } from '../../../services/DocumentService';
import CkeEditor from '../../../components/CkeEditor';
import { useLoader } from '../../../hooks';
import Typography from '../../../components/Typography/Typography';
import MultipleFilesUpload from '../../../components/Inputs/FileUpload/MultipleFileUpload';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import InputComponent from './InputComponent';

// Css imports
import classes from '../../../styles/AllDevices.module.css';
import documentClasses from '../Documents.module.css';

const DocumentEditor = ({ nodeData, setOpen, setNodeData }) => {
    const [documentData, setDocumentData] = useState('');
    const [startLoader, stopLoader] = useLoader();
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [storedDocumentData, setStoredDocumentData] = useState('');
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [activePageId, setActivePageId] = useState(1);
    const [readOnly, setReadOnly] = useState(true);
    const [documentsPage, setDocumentsPage] = useState([{ name: 'Page 1', pageId: 1, data: '' }]);

    useEffect(() => {
        const data = documentsPage.find((item) => item.pageId === activePageId);
        setDocumentData(data.data);
    }, [documentsPage, activePageId]);

    useEffect(() => {
        if (nodeData?._id) {
            getDocument();
        }
    }, [nodeData]);

    const getDocument = () => {
        DocumentsService.GetDocument(nodeData._id, startLoader, handleGetDocumentSuccess, handleError, stopLoader);
    };

    const handleGetDocumentSuccess = ({ data }) => {
        data?.data?.pages?.length && setDocumentsPage(data?.data?.pages);
        data?.data?.pages?.length && setStoredDocumentData(data?.data?.pages);
        setUploadedFiles(data?.data?.files || []);
    };

    const saveDocument = () => {
        const payload = {
            id: nodeData._id,
            pages: documentsPage,
        };
        DocumentsService.SaveDocument(payload, startLoader, handleSaveDocumentSuccess, handleError, stopLoader);
    };

    const handleSaveDocumentSuccess = () => {
        toast.success('Document saved!');
        setOpen(false);
        setDocumentData('');
        setNodeData({});
    };

    const uploadFiles = (files) => {
        let tempFiles = [];
        for (let i = 0; i < files.length; i++) {
            tempFiles.push(files[i]);
        }
        setUploadedFiles(uploadedFiles.concat(tempFiles));
        const formData = new FormData();
        formData.append('id', nodeData._id);
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        DocumentsService.UploadFiles(formData, handleUploadFilesSuccess, handleError);
    };
    const handleGetUploadedFilesSuccess = ({ data }) => {
        setUploadedFiles(data.data?.files || []);
    };

    const getUploadedFiles = () => {
        DocumentsService.GetFiles(nodeData._id, handleGetUploadedFilesSuccess, handleError);
    };

    const handleUploadFilesSuccess = () => {
        getUploadedFiles();
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleUploadClick = (e) => {
        const files = e.target.files;
        uploadFiles(files);
    };

    const removeFile = (key) => {
        const payload = {
            id: nodeData._id,
            file: key,
        };
        DocumentsService.RemoveFile(payload, startLoader, handleRemoveFilesSuccess, handleError, stopLoader);
    };

    const handleRemoveFilesSuccess = () => {
        toast.success('File Removed!');
        getUploadedFiles();
    };

    const addPage = () => {
        setDocumentsPage((prevData) => [
            ...prevData,
            {
                name: `Page ${prevData.length + 1}`,
                pageId: prevData.length + 1,
                data: '',
            },
        ]);
        setActivePageId(documentsPage.length + 1);
    };

    const setPageDocumentData = (data) => {
        const temp = documentsPage.map((item) => {
            if (item.pageId === activePageId) {
                return { ...item, data: data };
            } else {
                return item;
            }
        });
        setDocumentsPage(temp);
    };

    return (
        <div className={documentClasses.Container}>
            <DeleteModal
                opendeleteModal={confirmationModal}
                setOpenDeleteModal={(e) => {
                    setConfirmationModal(e);
                    setOpen(false);
                    setNodeData({});
                }}
                deletefunction={saveDocument}
                text={'Changes you made may not be saved.'}
                yesButtonText={'Save and Close'}
                noButtonText={'Close'}
            />
            <Formik enableReinitialize initialValues={{ editor: {} }}>
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div className={documentClasses.HeaderSection}>
                            <Typography content={nodeData?.name} />
                            <div>
                                <div className={classes.ButtonContainer} style={{ marginTop: '0' }}>
                                    <div>
                                        <button
                                            className="btn-secondary"
                                            style={{ marginTop: '0' }}
                                            onClick={() => {
                                                let isEdited = false;
                                                if (storedDocumentData.length !== documentsPage.length) {
                                                    isEdited = true;
                                                } else {
                                                    for (let i = 0; i < storedDocumentData.length; i++) {
                                                        if (storedDocumentData[i].name !== documentsPage[i].name || storedDocumentData[i].data !== documentsPage[i].data) {
                                                            isEdited = true;
                                                            break;
                                                        }
                                                    }
                                                }

                                                if (!isEdited || readOnly) {
                                                    setOpen(false);
                                                    setDocumentData('');
                                                    setNodeData({});
                                                } else {
                                                    setConfirmationModal(true);
                                                }
                                            }}
                                        >
                                            Close
                                        </button>
                                    </div>
                                    <div>
                                        {readOnly ? (
                                            <button type="button" style={{ marginTop: '0' }} className="btn-primary" onClick={() => setReadOnly(false)}>
                                                Edit
                                            </button>
                                        ) : (
                                            <button type="submit" style={{ marginTop: '0' }} className="btn-primary" onClick={saveDocument}>
                                                Save
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: '1vh' }}>
                            <Accordion allowZeroExpanded>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            <div>Documents</div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className={documentClasses.UploadSection}>
                                            {!readOnly && (
                                                <div className={classes.FieldControl2} style={{ width: '25vw' }}>
                                                    <label for="uploadFiles">Upload files</label>
                                                    <MultipleFilesUpload name="uploadFiles" id="uploadFiles" accept="*" multiple onChange={handleUploadClick} disabled={readOnly} />
                                                </div>
                                            )}

                                            <div className={classes.FieldControl2}>
                                                {uploadedFiles?.length ? <label for="uploadedFiles">Uploaded files</label> : ''}
                                                <div className={documentClasses.UploadedFiles}>
                                                    {uploadedFiles?.length
                                                        ? uploadedFiles.map((item, index) => (
                                                              <div>
                                                                  <div className={documentClasses.SelectedFile}>
                                                                      <div key={index}>
                                                                          <p>{item.name}</p>
                                                                      </div>
                                                                      {item?.key ? (
                                                                          <div style={{ display: 'flex' }}>
                                                                              <div className={documentClasses.CloseIcon}>
                                                                                  <a href={item.location} download={item.name} target="_blank">
                                                                                      <UilFileDownload
                                                                                          style={{
                                                                                              color: 'var(--color-primary)',
                                                                                          }}
                                                                                          size={'1.3vw'}
                                                                                      />
                                                                                  </a>
                                                                              </div>
                                                                              {!readOnly && (
                                                                                  <div onClick={() => removeFile(item.key)} className={documentClasses.CloseIcon}>
                                                                                      <UilTimesCircle
                                                                                          style={{
                                                                                              color: 'var(--color-primary)',
                                                                                          }}
                                                                                          size={'1.3vw'}
                                                                                      />
                                                                                  </div>
                                                                              )}
                                                                          </div>
                                                                      ) : (
                                                                          ''
                                                                      )}
                                                                  </div>
                                                                  {!item?.key ? <p className={documentClasses.Uploading}>Uploading...</p> : ''}
                                                              </div>
                                                          ))
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                        <div>
                            <CkeEditor
                                name="editor"
                                onChange={(e) => setPageDocumentData(e)}
                                defaultValue={documentData}
                                apiUrl={'admin/documents/upload-image'}
                                disabled={readOnly}
                            ></CkeEditor>
                        </div>
                        <div className={documentClasses.PageContainer}>
                            {documentsPage?.map((item) => {
                                if (item?.name) {
                                    return (
                                        <InputComponent
                                            pageValue={item}
                                            setDocumentsPage={setDocumentsPage}
                                            setDocumentData={setDocumentData}
                                            setActivePageId={setActivePageId}
                                            activePageId={activePageId}
                                            documentsPage={documentsPage}
                                        />
                                    );
                                }
                            })}
                            {!readOnly && (
                                <UilPlusCircle
                                    size={'1.2vw'}
                                    style={{
                                        color: 'var(--color-primary)',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        addPage();
                                    }}
                                />
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default DocumentEditor;
