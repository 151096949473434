// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getbids: '/root/bidding/get-bids',
    getAllbids: '/root/bidding/get-all-bids',
    createBulkBid: '/root/bidding/create-bulk-bids',
    fetchBidStatus: '/root/bidding/fetch-status',
    overallCapacity: 'root/bidding/overall-capacity',
    getAllBidsForEnerginet: 'root/bidding/get-all-bids-energinet',
    postBidEnerginet: 'root/bidding/post-bid-energinet',
    postBidFcr: 'root/bidding/post-bid-fcr',
    export: '/root/bidding/export',
    deleteBid: '/root/bidding/bid',
    general: '/admin/data',
    bidRequest: '/admin/data/bid-request',
    maintenanceHours: '/admin/data/maintenance-hours',
    biddingTool: 'admin/data',
};

const GetBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getbids}`, { params }).then(callback).catch(error).finally(next);
};
const GetAllBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllbids}`, { params }).then(callback).catch(error).finally(next);
};

const ExportBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.export}`, { params }).then(callback).catch(error).finally(next);
};
const Delete = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBid}/${id}`, { params }).then(callback).catch(error).finally(next);
};

const PostBidEnerginet = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBidEnerginet}`, payload).then(callback).catch(error).finally(next);
};

const FecthBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.fetchBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const CreateBulkBids = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createBulkBid}`, payload).then(callback).catch(error).finally(next);
};

const GetBidRequestForADate = (params, groupId, market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}/${market}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetMaintenanceHoursForADate = (params, groupId, market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}/${market}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetBiddingToolData = (market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.biddingTool}/${market}/bidding-tool`).then(callback).catch(error).finally(next);
};

const GetOverallCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.overallCapacity}`, { params }).then(callback).catch(error).finally(next);
};

const GetAllBidsForEnerginet = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getAllBidsForEnerginet}`, { params }).then(callback).catch(error).finally(next);
};

const PostBidFcr = (id, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBidFcr}/${id}`).then(callback).catch(error).finally(next);
};

export const BiddingService = {
    GetBids,
    ExportBids,
    Delete,
    PostBidEnerginet,
    FecthBidStatus,
    GetAllBids,
    CreateBulkBids,
    GetBidRequestForADate,
    GetMaintenanceHoursForADate,
    GetBiddingToolData,
    GetOverallCapacity,
    GetAllBidsForEnerginet,
    PostBidFcr,
};
