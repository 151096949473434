import React from 'react';
import Table from '../../../../../components/Table/Table';
import Typography from '../../../../../components/Typography/Typography';
import classes from '../index.module.css';

const BatchInfo = ({ status = [] }) => {
    const processedData = status.map((p) => ({
        ...p,
        commandReply: p.commandReply ? JSON.stringify(p.commandReply) : '--',
        commandSent: (
            <div className={classes.StatusContainer}>{p.commandSent ? <div className={classes.AccessNotBlocked}></div> : <div className={classes.AccessBlocked}></div>}</div>
        ),
        commandAckreceived: (
            <div className={classes.StatusContainer}>{p.commandAckreceived ? <div className={classes.AccessNotBlocked}></div> : <div className={classes.AccessBlocked}></div>}</div>
        ),
        bootNotificationRecieved: (
            <div className={classes.StatusContainer}>
                {p.bootNotificationRecieved ? <div className={classes.AccessNotBlocked}></div> : <div className={classes.AccessBlocked}></div>}
            </div>
        ),
        versionMatched: (
            <div className={classes.StatusContainer}>{p.versionMatched ? <div className={classes.AccessNotBlocked}></div> : <div className={classes.AccessBlocked}></div>}</div>
        ),
    }));

    return (
        <div>
            <Typography content={'Batch Info'} />
            <div style={{ width: '60vw', maxHeight: '60vh', overflowY: 'scroll' }}>
                <Table
                    head={['Customer', 'Edge', 'Cmd Sent', 'Cmd Ack Received', 'Cmd Reply', 'Boot Notification Recv', 'Version Match']}
                    keys={['customer', 'edgeAlias', 'commandSent', 'commandAckreceived', 'commandReply', 'bootNotificationRecieved', 'versionMatched']}
                    data={processedData}
                />
            </div>
        </div>
    );
};

export default BatchInfo;
