// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/edge-profile',
    create: '/root/edge-profile',
    update: '/root/edge-profile',
    delete: '/root/edge-profile',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

export const RootEdgeProfileService = {
    ReadAll,
    Create,
    Update,
    Delete,
};
