import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/admin/signals',
    update: '/admin/signals',
    delete: '/admin/signals',
    read_dataSourceList: '/admin/dataSourceList',
    readAllAdmin: '/admin/signals',
    readSignalDataFromSource: '/admin/signals/data',
    lastReportedData: '/admin/signals/lastReported',
    signalError: '/admin/signals/error',
    activationProfile: '/admin/signals/activation-profile',
    report: 'admin/signals/report',
    globalAndCustomerSingal: 'admin/signals/global-customer-signal',
    allSignalAndCustomerSignal: 'admin/signals/customer',
};

const createType = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

// deleting a signal accepting id as param
const deleteType = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};
const updateType = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const readDataSourceList = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_dataSourceList).then(callback).catch(error).finally(next);
};

const ReadAllAdmin = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAllAdmin).then(callback).catch(error).finally(next);
};

const ReadSignalDataFromSource = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readSignalDataFromSource, { params }).then(callback).catch(error).finally(next);
};

const getLastReportedData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.lastReportedData, { params }).then(callback).catch(error).finally(next);
};

const getSignalError = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.signalError, { params }).then(callback).catch(error).finally(next);
};

const getActivationProfile = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.activationProfile, { params }).then(callback).catch(error).finally(next);
};

const AutoRefreshData = (params, callback, error, next) => {
    return HttpClient.get(PATH.readSignalDataFromSource, { params }).then(callback).catch(error).finally(next);
};

const AutoRefreshActivationProfile = (params, callback, error, next) => {
    return HttpClient.get(PATH.activationProfile, { params }).then(callback).catch(error).finally(next);
};

const autoRefreshLastReportedData = (params, callback, error, next) => {
    return HttpClient.get(PATH.lastReportedData, { params }).then(callback).catch(error).finally(next);
};

const ReadAllById = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read_all}/${id}`).then(callback).catch(error).finally(next);
};

const ReadAllByIdAdmin = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read_all}/${id}`).then(callback).catch(error).finally(next);
};

const ReadAllSignalsAndCustomerSignal = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.allSignalAndCustomerSignal}/${id}`).then(callback).catch(error).finally(next);
};

const getReport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.report, { params }).then(callback).catch(error).finally(next);
};

const GetGlobalAndCustomerSignals = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.globalAndCustomerSingal}/${id}`).then(callback).catch(error).finally(next);
};

export const signalsService = {
    ReadAll,
    ReadAllById,
    deleteType,
    createType,
    readDataSourceList,
    updateType,
    ReadSignalDataFromSource,
    getLastReportedData,
    getSignalError,
    getActivationProfile,
    AutoRefreshData,
    AutoRefreshActivationProfile,
    autoRefreshLastReportedData,
    getReport,
    GetGlobalAndCustomerSignals,
};

export const signalsServiceAdmin = {
    RealAll: ReadAllAdmin,
    ReadAllById: ReadAllByIdAdmin,
    ReadAllSignalsAndCustomerSignal,
};
