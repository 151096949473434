// Standard library imports
import React from 'react';
// External library imports
import { Form, Formik } from 'formik';
// Internal module imports
import Typography from '../../../../../components/Typography/Typography';
import NewJSONEditor from '../../../../../components/JSONEditor/NewJSONEditor';
import classes from '../index.module.css';

const Info = ({ data, setInfoModal }) => {
    return (
        <div style={{ width: '50vw' }}>
            <div>
                <Typography content={'Response'}></Typography>
                <div style={{ marginTop: '0.5vw' }}>
                    <Formik initialValues={data}>
                        {() => {
                            return (
                                <Form>
                                    <NewJSONEditor name="response" defaultValue={data} height="15rem" />
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
            <div className={classes.ButtonRight}>
                <div className="btn-secondary" onClick={() => setInfoModal({ status: false, data: {} })}>
                    Close
                </div>
                <div></div>
            </div>
        </div>
    );
};

export default Info;
