import classes from './index.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { VersionHistoryService } from '../../../../services/VersionHistoryService';
import { LoaderContext } from '../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import { toast } from 'react-toastify';
import AddVersionForm from './AddVersionForm';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import addNoteIcon from '../../../../assets/addNote.svg';
import VersionDetails from './versionDetails';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { UilPen, UilExternalLinkAlt, UilTrash } from '@iconscout/react-unicons';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

const VersionHistory = ({ queryParamsData, updateQueryParams }) => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [open, setOpen] = useState(false);
    const [versions, setVersions] = useState([]);
    const [editData, setEditData] = useState({});
    const [selectedVersion, setSelectedVersion] = useState({
        status: false,
        version: {},
    });
    const [deleteReleaseModal, setDeleteReleaseModal] = useState({
        status: false,
        id: '',
    });
    const [sendNotificationModal, setSendNotificationModal] = useState({
        status: false,
        id: '',
    });
    const [selectedApplication, setSelectedApplication] = useState(
        queryParamsData.application
            ? JSON.parse(queryParamsData.application)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const applicationOptions = [
        { label: 'All', value: 'all' },
        { label: 'Web', value: 'web' },
        { label: 'IOT', value: 'iot' },
    ];

    useEffect(() => {
        fetchNotes();
    }, [selectedApplication.value]);

    const fetchNotes = () => {
        VersionHistoryService.ReadAll(
            selectedApplication.value,
            () => startLoader('getVersions'),
            handleSuccess,
            handleError,
            () => stopLoader('getVersions')
        );
    };
    const handleSuccess = ({ data }) => {
        setVersions(data.data);
    };

    const handleDelete = (id) => {
        VersionHistoryService.Delete(
            id,
            () => startLoader('deleteVersion'),
            handleDeleteSuccess,
            handleError,
            () => stopLoader('deleteVersion')
        );
    };

    const handleDeleteSuccess = () => {
        fetchNotes();
        toast.success('Release deleted successfully!');
    };

    const notifyCustomers = (id) => {
        id && VersionHistoryService.Notify(id, startLoader, handleNotifySuccess, handleError, stopLoader);
    };

    const handleNotifySuccess = () => {
        toast.success('Customers notified successfully!');
        setSendNotificationModal({ status: false, id: '' });
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const closeVersionDetails = () => {
        setSelectedVersion({ status: false, version: {} });
    };

    const getSubList = (text) => {
        let splitText = text?.split('\n').filter((e) => !!e.replace(/\s/g, ''));
        if (splitText.length > 3) {
            splitText = splitText.slice(0, 3);
            splitText[2] += '...';
        }
        return splitText.map((e) => <li>{e}</li>);
    };

    return (
        <>
            <DeleteModal
                deletefunction={notifyCustomers}
                deviceId={sendNotificationModal.id}
                opendeleteModal={sendNotificationModal.status}
                setOpenDeleteModal={(status) => setSendNotificationModal({ id: '', status })}
                title="Wanna Notify Customers?"
            />
            <DeleteModal
                deletefunction={handleDelete}
                deviceId={deleteReleaseModal.id}
                opendeleteModal={deleteReleaseModal.status}
                setOpenDeleteModal={(status) => setDeleteReleaseModal({ id: '', status })}
            />
            <ModalComponent isOpen={open} setOpen={setOpen}>
                <AddVersionForm
                    fetchNotes={fetchNotes}
                    setEditData={setEditData}
                    setOpen={setOpen}
                    data={editData}
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    setSendNotificationModal={setSendNotificationModal}
                />
            </ModalComponent>
            <ModalComponent isOpen={selectedVersion.status} setOpen={closeVersionDetails} style={{ maxWidth: '50vw' }}>
                <VersionDetails setOpen={closeVersionDetails} version={selectedVersion.version} />
            </ModalComponent>
            <div className={classes.Filter}>
                <div>
                    <div className={classes.FieldControl} style={{ maxWidth: '15vw' }}>
                        <label>Application</label>
                        <DropdownComponent
                            name="application"
                            options={applicationOptions}
                            onChange={(e) => {
                                setSelectedApplication(e);
                                updateQueryParams('application', JSON.stringify(e));
                            }}
                            defaultValue={selectedApplication}
                        />
                    </div>
                </div>
            </div>
            <div className={classes.CardsContainer}>
                <div
                    className={classes.Card2}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <div className={classes.Title}> {'Add Version'}</div>
                    <div>
                        <img src={addNoteIcon} alt="addNoteIcon" />
                    </div>
                </div>
                {versions.map((version, key) => (
                    <div className={classes.Card} key={key} onClick={() => setSelectedVersion({ status: true, version })}>
                        <div>
                            <div className={classes.TitleWrapper}>
                                <div className={classes.Title}> {version.title}</div>
                                <div className={classes.ActionWrapper}>
                                    {!!version.releaseLink && (
                                        <div>
                                            <CustomTooltip content={'Details'}>
                                                <UilExternalLinkAlt
                                                    size={'1.2vw'}
                                                    style={{ color: 'var(--color-primary)' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(version.releaseLink, '_blank');
                                                    }}
                                                />
                                            </CustomTooltip>
                                        </div>
                                    )}
                                    <div>
                                        <CustomTooltip content={'Edit'}>
                                            <UilPen
                                                size={'1.2vw'}
                                                style={{ color: 'var(--color-primary)' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEditData(version);
                                                    setOpen(true);
                                                }}
                                            />
                                        </CustomTooltip>
                                    </div>
                                    <div>
                                        <CustomTooltip content={'Delete'}>
                                            <UilTrash
                                                size={'1.2vw'}
                                                style={{ color: 'var(--color-primary)' }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setDeleteReleaseModal({
                                                        status: true,
                                                        id: version._id,
                                                    });
                                                }}
                                            />
                                        </CustomTooltip>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.SubTitleWrapper}>
                                <div className={classes.SubTitle}>
                                    {' '}
                                    {version?.application} - {version?.type}
                                </div>
                                <div className={classes.SubTitle}>{version.released ? momentTimeFormater(version.released).format('YYYY-MM-DD') : '--'}</div>
                            </div>
                        </div>
                        <div className={classes.Content}>
                            <ul>{version.description?.length > 200 ? getSubList(version.description?.substring(0, 200) + '...') : getSubList(version.description)}</ul>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default VersionHistory;
