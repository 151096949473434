// Standard module imports
import React, { useState, useEffect } from 'react';

// Internal module imports
import Table from '../../../../components/Table/Table.js';

// Css imports
import classes from './ActivationProfiler.module.css';
import Typography from '../../../../components/Typography/Typography.js';
import IntMetaLogModal from './ModalComponent/IntMetaLogsModal.js';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent.js';
import { momentTimeFormater } from '../../../../utils/timeHelper.js';
import moment from 'moment';

const IntMeta = ({ profile, intMeta }) => {
    const [tableData, setTableData] = useState([]);
    const [logModal, setLogModal] = useState({
        status: false,
        data: [],
    });

    useEffect(() => {
        handleEventsSuccess(intMeta);
    }, [intMeta]);

    const handleEventsSuccess = (datas) => {
        const processEvents = [];

        for (let data in datas) {
            const log = datas[data];
            processEvents.push({
                activationId: data,
                startTime: log.startTime ? momentTimeFormater(log.startTime).format('YYYY-MM-DD HH:mm:ss') : '--',
                endTime: log.endTime ? momentTimeFormater(log.endTime).format('YYYY-MM-DD HH:mm:ss') : '--',
                duration: log.duration ? log.duration : '--',
                action: (
                    <div className={classes.LogContainer} onClick={() => setLogModal({ status: true, data: log.logs })}>
                        logs
                    </div>
                ),
            });
        }
        processEvents.sort((a, b) => moment(b.startTime).valueOf() - moment(a.startTime).valueOf());
        setTableData(processEvents);
    };

    return (
        <div className={classes.Events} style={{ borderRadius: '0.7vw', flexGrow: 1 }}>
            <ModalComponent isOpen={logModal.status} setOpen={() => setLogModal({ status: false, data: [] })}>
                <IntMetaLogModal setOpen={() => setLogModal({ status: false, data: [] })} logData={logModal.data} />
            </ModalComponent>
            <div>
                <div className={classes.TabelHeading}>
                    <Typography size={14} content={`INT Meta (${profile?.name})`} />
                </div>
                <Table
                    head={['ACTIVATION ID', 'START TIME (CET)', 'END TIME (CET)	', 'DURATION(SECONDS)	', 'LOGS']}
                    keys={['activationId', 'startTime', 'endTime', 'duration', 'action']}
                    data={tableData}
                />
            </div>
        </div>
    );
};

export default IntMeta;
