// Standard library imports
import React, { useState, useEffect } from 'react';

// External library import
import { UilImport } from '@iconscout/react-unicons';
import moment from 'moment';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import { InputWithOutState } from '../../../components/Inputs/Input';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import Table from '../../../components/Table/Table';
import { EdgeService } from '../../../services/EdgeService';
import { momentTimeFormater } from '../../../utils/timeHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';

// CSS Imports
import classes from '../EdgeError/EdgeError.module.css';
import bidClasses from '../../../styles/Bids.module.css';

const UploadLogFiles = ({ deviceId, setOpen }) => {
    const curCetDateTime = momentTimeFormater();
    const [startLoader, stopLoader] = useLoader();
    const [limit] = useState(15);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [tableData, setTableData] = useState([]);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState(new Date());
    const [endTime, setEndTime] = useState(curCetDateTime.format('HH:mm'));
    const [startTime, setStartTime] = useState(curCetDateTime.subtract(15, 'minutes').format('HH:mm'));

    useEffect(() => {
        getUploadedEdgeLogFiles();
    }, [deviceId, page]);

    const getUploadedEdgeLogFiles = () => {
        if (deviceId) {
            const params = {
                skip,
                limit,
                deviceId,
                date: moment(date).format('YYYY-MM-DD'),
                startTime,
                endTime,
                search,
            };

            EdgeService.GetUploadedFiles(params, startLoader, handleUploadedFilesGetSuccess, handleError, stopLoader);
        }
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleGetSignedUrl = (key) => {
        EdgeService.GetUploadedFileSignedURL({ key }, startLoader, handleFileSignedURLSuccess, handleError, stopLoader);
    };

    const handleFileSignedURLSuccess = ({ data }) => {
        if (data.data?.signedUrl) {
            window.open(data.data.signedUrl, '_blank');
        }
    };

    const handleUploadedFilesGetSuccess = ({ data }) => {
        const files = data.data?.[0];
        const processedData = files?.data?.map((item) => {
            return {
                name: item.fileName.length > 40 ? <CustomTooltip content={item.fileName}>{item.fileName.substring(0, 40) + '...'}</CustomTooltip> : item.fileName,
                createdAt: item.createdAt ? momentTimeFormater(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                updatedAt: item.updatedAt ? momentTimeFormater(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : '--',
                action: (
                    <div>
                        <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => handleGetSignedUrl(item.key)} />
                    </div>
                ),
            };
        });
        setTableData(processedData);
        setTotalCount(files?.count ? Number(files.count) : 0);
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    return (
        <div className={bidClasses.Bids}>
            <div className={classes.Header}>
                <div className={classes.ErrorDropdownContainer}>
                    <Typography content={'Uploaded Files'} />
                    <div className={bidClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount || 0} />
                        </span>
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', gap: '0.521vw', alignItems: 'center' }}>
                        <DatePicker date={date} setDate={setDate} showArrow={true} />
                        <CustomTimePicker startTime={startTime} setStartTime={setStartTime} type="start" />
                        <CustomTimePicker endTime={endTime} setEndTime={setEndTime} type="end" />
                        <InputWithOutState label="File Name Search" value={search} onChange={(e) => setSearch(e.target.value)} style={{ marginTop: '0px' }} />
                        <div>
                            <button className="btn-primary" style={{ marginTop: '0px' }} onClick={getUploadedEdgeLogFiles}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['FILE NAME', 'CREATED AT (CET)', 'UPDATED AT (CET)', 'ACTION']}
                    keys={['name', 'createdAt', 'updatedAt', 'action']}
                    data={tableData}
                    page={page}
                    Pagination={true}
                    limit={limit}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};

export default UploadLogFiles;
