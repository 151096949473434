import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { InputWithOutState } from '../../../components/Inputs/Input';
import Typography from '../../../components/Typography/Typography';
import EdgeClasses from '../Edge.module.css';
import LoginIcon from '../../../assets/Login.svg';
import { EdgeGatewayService } from '../../../services/EdgeGatewayService';

const CommandsGateway = ({ device = {}, startLoader, stopLoader, setOpen }) => {
    const avaialbleCommads = [
        { label: 'Start App', value: 'start-app' },
        { label: 'Stop App', value: 'stop-app' },
        { label: 'Restart App', value: 'restart-app' },
    ];
    const [reply, setReply] = useState('');
    const [customerCommand, setCustomCommand] = useState('');

    console.log("log CommandsGateway", device);


    const handleSendCommad = (command) => {
        setReply((prev) => prev + (prev ? '\n> ' : '> ') + command);
        EdgeGatewayService.SendCommand({ command, deviceId: device.gatewayId }, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        if (data.data) {
            const status = typeof data.data.status === 'object' ? JSON.stringify(data.data.status) : data.data.status;
            setReply((prev) => prev + '\n' + status);
        }
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div style={{ minWidth: '70vw', minHeight: '80vh' }}>
            <Typography
                content={
                    <CustomTooltip content={device.edgeGatewayAlias}>
                        {`Send Commands (${device.edgeGatewayAlias?.length > 20 ? `${device.edgeGatewayAlias.substring(0, 20)}...` : device.edgeGatewayAlias})`}
                    </CustomTooltip>
                }
            />
            <div className={EdgeClasses.FlexContainer}>
                <div className={EdgeClasses.FirstBlock}>
                    <div className={EdgeClasses.ReplyWrapper}>
                        <textarea className={EdgeClasses.ReplyArea} disabled value={reply}></textarea>
                    </div>
                </div>
                <div className={EdgeClasses.SecondBlock}>
                    <div className={EdgeClasses.ButtonFlexRow}>
                        {avaialbleCommads.map((command) => (
                            <div>
                                <button className={EdgeClasses.RowFlexBtn + ' btn-primary'} onClick={() => handleSendCommad(command.value)}>
                                    {command.label}
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className={EdgeClasses.CustomWrapper}>
                        <div className={EdgeClasses.InputContainer}>
                            <InputWithOutState label="custom commands" value={customerCommand} onChange={(e) => setCustomCommand(e.target.value)} />
                        </div>
                        <button className={EdgeClasses.SendCustomBtn + ' btn-primary'} onClick={() => handleSendCommad(customerCommand)}>
                            <img src={LoginIcon} alt="send" className={EdgeClasses.SendIcon} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CommandsGateway;
