// Standard library imports
import React, { useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
// Internal library imports
import classes from '../../../../styles/CreateDevice.module.css';
import Typography from '../../../../components/Typography/Typography';
import { Input, InputFile, Password, ToggleButton } from '../../../../components/Inputs/Input';
import { RootService } from '../../../../services/RootSevice';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import { createRoot, updateRoot } from '../../../../validations/Root/rootValidator';
import PhoneNumberComponent from '../../../../components/Inputs/PhoneNumberComponent';
import Dropdown from '../../../../components/Inputs/Dropdown';

const getInitialValues = (rootData) => {
    if (rootData?._id) {
        delete rootData?.mongoDetails?.password;
        delete rootData?.teamviewerDetails?.password;
        return {
            name: rootData.name,
            email: rootData.email,
            countryCode: rootData.countryCode,
            contact: rootData.contact,
            accessBlocked: rootData.accessBlocked,
            mongoDetails: rootData?.mongoDetails,
            awsDetails: rootData?.awsIamUser,
            teamviewerDetails: rootData?.teamviewerDetails,
            userRole: rootData.userRole,
            mqttDetails: {
                enable: rootData.mqttUserName || rootData.mqttPassword ? true : false,
                mqttUserName: rootData.mqttUserName,
                mqttPassword: rootData.mqttPassword,
            },
        };
    } else {
        return {
            name: '',
            email: '',
            password: '',
            countryCode: '',
            contact: '',
            accessBlocked: false,
            awsDetails: {
                awsAliasName: '',
                enable: false,
            },
            mongoDetails: {
                enable: false,
                password: '',
                permission: {
                    read: false,
                    write: false,
                    dbAdmin: false,
                },
            },
            teamviewerDetails: {
                enable: false,
                password: '',
            },
            userRole: '',
            mqttDetails: {
                mqttUserName: '',
                mqttPassword: '',
            },
        };
    }
};

const CreateRoot = ({ rootData }) => {
    const [startLoader, stopLoader] = useLoader();
    const initialValues = getInitialValues(rootData);
    const rootRole = [
        { value: 'root', label: 'Root Super Admin' },
        { value: 'root-admin', label: 'Root Admin' },
        { value: 'root-developer', label: 'Root Developer' },
    ];

    const handleSubmit = (values, resetForm) => {
        if (rootData?._id) {
            RootService.Update(rootData._id, values, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            RootService.Create(values, startLoader, ({ data }) => handleCreateSuccess(data, resetForm), handleError, stopLoader);
        }
    };

    const handleUpdateSuccess = () => {
        toast.success('Root updated!');
    };

    const handleCreateSuccess = (data, resetForm) => {
        resetForm();
        toast.success('Root created!');
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            {' '}
            <div className={classes.FormContainer}>
                <Typography content={`${rootData?._id ? 'Edit' : 'Create'} Root`} />
                <div className={classes.FormContent}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                        validationSchema={rootData?._id ? updateRoot : createRoot}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        <Typography content={'User Details'} size={14} />

                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="name">
                                                    Name <span className="required">*</span>
                                                </label>
                                                <Input name="name" id="name" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="Email">
                                                    Email <span className="required">*</span>
                                                </label>
                                                <Input name="email" id="Email" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="password">
                                                    Password <span className="required">*</span>
                                                </label>
                                                <Input name="password" id="password" type="password" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="contact">
                                                    Contact <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <PhoneNumberComponent
                                                        inputName="contact"
                                                        countryCodeName="countryCode"
                                                        inputValues={values}
                                                        defaultCountryCode={{ value: values.countryCode }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="userRole">
                                                    User Role
                                                    <span className="required">*</span>
                                                </label>
                                                {console.log('log role', rootRole, rootData)}
                                                <Dropdown
                                                    name="userRole"
                                                    id="userRole"
                                                    options={rootRole}
                                                    defaultValue={rootRole.filter((role) => role.value === rootData.userRole)[0]}
                                                />
                                            </div>
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl}>
                                                    <ToggleButton values={values} label={'Block Access'} name={`accessBlocked`} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <ToggleButton values={values} label={'MongoDb Access Management'} name={`mongoDetails.enable`} />
                                            </div>
                                        </div>
                                        {values?.mongoDetails?.enable && (
                                            <div className={classes.InputContainer}>
                                                <div className={classes.FieldControl}>
                                                    <label for="mongopassword">
                                                        Mongo DB Password <span className="required">*</span>
                                                    </label>
                                                    <Password name="mongoDetails.password" id="mongoDbPassword" style={{ marginTop: '0' }} autoComplete="new-password" />
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="name">
                                                        Mongo DB Permission <span className="required">*</span>
                                                    </label>
                                                    <div style={{ display: 'flex', gap: '2vw' }}>
                                                        <div style={{ marginTop: '0' }}>
                                                            <ToggleButton values={values} label={'Read'} name={`mongoDetails.permission.read`} style={{ marginTop: '0' }} />
                                                        </div>
                                                        <div style={{ marginTop: '0' }}>
                                                            <ToggleButton values={values} label={'Read/Write'} name={`mongoDetails.permission.write]`} style={{ marginTop: '0' }} />
                                                        </div>
                                                        <div style={{ marginTop: '0' }}>
                                                            <ToggleButton values={values} label={'DB Admin'} name={`mongoDetails.permission.dbAdmin]`} style={{ marginTop: '0' }} />
                                                        </div>
                                                    </div>
                                                    {errors?.mongoDetails?.permission && (
                                                        <div className="error-msg">
                                                            <span>{errors?.mongoDetails?.permission}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl}>
                                            <ToggleButton values={values} label={'AWS IAM User'} name={`awsDetails.enable`} />
                                        </div>
                                    </div>

                                    {values?.awsDetails?.enable && (
                                        <>
                                            <div>
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl}>
                                                        <label for="awsAliasName">
                                                            Alias Name <span className="required">*</span>
                                                        </label>
                                                        <Input name="awsDetails.awsAliasName" id="awsAliasName" disabled={rootData?.awsDetails?.awsAliasName ? true : false} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl}>
                                            <ToggleButton values={values} label={'Teamviewer'} name={`teamviewerDetails.enable`} />
                                        </div>
                                    </div>

                                    {values?.teamviewerDetails?.enable && (
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="teamviewerPassword">
                                                    Teamviewer Password <span className="required">*</span>
                                                </label>
                                                <Password name="teamviewerDetails.password" id="teamviewerDetails" style={{ marginTop: '0' }} autoComplete="new-password" />
                                            </div>
                                        </div>
                                    )}

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl}>
                                            <ToggleButton values={values} label={'MQTT'} name={`mqttDetails.enable`} />
                                        </div>
                                    </div>

                                    {values?.mqttDetails?.enable && (
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl}>
                                                <label for="name">
                                                    MQTT User Name
                                                    <span className="required">*</span>
                                                </label>
                                                <Input name="mqttDetails.mqttUserName" id="mqttDetails" />
                                            </div>
                                            <div className={classes.FieldControl}>
                                                <label for="password">
                                                    MQTT Password
                                                    <span className="required">*</span>
                                                </label>
                                                <Input name="mqttDetails.mqttPassword" id="mqttDetails" type="password" />
                                            </div>
                                        </div>
                                    )}

                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default CreateRoot;
