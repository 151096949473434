// Standard library imports
import React from 'react';

// Internal library imports
import ModalComponent from '../ModalComponent/ModalComponent';

// Css imports
import classes from './DeleteModal.module.css';

function DeleteModal({ opendeleteModal, setOpenDeleteModal, deletefunction, deviceId, text = null, title = 'Are You Sure ?', yesButtonText = 'Yes', noButtonText = 'No' }) {
    return (
        <ModalComponent isOpen={opendeleteModal} setOpen={setOpenDeleteModal}>
            <div className={classes.DeleteModal}>
                <div className={classes.Heading}>
                    {title} <br />
                </div>
                {text && (
                    <div className={classes.note}>
                        <div>Warning!</div>
                        <div>{text}</div>
                    </div>
                )}
                <div
                    className={classes.ButtonContainer}
                    onClick={() => {
                        setOpenDeleteModal(false);
                    }}
                >
                    <div className={`${classes.yes} btn-secondary`}>{noButtonText}</div>
                    <div
                        className={`${classes.yes} btn-primary`}
                        onClick={() => {
                            deletefunction(deviceId);
                        }}
                    >
                        {yesButtonText}
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
}

export default DeleteModal;
