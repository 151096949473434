// Standard library imports
import React, { useState, useEffect } from 'react';
// Css imports
import classes from './ThreeStateToggle.module.css';

const ThreeStateToggle = ({ labels = {}, onChange, defaultValue, style = {} }) => {
    const labelLength = Object.keys(labels).length;
    const sliderWidth = `${100 / labelLength - 3}%`;

    const rightSideStyle = {
        left: labelLength === 2 ? '51%' : '69%',
        keyFrame: labelLength === 2 ? '7rem' : '8.7rem',
    };

    const [toggleState, setToggleState] = useState({
        position: defaultValue || 'center',
        animation: null,
    });

    useEffect(() => {
        setToggleState((prevState) => ({
            ...prevState,
            position: defaultValue,
        }));
    }, [defaultValue]);

    const getSwitchAnimation = (value) => {
        let animation = null;
        if (value === 'center' && toggleState.position === 'left') {
            animation = 'left-to-center';
        } else if (value === 'right' && toggleState.position === 'center') {
            animation = 'center-to-right';
        } else if (value === 'center' && toggleState.position === 'right') {
            animation = 'right-to-center';
        } else if (value === 'left' && toggleState.position === 'center') {
            animation = 'center-to-left';
        } else if (value === 'right' && toggleState.position === 'left') {
            animation = 'left-to-right';
        } else if (value === 'left' && toggleState.position === 'right') {
            animation = 'right-to-left';
        }

        setToggleState({
            position: value,
            animation: animation,
        });
        onChange(value);
    };
    return (
        <div className={classes.ThreeStateContainer}>
            <div style={style}>
                <div className={classes.MainContainer}>
                    <div
                        className={`${classes.Switch} ${classes[toggleState.animation]} ${classes[toggleState.position]}`}
                        style={{
                            '--slider-width': sliderWidth,
                            '--right-side-left': rightSideStyle.left,
                            '--right-side-key': rightSideStyle.keyFrame,
                        }}
                    ></div>

                    {Object.keys(labels).map((key, index) => (
                        <input key={index} onChange={() => getSwitchAnimation(labels[key].value)} name="map-switch" id={labels[key].value} type="radio" value={labels[key].value} />
                    ))}

                    <div className={classes.Labels}>
                        {Object.keys(labels).map((key, index) => (
                            <label key={index} className={`${toggleState.position === labels[key].value && classes.BlackFont}`} htmlFor={labels[key].value}>
                                {labels[key].title}
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThreeStateToggle;
