import React, { useEffect, useState, useContext } from 'react';
import classes from '../../../styles/Bids.module.css';
import Typography from '../../../components/Typography/Typography';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import Table from '../../../components/Table/Table';
import { regulationsServiceAdmin } from '../../../services/regulationsService';
import { useLoader } from '../../../hooks';
import { toast } from 'react-toastify';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import TreeChart from '../../../components/TreeChart/TreeChart';
import { AuthContext } from '../../../context/AuthContext';
import { momentTimeFormater } from '../../../utils/dateHelper';
const ApprovalOverview = () => {
    const [data, setData] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const { state } = useContext(AuthContext);

    const [chartData, setChartData] = useState({});

    useEffect(() => {
        getData();
    }, []);

    const handleDownloadExcel = () => {
        const formattedData = [];
        const downloadData = [];
        chartData?.children.map((market) => {
            market?.children.map((customer) => {
                let temp = {};
                temp.market = market.name;
                temp.customer = customer.name;
                let tempLicense = [];
                let tempInstance = [];
                customer.children.map((license) => {
                    tempLicense.push(license.name);
                    license.children.map((instance) => {
                        tempInstance.push(instance.name);
                    });
                });
                temp.license = tempLicense;
                temp.instance = tempInstance;
                formattedData.push(temp);
            });
        });
        formattedData.map((item, index) => {
            downloadData.push({
                sno: index + 1,
                ...item,
                license: item.license.toString(),
                instance: item.instance.toString(),
            });
        });

        downloadData.length && DownloadAsExcel(downloadData, `Market Overview`, ['S.no', 'Market', 'Customer', 'License', 'Instance']);
    };

    const getData = () => {
        regulationsServiceAdmin.GetMarketOverviewDetails(startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const getChartDataCustomer = (data) => {
        let temp = [];
        data.map((item) => {
            item?.customerData?.[0] &&
                temp.push({
                    name: item?.customerData,
                    children: getChartLicese(item.licenseData, item.instance),
                });
        });
        return temp;
    };

    const getChartLicese = (license, instance) => {
        let temp = [];
        license.map((item) => {
            temp.push({
                name: item.name,
                children: getChartInstance(item._id, instance),
            });
        });
        return temp;
    };

    const getChartInstance = (licenseId, data) => {
        let temp = [];
        if (data.length > 0) {
            data.map((item) => {
                let validity = '';
                if (item.licenseId == licenseId) {
                    for (let key in item?.approvalValidity) {
                        if (item.approvalValidity[key]?.endDate) {
                            validity = `Approval expires on ${momentTimeFormater(item.approvalValidity[key]?.endDate).format('YYYY-MM-DD')}`;
                        }
                    }
                    temp.push({
                        name: `${item.name} (${item?.properties?.capacity || '--'} kW) ${validity}`,
                        children: [],
                    });
                }
            });
        }

        return temp;
    };

    const handleGetDataSuccess = ({ data }) => {
        setData(data?.data);
        const tempData = {
            name: state?.user.name,
            children: [],
        };

        data?.data?.map((item) => {
            tempData.children.push({
                name: item.market,
                children: getChartDataCustomer(item.data),
            });
        });
        setChartData(tempData);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <div className={classes.Bids}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Approval Overview" />
                    </div>
                    <div className={classes.ButtonsContainer}>
                        <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} />
                    </div>
                </div>
                <div>
                    <TreeChart chartData={chartData} orientation="horizontal" />
                </div>
            </div>
        </div>
    );
};

export default ApprovalOverview;
