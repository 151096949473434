import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const addVersionValidator = yup.object().shape({
    title: yup.string().required(ERRORS.required),
    description: yup.string().required(ERRORS.required),
    released: yup.string().required(ERRORS.required),
    enable: yup.boolean().required(ERRORS.required),
    application: yup.string().required(ERRORS.required),
    type: yup.string().required(ERRORS.required),
    releaseLink: yup.string().url(ERRORS.url).required(ERRORS.required),
});
