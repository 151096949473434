import React, { useEffect, useState } from 'react';
import { useLoader } from '../../../hooks';
import Table from '../../../components/Table/Table';
import { EdgeService } from '../../../services/EdgeService';
import { toast } from 'react-toastify';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import Typography from '../../../components/Typography/Typography';
import classes from './EdgeError.module.css';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../components/Inputs/TimePicker/TimePicker';
import moment from 'moment';
import { UilInfoCircle } from '@iconscout/react-unicons';
import { momentTimeFormater } from '../../../utils/timeHelper';
import { datetimeOnly2 } from '../../../utils/dateHelper';
import bidClasses from '../../../styles/Bids.module.css';
import { DropdownComponent } from '../../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import NewJSONEditor from '../../../components/JSONEditor/NewJSONEditor';
import EdgeClasses from './EdgeError.module.css';

const EdgeError = ({ edgeDeviceId, selectedEdge }) => {
    const curCetDateTime = momentTimeFormater();
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [infoModal, setInfoModal] = useState({ status: false, data: {} });
    const [date, setDate] = useState(new Date());
    const [endTime, setEndTime] = useState(curCetDateTime.format('HH:mm'));
    const [startTime, setStartTime] = useState(curCetDateTime.subtract(15, 'minutes').format('HH:mm'));
    const [selectedType, setSelectedType] = useState({
        label: 'Edge Sent',
        value: 'edge-sent',
    });
    const [selectedEdgeSentType, setSelectedEdgeSentType] = useState({
        label: 'All',
        value: 'All',
    });
    const [selectedStatusCode, setSelectedStatusCode] = useState({
        label: '>= 100',
        value: 100,
    });
    const [tableHeaders, setTableHeaders] = useState({
        header: [],
        key: [],
    });
    const LOG_TYPE_OPTIONS = [
        {
            label: 'Edge Sent',
            value: 'edge-sent',
        },
        {
            label: 'API Logs',
            value: 'api-logs',
        },
    ];

    const EDGE_SENT_OPTIONS = [
        {
            label: 'All',
            value: 'All',
        },
        {
            label: 'Critical',
            value: 'critical',
        },
        {
            label: 'Boot',
            value: 'boot',
        },
        {
            label: 'Errors',
            value: 'error',
        },
        {
            label: 'Notifications',
            value: 'notification',
        },
        {
            label: 'Commands',
            value: 'command',
        },
        {
            label: 'System Alert',
            value: 'system-alert',
        }
    ]

    if (selectedEdge.market === 'aFRR') {
        LOG_TYPE_OPTIONS.push(
            {
                label: 'AFRR Logs',
                value: 'aFRRLogs',
            },
            {
                label: 'AFRR Power Logs',
                value: 'aFRRPowerLogs',
            }
        );
    }

    const STATUS_TYPE_OPTIONS = [
        {
            label: '>= 100',
            value: 100,
        },
        {
            label: '>= 200',
            value: 200,
        },
        {
            label: '>= 300',
            value: 300,
        },
        {
            label: '>= 400',
            value: 400,
        },
        {
            label: '>= 500',
            value: 500,
        },
    ];

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    useEffect(() => {
        if (selectedType?.value) {
            getEdgeError();
            if (selectedType.value === 'aFRRPowerLogs') {
                setTableHeaders({
                    header: ['Timestamp (CET)', 'Power', 'Market', 'Area', 'Activation Direction', 'Mode'],
                    key: ['timestamp', 'power', 'market', 'area', 'activationDirection', 'mode'],
                });
            } else if (selectedType.value === 'aFRRLogs') {
                setTableHeaders({
                    header: ['Source', 'Type', 'Timestamp (CET)', 'Set Point', 'Status', 'Activation Direction', 'Action', 'Mode', 'Response'],
                    key: ['source', 'type', 'timestamp', 'setPoint', 'status', 'activationDirection', 'action', 'mode', 'response'],
                });
            } else {
                setTableHeaders({
                    header: ['Path', 'Script', 'Message', 'Meta', 'Timestamp (CET)', 'Action'],
                    key: ['path', 'script', 'message', 'meta', 'timestampCET', 'action'],
                });
            }
        }
    }, [edgeDeviceId, skip, limit, selectedType?.value, selectedEdgeSentType?.value]);

    useEffect(() => {
        if (selectedStatusCode?.value) {
            getEdgeError();
        }
    }, [selectedStatusCode?.value]);

    const getEdgeError = () => {
        const params = {
            limit,
            skip,
            deviceId: edgeDeviceId,
            date: moment(date).format('YYYY-MM-DD'),
            startTime,
            endTime,
            type: selectedType.value,
        };
        if (selectedType.value === 'api-logs' && selectedEdge?.customerId) params.customerId = selectedEdge.customerId;
        if (selectedType.value === 'api-logs' && selectedEdge?._id) params.edgeId = selectedEdge._id;
        if (selectedType.value === 'api-logs' && selectedStatusCode?.value) params.statusCode = selectedStatusCode.value;
        if (selectedType.value === 'aFRRLogs' && selectedEdge?._id) params.area = selectedEdge?.signalData[0].area;
        if (selectedType.value === 'edge-sent') params.type = selectedEdgeSentType?.value;
        EdgeService.GetEdgeError(params, startLoader, handleGetEdgeErrorSuccess, handleError, stopLoader);
    };

    const truncateString = (string) => {
        return string.length > 50 ? `${string.substring(0, 20)}…` : string;
    };

    const handleGetEdgeErrorSuccess = ({ data }) => {
        const processedData = data?.data[0]?.data?.map((item) => {
            let temp = {};
            let tempModal = {};
            if (selectedType.value === 'aFRRPowerLogs') {
                temp = {
                    power: item?.value?.power || item?.value?.power === 0 ? item?.value?.power : '--',
                    area: item?.value?.area || '--',
                    market: item?.value?.market || '--',
                    activationDirection: item?.value?.activationDirection || '--',
                    mode: item?.value?.mode || '--',
                    timestamp: item?.timestamp ? momentTimeFormater(item?.timestamp).format('YYYY-MM-DD HH:mm:ss') : '--',
                };
            } else if (selectedType.value === 'aFRRLogs') {
                temp = {
                    source: item.source || '--',
                    type: item.type || '--',
                    setPoint: item?.value?.setPoint || item?.value?.setPoint === 0 ? item?.value?.setPoint : '--',
                    status: item?.value?.status || '--',
                    activationDirection: item?.value?.activationDirection || '--',
                    action: item?.action || '--',
                    mode: item?.value?.mode,
                    response: item?.value?.response ? (
                        <div className={EdgeClasses.ResponseContainer}>
                            {item?.value?.response}
                            <CustomTooltip content={'Info'}>
                                <UilInfoCircle
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() =>
                                        setInfoModal({
                                            status: true,
                                            data: {
                                                response: item?.value,
                                            },
                                        })
                                    }
                                />
                            </CustomTooltip>
                        </div>
                    ) : (
                        '--'
                    ),
                    timestamp: item?.timestamp ? momentTimeFormater(item.timestamp).format('YYYY-MM-DD HH:mm:ss') : '--',
                };
            } else if (selectedType.value === 'api-logs') {
                const path = item.request?.url;
                temp = {
                    path: path.length > 35 ? <CustomTooltip content={path}>{path.substring(0, 35) + '...'}</CustomTooltip> : path || '--',
                    script: '--',
                    message: item.response?.status ? item.response?.status : '--',
                    meta: '--',
                    timestamp: item.timestamp,
                    timestampCET: datetimeOnly2(momentTimeFormater(item.timestamp)),
                };
                tempModal = {
                    request: item.request,
                    response: item.response,
                };
            } else {
                temp = {
                    path: '--',
                    script: item.script ? item.script : '--',
                    message: item.message ? truncateString(item.message) : '--',
                    meta: item.meta ? truncateString(JSON.stringify(item.meta)) : '--',
                    timestamp: item.timestamp,
                    timestampCET: datetimeOnly2(momentTimeFormater(item.timestamp)),
                };
                tempModal = {
                    script: item.script,
                    message: item.message ? item.message : '--',
                    meta: item.meta ? JSON.stringify(item.meta) : '--',
                    timestamp: item.timestamp,
                    timestampCET: datetimeOnly2(momentTimeFormater(item.timestamp)),
                };
            }
            if (!['aFRRLogs', 'aFRRPowerLogs'].includes(selectedType.value)) {
                temp.action = (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CustomTooltip content={'Info'}>
                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setInfoModal({ status: true, data: tempModal })} />
                        </CustomTooltip>
                    </div>
                );
            }
            return temp;
        });
        setData(processedData);
        setTotalCount(data?.data[0]?.count.length ? data?.data[0]?.count[0]?.count : 0);
    };
    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div className={bidClasses.Bids}>
            <div className={classes.Header}>
                <div className={EdgeClasses.ErrorDropdownContainer}>
                    <Typography content="Edge Logs" />
                    <div style={{ minWidth: '9vw' }}>
                        <DropdownComponent name="type" options={LOG_TYPE_OPTIONS} onChange={(e) => setSelectedType(e)} defaultValue={LOG_TYPE_OPTIONS[0]} />
                    </div>
                    {selectedType.value === 'edge-sent' && (
                        <div style={{ minWidth: '9vw' }}>
                            <DropdownComponent name="type" options={EDGE_SENT_OPTIONS} onChange={(e) => setSelectedEdgeSentType(e)} defaultValue={EDGE_SENT_OPTIONS[0]} />
                        </div>
                    )}
                    {selectedType.value === 'api-logs' && (
                        <div style={{ minWidth: '9vw' }}>
                            <DropdownComponent name="status" options={STATUS_TYPE_OPTIONS} onChange={(e) => setSelectedStatusCode(e)} defaultValue={STATUS_TYPE_OPTIONS[0]} />
                        </div>
                    )}
                </div>
                <div>
                    <div style={{ display: 'flex', gap: '0.521vw', alignItems: 'center' }}>
                        <DatePicker date={date} setDate={setDate} showArrow={true} />
                        <CustomTimePicker startTime={startTime} setStartTime={setStartTime} type="start" />
                        <CustomTimePicker endTime={endTime} setEndTime={setEndTime} type="end" />
                        <div>
                            <button className="btn-primary" style={{ marginTop: '0px' }} onClick={getEdgeError}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <ModalComponent isOpen={infoModal.status} setOpen={(status) => setInfoModal({ status, data: {} })}>
                <div style={{ width: '50vw' }}>
                    <div>
                        <Typography content={'Error Info'}></Typography>
                        {selectedType.value === 'api-logs' || selectedType.value === 'aFRRLogs' ? (
                            <div>
                                {selectedType.value === 'api-logs' && (
                                    <div>
                                        <Typography content={'Request Info'}></Typography>
                                        <Formik initialValues={{ ...infoModal.data?.request }}>
                                            {() => {
                                                return (
                                                    <Form>
                                                        <NewJSONEditor name="request" defaultValue={infoModal.data?.request} height="15rem" />
                                                    </Form>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                )}
                                <div style={{ marginTop: '0.5vw' }}>
                                    <Typography content={'Response Info'}></Typography>
                                    <Formik initialValues={{ ...infoModal.data?.response }}>
                                        {() => {
                                            return (
                                                <Form>
                                                    <NewJSONEditor name="response" defaultValue={infoModal.data?.response} height="15rem" />
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                                <div className={EdgeClasses.ButtonRight}>
                                    <div className="btn-secondary" onClick={() => setInfoModal({ status: false, data: {} })}>
                                        Close
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p className={classes.Text}>
                                    <span className={classes.Heading}>Script: </span>
                                    {infoModal.data.script}
                                </p>
                                <p className={classes.Text}>
                                    <span className={classes.Heading}>Message: </span>
                                    {infoModal.data.message}
                                </p>
                                <p className={classes.Text}>
                                    <span className={classes.Heading}>Meta: </span>
                                    {JSON.stringify(infoModal.data.meta)}
                                </p>
                                <p className={classes.Text}>
                                    <span className={classes.Heading}>Timestamp (CET): </span>
                                    {infoModal.data.timestampCET}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </ModalComponent>

            <div>
                <Table
                    head={tableHeaders.header || []}
                    keys={tableHeaders.key || []}
                    data={data}
                    page={page}
                    Pagination={true}
                    limit={limit}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};

export default EdgeError;
