// Standard Library Imports
import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

// Internal library imports
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { useLoader } from '../../../hooks';
import { UilTrash } from '@iconscout/react-unicons';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { EdgeGatewayService } from '../../../services/EdgeGatewayService';

// Css imports
import TableClasses from '../../../components/Table/Table.module.css';
import classes from '../../../styles/AllDevices.module.css';

function AlertsGatewayTable({ edgeGatewayId }) {
    const [startLoader, stopLoader] = useLoader();
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        alertId: '',
    });

    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        fetchAlerts();
    }, []);

    const handleAlertsSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            alertId: key._id,
            resource: key.resource,
            threshold: key.threshold,
            notificationType: key.notificationType.length ? key.notificationType.map((notification) => notification.label).join(', ') : '--',
            notificationValue: key.notificationType.length
                ? key.notificationType
                    .filter((notification) => (notification.value ? notification.value : ''))
                    .map((notification) => (notification.value ? notification.value : ''))
                    .join(', ')
                : '--',
            action: (
                <div className={TableClasses.ActionContainer}>
                    <div>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, alertId: key._id })} />
                    </div>
                </div>
            ),
        }));
        setAlerts(processedData);
    };

    const fetchAlerts = () => {
        EdgeGatewayService.GetAlerts(
            edgeGatewayId,
            () => startLoader('getSignals'),
            handleAlertsSuccess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const handleDelete = () => {
        EdgeGatewayService.DeleteAlert(deleteModal.alertId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Alert deleted successfully!');
            setDeleteModal({ status: false, alertId: '' });
            fetchAlerts();
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>

            <div className={classes.Header}>
                <div>
                    <Typography size="14" content={'Alerts'} />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={alerts.length} />
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ height: 'auto', width: 'auto', maxHeight: '70vh', overflowY: 'scroll' }}>
                <Table
                    head={['s.no', 'Alert Id', 'Resource', 'Threshold', 'Notification Type', 'Notification Value', 'Action']}
                    keys={['s.no', 'alertId', 'resource', 'threshold', 'notificationType', 'notificationValue', 'action']}
                    data={alerts}
                />
            </div>
        </div>
    );
}

export default AlertsGatewayTable;
