import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/areas',
    update: '/root/areas',
    delete: '/root/areas',
    readAllAdmin: '/admin/areas',
};

const createArea = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

// deleting an area accepting id as param
const deleteArea = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const ReadAllAdmin = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAllAdmin).then(callback).catch(error).finally(next);
};

export const areaService = {
    ReadAll,
    deleteArea,
    createArea,
};

export const areaServiceAdmin = {
    RealAll: ReadAllAdmin,
};
