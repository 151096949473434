import React, { useState } from 'react';
import TabsComponent from '../../../../components/Tabs/Tabs';
import Images from './Images/Images';

const ContentManager = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    let tabs = [
        {
            name: 'Images',
            component: (
                <>
                    <Images />
                </>
            ),
            tabId: 3,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent selectedIndex={selectedIndex} tabs={tabs} onChangeTab={setSelectedIndex} />
        </div>
    );
};

export default ContentManager;
