// Standard library imports
import React, { useState, useEffect, useRef } from 'react';

// External library imports
import { useField } from 'formik';

// Css imports
import classes from './FileUpload.module.css';

function MultipleFilesUpload({ label, onChange, ...props }) {
    const [file, setFile] = useState(null);
    const [field, meta] = useField(props);

    const drop = useRef(null);

    const onChangeHandler = (e) => {
        setFile(e.target.files);
        onChange(e);
    };

    // drag and drop
    useEffect(() => {
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
        return () => {
            drop.current && drop.current.removeEventListener('dragover', handleDragOver);
            drop.current && drop.current.removeEventListener('drop', handleDrop);
        };
    }, []);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFile(e.dataTransfer.files);
        const file = {
            target: {
                files: e.dataTransfer.files,
            },
        };
        onChange(file);
    };
    return (
        <div ref={drop}>
            <div className={classes.MainContainer}>
                <input type="file" name="uploadfile" {...field} {...props} placeholder={label} style={{ display: 'none' }} onChange={onChangeHandler} />
                <label className={classes.Text} for={props.id}>
                    Drag and Drop or <span className={classes.Browse}>Browse</span>
                </label>
            </div>
        </div>
    );
}

export default MultipleFilesUpload;
