// Standard library imports
import React, { useContext, useEffect, useState } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilPen, UilTrash } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { LoaderContext } from '../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Input } from '../../../../components/Inputs/Input';
import { addSignalsValidation } from '../../../../validations/SystemTools/addSignalsValidation';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { ToggleButton } from '../../../../components/Inputs/Input';
import { signalTypesServiceForRoot } from '../../../../services/signalTypesServiceForRoot';
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import { areaService } from '../../../../services/areaService';
import Dropdown from '../../../../components/Inputs/Dropdown';
import NewJSONEditor from '../../../../components/JSONEditor/NewJSONEditor';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';
import signalClasses from '../index.module.css';

const Signals = () => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const initialValues = {
        data: {
            name: '',
            type: '',
            area: '',
            queryString: {},
            dataSource: '',
            loggingType: '',
        },
    };
    const [addTypesModal, setAddTypesModal] = useState(false);
    const [signalTypes, setSignalTypes] = useState([]);
    const [areaTypes, setAreaTypes] = useState([]);
    const [signals, setSignals] = useState([]);
    const [dataSourceList, setDataSourceList] = useState([]);
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        signalId: '',
    });
    const [createEditSignal, setCreateEditSignal] = useState(initialValues);
    const [editSignalModal, setEditSignalModal] = useState({
        status: false,
        signalId: '',
    });
    const [showMenu, setShowMenu] = useState(false);
    const loggingTypeOptions = [
        { label: 'None', value: '' },
        { label: 'Seconds', value: 'seconds' },
        {
            label: 'Milliseconds',
            value: 'milliseconds',
        },
    ];

    useEffect(() => {
        fetchTypes();
        fetchAreas();
        fetchSignals();
        fetchDataSourceList();
    }, []);

    const fetchTypes = () => {
        signalTypesServiceForRoot.ReadAll(
            () => startLoader('getTypesSignals'),
            handleTypeSuccess,
            handleError,
            () => stopLoader('getTypesSignals')
        );
    };

    const fetchSignals = () => {
        signalsServiceForRoot.ReadAll(
            () => startLoader('getSignals'),
            handleSignalSuccess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const fetchAreas = () => {
        areaService.ReadAll(
            () => startLoader('getAreasSignals'),
            handleAreaSuccess,
            handleError,
            () => stopLoader('getAreasSignals')
        );
    };

    const fetchDataSourceList = () => {
        signalsServiceForRoot.readDataSourceList(
            () => startLoader('fetchDataSourceList'),
            handleDataSourceListSuccess,
            handleError,
            () => stopLoader('fetchDataSourceList')
        );
    };

    const handleSignalSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            title: key.name,
            area: key.area,
            type: key.type,
            queryString: JSON.stringify(key.queryString),
            dataSource: key.dataSource,
            action: (
                <div className={signalClasses.ActionContainer}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() => {
                                setCreateEditSignal({
                                    data: {
                                        name: key.name,
                                        area: key.area,
                                        type: key.type,
                                        queryString: key.queryString,
                                        dataSource: key.dataSource,
                                        monitoringToggle: key.monitoringToggle || false,
                                        loggingType: key.loggingType,
                                        constantMonitoring: key?.constantMonitoring || false,
                                    },
                                });
                                setEditSignalModal({
                                    status: true,
                                    signalId: key._id,
                                });
                            }}
                        />
                    </CustomTooltip>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, signalId: key._id })} />
                    </CustomTooltip>
                </div>
            ),
        }));
        setSignals(processedData);
    };

    const handleDataSourceListSuccess = ({ data }) => {
        setDataSourceList(
            data.data.map((item) => ({
                label: item,
                value: item,
            }))
        );
    };

    const handleAreaSuccess = ({ data }) => {
        setAreaTypes(
            data.data.map((item) => ({
                label: item.name,
                value: item.name,
            }))
        );
    };

    const handleTypeSuccess = ({ data }) => {
        setSignalTypes(
            data.data.map((item) => ({
                label: item.name,
                value: item.name,
            }))
        );
    };

    const handleSubmit = (values) => {
        let payload = values.dataSource.label ? { ...values, dataSource: values.dataSource.label, signalType: 'global' } : { ...values, signalType: 'global' };

        if (editSignalModal.status) {
            signalsServiceForRoot.updateType(
                editSignalModal.signalId,
                payload,
                () => startLoader('updatedType'),
                handleEditSuccess,
                handleError,
                () => stopLoader('updatedType')
            );
            return;
        }
        let params = {};
        signalsServiceForRoot.createType(
            payload,
            params,
            () => startLoader('createdType'),
            handleCreateSuccess,
            handleError,
            () => stopLoader('createdType')
        );
    };

    const handleEditSuccess = (res) => {
        fetchSignals();
        toast.success('Signal updated sucessfully');
        setEditSignalModal({ ...editSignalModal, status: false });
        setAddTypesModal(false);
    };
    const handleCreateSuccess = (res) => {
        fetchSignals();
        toast.success('Signal created sucessfully');
        setAddTypesModal(false);
    };

    const handleDelete = () => {
        if (deleteModal.signalId) {
            signalsServiceForRoot.deleteType(
                deleteModal.signalId,
                startLoader,
                () => {
                    fetchSignals();
                    toast.success('Signal deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={addTypesModal || editSignalModal.status} setOpen={setAddTypesModal || setEditSignalModal} style={{ overflow: 'initial' }}>
                <Typography content={addTypesModal ? 'Add Signals' : 'Edit Signals'} />
                <div className={classes.SupportNoteForm} style={{ minWidth: '28vw' }}>
                    <Formik
                        initialValues={createEditSignal.data}
                        enableReinitialize={true}
                        validationSchema={addSignalsValidation}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        <div>
                                            <div>
                                                <div className={classes.InputContainer}>
                                                    <div className={classes.FieldControl2}>
                                                        <label for="name">
                                                            Name <span className="required">*</span>
                                                        </label>
                                                        <Input
                                                            name="name"
                                                            id="name"
                                                            disabled={editSignalModal.status}
                                                            setFieldValue={props.setFieldValue}
                                                            setFieldTouched={props.setFieldTouched}
                                                            getFieldMeta={props.getFieldMeta}
                                                            style={{ marginTop: '0' }}
                                                        />
                                                    </div>
                                                    <div className={classes.FieldControl2}>
                                                        <label for="type">
                                                            Signal Type <span className="required">*</span>
                                                        </label>
                                                        <div>
                                                            <Dropdown
                                                                name="type"
                                                                id="type"
                                                                options={signalTypes}
                                                                defaultValue={
                                                                    createEditSignal?.data?.type
                                                                        ? {
                                                                              label: createEditSignal?.data?.type,
                                                                              value: createEditSignal?.data?.type,
                                                                          }
                                                                        : signalTypes[0]
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.InputContainer}>
                                                    {['Frequency'].includes(values.type) && (
                                                        <div className={classes.FieldControl2}>
                                                            <label for="loggingType">
                                                                Logging Type <span className="required">*</span>
                                                            </label>
                                                            <div>
                                                                <Dropdown
                                                                    name="loggingType"
                                                                    id="loggingType"
                                                                    options={loggingTypeOptions}
                                                                    defaultValue={
                                                                        createEditSignal?.data?.type
                                                                            ? {
                                                                                  value: createEditSignal?.data?.loggingType,
                                                                              }
                                                                            : loggingTypeOptions[0]
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={classes.FieldControl2}>
                                                        <label for="area">
                                                            Area <span className="required">*</span>
                                                        </label>
                                                        <div>
                                                            <Dropdown
                                                                name="area"
                                                                id="area"
                                                                options={areaTypes}
                                                                defaultValue={
                                                                    createEditSignal?.data?.area
                                                                        ? {
                                                                              label: createEditSignal?.data?.area,
                                                                              value: createEditSignal?.data?.area,
                                                                          }
                                                                        : areaTypes[0]
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="dataSource">
                                                        Data Source <span className="required">*</span>
                                                    </label>
                                                    <div>
                                                        <Dropdown
                                                            name="dataSource"
                                                            id="dataSource"
                                                            defaultValue={{
                                                                label: createEditSignal.data.dataSource,
                                                                value: createEditSignal.data.dataSource,
                                                            }}
                                                            // createEditSignal.data.dataSource
                                                            options={dataSourceList}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className={classes.FieldControl2}>
                                                        <label for="queryString">
                                                            Query String <span className="required">*</span>
                                                        </label>
                                                        <NewJSONEditor name="queryString" defaultValue={values.queryString} height="10rem" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={classes.ButtonContainer}>
                                                <div>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setAddTypesModal(false);
                                                            setEditSignalModal({
                                                                signalId: '',
                                                                status: false,
                                                            });
                                                            setCreateEditSignal(initialValues);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                <div>
                    <Typography content="Signals" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{signals.length}</span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddTypesModal(true)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Title', 'Area', 'Signal Type', 'Query String', 'Data Source', 'Action']}
                    keys={['s.no', 'title', 'area', 'type', 'queryString', 'dataSource', 'action']}
                    data={signals}
                />
            </div>
        </div>
    );
};

export default Signals;
