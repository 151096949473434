import classes from '../../../styles/CreateDevice.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography/Typography';
import { Form, Formik } from 'formik';
import { DropdownComponent, Input, InputFile } from '../../../components/Inputs/Input';
import 'react-toggle/style.css'; // for ES6 modules
import { AccessRoutes } from '../../../constants';
import { toast } from 'react-toastify';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { useLoader } from '../../../hooks';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import { UserService } from '../../../services/UserService';
import { CreateUserValidation, updateUserValidation } from '../../../validations/Home/userValidation';
import Dropdown from '../../../components/Inputs/Dropdown';
import PhoneNumberComponent from '../../../components/Inputs/PhoneNumberComponent';
import FileUpload from '../../../components/Inputs/FileUpload/FileUpload';
const getInitialValues = (editUser) => {
    if (!editUser?._id) {
        return {
            name: '',
            email: '',
            password: '',
            userRole: '',
            contact: '',
            customerId: '',
            countryCode: '',
        };
    } else {
        let userRole = null;
        if (editUser.userRole === 'expert') {
            userRole = `{"id":"expert","name":"Expert"}`;
        } else if (editUser.userRole === 'sudoer') {
            userRole = `{"id":"sudoer","name":"Sudoer"}`;
        } else if (editUser.userRole === 'custom') {
            userRole = `{"id":"custom","name":"Custom"}`;
        } else {
            userRole = `{"id":"auditor","name":"Auditor"}`;
        }
        let temp = {
            name: editUser.name,
            email: editUser.email,
            password: '',
            userRole: editUser.userRole,
            contact: editUser.contact,
            customerId: editUser.customerId,
            countryCode: editUser.countryCode,
        };
        return temp;
    }
};

const CreateUser = ({ editUser = null, setRefresh, customersData, autoFillCustomerId }) => {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([{ label: 'None', value: '' }]);
    const [customerId, setCustomerId] = useState(null);
    const [userRoleSelected, setUserRole] = useState(null);
    const [customIntialValue, setCustomIntialValue] = useState(getInitialValues({}));
    const [profileImage, setProfileImage] = useState([]);
    const [state, setState] = useState({
        dashboard: true,
        nordpool: true,
        frequency: true,
        datahub: true,
        gridManagerOnline: true,
        versionHistory: true,
        carbonEmission: true,
        eConomics: true,
        realTimeMarket: true,
        fcrPricing: true,
    });
    const userRole = [
        { value: 'expert', label: 'Expert' },
        { value: 'auditor', label: 'Auditor' },
        { value: 'sudoer', label: 'Sudoer' },
        { value: 'custom', label: 'Custom' },
    ];

    useEffect(() => {
        if (editUser?._id) {
            UserService.Read(editUser?._id, startLoader, handleFetchUserSuccess, handleError, stopLoader);
        }
    }, []);

    const handleFetchUserSuccess = (res) => {
        let data = res.data.data;
        setCustomIntialValue(getInitialValues(data, state));
        let tempPermission = state;
        for (let key in state) {
            tempPermission[key] = editUser.allowed[key] || false;
        }
    };

    const handleSubmit = (values, resetForm) => {
        const formData = new FormData();
        const isProfileImageValid = /(jpg|png|peg|svg)$/.test(profileImage?.name?.slice(-4));
        if (profileImage.length && !isProfileImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('profileImage', profileImage);
        if (values.userRole === 'custom') {
            let newState = state;
            for (const [key, value] of Object.entries(newState)) {
                if (newState[key] === false || newState[key] === null) {
                    delete newState[key];
                }
            }
            formData.append('allowed', JSON.stringify(newState));
        }
        formData.append('name', values.name);
        formData.append('contact', values.contact);
        formData.append('countryCode', values.countryCode);
        formData.append('customerId', values.customerId);
        formData.append('email', values.email);
        formData.append('password', values.password);
        formData.append('userRole', values.userRole);
        if (editUser?._id) {
            UserService.Update(editUser?._id, formData, startLoader, handleUpdateSuccess, handleError, stopLoader);
        } else {
            UserService.Create(formData, startLoader, (data) => handleCreateSuccess(data, resetForm), handleError, stopLoader);
        }
    };

    const handleCreateSuccess = (data, resetForm) => {
        resetForm();
        toast.success('User created!');
        setRefresh(Math.random() * 3);
    };

    const handleUpdateSuccess = (data, resetForm) => {
        setRefresh(Math.random() * 3);
        toast.success('User updated!');
    };

    useEffect(() => {
        let temp = [];
        customersData.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
                license: element.license,
            });
        });
        setCustomers(temp);
    }, [JSON.stringify(customersData)]);

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
        console.log(err);
    };

    const handleChange = (type, checked) => {
        setState({ ...state, [type]: checked });
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setProfileImage(file);
    };
    return (
        <div className={classes.FormContainer}>
            <Typography content={!editUser?._id ? 'Create User' : 'Edit User'} />
            <div className={classes.FormContent}>
                <Formik
                    enableReinitialize
                    initialValues={customIntialValue}
                    validationSchema={editUser?._id ? updateUserValidation : CreateUserValidation}
                    onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="name">
                                            Name <span className="required">*</span>
                                        </label>
                                        <Input name="name" id="name" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="Email">
                                            Email <span className="required">*</span>
                                        </label>
                                        <Input name="email" id="Email" />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="password">
                                            Password <span className="required">*</span>
                                        </label>
                                        <Input name="password" id="password" type="password" />
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="userRole">User Role</label>
                                        <Dropdown
                                            name="userRole"
                                            id="userRole"
                                            options={userRole}
                                            onChange={({ value }) => setUserRole(value)}
                                            defaultValue={userRole.filter((role) => role.value === editUser.userRole)[0]}
                                        />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="contact">
                                            Contact <span className="required">*</span>
                                        </label>
                                        <div>
                                            <PhoneNumberComponent
                                                inputName="contact"
                                                countryCodeName="countryCode"
                                                inputValues={values}
                                                defaultCountryCode={{ value: values.countryCode }}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.FieldControl}>
                                        <label for="customerId">
                                            Customer Id <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="customerId"
                                            id="customerId"
                                            options={customers}
                                            onChange={({ value }) => setCustomerId(value)}
                                            defaultValue={
                                                autoFillCustomerId && customers.filter((customer) => customer.value === autoFillCustomerId).length
                                                    ? customers.filter((customer) => customer.value === autoFillCustomerId)[0]
                                                    : editUser?._id
                                                      ? {
                                                            label: editUser.customerName,
                                                            value: editUser.customerId,
                                                        }
                                                      : {}
                                            }
                                        />
                                    </div>
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="profileImage">Profile Image</label>
                                        <FileUpload name="profileImage" id="profileImage" accept="image/*" onChange={handleUploadClick} />
                                    </div>
                                </div>
                                <div className="flex-box">
                                    {userRoleSelected === 'custom' &&
                                        AccessRoutes.map((route, i) => (
                                            <Checkbox
                                                name={route}
                                                label={CamelCaseToTextCase(route)}
                                                checked={state[route]}
                                                onChange={(checked) => {
                                                    handleChange(route, checked);
                                                }}
                                                id={`checkbox-${i}`}
                                                key={i}
                                            />
                                        ))}
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateUser;
