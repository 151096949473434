import classes from '../../../../../styles/Overview.module.css';
import React, { useContext, useEffect, useState } from 'react';
import ChartComponent from '../../../../../components/ApexCharts/Chart';
import { START_LOADER, STOP_LOADER } from '../../../../../constants';
import { DataHubServiceRoot } from '../../../../../services/DataHubService';
import { LoaderContext } from '../../../../../context/LoaderContext';
import moment from 'moment';
import { usToEuCurrencyFormat } from '../../../../../utils/currencyHelper';
import { truncateNumber } from '../../../../../utils/numberHelper';
import { momentTimeFormater } from '../../../../../utils/timeHelper';
import { DownloadAsExcel } from '../../../../../utils/downloadAsExcel';
import Typography from '../../../../../components/Typography/Typography';
import DownloadButton from '../../../../../components/Buttons/DownloadButton';
import { timeOnly } from '../../../../../utils/dateHelper';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';
import { toast } from 'react-toastify';

const today = new Date();

const Overview = ({ device, updateQueryParams, queryParamsData }) => {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [date, setDate] = useState(queryParamsData?.date || null);
    const [graphData, setGraphData] = useState([]);
    const [lastStatus, setLastStatus] = useState('--');
    const [isParams, setIsParams] = useState(queryParamsData?.date ? true : false);
    useEffect(() => {
        if (device.id && date) {
            const processedDate = moment(date).format('YYYY-MM-DD');
            DataHubServiceRoot.readGraphData(
                device.id,
                {
                    date: processedDate,
                    priceSignal: device.priceSignal,
                    taxValue: device.taxValue,
                },
                () => startLoader('readGraphData'),
                handleReadSuccess,
                handleError,
                () => stopLoader('readGraphData')
            );
        }
    }, [date]);

    useEffect(() => {
        if (device.id)
            DataHubServiceRoot.readLastStatus(
                device.id,
                () => startLoader('readLastData'),
                handleReadLastStatusSuccess,
                handleError,
                () => stopLoader('readLastData')
            );
    }, [device]);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    const handleReadLastStatusSuccess = ({ data }) => {
        if (data.data?.timestamp) {
            const date = momentTimeFormater(data.data?.timestamp).format('YYYY-MM-DD');
            if (!isParams) {
                setDate(moment(date).toDate());
            }
            setLastStatus(date);
        } else {
            if (!isParams) {
                setDate(today);
            }
            setLastStatus('--');
        }
        setIsParams(false);
    };

    const handleReadSuccess = ({ data }) => {
        const processedData = data.data.graph.map((mydata) => {
            return {
                ...mydata,
                type: mydata.name === 'cost' ? 'line' : 'column',
            };
        });
        setGraphData(processedData);
    };

    const handleExport = (type) => {
        if (graphData[0]?.data?.length) {
            const combinedData = graphData[0].data?.map((item, index) => ({
                a: item.x,
                b: item.y,
                c: graphData[1]?.data?.[index]?.y,
            }));
            graphData[0]?.data?.length &&
                DownloadAsExcel(combinedData, `Datahub ${moment(date).tz('Europe/Berlin').format('YYYY-MM-DD')} Export`, ['Date & Time (CET)', 'Cost (DKK)', 'Consumption (kWh)']);
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.Overview} style={{ height: '31vw' }}>
            <div className={classes.ChartContainer}>
                <div>
                    <div className={classes.Header}>
                        <div>
                            {/* <img
                src={SettingsIcon}
                alt=""
                onClick={() => {
                  handleOpen(device);
                  setEditDevice({ ...device });
                }}
              /> */}
                            <Typography content={`Meter name: ${device?.name || ' -- '}`} />
                            <Typography content={`Meter ID: ${device.id || ' -- '}`} />
                            <Typography subtext content={'Last Reported: ' + (lastStatus || '--')} />
                        </div>
                        <div className={classes.Actions}>
                            <DatePicker date={date} setDate={setDate} showArrow />
                            <DownloadButton size="medium" onClick={() => handleExport('power')} />
                        </div>
                    </div>
                    <div className={classes.OverviewBorder}></div>
                </div>
                <ChartComponent
                    dataLabels={{
                        enabled: true,
                        enabledOnSeries: [0],
                        formatter: function (val, opts) {
                            return usToEuCurrencyFormat(truncateNumber(val, 2));
                        },
                    }}
                    type="line"
                    series={graphData}
                    xFormatter={timeOnly}
                />
            </div>
        </div>
    );
};

export default Overview;
