import classes from '../../../styles/AllDevices.module.css';
import React, { useEffect, useState } from 'react';
import { SupportNotesService } from '../../../services/SupportNotesService';
import { toast } from 'react-toastify';
import SupportNoteForm from './SupportNoteForm';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';
import Typography from '../../../components/Typography/Typography';
import Table from '../../../components/Table/Table';
import { DropdownComponent, ToggleButtonWithState } from '../../../components/Inputs/Input';
import { useLoader } from '../../../hooks';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

const SupportNotes = ({ customersData, updateQueryParams, queryParamsData = {} }) => {
    const [startLoader, stopLoader] = useLoader();
    const [open, setOpen] = useState(queryParamsData.noteModal ? queryParamsData.noteModal : false);
    const [versions, setVersions] = useState([]);
    const [editData, setEditData] = useState({});
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        noteId: '',
    });
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(queryParamsData.customer ? queryParamsData.customer : {});

    useEffect(() => {
        fetchNotes();
        updateQueryParams('customer', JSON.stringify(customer));
    }, [customer]);

    useEffect(() => {
        updateQueryParams('noteModal', open);
    }, [open]);

    const fetchNotes = () => {
        customer?.value &&
            SupportNotesService.ReadAll(
                { customerId: customer.value },
                () => startLoader('getVersions'),
                handleSuccess,
                handleError,
                () => stopLoader('getVersions')
            );
    };

    const handleToggleChange = (item, id, value) => {
        let payLoad = {
            supportNote: {
                enable: value,
            },
        };
        SupportNotesService.Update(item ? id : '', payLoad, startLoader, handleUpdateSuccess, handleError, stopLoader);
    };

    const handleUpdateSuccess = (res) => {
        if (res) {
            toast.success('Status updated!');
            fetchNotes();
        }
    };

    const handleSuccess = ({ data }) => {
        let newData = data?.data.map((item, index) => {
            item['released'] = new Date(item['released']).toISOString().split('T')[0];
            return {
                ...item,
                's.no': index + 1,
                action: (
                    <div
                        style={{
                            display: 'flex',
                            gridColumnGap: '1vw',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <CustomTooltip content={'Edit'}>
                                <UilPen
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() => {
                                        setEditData({ ...item, customerName: customer.label });
                                        setOpen(true);
                                    }}
                                />
                            </CustomTooltip>
                        </div>
                        <div className={classes.ToggleContainer}>
                            <ToggleButtonWithState disabled={false} value={item.enable} onChange={(e) => handleToggleChange(item, item._id, e.target.checked)} />
                        </div>

                        <div>
                            <CustomTooltip content={'Delete'}>
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, noteId: item._id })} />
                            </CustomTooltip>
                        </div>
                    </div>
                ),
            };
        });
        setVersions(newData);
    };

    const handleDelete = () => {
        SupportNotesService.Delete(deleteModal.noteId, startLoader, handleVersionDeleteSuccess, handleError, stopLoader);
    };

    const handleVersionDeleteSuccess = (res) => {
        if (res) {
            setDeleteModal({ status: false, noteId: '' });
            toast.success('SupportNotes deleted successfully!');
            fetchNotes();
        }
    };

    useEffect(() => {
        let temp = [];
        customersData.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomers(temp);
        if (temp.length) {
            setCustomer(temp[0]);
        }
    }, [JSON.stringify(customersData)]);

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <>
            <ModalComponent isOpen={open} setOpen={setOpen} onClose={() => setEditData({})}>
                <SupportNoteForm
                    fetchNotes={fetchNotes}
                    setEditData={setEditData}
                    setOpen={setOpen}
                    data={editData}
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    customersData={customersData}
                />
            </ModalComponent>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Support Notes" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={versions?.length || 0} />
                            </span>
                        </div>
                        <Formik
                            initialValues={{
                                customerId: queryParamsData.customer ? queryParamsData.customer : customer,
                            }}
                        >
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form>
                                    <DropdownComponent
                                        name="customerId"
                                        label="Customer ID"
                                        options={customers}
                                        onChange={(e) => setCustomer(e)}
                                        defaultValue={queryParamsData.customer ? queryParamsData.customer : customer}
                                    />
                                </Form>
                            )}
                        </Formik>
                        <div>
                            <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setOpen(true)}>
                                Add Note
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <Table head={['S.No', 'Title', 'Created At', 'Action']} keys={['s.no', 'title', 'released', 'action']} data={versions} />
                </div>
            </div>
        </>
    );
};

export default SupportNotes;
