// Standard library imports
import React from 'react';

// External library imports
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { UilCopy } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

// Css module imports
import classes from '../../../../styles/AllDevices.module.css';

const ShowSecretKeyModal = ({ secretKey = '' }) => {
    return (
        <div>
            <Typography content={'Secret Key'} />
            <div style={{ minWidth: '25vw' }}>
                <CustomTooltip content={'Click to copy'}>
                    <CopyToClipboard text={secretKey} onCopy={() => toast.success('Copied!')}>
                        <div className={classes.copyToClipBoardWrapper}>
                            <span className={classes.copyToClipBoardKey}>{secretKey}</span>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </CopyToClipboard>
                </CustomTooltip>
            </div>
        </div>
    );
};

export default ShowSecretKeyModal;
