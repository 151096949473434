// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    bundleBids: 'root/bundle-bidding',
    deleteBid: 'root/bundle-bidding',
};

const GetBundleBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bundleBids}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteBundleBid = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBid}/${id}`, { params }).then(callback).catch(error).finally(next);
};

export const RootBundleBiddingService = {
    GetBundleBids,
    DeleteBundleBid,
};
