import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/aggrement',
    read_all: '/admin/aggrement',
    read: '/admin/aggrement',
    delete: '/admin/aggrement',
};

//Post call for creating a aggrement
const Create = (params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload, { params }).then(callback).catch(error).finally(next);
};

//Hit for getting all aggrement info from API
const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

//Delete request for deleting a aggrement based on id
const Delete = (params, id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`, { params }).then(callback).catch(error).finally(next);
};

//Post call for update a aggrement by id
const Update = (id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.create}/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};
export const FcrAggrementService = { Create, ReadAll, Delete, Update };
