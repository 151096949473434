// External library import
import * as yup from 'yup';

export const EdgeGatewayCreate = yup.object().shape({
    edgeGatewayAlias: yup.string().required(),
    edgeId: yup.string().required(),
    systemId: yup.string().required(),
    disabled: yup.boolean().required(),
    properties: yup.object().shape({
        gatewayConfiguration: yup.mixed().optional(),
        codeVersion: yup.string().optional(),
        notes: yup.string().optional(),
        notificationConfig: yup.object().shape({
            master: yup.boolean().required()
        })
    }),
});
