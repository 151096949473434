// Standard library imports
import React from 'react';
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import { Input, TextArea } from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import { createTransaction } from '../../../../../validations/Root/creditsValidator';
import { useLoader } from '../../../../../hooks';
import { CreditsServiceRoot } from '../../../../../services/RootCreditsService';
// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

const Create = ({ accountType, accountId, setOpen, setRefresh }) => {
    const [startLoader, stopLoader] = useLoader();

    const initialValues = {
        type: '',
        amount: 0,
        description: '',
    };

    const transactionTypes = [
        { label: 'Customer Revenue', value: 'in' },
        { label: 'Customer Expense', value: 'out' },
        { label: 'Paid to customer', value: 'out' },
    ];

    const handleSubmit = (values) => {
        const payload = {
            accountType: accountType == 'admin' ? 'partner' : accountType,
            accountId: accountId,
            ...values,
        };
        CreditsServiceRoot.Create(payload, startLoader, handleCreateSuccess, handleError, stopLoader);
    };

    const handleCreateSuccess = ({ data }) => {
        toast.success('Created Successfully');
        setRefresh(true);
        setOpen(false);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    return (
        <div div className={classes.AllDevices}>
            <Typography content={'Add Transaction'} />
            <div className={classes.SupportNoteForm}>
                <Formik initialValues={initialValues} enableReinitialize validationSchema={createTransaction} onSubmit={handleSubmit}>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Type <span className="required">*</span>
                                                </label>
                                                <Dropdown name="type" options={transactionTypes} />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Amount (€) <span className="required">*</span>
                                                </label>
                                                <Input type="number" name="amount" style={{ marginTop: '0' }} />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Description <span className="required">*</span>
                                                </label>
                                                <TextArea name="description" type="text" />
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default Create;
