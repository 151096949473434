import CodeMirror from '@uiw/react-codemirror';
import { basicSetup } from 'codemirror';
import { javascript } from '@codemirror/lang-javascript';

const CodeEditor = ({ defaultValue = '', value = '', name = '', ...props }) => {
    const meta = props.getFieldMeta(name);
    return (
        <div>
            <CodeMirror name={name} label={name} defaultValue={defaultValue} value={value} extensions={[basicSetup, javascript()]} onChange={(x) => props.setFieldValue(name, x)} />
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export default CodeEditor;
