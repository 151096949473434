import classes from './Table.module.css';
import React from 'react';
import NoData from '../../assets/noData.png';
import { get as LodashGet } from 'lodash';
import ReactPaginate from 'react-paginate';

const Table = ({ data = [], head, keys, addIndex, Pagination = false, limit = 30, handlePageChange, page = 1, totalCount = 0, size = 'small', style = {} }) => {
    const a = document.querySelectorAll('.react-pagination .selected');
    if (size === 'large') {
        if (a[0]) {
            a[0].style.padding = '0.8vw';
            a[0].style.height = '1vw';
            a[0].style.width = '1vw';
        }
    } else if (size === 'small') {
        if (a[0]) {
            a[0].style.padding = '0.5vw';
            a[0].style.height = '0.6vw';
            a[0].style.width = '0.6vw';
        }
    } else {
        if (a[0]) {
            a[0].style.padding = '0.7vw';
            a[0].style.height = '0.8vw';
            a[0].style.width = '0.8vw';
        }
    }
    return (
        <div className={classes.Table} style={style}>
            <div className={classes.Content}>
                <table>
                    <tr className={classes.Header}>{head && head.map((head) => <th>{head}</th>)}</tr>
                    {data.length ? (
                        data.map((row, i) => (
                            <tr style={row?.style || {}} className={classes.Data}>
                                {addIndex ? <td>{i + 1}</td> : null}
                                {keys.map((key) => (
                                    <td>{typeof LodashGet(row, key) !== 'boolean' ? LodashGet(row, key) : LodashGet(row, key).toString()}</td>
                                ))}
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="9">
                                <div className={classes.NoData}>
                                    <div>
                                        {' '}
                                        <img src={NoData} alt="" />
                                    </div>
                                    <div>No Records To Display</div>
                                </div>
                            </td>
                        </tr>
                    )}
                </table>
            </div>

            <div>
                {Pagination ? (
                    <ReactPaginate
                        containerClassName="react-pagination"
                        forcePage={page}
                        pageCount={Math.ceil(totalCount / limit)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={({ selected }) => handlePageChange(selected)}
                        nextLabel=">"
                        previousLabel="<"
                    />
                ) : null}
            </div>
        </div>
    );
};

export default Table;
