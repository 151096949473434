// Standard library imports
import React, { useState, useEffect } from 'react';

// Internal module imports
import TabsComponent from '../../../components/Tabs/Tabs';
import Typography from '../../../components/Typography/Typography';
import ThreeStateToggle from '../../../components/Inputs/ThreeStateToggle/ThreeStateToggle';
import { THREE_STATE_TOGGLE_LABELS } from '../../../constants';
import { Form, Formik } from 'formik';
import { Input } from '../../../components/Inputs/Input';
import allDeviceClasses from '../../../styles/AllDevices.module.css';
import { useLoader } from '../../../hooks';
import { FcrDBidService } from '../../../services/FcrDBidService';
import { toast } from 'react-toastify';

const modalStyle = {
    width: '28vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '3vh',
};

const FORCE_FACTOR_OPTIONS = {
    left: {
        title: 'Off',
        value: 'left',
    },
    center: {
        title: 'Default',
        value: 'center',
    },
    right: {
        title: 'Custom',
        value: 'right',
    },
};

const ForceFactorActivation = ({ instance, market, setForceModal, getEdgeData }) => {
    const [startLoader, stopLoader] = useLoader();

    const [forceFactor, setForceFactor] = useState({
        status: 'OFF',
        value: 1,
        adjustmentFactor: 0,
    });
    const [selectedState, setSelectedState] = useState('center');

    useEffect(() => {
        getInstanceDetails();
    }, []);

    const getInstanceDetails = () => {
        if (instance?._id) {
            FcrDBidService.GetInstanceDetails(instance?._id, startLoader, handleInstanceDetailsSuccess, handleError, stopLoader);
        }
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleInstanceDetailsSuccess = ({ data }) => {
        if (market) {
            let state = 'center';
            if (data?.data?.forceFactorStatus?.[market]) {
                setForceFactor({
                    ...data?.data?.forceFactorStatus?.[market],
                    status: data?.data?.forceFactorStatus?.[market].status,
                    adjustmentFactor: data?.data?.forceFactorStatus?.[market]?.adjustmentFactor || 0,
                });
            } else {
                setForceFactor({
                    status: 'OFF',
                    value: 1,
                    adjustmentFactor: 0,
                });
            }
            switch (data?.data?.forceAcivationStatus?.[market]) {
                case 'DEACTIVATE': {
                    state = 'left';
                    break;
                }
                case 'DEFAULT': {
                    state = 'center';
                    break;
                }
                case 'ACTIVATE': {
                    state = 'right';
                    break;
                }
                default: {
                    break;
                }
            }
            setSelectedState(state);
        }
    };

    const onChangeForceState = (value) => {
        if (instance?._id && market) {
            let state = 'DEFAULT';
            switch (value) {
                case 'left': {
                    state = 'DEACTIVATE';
                    break;
                }
                case 'center': {
                    state = 'DEFAULT';
                    break;
                }
                case 'right': {
                    state = 'ACTIVATE';
                    break;
                }
                default: {
                    state = 'DEFAULT';
                    break;
                }
            }

            const params = {
                forceAcivationStatus: state,
                id: instance?._id,
                market,
            };

            FcrDBidService.ForceActivation(params, startLoader, handleForceStatusSuccess, handleError, stopLoader);
        }
    };

    const handleForceStatusSuccess = () => {
        toast.success('Activation state updated!');
        getInstanceDetails();
        getEdgeData();
    };

    const updateForceFactor = (values) => {
        if (instance?._id && market) {
            const payload = {
                forceFactorStatus: {
                    status: values.status,
                    value: values.status === 'CUSTOM' ? Number(values.value) : values.status === 'OFF' ? 1 : '',
                    adjustmentFactor: values.status === 'CUSTOM' ? Number(values.adjustmentFactor) : 0,
                },
                id: instance?._id,
                market,
            };

            FcrDBidService.UpdateForceFactor(payload, startLoader, handleForceFactorSuccess, handleError, stopLoader);
        }
    };

    const handleForceFactorSuccess = () => {
        toast.success('Factor state updated!');
        getEdgeData();
    };
    const tabs = [
        {
            name: 'Force Activation',
            component: instance?.name ? (
                <div style={modalStyle}>
                    <Typography size={'14'} content={`Instance: ${instance?.name}`} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <ThreeStateToggle labels={THREE_STATE_TOGGLE_LABELS} onChange={onChangeForceState} defaultValue={selectedState} />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                        }}
                    >
                        <button
                            onClick={() => {
                                setForceModal(false);
                            }}
                            style={{ width: '7vw', margin: '0' }}
                            className="btn-secondary"
                            type="button"
                        >
                            Close
                        </button>
                    </div>
                </div>
            ) : (
                <div style={modalStyle}>
                    <Typography size={'14'} content={`Instance Not Configured`} />
                </div>
            ),
        },
        {
            name: 'Force Factor',
            component: instance?.name ? (
                <div style={modalStyle}>
                    <Typography size={'14'} content={`Instance: ${instance?.name}`} />
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Formik initialValues={forceFactor} onSubmit={updateForceFactor} enableReinitialize>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '0.5vw',
                                            alignItems: 'center',
                                            fontSize: '0.9vw',
                                            fontWeight: '500',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <ThreeStateToggle
                                            labels={FORCE_FACTOR_OPTIONS}
                                            onChange={(e) => {
                                                let status = 'OFF';
                                                switch (e) {
                                                    case 'left': {
                                                        status = 'OFF';
                                                        break;
                                                    }
                                                    case 'center': {
                                                        status = 'DEFAULT';
                                                        break;
                                                    }
                                                    case 'right': {
                                                        status = 'CUSTOM';
                                                        break;
                                                    }
                                                    default: {
                                                        status = 'OFF';
                                                        break;
                                                    }
                                                }
                                                props.setFieldValue('status', status);
                                                setForceFactor({
                                                    status: status,
                                                    value: values.value,
                                                    adjustmentFactor: values.adjustmentFactor,
                                                });
                                            }}
                                            defaultValue={forceFactor.status === 'OFF' ? 'left' : forceFactor.status === 'DEFAULT' ? 'center' : 'right'}
                                        />
                                    </div>
                                    {forceFactor.status === 'CUSTOM' && (
                                        <div>
                                            <div className={allDeviceClasses.InputContainer}>
                                                <div className={allDeviceClasses.FieldControl2}>
                                                    <label>
                                                        Capacity Factor <span className="required">*</span>
                                                    </label>
                                                    <Input name="value" type="number" style={{ marginTop: '0' }} />
                                                </div>
                                                <div className={allDeviceClasses.FieldControl2}>
                                                    <label>
                                                        Adjustment Factor <span className="required">*</span>
                                                    </label>
                                                    <Input name="adjustmentFactor" type="number" style={{ marginTop: '0' }} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className={allDeviceClasses.ButtonContainer}>
                                        <div>
                                            <button
                                                onClick={() => {
                                                    setForceModal(false);
                                                }}
                                                className="btn-secondary"
                                                type="button"
                                            >
                                                Close
                                            </button>
                                        </div>

                                        <div>
                                            <button className="btn-primary" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            ) : (
                <div style={modalStyle}>
                    <Typography size={'14'} content={`Instance Not Configured`} />
                </div>
            ),
        },
    ];

    return (
        <div style={{ height: 'auto', width: 'auto' }}>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default ForceFactorActivation;
