// Standard library imports
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// External library imports
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { UilImport } from '@iconscout/react-unicons';
import 'react-tabs/style/react-tabs.css';
import { get as lodashGet } from 'lodash';

// Internal module imports
import ModalComponent from '../ModalComponent/ModalComponent';
import Settings from '../../assets/settings.png';
import EditIcon from '../../assets/settingicon.svg';
import RestartIcon from '../../assets/restartIcon.svg';
import ApiIcon from '../../assets/api.svg';
import { AuthContext } from '../../context/AuthContext';
import { updateRecentTabIndex } from '../../utils/localStorage';
// Css imports
import classes from '../../styles/Tabs.module.css';
import { PROTECTED_ROUTES_ROOT } from '../../routes/Routes';

const TabsComponent = ({
    tabs,
    modal = null,
    modalComponent,
    isOpen = false,
    setOpen,
    selectedIndex,
    setEditDevice = null,
    setPrefillGroup = null,
    resetIndex = null,
    setConfigOpen,
    setRestartModel,
    setEditGroup = null,
    setExternalApiModal = null,
    setDevice = null,
    onChangeTab = null,
    setShowExportModal = null,
    style = {},
}) => {
    const location = useLocation();
    const history = useHistory();
    const { state } = useContext(AuthContext);
    const userRole = lodashGet(state, 'user.userRole');

    const accesableTabs = state.user.allowed || null;

    const [tabIndex, setTabIndex] = useState(selectedIndex ? selectedIndex : 0);
    useEffect(() => {
        if (tabIndex !== 0) setDevice && setDevice({ uId: '', name: '' });
    }, [tabIndex, setDevice]);
    useEffect(() => {
        if (selectedIndex >= 0) setTabIndex(selectedIndex);
    }, [selectedIndex]);

    useEffect(() => {
        if (resetIndex) setTabIndex(0);
    }, [resetIndex]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const recentTab = params.get('recentTab');
        if (recentTab === 'true') {
            const recentTabIndex = params.get('tabIndex');
            handleTabChange(Number(recentTabIndex), true);
            params.delete('recentTab');
            params.delete('tabIndex');
            history.replace({ search: params.toString() });
        }
    }, [location.search]);

    const handleRecentTabIndex = (index) => {
        location.pathname !== '/' && updateRecentTabIndex(location.pathname, index, state);
    };

    const handleTabChange = (index, isRecentTab = false) => {
        if (setEditDevice && index !== selectedIndex) {
            setEditDevice({});
            if (setPrefillGroup) setPrefillGroup('');
        }
        if (setEditGroup && index !== selectedIndex) {
            setEditGroup({});
        }
        setTabIndex(index);
        onChangeTab && onChangeTab(index);
        !isRecentTab && handleRecentTabIndex(index);
    };

    const allowedTabs = (id) => {
        // let show = false;
        // if (userRole === "root") {
        //   show = true;
        // } else if (userRole === "admin" || userRole === "enabler") {
        //   show = true;
        // } else if (userRole === "enabler") {
        //   show = true;
        // } else {
        //   const compId = PROTECTED_ROUTES_ROOT.find(
        //     (comp) => comp.url === location.pathname
        //   );
        //   const filteredComp = accesableTabs?.properties?.[userRole]?.find(
        //     (item) => item.componentId === compId.id
        //   );
        //   const tabs = filteredComp?.tabsId?.find((tabs) => tabs.tabId === id);
        //   if (tabs) {
        //     show = true;
        //   }
        // }
        return true;
    };

    return (
        <Tabs selectedIndex={tabIndex} onSelect={(index) => handleTabChange(index)}>
            <TabList>
                {tabs.map((tab) => allowedTabs(tab?.tabId) && <Tab>{tab.name}</Tab>)}
                <div className={classes.ModalSettings}>
                    {setRestartModel ? (
                        <div onClick={() => setRestartModel(true)}>
                            <img src={RestartIcon} alt="" />
                        </div>
                    ) : null}
                    {setConfigOpen ? (
                        <div onClick={() => setConfigOpen(true)}>
                            <img src={EditIcon} alt="" />
                        </div>
                    ) : null}
                    {modal ? (
                        <div onClick={() => setOpen(true)}>
                            <img src={Settings} alt="" />
                        </div>
                    ) : null}
                    {setExternalApiModal ? (
                        <div onClick={() => setExternalApiModal(true)}>
                            <img src={ApiIcon} alt="ApiIcon" />
                        </div>
                    ) : null}
                    {setShowExportModal ? (
                        <UilImport
                            size="1.5vw"
                            style={{
                                color: 'var(--color-primary)',
                                cursor: 'pointer',
                            }}
                            onClick={() => setShowExportModal(true)}
                        />
                    ) : null}
                </div>
            </TabList>

            {tabs.map((tab) => allowedTabs(tab?.tabId) && <TabPanel style={style ? style : { marginTop: '1.5vw' }}>{tab.component}</TabPanel>)}
            {modal ? (
                <ModalComponent isOpen={isOpen} setOpen={setOpen}>
                    {modalComponent}
                </ModalComponent>
            ) : null}
        </Tabs>
    );
};

export default TabsComponent;
