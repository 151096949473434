import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/license-types',
    update: '/root/license-types',
    delete: '/root/license-types',
    licenseByCustomerId: '/root/license-types/customer',
    instance: '/root/license-types/customer/instance',
    read_all_admin: '/admin/license-types',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const ReadAllAdmin = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all_admin).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetLicenseByCustomerId = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.licenseByCustomerId}/${id}`).then(callback).catch(error).finally(next);
};

const GetInstance = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instance}`, { params }).then(callback).catch(error).finally(next);
};

export const LicenseTypeService = {
    ReadAll,
    Delete,
    Create,
    Update,
    GetLicenseByCustomerId,
    GetInstance,
};

export const LicenseTypeServiceAdmin = {
    RealAll: ReadAllAdmin,
};
