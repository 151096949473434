import React from 'react';
import Typography from '../../../../components/Typography/Typography';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import classes from '../../../../styles/BidModal.module.css';
import { useState } from 'react';
import { Input } from '../../../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { DownloadFileFromBuffer } from '../../../../utils/downloadHelper';
import { BiddingService } from '../../../../services/BiddingService';

const ExportBid = ({ setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(new Date());
    const initialValues = {
        businessType: 'FCR-D bud (Z06)',
        auction: 'D-2',
        blockBid: 'Nej',
        direction: 'Ned',
    };
    const auctionOptions = [
        { label: 'D-2', value: 'D-2' },
        { label: 'D-1', value: 'D-1' },
    ];
    const directionOptions = [
        { label: 'Ned & Op', value: 'both' },
        { label: 'Ned', value: 'increased' },
        { label: 'Op', value: 'decreased' },
    ];

    const handleSubmit = (values) => {
        BiddingService.ExportBids(
            {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                ...values,
            },
            startLoader,
            handleSuccess,
            handleError,
            stopLoader
        );
    };

    const handleSuccess = async ({ data }) => {
        if (data.data) {
            const { base64String, fileName, fileType } = data.data;
            const buffer = Buffer.from(base64String, 'base64');
            DownloadFileFromBuffer(buffer, fileName, fileType);
        }
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div style={{ minWidth: '20vw', overflow: 'initial' }}>
            <Typography content="Export Bids" />
            <div>
                <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                    <Form>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl2}>
                                <label>Business Type</label>
                                <Input name="businessType" disabled />
                            </div>
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl2}>
                                <label>Block Bid</label>
                                <Input name="blockBid" disabled />
                            </div>
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl2}>
                                <label>Direction</label>
                                <Dropdown name="direction" options={directionOptions} defaultValue={directionOptions[0]} />
                            </div>
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl2}>
                                <label>Auction</label>
                                <Dropdown name="auction" options={auctionOptions} defaultValue={auctionOptions[0]} />
                            </div>
                        </div>
                        <div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl2}>
                                    <label>
                                        Date <span className="required">*</span>
                                    </label>
                                    <div className="modal-date-picker">
                                        <DatePicker date={date} setDate={setDate} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    );
};

export default ExportBid;
