// Standard library imports
import React, { useEffect, useState } from 'react';

// Extenal library imports
import { Form, Formik } from 'formik';
import { capitalize } from 'lodash';
import { toast } from 'react-toastify';

// Internal module imports
import { ToggleButton } from '../../../../components/Inputs/Input';
import Typography from '../../../../components/Typography/Typography';
import { CustomerService } from '../../../../services/CustomerService';
import { LicenseService } from '../../../../services/LicenseService';
import classes from '../../../../styles/AllDevices.module.css';
import bidClasses from '../../../../styles/BidModal.module.css';
import { Capitalize } from '../../../../utils/stringHelper';
import Dropdown from '../../../../components/Inputs/Dropdown';
import MultiSelectDropdown from '../../../../components/Inputs/MultiSelectDropdown';
import { DataHubService } from '../../../../services/DataHubService';

// Css imports
import '../index.module.css';

const getInitialValues = (customer) => {
    if (customer?.e3DashboardConfig) {
        const { e3DashboardConfig } = customer;
        const data = { ...e3DashboardConfig };
        let eventMarkets = [];
        e3DashboardConfig?.eventMarkets?.map((item) => {
            eventMarkets.push({ label: item, value: item });
        });
        data.eventMarkets = eventMarkets;
        return data;
    } else {
        return {
            enableFirstRow: true,
            r1c1: { value: '' },
            r1c3: { value: false },
            enableSecondRow: true,
            r2c1: { value: '', groupId: '' },
            r2c2: { value: '', groupId: '' },
            enableThirdRow: false,
            r3c1: { value: '', groupId: '' },
            r3c2: { value: '', groupId: '' },
            enableEventFaultRow: false,
            eventMarkets: {},
        };
    }
};

const getr1c1Options = (customer) => {
    const { frequency = [] } = customer;
    const options = [{ label: 'None', value: '' }];
    frequency.forEach((f) => options.push({ label: f.name, value: f._id }));
    return options;
};

const getCustomerMarketOptions = (customer) => {
    const { biddingMarkets } = customer;
    const options = [{ label: 'None', value: '' }];
    biddingMarkets.forEach((f) => {
        options.push({ label: f, value: f });
    });
    return options;
};

const getEventMarketOptions = (customer) => {
    const { biddingMarkets } = customer;
    const options = [];
    biddingMarkets?.forEach((market) => {
        if (market !== 'DC-PLANNER') options.push({ label: market, value: market });
    });
    return options;
};

const E3DashboardConfig = ({ customer, startLoader, stopLoader, setRefresh, setOpen }) => {
    const [initialValues] = useState(getInitialValues(customer));
    const [r1c1Options, setr1c1Options] = useState([{ label: 'None', value: '' }]);
    const [r1c3Options] = useState([
        { label: 'Hide', value: false },
        { label: 'Show', value: true },
    ]);
    const [licenseOptions, setLicenseOptions] = useState([]);
    const [r2c1GroupOptions, setR2c1GroupOptions] = useState([]);
    const [r2c2GroupOptions, setR2c2GroupOptions] = useState([]);
    const [eventMarketOptions] = useState(getEventMarketOptions(customer));
    const [r3c1Options] = useState(getCustomerMarketOptions(customer));
    const [r3c2Options] = useState(getCustomerMarketOptions(customer));
    const [r3c1GroupOptions, setR3c1GroupOptions] = useState([]);
    const [r3c2GroupOptions, setR3c2GroupOptions] = useState([]);

    const instanceNotRequiringMarket = ['DC-PLANNER'];

    useEffect(() => {
        if (customer._id) getPopulatedCustomerDetails();
        if (initialValues.r2c1?.value) getLicenseGroups(1, { value: initialValues.r2c1?.value });
        if (initialValues.r2c2?.value) getLicenseGroups(2, { value: initialValues.r2c2?.value });
    }, []);

    useEffect(() => {
        if (customer?._id) {
            LicenseService.GetCustomerLicense(customer?._id, startLoader, handleLicenseSucess, handleError, stopLoader);
        }
    }, [customer]);

    const handleLicenseSucess = ({ data }) => {
        const temp = data.data.map((e) => ({ label: e.name, value: e._id }));
        setLicenseOptions(temp);
    };

    const getPopulatedCustomerDetails = () => {
        if (customer._id) {
            CustomerService.Read(
                customer._id,
                () => startLoader('getPopulatedCustomerDetails'),
                handlePopulatedCustomerSuccess,
                handleError,
                () => stopLoader('getPopulatedCustomerDetails')
            );
        }
    };

    const handlePopulatedCustomerSuccess = ({ data }) => {
        if (data.data) {
            setr1c1Options(getr1c1Options(data.data));
        } else {
            setr1c1Options([{ label: 'None', value: '' }]);
        }
    };

    const getMarketEnabledInstances = (selected, setFunc) => {
        if (selected.value && !instanceNotRequiringMarket.includes(selected.value)) {
            CustomerService.GetMarketEnabledgroups(
                selected.value,
                { customerId: customer._id },
                () => startLoader(`getMarketEnabledInstances-${selected.value}`),
                ({ data }) => {
                    const res = data?.data?.licenses;
                    if (res) {
                        const options = [{ label: 'None', value: '' }];
                        res?.forEach((l) => {
                            if (l.groups?.length) {
                                l.groups.forEach((g) => {
                                    options.push({
                                        label: `${capitalize(l._id)} | ${g.name}`,
                                        value: g._id,
                                    });
                                });
                            }
                        });
                        setFunc(options);
                    }
                },
                handleError,
                () => stopLoader(`getMarketEnabledInstances-${selected.value}`)
            );
        }
    };

    const getLicenseGroups = (col, selected) => {
        const licenseType = licenseOptions.filter((option) => option?.value === selected.value)[0]?.label === 'dataHub';
        if (licenseType) {
            DataHubService.getMeters(
                { customerId: customer?._id },
                startLoader,
                ({ data }) => {
                    const options = data?.data ? data.data : [];
                    col === 1 && setR2c1GroupOptions(options);
                    col === 2 && setR2c2GroupOptions(options);
                },
                handleError,
                stopLoader
            );
        } else {
            LicenseService.GetInstancesByLicenseTypeId(
                selected.value,
                { customerId: customer?._id },
                () => startLoader('getLicenseGroups'),
                ({ data }) => {
                    const options = [{ label: 'None', value: '' }];
                    data?.data?.forEach((g) => {
                        options.push({ label: g.name, value: g._id });
                    });
                    col === 1 && setR2c1GroupOptions(options);
                    col === 2 && setR2c2GroupOptions(options);
                },
                handleError,
                () => stopLoader('getLicenseGroups')
            );
        }
    };

    const handleSubmit = (values) => {
        if (!values.r2c1.value) values.r2c1.groupId = '';
        if (!values.r2c2.value) values.r2c2.groupId = '';
        const eventMarkets = [];
        values.eventMarkets &&
            values.eventMarkets.map((item) => {
                eventMarkets.push(item.value);
            });
        values.eventMarkets = eventMarkets;
        CustomerService.UpdateE3DashboardConfig(customer._id, values, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = (res) => {
        toast.success('Config Updated!');
        setRefresh && setRefresh(Date.now());
        setOpen(false);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <Typography content={`E3 Dashbaord Config (${customer.name})`} />
            <div style={{ minWidth: '40vw', maxHeight: '70vh', overflowY: 'scroll' }}>
                <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values)} enableReinitialize>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <ToggleButton values={values} label={'Enable First Row'} name={`enableFirstRow`} />
                                    {values.enableFirstRow && (
                                        <div>
                                            <div className={bidClasses.InputContainer2}>
                                                <div className={bidClasses.FieldControl2}>
                                                    <label>Frequency Card</label>
                                                    <Dropdown
                                                        name="r1c1.value"
                                                        options={r1c1Options}
                                                        defaultValue={
                                                            customer?.e3DashboardConfig?.r1c1?.value
                                                                ? r1c1Options.filter((i) => i.value === customer?.e3DashboardConfig?.r1c1?.value)[0]
                                                                : r1c1Options[0]
                                                        }
                                                    />
                                                </div>
                                                <div className={bidClasses.FieldControl2}>
                                                    <label>Revenue card</label>
                                                    <Dropdown
                                                        name="r1c3.value"
                                                        options={r1c3Options}
                                                        defaultValue={values.r1c3?.value ? r1c3Options.filter((i) => i.value === values.r1c3.value)[0] : r1c3Options[0]}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <ToggleButton values={values} label={'Enable Second Row'} name={`enableSecondRow`} />
                                    <div>
                                        {values.enableSecondRow && (
                                            <div>
                                                <div className={bidClasses.InputContainer}>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <label>License Card 1</label>
                                                        <Dropdown
                                                            name="r2c1.value"
                                                            options={licenseOptions}
                                                            defaultValue={values.r2c1?.value ? licenseOptions.filter((i) => i.value === values.r2c1.value)[0] : licenseOptions[0]}
                                                            onChange={(selected) => {
                                                                getLicenseGroups(1, selected);
                                                            }}
                                                        />
                                                    </div>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <label>License Card 2</label>
                                                        <Dropdown
                                                            name="r2c2.value"
                                                            options={licenseOptions}
                                                            defaultValue={values.r2c2?.value ? licenseOptions.filter((i) => i.value === values.r2c2.value)[0] : licenseOptions[0]}
                                                            onChange={(selected) => {
                                                                getLicenseGroups(2, selected);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={bidClasses.InputContainer}>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <>
                                                            {r2c1GroupOptions.length > 0 && (
                                                                <>
                                                                    <label>
                                                                        Select
                                                                        {licenseOptions.filter((option) => option?.value === values?.r2c1?.value)[0]?.label === 'dataHub'
                                                                            ? ' Meter'
                                                                            : ' Group'}
                                                                    </label>
                                                                    <Dropdown
                                                                        name="r2c1.groupId"
                                                                        options={r2c1GroupOptions}
                                                                        defaultValue={
                                                                            values.r2c1?.groupId && r2c1GroupOptions.filter((i) => i.value === values.r2c1.groupId).length
                                                                                ? r2c1GroupOptions.filter((i) => i.value === values.r2c1.groupId)[0]
                                                                                : r2c1GroupOptions[0]
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    </div>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <>
                                                            {r2c2GroupOptions.length > 0 && (
                                                                <>
                                                                    <label>
                                                                        Select
                                                                        {licenseOptions.filter((option) => option?.value === values?.r2c2?.value)[0]?.label === 'dataHub'
                                                                            ? ' Meter'
                                                                            : ' Group'}
                                                                    </label>
                                                                    <Dropdown
                                                                        name="r2c2.groupId"
                                                                        options={r2c2GroupOptions}
                                                                        defaultValue={
                                                                            values.r2c2?.groupId && r2c2GroupOptions.filter((i) => i.value === values.r2c2.groupId).length
                                                                                ? r2c2GroupOptions.filter((i) => i.value === values.r2c2.groupId)[0]
                                                                                : r2c2GroupOptions[0]
                                                                        }
                                                                    />
                                                                </>
                                                            )}
                                                        </>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <ToggleButton values={values} label={'Enable Third Row'} name={`enableThirdRow`} />
                                    <div>
                                        {values.enableThirdRow && (
                                            <div>
                                                <div className={bidClasses.InputContainer}>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <label>License Capacity Card 1</label>
                                                        <Dropdown
                                                            name="r3c1.value"
                                                            options={r3c1Options}
                                                            defaultValue={values.r3c1?.value ? r3c1Options.filter((i) => i.value === values.r3c1.value)[0] : {}}
                                                            onChange={(e) => getMarketEnabledInstances(e, setR3c1GroupOptions)}
                                                        />
                                                    </div>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <label>License Capacity Card 2</label>
                                                        <Dropdown
                                                            name="r3c2.value"
                                                            options={r3c2Options}
                                                            defaultValue={values.r3c2?.value ? r3c2Options.filter((i) => i.value === values.r3c2.value)[0] : {}}
                                                            onChange={(e) => getMarketEnabledInstances(e, setR3c2GroupOptions)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={bidClasses.InputContainer}>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <label>Select Group</label>
                                                        <Dropdown
                                                            name="r3c1.groupId"
                                                            options={r3c1GroupOptions}
                                                            defaultValue={
                                                                values.r3c1?.groupId ? r3c1GroupOptions.filter((i) => i.value === values.r3c1.groupId)[0] : r2c1GroupOptions[0]
                                                            }
                                                        />
                                                    </div>
                                                    <div className={bidClasses.FieldControl2}>
                                                        <label>Select Group</label>
                                                        <Dropdown
                                                            name="r3c2.groupId"
                                                            options={r3c2GroupOptions}
                                                            defaultValue={
                                                                values.r3c2?.groupId ? r3c2GroupOptions.filter((i) => i.value === values.r3c2.groupId)[0] : r3c2GroupOptions[0]
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <ToggleButton values={values} label={'Enable Event Fault Row'} name={`enableEventFaultRow`} />
                                    <div>
                                        {values.enableEventFaultRow && (
                                            <div className={bidClasses.InputContainer}>
                                                <div className={bidClasses.FieldControl2} style={{ width: '100%' }}>
                                                    <label>Select Markets</label>
                                                    <MultiSelectDropdown name="eventMarkets" options={eventMarketOptions} defaultValue={initialValues?.eventMarkets} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default E3DashboardConfig;
