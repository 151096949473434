// Standard library import
import React from 'react';

// External library import
import { Form, Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { capitalize } from 'lodash';
import _ from 'lodash';
import { UilExpandArrows, UilTimesCircle, UilFileDownload } from '@iconscout/react-unicons';

// Internal module import
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/BidModal.module.css';
import { EdgeService } from '../../../services/EdgeService';
import { TextArea, ToggleButton, Input } from '../../../components/Inputs/Input';
import { EdgeDevice } from '../../../validations/Edge/edgeDevice';
import { AccessManagerService } from '../../../services/AccessManagerService';
import { useEffect } from 'react';
import { useState } from 'react';
import Dropdown from '../../../components/Inputs/Dropdown';
import { SystemServices } from '../../../services/SystemServices';
import { LicenseService } from '../../../services/LicenseService';
import { signalsService } from '../../../services/signalsService';
import { FREQUENCY_VALUES } from '../../../constants';
import NewJSONEditor from '../../../components/JSONEditor/NewJSONEditor';
import edgeClasses from '../Edge.module.css';
import { CamelCaseToTextCase } from '../../../utils/stringHelper';
import MultipleFilesUpload from '../../../components/Inputs/FileUpload/MultipleFileUpload';

const Device = ({ setModal, startLoader, stopLoader, fetchBids, deviceDetails, customerId, customerCode, selectCustomer, createDuplicate = false }) => {
    const [systems, setSystems] = useState([]);
    const [license, setLicense] = useState([]);
    const [groups, setGroups] = useState([{ label: 'None', value: '' }]);
    const [market, setMarket] = useState([{ label: 'None', value: '' }]);
    const [allSignalOption, setAllSignalOption] = useState([]);
    const [signalOption, setSignalOption] = useState([]);
    const [frequencyValue, setFrequencyValue] = useState({});
    const [selectedMarket, setSelectedMarket] = useState(deviceDetails?.market ? { value: deviceDetails.market } : {});
    const [selectedInstance, setSelectedInstance] = useState(deviceDetails?.groupId ? { value: deviceDetails.groupId } : { label: 'None', value: '' });
    const [storedFrequency, setStoredFrequency] = useState(deviceDetails.properties?.frequency || {});
    const [isFullScreen, setFullScreen] = useState({
        status: false,
        editor: 0,
    });

    const [edgeProfileOptions, setEdgeProfileOptions] = useState({
        controlProfile: [],
        meterProfile: [],
        controllerProfile: [],
    });
    const [updateDefaultValueEditor, setUpdateDefaultValueEditor] = useState(false);

    const profileDefaultValue = {
        enabled: false,
        name: '',
        totalCapacity: 0,
        ctFactor: 1,
        basePower: false,
    };
    const defaultValue = {
        edgeAlias: deviceDetails.edgeAlias || '',
        secrets: deviceDetails.secrets || {},
        disabled: deviceDetails.disabled || false,
        properties: {
            notificationConfig: {
                master: deviceDetails.properties?.notificationConfig?.master === false ? false : true,
            },
            frequency: deviceDetails.properties?.frequency || {},
            edgeConfiguration: deviceDetails.properties?.edgeConfiguration || {},
            profile: deviceDetails.properties?.profile || profileDefaultValue,
            signalId: deviceDetails.properties?.signalId || '',
            maxDuration: deviceDetails.properties?.maxDuration,
            startDelay: deviceDetails.properties?.startDelay,
            codeVersion: deviceDetails.properties?.codeVersion,
            notes: deviceDetails.properties?.notes || '',
        },
        systemId: deviceDetails.systemId || '',
        licenseId: deviceDetails.licenseId || '',
        groupId: deviceDetails.groupId || '',
        market: deviceDetails.market || '',
    };

    const [initialValues, setInitialValues] = useState(defaultValue);
    const [selectedLicense, setSelectedLicense] = useState(deviceDetails?.licenseId ? { value: deviceDetails.licenseId } : { label: 'None', value: '' });
    const [files, setFiles] = useState(deviceDetails?.files || []);

    useEffect(() => {
        getEdgeProfiles();
    }, []);

    const getEdgeProfiles = () => {
        EdgeService.EdgeProfiles(startLoader, handleEdgeProfileSuccess, handleError, stopLoader);
    };

    const handleEdgeProfileSuccess = ({ data }) => {
        const controlProfile = [];
        const meterProfile = [];
        const controllerProfile = [];
        data.data.map((e) => {
            if (e.type === 'controlProfile') {
                controlProfile.push({
                    label: e.name,
                    value: e.profile,
                });
            } else if (e.type === 'meterProfile') {
                meterProfile.push({
                    label: e.name,
                    value: e.profile,
                });
            } else if (e.type === 'controllerProfile') {
                controllerProfile.push({
                    label: e.name,
                    value: e.profile,
                });
            }
        });
        setEdgeProfileOptions({
            controlProfile: controlProfile,
            meterProfile: meterProfile,
            controllerProfile: controllerProfile,
        });
    };

    useEffect(() => {
        if (selectCustomer?.value) {
            LicenseService.GetCustomerLicense(selectCustomer.value, startLoader, handleLicenseSucess, handleError, stopLoader);
        }
    }, [selectCustomer]);

    const handleLicenseSucess = ({ data }) => {
        const temp = [{ label: 'None', value: '' }];
        data.data.map((e) => {
            temp.push({
                label: CamelCaseToTextCase(e.name),
                value: e._id,
            });
        });
        setLicense(temp);
    };

    useEffect(() => {
        getSystems();
        getGlobalAndCustomerSignal();
    }, [customerId]);

    const handleSubmit = (values) => {
        delete values.controlProfile;
        delete values.meterProfile;
        delete values.controllerProfile;
        let profile = {
            ...values.properties.profile,
            name: selectedInstance.label !== 'None' ? `${selectedInstance.label} ${selectedMarket.label}` : '',
        };
        values.properties.profile = profile;
        values.files = files;

        if (deviceDetails?._id && !createDuplicate) {
            EdgeService.Update(deviceDetails._id, values, startLoader, handleUpdateSucess, handleError, stopLoader);
        } else {
            const payload = {
                ...values,
                customerId,
                customerCode,
            };
            EdgeService.Create(payload, startLoader, handleSubmitSucess, handleError, stopLoader);
        }
    };

    const handleUpdateSucess = ({ res }) => {
        fetchBids();
        setModal(false);
        toast.success('Device updated successfully');
    };

    const handleSubmitSucess = ({ res }) => {
        fetchBids();
        setModal(false);
        toast.success('Device created successfully');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleSystemSuccess = (res) => {
        const systems = res?.data?.data.map((host) => {
            return {
                label: host.hostName,
                value: host._id,
            };
        });
        setSystems(systems);
    };

    const getSystems = () => {
        SystemServices.getSystems(customerId, startLoader, (data) => handleSystemSuccess(data), handleError, stopLoader);
    };

    const getGroup = (selected) => {
        LicenseService.GetInstancesByLicenseTypeId(
            selected.value,
            { customerId: customerId },
            () => startLoader('getLicenseGroups'),
            ({ data }) => {
                const options = [{ label: 'None', value: '' }];
                data?.data?.forEach((g) => {
                    options.push({
                        label: g.name,
                        value: g._id,
                        market: g.market.map((m) => ({
                            label: m,
                            value: m,
                        })),
                    });
                });
                setGroups(options);
            },
            handleError,
            () => stopLoader('getLicenseGroups')
        );
    };

    const handleGroupChange = (group, values) => {
        if (group.value) {
            setMarket(group.market);
            setSignalOption(allSignalOption);
        } else {
            setSignalOption([{ label: 'None', value: '' }]);
            setMarket([{ label: 'None', value: '' }]);
            setSelectedMarket({});
        }
    };

    const getGlobalAndCustomerSignal = () => {
        signalsService.GetGlobalAndCustomerSignals(customerId, startLoader, handleGetSignalSuccess, handleError, stopLoader);
    };

    const handleGetSignalSuccess = ({ data }) => {
        const processedSignal = [{ label: 'None', value: '' }];
        data.data.map((item) => {
            processedSignal.push({
                label: item.name,
                value: item._id,
            });
        });
        setAllSignalOption(processedSignal);
        setSignalOption(processedSignal);
    };

    useEffect(() => {
        if (selectedLicense?.value) {
            getGroup(selectedLicense);
        } else {
            setGroups([{ label: 'None', value: '' }]);
            setSignalOption([{ label: 'None', value: '' }]);
            setMarket([{ label: 'None', value: '' }]);
            setSelectedInstance({ label: 'None', value: '' });
            setSelectedMarket({ label: 'None', value: '' });
        }
    }, [selectedLicense]);

    const fillFequency = (e, setFieldValue) => {
        const selectedMarket = e;
        if (selectedMarket?.value) {
            if (selectedMarket?.value !== 'FFR') {
                setFrequencyValue(FREQUENCY_VALUES[selectedMarket?.value] || {});
                let temp = {};
                if (!Object.keys(deviceDetails?.properties?.frequency || {}).length || selectedMarket.value !== deviceDetails?.market) {
                    FREQUENCY_VALUES[selectedMarket?.value] &&
                        Object.keys(FREQUENCY_VALUES[selectedMarket?.value]).map((key) => {
                            temp[`p${key}`] = FREQUENCY_VALUES[selectedMarket?.value][key];
                        });
                    setInitialValues(_.set(initialValues, `properties.frequency`, temp));
                    setFieldValue(`properties.frequency`, temp);
                } else {
                    setInitialValues(_.set(initialValues, `properties.frequency`, storedFrequency));
                    setFieldValue(`properties.frequency`, storedFrequency);
                }
            } else {
                if (!Object.keys(deviceDetails?.properties?.frequency || {}).length || selectedMarket.value !== deviceDetails?.market) {
                    setInitialValues(
                        _.set(initialValues, `properties.frequency`, {
                            loggingThreshold: 49.7,
                            activationThreshold: 49.5,
                        })
                    );
                    setFieldValue(`properties.frequency`, {
                        loggingThreshold: 49.7,
                        activationThreshold: 49.5,
                    });
                } else {
                    setInitialValues(_.set(initialValues, `properties.frequency`, storedFrequency));
                    setFieldValue(`properties.frequency`, storedFrequency);
                }
            }
        } else {
            setFieldValue(`properties.frequency`, {});
        }
    };

    const handleUploadClick = (e) => {
        const files = e.target.files;
        uploadFiles(files);
    };

    const uploadFiles = (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        EdgeService.UploadFiles(formData, startLoader, handleUploadFilesSuccess, handleError, stopLoader);
    };

    const handleUploadFilesSuccess = ({ data }) => {
        setFiles((prev) => [...prev, ...data.data]);
    };

    const removeFile = (key) => {
        const temp = files.filter((e) => e.key !== key);
        setFiles(temp);
    };

    const handleGetSignedUrl = (key) => {
        EdgeService.GetUploadedFileSignedURL({ key, type: 'edgeFile' }, startLoader, handleFileSignedURLSuccess, handleError, stopLoader);
    };

    const handleFileSignedURLSuccess = ({ data }) => {
        if (data.data?.signedUrl) {
            window.open(data.data.signedUrl, '_blank');
        }
    };

    return (
        <div className={isFullScreen.status ? edgeClasses.AddModalFullScreen : edgeClasses.AddModal}>
            {!isFullScreen.status && <Typography content={`${deviceDetails?._id && createDuplicate ? 'Create' : deviceDetails?._id ? 'Edit' : 'Create'} Device`} size="16" />}

            <div className={classes.FormContainer}>
                <Formik enableReinitialize={true} initialValues={initialValues} onSubmit={handleSubmit} validationSchema={EdgeDevice}>
                    {({ values, setFieldValue, ...props }) => {
                        return (
                            <Form>
                                {!isFullScreen.status ? (
                                    <>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="edgeAlias">
                                                    Edge Alias <span className="required">*</span>
                                                </label>
                                                <Input name="edgeAlias" id="edgeAlias" type="text" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label
                                                    for="secrets"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <span>Secrets </span>
                                                    <UilExpandArrows
                                                        size={'1vw'}
                                                        style={{
                                                            color: 'var(--color-primary)',
                                                            cursor: 'pointer',
                                                            zIndex: '1',
                                                        }}
                                                        onClick={() => {
                                                            setFullScreen({
                                                                status: true,
                                                                editor: 0,
                                                            });
                                                        }}
                                                    />
                                                </label>

                                                <NewJSONEditor name="secrets" defaultValue={values.secrets} height="15rem" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="systemId">
                                                    System
                                                    <span className="required"> * </span>
                                                </label>
                                                <Dropdown name="systemId" options={systems} defaultValue={{ value: initialValues.systemId }} />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="licenseId">License</label>
                                                <Dropdown
                                                    name="licenseId"
                                                    options={license}
                                                    defaultValue={selectedLicense}
                                                    onChange={(e) => {
                                                        setSelectedLicense(e);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="groupId">Group</label>
                                                <Dropdown
                                                    name="groupId"
                                                    options={groups}
                                                    defaultValue={selectedInstance}
                                                    onChange={(e) => {
                                                        handleGroupChange(e, values);
                                                        setSelectedInstance(e);
                                                        setFieldValue('market', '');
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="market">Market</label>
                                                <Dropdown
                                                    name="market"
                                                    options={market}
                                                    defaultValue={selectedMarket}
                                                    onChange={(e) => {
                                                        setSelectedMarket(e);
                                                        fillFequency(e, setFieldValue);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="licenseId">Signal</label>
                                                <Dropdown
                                                    name="properties.signalId"
                                                    options={signalOption}
                                                    defaultValue={{
                                                        value: values?.properties?.signalId || '',
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <div style={{ display: 'flex', marginTop: '2vh' }}>
                                                    <div className={classes.FieldControl}>
                                                        <ToggleButton values={values} label={'Monitoring'} name={`properties.notificationConfig.master`} />
                                                    </div>
                                                    <div className={classes.FieldControl}>
                                                        <ToggleButton values={values} label={'Disable (Edge)'} name={`disabled`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {values.market && (
                                            <div className={classes.FlexBox}>
                                                {values.market != 'FFR' ? (
                                                    <>
                                                        <div>
                                                            <div className={classes.FieldControl3}>
                                                                <label for="p100a">100a Frequency</label>
                                                                <Input name={`properties.frequency.p100a`} id="p100a" type="number" />
                                                            </div>
                                                        </div>

                                                        {Object.keys(frequencyValue).map(
                                                            (item) =>
                                                                item !== '100a' && (
                                                                    <>
                                                                        <div>
                                                                            <div className={classes.FieldControl3}>
                                                                                <label for={item}>{item} Frequency</label>
                                                                                <Input name={`properties.frequency.p${item}`} id={item} type="number" />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        <div>
                                                            <div className={classes.FieldControl3}>
                                                                <label>Logging Threshold Frequency </label>
                                                                <Input name={`properties.frequency.loggingThreshold`} id="threshold" type="number" />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className={classes.FieldControl3}>
                                                                <label>Activation Threshold Frequency</label>
                                                                <Input name={`properties.frequency.activationThreshold`} id="threshold" type="number" />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="maxDuration">
                                                    Max Duration (Seconds) <span className="required">*</span>
                                                </label>
                                                <Input name="properties.maxDuration" type="number" />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="startDelay">
                                                    Start Delay (Seconds) <span className="required">*</span>
                                                </label>
                                                <Input name="properties.startDelay" type="number" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="controlProfile">Control Profile</label>
                                                <Dropdown
                                                    name="controlProfile"
                                                    options={edgeProfileOptions.controlProfile}
                                                    defaultValue={{}}
                                                    onChange={(e) => {
                                                        setUpdateDefaultValueEditor(true);
                                                        const temp = {
                                                            ...values.properties.edgeConfiguration,
                                                            controlProfile: e.value,
                                                        };
                                                        setFieldValue('properties.edgeConfiguration', temp);
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="meterProfile">Meter Profile</label>
                                                <Dropdown
                                                    name="meterProfile"
                                                    options={edgeProfileOptions.meterProfile}
                                                    defaultValue={{}}
                                                    onChange={(e) => {
                                                        setUpdateDefaultValueEditor(true);
                                                        const temp = {
                                                            ...values.properties.edgeConfiguration,
                                                            meterProfile: e.value,
                                                        };
                                                        setFieldValue('properties.edgeConfiguration', temp);
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="controllerProfile">Controller Profile</label>
                                                <Dropdown
                                                    name="controllerProfile"
                                                    options={edgeProfileOptions.controllerProfile}
                                                    defaultValue={{}}
                                                    onChange={(e) => {
                                                        setUpdateDefaultValueEditor(true);
                                                        const temp = {
                                                            ...values.properties.edgeConfiguration,
                                                            controllerProfile: e.value,
                                                        };
                                                        setFieldValue('properties.edgeConfiguration', temp);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2} style={{ marginTop: '1vh' }}>
                                                <label
                                                    for="edgeConfiguration"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <span>Edge Configuration</span>

                                                    <UilExpandArrows
                                                        size={'1vw'}
                                                        style={{
                                                            color: 'var(--color-primary)',
                                                            cursor: 'pointer',
                                                            zIndex: '1',
                                                        }}
                                                        onClick={() => {
                                                            setFullScreen({
                                                                status: true,
                                                                editor: 1,
                                                            });
                                                        }}
                                                    />
                                                </label>
                                                <NewJSONEditor
                                                    name="properties.edgeConfiguration"
                                                    defaultValue={values.properties.edgeConfiguration}
                                                    height="15rem"
                                                    manualDefaultValueUpdate={updateDefaultValueEditor}
                                                    setManualDefaultValueUpdate={setUpdateDefaultValueEditor}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="profile">Profiler Properties</label>
                                                <div>
                                                    <div className={classes.InputContainer2}>
                                                        <div className={classes.FieldControl2}>
                                                            <label for="profile">Total Capacity</label>
                                                            <Input name={`properties.profile.totalCapacity`} type="number" />
                                                        </div>
                                                        <div className={classes.FieldControl2}>
                                                            <label for="profile">CT Factor</label>
                                                            <Input name={`properties.profile.ctFactor`} type="number" />
                                                        </div>
                                                    </div>

                                                    <div className={classes.InputContainer2}>
                                                        <div className={classes.FieldControl2}>
                                                            <label for="codeVersion">Code Version</label>
                                                            <Input name={`properties.codeVersion`} id="properties.codeVersion" />
                                                        </div>
                                                        <div className={classes.FieldControl2}>
                                                            <div style={{ display: 'flex', marginTop: '2vh' }}>
                                                                <div className={classes.FieldControl}>
                                                                    <ToggleButton values={values} label={'Profile'} name={`properties.profile.enabled`} />
                                                                </div>
                                                                <div className={classes.FieldControl}>
                                                                    <ToggleButton values={values} label={'Base Power'} name={`properties.profile.basePower`} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="notes">Notes</label>
                                                <TextArea name={`properties.notes`} id="notes" type="text" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2} style={{ width: '99.7%' }}>
                                                <label for="profile">Files</label>
                                                <div>
                                                    <MultipleFilesUpload name="uploadFiles" id="uploadFiles" accept="*" multiple onChange={handleUploadClick} />
                                                </div>
                                            </div>
                                        </div>
                                        {files?.length ? (
                                            <div className={edgeClasses.UploadedEdgeFiles}>
                                                {files.map((item, index) => (
                                                    <div>
                                                        <div className={edgeClasses.SelectedFile}>
                                                            <div key={index}>
                                                                <p>{item.name}</p>
                                                            </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className={edgeClasses.CloseIcon} onClick={() => handleGetSignedUrl(item.key)}>
                                                                    <UilFileDownload
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                        }}
                                                                        size={'1.3vw'}
                                                                    />
                                                                </div>
                                                                <div onClick={() => removeFile(item.key)} className={edgeClasses.CloseIcon}>
                                                                    <UilTimesCircle
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                        }}
                                                                        size={'1.3vw'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className={classes.ButtonContainer} style={{ marginBottom: '2vh' }}>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {isFullScreen.editor === 0 ? (
                                            <div className={classes.InputContainer2}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="secrets">Secrets</label>
                                                    <NewJSONEditor name="secrets" defaultValue={values.secrets} height="80vh" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className={classes.InputContainer2}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="edgeConfiguration">Edge Configuration</label>
                                                    <NewJSONEditor name="properties.edgeConfiguration" defaultValue={values.properties.edgeConfiguration} height="80vh" />
                                                </div>
                                            </div>
                                        )}

                                        <div className={classes.ButtonContainer} style={{ marginBottom: '2vh' }}>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setFullScreen({
                                                            status: false,
                                                            editor: 0,
                                                        });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default Device;
