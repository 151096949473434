// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { UilInfoCircle } from '@iconscout/react-unicons';

// Internal module imports
import classes from '../Economics.module.css';
import Typography from '../../../components/Typography/Typography';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import Table from '../../../components/Table/Table';
import { Capitalize } from '../../../utils/stringHelper';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import { CustomerService } from '../../../services/CustomerService';
import { useLoader } from '../../../hooks';
import { EconomicsService } from '../../../services/EconomicsService';
import { DropdownComponent } from '../../../components/Inputs/Input';
import DateRangePicker from '../../../components/Inputs/DateRangePicker/DateRangePicker';
import BiddingInfoContent from '../ModalComponent/Info';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';

const LIMIT = 15;

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const granularityOptions = [
    { label: 'Hours', value: 'hours' },
    { label: 'Days', value: 'days' },
];

const Details = ({ updateQueryParams, queryParamsData = {} }) => {
    const [startLoader, stopLoader] = useLoader();
    const date = new Date();
    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });
    const [tableData, setTableData] = useState([]);
    const [excelData, setExcelData] = useState([]);
    const [TotalRevenue, setTotalRevenue] = useState(0);
    const [hourlyModal, setHourlyModal] = useState({
        status: false,
        data: {},
    });
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState(queryParamsData?.customer ? queryParamsData.customer : {});
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [granularity, setGranularity] = useState(granularityOptions[0]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    useEffect(() => {
        fetchTableData(true);
        setPage(0);
    }, [customer]);

    useEffect(() => {
        fetchTableData();
    }, [skip]);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, handleFetchSucess, handleError, stopLoader);
    };

    const handleFetchSucess = ({ data }) => {
        const customerOptions = getCustomerOptions(data.data);
        setCustomers(customerOptions);
        setCustomer(customerOptions[0]);
    };

    const fetchTableData = (skipNone = false) => {
        if (customer.value) {
            const processedFilter = {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                customerId: customer.value,
                limit: LIMIT,
                skip: skipNone ? 0 : skip,
                granularity: granularity?.value,
            };

            EconomicsService.Read(processedFilter, startLoader, handleEconomicDataSuccess, handleError, stopLoader);
        }
    };

    const handleEconomicDataSuccess = ({ data }) => {
        const newData = [...data.data.exportData];
        setExcelData(newData);
        const processedData = data.data.data.map((item) => {
            return {
                ...item,
                date: moment(item.date).format('DD-MM-YYYY'),
                capacity: parseFloat(item.capacity).toLocaleString('da-DK'),
                partner: parseFloat(item.partner).toLocaleString('da-DK'),
                source: Capitalize(item.source),
                productType: item?.productType || '--',
                ...(item?.modalData !== '--'
                    ? {
                          info: (
                              <CustomTooltip content={'Bidding Info'}>
                                  <div
                                      style={{ display: 'flex', justifyContent: 'center' }}
                                      onClick={() => {
                                          setHourlyModal({
                                              status: true,
                                              data: {
                                                  capacity: {
                                                      overall: item.modalData.capacity,
                                                      panicHours: item.modalData.panicHours,
                                                  },
                                                  price: {
                                                      overall: item.modalData.price,
                                                  },
                                                  hourlyData: {
                                                      bidStatus: item.modalData.bidStatus,
                                                  },
                                              },
                                          });
                                      }}
                                  >
                                      <div>
                                          <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                      </div>
                                  </div>
                              </CustomTooltip>
                          ),
                      }
                    : { info: '--' }),
            };
        });
        setTableData(processedData);
        setTotalRevenue(Math.round(data.data.totalRevenue).toLocaleString('da'));
        setTotalCount(data.data.count);
    };

    const handleDownloadExcel = () => {
        let processExcelData = excelData.map((item) => ({
            date: moment(item.date).format('DD.MM.YYYY'),
            slot: item.timeSlot || '--',
            customer: item.customer,
            group: item.instance,
            productName: item.productName,
            productType: item?.productType || '--',
            capacity: Number(parseFloat(item.capacity).toFixed(6)),
            partner: Number(parseFloat(item.partner).toFixed(6)),
            source: item.source,
            type: item.type,
        }));
        processExcelData.push({
            // date:null,
            productName: 'Total',
            partner: TotalRevenue,
            type: 'Euro',
        });
        processExcelData.length > 1 &&
            DownloadAsExcel(processExcelData, `Economics ${moment(dateRange.startDate).format('YYYY-MM-DD')}-${moment(dateRange.endDate).format('YYYY-MM-DD')}`, [
                'Date',
                'Slot',
                'Customer',
                'Group',
                'Product Name',
                'Product Type',
                'Capacity (MW)',
                'Revenue (€)',
                'Source',
                'Type',
            ]);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.Details}>
            <div className={classes.FilterContainer}>
                <div className={classes.DropdownContainer}>
                    {/* <Typography content='FCR Bids' /> */}
                    <div className={classes.TableCount}>
                        Total Revenue :
                        <span>
                            <Typography size="14" content={TotalRevenue + ' €'} />
                        </span>
                    </div>
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={totalCount || 0} />
                        </span>
                    </div>
                    <div style={{ width: '9vw' }}>
                        <DropdownComponent
                            name="customerId"
                            options={customers}
                            defaultValue={queryParamsData?.customer ? queryParamsData.customer : customer}
                            onChange={(e) => {
                                setCustomer(e);
                                updateQueryParams('customer', JSON.stringify(e));
                            }}
                        />
                    </div>
                </div>
                <div className={classes.DatePickers}>
                    <div className={classes.DropDnContainer}>
                        <label>Granularity</label>
                        <DropdownComponent
                            name="granularity"
                            options={granularityOptions}
                            defaultValue={granularity}
                            onChange={(e) => {
                                if (e.value !== granularity?.value) {
                                    setGranularity(e);
                                }
                            }}
                        />
                    </div>

                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.DateLabels}>Start Date</div>
                            <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                End Date
                            </div>
                        </div>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(date) => {
                                setDateRange({
                                    startDate: new Date(date.startDate),
                                    endDate: new Date(date.endDate),
                                });
                                updateQueryParams('dateRange', JSON.stringify(date));
                            }}
                        />
                    </div>
                    <div
                        className={classes.SubmitButton}
                        onClick={() => {
                            fetchTableData(true);
                            setPage(0);
                        }}
                    >
                        <button className="btn-primary" style={{ marginTop: '1.2vw' }}>
                            Submit
                        </button>
                    </div>
                    <div style={{ marginTop: '1.2vw' }}>
                        <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} />
                    </div>
                </div>
            </div>
            <Table
                head={[
                    'Date',
                    'Timeslot',
                    'Customer',
                    'Group',
                    'Product Name',
                    'Product Type',
                    'Capacity (MW)',
                    'Revenue (€)',
                    // "Source",
                    'Type',
                    'Info',
                ]}
                keys={[
                    'date',
                    'timeSlot',
                    'customer',
                    'instance',
                    'productName',
                    'productType',
                    'capacity',
                    'partner',
                    // "source",
                    'type',
                    'info',
                ]}
                data={[...tableData]}
                page={page}
                Pagination={true}
                limit={LIMIT}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
            <ModalComponent
                isOpen={hourlyModal.status}
                setOpen={() => {
                    setHourlyModal({ status: false, data: {} });
                }}
            >
                <BiddingInfoContent data={hourlyModal.data} setOpen={setHourlyModal} />
            </ModalComponent>
        </div>
    );
};

export default Details;
