import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/signalTypes',
    update: '/root/signalTypes',
    delete: '/root/signalTypes',
    edit: '/root/signalTypes',
};

const createSignalType = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();

    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

// deleting a signalType accepting id as param
const deleteSignalType = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};
// edit a signalType accepting id as param
const editSignalType = (id, data, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.edit}/${id}`, data).then(callback).catch(error).finally(next);
};

export const signalTypesServiceForRoot = {
    ReadAll,
    deleteSignalType,
    createSignalType,
    editSignalType,
};
