// Standard library Imports
import React, { useEffect, useState } from 'react';

// CSS Imports
import classes from '../../../../styles/Bids.module.css';

// External Librar Imports
import { mean as LodashMean, minBy as LodashMin } from 'lodash';
import { maxBy as LodashMax } from 'lodash';
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import { UilTrash, UilCopy, UilCapture } from '@iconscout/react-unicons';
import { UilInfoCircle, UilImport, UilBolt, UilEuro } from '@iconscout/react-unicons';
// Internal Module Imports
import Typography from '../../../../components/Typography/Typography';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import Table from '../../../../components/Table/Table';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import moment from 'moment-timezone';
import AlertModal from '../../../../components/AlertModal/AlertModal';
import BidContent from './ModalContent/BidContent';
import BiddingInfoContent from './ModalContent/BiddingInfoContent';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import Status from './ModalContent/Status';
import { useLoader } from '../../../../hooks';
import { DCPlannerBidService } from '../../../../services/DCPlannerBidService';
import { CustomerService } from '../../../../services/CustomerService';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { dkCurrencyFormat } from '../../../../utils/currencyHelper';
import { euToUsCurrencyFormat } from '../../../../utils/currencyHelper';
import { getArrayOfDatesForDateRange } from '../../../../utils/dateHelper';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { Capitalize } from '../../../../utils/stringHelper';
import DateRangePicker from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import Dropdown from '../../../../components/Inputs/Dropdown';

const Bids = ({ updateQueryParams, queryParamsData = {} }) => {
    const date = new Date();
    const [startLoader, stopLoader] = useLoader();
    const [statusData, setStatusData] = useState({});
    const [rawCustomers, setRawCustomers] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState(
        queryParamsData?.customer
            ? queryParamsData.customer
            : {
                  value: '',
                  label: '',
              }
    );

    const [customerLicenses, setCustomerLicenses] = useState([]);
    const [selectedLicense, setSelectedLicense] = useState(queryParamsData?.license ? queryParamsData.license : {});
    const [capacityModal, setCapacityModal] = useState(false);
    const [priceModal, setPriceModal] = useState({ status: false, data: {} });
    const [hourlyModal, setHourlyModal] = useState(false);
    const [biddingInfoModal, setBiddingInfoModal] = useState(false);

    const [bidsModal, setBidsModal] = useState(queryParamsData.bidModal ? queryParamsData.bidModal : false);
    const [alertModal, setAlertModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);
    const [downloadHourData, setDownloadHourData] = useState({});

    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.startDate) : new Date(date.getFullYear(), date.getMonth(), 1),
        endDate: queryParamsData?.dateRange ? new Date(queryParamsData.dateRange.endDate) : new Date(date.getFullYear(), date.getMonth() + 1, 0),
    });

    const [bids, setBids] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [totalRevenue, setTotalRevenue] = useState(0);

    const [deleteModal, setDeleteModal] = useState({
        status: false,
        bidId: '',
    });

    const [createBidDate, setCreateBidDate] = useState(queryParamsData.bidDate ? queryParamsData.bidDate : '');
    const [plantUnits, setPlantUnits] = useState([]);
    const [selectedPlantUnits, setSelectedPlantUnits] = useState(queryParamsData?.groups ? queryParamsData.groups : {});
    const [plantId, setPlantId] = useState('');
    const [unitId, setUnitId] = useState('');

    useEffect(() => {
        fetchMarketEnabledGroups();
    }, []);

    useEffect(() => {
        if (customers.length) {
            setSelectCustomer(customers[0]);
        }
    }, [customers]);

    useEffect(() => {
        if (selectCustomer.value && Object.keys(rawCustomers).length) {
            const customer = rawCustomers[selectCustomer.value];
            const licenses = customer.licenses;
            const activeLicenses = Object.keys(licenses).map((id) => ({
                label: Capitalize(licenses[id].type),
                value: id,
            }));
            if (activeLicenses.length) {
                setCustomerLicenses(activeLicenses);
                const queryMatched = matchQueryParams(activeLicenses, queryParamsData.license);
                setSelectedLicense(queryMatched ? queryParamsData.license : activeLicenses[0]);
                !queryMatched && updateQueryParams('license', JSON.stringify(activeLicenses[0]));
            }
        } else {
            setCustomerLicenses([]);
            setSelectedLicense({});
        }
    }, [selectCustomer]);

    useEffect(() => {
        if (selectedLicense.value && selectCustomer.value && Object.keys(rawCustomers).length) {
            const license = rawCustomers[selectCustomer.value].licenses[selectedLicense.value];
            const groups = [];

            license.groups?.forEach((g) => {
                const pId = g.properties?.plantId;
                if (pId) {
                    g.devices?.forEach((d) => {
                        const uId = d.properties?.unitId;
                        if (uId) {
                            groups.push({
                                value: `${pId}|${uId}`,
                                label: `${g.name} | ${d.name}`,
                            });
                        }
                    });
                }
            });

            setPlantUnits(groups);
            if (groups[0]) {
                const queryMatched = matchQueryParams(groups, queryParamsData.groups);
                setSelectedPlantUnits(queryMatched ? queryParamsData.groups : groups[0]);
                !queryMatched && updateQueryParams('groups', JSON.stringify(groups[0]));
            } else {
                setSelectedPlantUnits({});
            }
        } else {
            setPlantUnits([]);
            setSelectedPlantUnits({});
        }
    }, [selectedLicense]);

    useEffect(() => {
        if (selectedPlantUnits.value) {
            const [plantId, unitId] = selectedPlantUnits.value.split('|');
            setPlantId(Number(plantId));
            setUnitId(Number(unitId));
        } else {
            setPlantId('');
            setUnitId('');
        }
    }, [selectedPlantUnits]);

    useEffect(() => {
        fetchBids();
    }, [refresh, unitId]);

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const fetchMarketEnabledGroups = () => {
        DCPlannerBidService.GetMarketEnabledGroups(startLoader, groupSuccess, handleError, stopLoader);
    };

    const groupSuccess = ({ data }) => {
        const res = data.data;
        setRawCustomers(res);
        const curCustomers = Object.keys(res);
        if (curCustomers.length) {
            const customerOptions = curCustomers.map((id) => ({
                label: res[id].name,
                value: id,
            }));
            setCustomers(customerOptions);
            if (customerOptions.length) {
                const queryMatched = matchQueryParams(customerOptions, queryParamsData.customer);
                setSelectCustomer(queryMatched ? queryParamsData.customer : customerOptions[0]);
                !queryMatched && updateQueryParams('customer', JSON.stringify(customerOptions[0]));
            }
        } else {
            toast.error('No Instances Enabled!');
            setCustomers([]);
            setSelectCustomer({
                value: '',
                label: '',
            });
        }
    };

    const fetchCustomers = () => {
        CustomerService.ReadByBidMarket('DC-PLANNER', startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        setRawCustomers(customers);
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomers(temp);
    };

    const fetchBids = () => {
        if (selectCustomer.value) {
            let params = {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
                customerId: selectCustomer.value,
                plantId,
                unitId,
            };
            DCPlannerBidService.BidData(params, startLoader, handleGetDataSuccess, handleError, stopLoader);
        }
    };

    const getEnablerRevenue = (enablers = {}, enablersData = []) => {
        let totalRevenue = 0;
        let tooltip = [];
        enablersData.map((e) => {
            for (const key in enablers) {
                if (key == e._id) {
                    totalRevenue = totalRevenue + Number(enablers[key]);
                    tooltip.push(`${e.name} : ${enablers[key].toLocaleString('da-DK')}`);
                }
            }
        });

        return (
            <CustomTooltip
                content={tooltip.map((e) => (
                    <div>{e}</div>
                ))}
            >
                {totalRevenue.toLocaleString('da-DK')}
            </CustomTooltip>
        );
    };

    const handleGetDataSuccess = (res) => {
        const { data: bidData, totalRevenue } = res.data.data;
        let processedData = bidData.map((data, index) => {
            const bid = {
                ...data,
                sc: data?.sc === '--' ? data.sc : dkCurrencyFormat(data?.sc),
                customer: !data?.isPaid ? (
                    data?.customer === '--' ? (
                        data?.customer
                    ) : (
                        dkCurrencyFormat(data?.customer)
                    )
                ) : (
                    <div>
                        <CustomTooltip
                            content={
                                <div>
                                    <div> Paid to customer. </div>
                                    {<div>Amount :{data?.customer === '--' ? data?.customer : dkCurrencyFormat(data?.customer)}</div>}
                                </div>
                            }
                        >
                            <div className={classes.Paid}>
                                <div> 0</div>
                                <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                            </div>
                        </CustomTooltip>
                    </div>
                ),

                partner: data?.partner ? dkCurrencyFormat(data?.partner) : '--',
                enablersAmount: getEnablerRevenue(data?.enablers, data.enablersData),
                avgCapacity: LodashMean(data.capacity).toFixed(2),
                timestamp: data.date,
                actions: getActions(data._id, data),
                revenue: data.revenue ? dkCurrencyFormat(Number(data?.revenue)) : 0,
            };
            if (!data?.bidStatus?.length) {
                bid.style = { background: '#E6E8FA', color: '#4C5AD9' };
            }
            if (data?.reverseBid) {
                bid.style = { background: '#fef6e5', color: '#faa500' };
            }
            return bid;
        });
        let allDates = getArrayOfDatesForDateRange(moment(processedData[processedData.length - 1]?.date).format('YYYY-MM-DD'), moment(processedData[0]?.date).format('YYYY-MM-DD'));
        allDates = allDates.reverse();
        let bidArrayWithEveryDate = [];
        processedData.length &&
            allDates.forEach((date) => {
                const existingBids = processedData.filter((bid) => bid.cetDate === date);
                if (existingBids.length > 0) {
                    bidArrayWithEveryDate = bidArrayWithEveryDate.concat(existingBids);
                } else {
                    bidArrayWithEveryDate.push({
                        dynamicallyCreated: true,
                        cetDate: date,
                        avgCapacity: '--',
                        customer: '--',
                        sc: '--',
                        partner: '--',
                        enablers: {},
                        revenue: '--',
                        status: '--',
                        style: { background: '#FFE3E3', color: '#E25657' },
                        actions: (
                            <div className={classes.ActionContainer}>
                                <CustomTooltip content={'Create Bid'}>
                                    <div
                                        className={classes.Action}
                                        onClick={() => {
                                            setCreateBidDate(date);
                                            setBidsModal(true);
                                            updateQueryParams('bidModal', true);
                                            updateQueryParams('bidDate', date);
                                        }}
                                    >
                                        <div>
                                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                        </div>
                                    </div>
                                </CustomTooltip>
                            </div>
                        ),
                    });
                }
            });
        setTotalRevenue(totalRevenue.toLocaleString('da-DK'));
        setBids(bidArrayWithEveryDate);
    };

    const getActions = (id, data) => {
        const { capacity, price, regulationPrice, bidStatus, energyBidStatus } = data;
        return (
            <div className={classes.ActionContainer}>
                <CustomTooltip content={'Bidding Info'}>
                    <div
                        className={classes.Action}
                        onClick={() => {
                            bidStatus &&
                                bidStatus.length &&
                                setHourlyModal({
                                    bidStatus,
                                    energyBidStatus,
                                    bidId: id,
                                    reverseReason: data.reverseReason,
                                    reverseBid: data?.reverseBid,
                                });
                            price?.length && setPriceModal({ data: { price, regulationPrice } });
                            setCapacityModal(capacity);
                            setBiddingInfoModal(true);
                            setDownloadHourData(data);
                        }}
                    >
                        <div>
                            <UilInfoCircle size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </div>
                </CustomTooltip>
                {!data?.reverseBid ? (
                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                        <CustomTooltip content={'Download'}>
                            <div className={classes.Action} onClick={() => handleDownloadHourData(data)}>
                                <div>
                                    <UilImport size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Bid'}>
                            <div className={classes.Action} onClick={() => handleExternalBid(id, data)}>
                                <div>
                                    <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Capacity Fetch Status'}>
                            <div className={classes.Action} onClick={() => handleCheckStatus(id, 'capacity')}>
                                <div>
                                    <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Enerygy Fetch Status'}>
                            <div className={classes.Action} onClick={() => handleCheckStatus(id, 'energy')}>
                                <div>
                                    <UilCapture size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>

                        <CustomTooltip content={'Paid to customer'}>
                            <div className={classes.Action} onClick={() => updateIsPaid(id)}>
                                <div>
                                    <UilEuro size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                    </div>
                ) : (
                    ''
                )}

                <CustomTooltip content={'Delete'}>
                    <div className={classes.Action} onClick={() => setDeleteModal({ status: true, bidId: id })}>
                        <div>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </div>
                </CustomTooltip>
            </div>
        );
    };

    const handleCheckStatus = (id, type) => {
        DCPlannerBidService.CheckBidStatus(id, { type }, startLoader, handleCheckSuccess, handleError, stopLoader);
    };

    const handleCheckSuccess = ({ data }) => {
        setRefresh(Math.random() * 3);
        setStatusData(data.data);
        setStatusModal(true);
    };

    const handleExternalBid = (id, data) => {
        const { date, price, capacity } = data;
        DCPlannerBidService.PostBid(
            id,
            {},
            { bid: { price, capacity } },
            () => startLoader('postExternal'),
            handlePostSuccess,
            handleError,
            () => stopLoader('postExternal')
        );
    };

    const handlePostSuccess = ({ data }) => {
        fetchBids();
        setStatusData(data.data);
        setStatusModal(true);
    };

    const handleDelete = (id) => {
        DCPlannerBidService.Delete(deleteModal.bidId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        fetchBids();
        toast.success('Bid Deleted');
    };

    const minMaxValues = {
        min: LodashMin(bids, 'avgCapacity') && LodashMin(bids, 'avgCapacity').avgCapacity,
        max: LodashMax(bids, 'avgCapacity') && LodashMax(bids, 'avgCapacity').avgCapacity,
    };
    const averageArray = (nums) => {
        return nums.reduce((a, b) => a + b) / nums.length;
    };
    const handleDownloadExcel = () => {
        // let processedData = LodashMap(bids, (item) => LodashPick(item, ['timestamp', 'avgCapacity', 'revenue', 'status','bidStatus']));
        let data = [];
        let totalRevenue = 0;
        let totalSC = 0;
        let totalCustomer = 0;
        let totalPartner = 0;
        let totalEnabler = 0;
        bids.map((item) => {
            if (!item.dynamicallyCreated) {
                const r = euToUsCurrencyFormat(item?.revenue);
                const s = euToUsCurrencyFormat(item?.sc);
                const c = euToUsCurrencyFormat(item?.customer);
                const p = euToUsCurrencyFormat(item?.partner);
                let e = 0;
                for (const key in item?.enablers) {
                    e += item?.enablers[key];
                }
                if (!isNaN(r)) {
                    totalRevenue += Number(r);
                }
                if (!isNaN(s)) {
                    totalSC += Number(s);
                }
                if (!isNaN(c)) {
                    totalCustomer += Number(c);
                }
                if (!isNaN(p)) {
                    totalPartner += Number(p);
                }
                if (!isNaN(e)) {
                    totalEnabler += Number(e);
                }
                data.push({
                    date: momentTimeFormater(item?.date).format('YYYY-MM-DD'),
                    capacity: Number(averageArray(item.capacity).toFixed(3)),
                    status: item.status,
                    revenue: item.revenue === null ? '--' : Number(r),
                    sc: s === '--' ? '--' : Number(s),
                    customer: c === '--' ? '--' : Number(c),
                    partner: p === '--' ? '--' : Number(p),
                    enabler: Number(e),
                });
            }
        });

        data.push({
            date: null,
            capacity: null,
            status: 'TOTAL',
            revenue: totalRevenue,
            sc: totalSC,
            customer: totalCustomer,
            partner: totalPartner,
            enablers: totalEnabler,
        });

        DownloadAsExcel(data, 'bids', ['Date (CET)', 'Capacity (MW)', 'Status (TSO)', 'Revenue (€)', 'SC (€)', 'Customer split (€)', 'Partner split (€)', 'Enablers split (€)']);
    };

    const handleDownloadHourData = (item) => {
        let data = item.capacity.map((i, index) => ({
            hour: index,
            capacity: i,
            price: item.price[index],
            hourcost: item?.bidStatus.length <= 0 ? '--' : index === 0 ? item?.bidStatus[index]?.price : (index + 1) % 4 === 0 ? item?.bidStatus[(index + 1) / 4]?.price : '--',
        }));
        DownloadAsExcel(data, `DcPlanner_${momentTimeFormater(item?.date).format('YYYY-MM-DD')}`, ['Hour', 'Capacity', 'Price', 'Settlement']);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const updateIsPaid = (id) => {
        DCPlannerBidService.UpdateIsPaid(id, startLoader, handleUpdateIsPaidSuccess, handleError, stopLoader);
    };

    const handleUpdateIsPaidSuccess = () => {
        toast.success('Bid Updated!');
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header}>
                <div>
                    <Typography content="dcPlanner Bids" />
                    <div className={classes.TableCount}>
                        Total Revenue :
                        <span>
                            <Typography size="14" content={totalRevenue + ' €'} />
                        </span>
                    </div>
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={bids.length || 0} />
                        </span>
                    </div>
                    <Formik initialValues={{ customerId: '' }}>
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form style={{ display: 'flex', gridColumnGap: '0.3vw' }}>
                                {customers.length > 0 && (
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="customerId"
                                            options={customers}
                                            defaultValue={queryParamsData?.customer ? queryParamsData.customer : selectCustomer}
                                            onChange={(e) => {
                                                setSelectCustomer(e);
                                                updateQueryParams('customer', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                )}
                                {customerLicenses.length > 0 && (
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="licenseId"
                                            options={customerLicenses}
                                            defaultValue={selectedLicense}
                                            onChange={(e) => {
                                                setSelectedLicense(e);
                                                updateQueryParams('license', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                )}
                                {plantUnits.length > 0 && (
                                    <div className={classes.DropdrownWidth}>
                                        <Dropdown
                                            name="unitId"
                                            options={plantUnits}
                                            defaultValue={queryParamsData?.groups ? queryParamsData.groups : plantUnits[0]}
                                            onChange={(e) => {
                                                if (e.value !== selectedPlantUnits?.value) {
                                                    setSelectedPlantUnits(e);
                                                }
                                                updateQueryParams('groups', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                )}
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className={classes.ButtonsContainer}>
                    <div>
                        <button
                            onClick={() => {
                                setBidsModal(true);
                                updateQueryParams('bidModal', true);
                            }}
                            style={{ width: '7vw', margin: 0 }}
                            className="btn-primary"
                        >
                            Create Bid
                        </button>
                    </div>
                </div>
            </div>
            <div className={classes.SubHeader}>
                <div className={classes.Badges}>
                    <div className={classes.BadgeContainer}>
                        <Typography content="Max Capacity" />
                        <div className={classes.TableCount}>
                            <Typography size="14" content={minMaxValues.max} />
                        </div>
                    </div>
                    <div className={classes.BadgeContainer}>
                        <Typography content="Min Capacity" />
                        <div className={classes.TableCount}>
                            <Typography size="14" content={minMaxValues.min} />
                        </div>
                    </div>
                </div>
                <div className={classes.DatePickers}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={classes.DateLabels}>Start Date</div>
                            <div className={classes.DateLabels} style={{ marginRight: '2.604vw' }}>
                                End Date
                            </div>
                        </div>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(date) => {
                                setDateRange({
                                    startDate: new Date(date.startDate),
                                    endDate: new Date(date.endDate),
                                });
                                updateQueryParams('dateRange', JSON.stringify(date));
                            }}
                        />
                    </div>
                    <div className={classes.ButtonsContainer} onClick={() => fetchBids()}>
                        <button className="btn-primary" style={{ width: '7vw', margin: 0 }}>
                            Submit
                        </button>
                        <DownloadButton size={'medium'} onClick={() => handleDownloadExcel()} style={{ margin: 0 }} />
                    </div>
                </div>
            </div>
            <Table
                head={['Date', 'Avg Capacity', 'Customer', 'Partner', 'S.C', 'Enablers', 'Revenue', 'Status', 'Action']}
                keys={['cetDate', 'avgCapacity', 'customer', 'partner', 'sc', 'enablersAmount', 'revenue', 'status', 'actions']}
                data={bids}
            />
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent
                isOpen={biddingInfoModal}
                setOpen={(status) => {
                    setBiddingInfoModal(status);
                    if (!status) {
                        setHourlyModal([]);
                        setPriceModal([]);
                        setCapacityModal([]);
                    }
                }}
            >
                <BiddingInfoContent
                    setOpen={setBiddingInfoModal}
                    hourlyModalData={hourlyModal}
                    priceModalData={priceModal.data}
                    capacityModalData={capacityModal}
                    handleDownloadHourData={handleDownloadHourData}
                    downloadHourData={downloadHourData}
                    fetchBids={fetchBids}
                />
            </ModalComponent>
            {/* <ModalComponent
        isOpen={priceModal.status}
        setOpen={(status) => setPriceModal({ status, data: {} })}
      >
        <PriceContent
          data={priceModal.data}
          setOpen={(status) => setPriceModal({ status, data: {} })}
        />
      </ModalComponent> */}
            {/* <ModalComponent isOpen={hourlyModal} setOpen={setHourlyModal}>
        <HourlyCost data={hourlyModal} setOpen={setHourlyModal} />
      </ModalComponent> */}
            <ModalComponent
                isOpen={bidsModal}
                setOpen={(status) => {
                    setBidsModal(status);
                    updateQueryParams('bidModal', status);
                    setCreateBidDate('');
                    updateQueryParams('bidDate', '');
                }}
            >
                <BidContent
                    startLoader={startLoader}
                    stopLoader={stopLoader}
                    fetchBids={fetchBids}
                    setOpen={(status) => {
                        setBidsModal(status);
                        updateQueryParams('bidModal', status);
                    }}
                    customerId={selectCustomer.value}
                    bidDate={queryParamsData.bidDate ? queryParamsData.bidDate : createBidDate}
                    plantId={plantId}
                    unitId={unitId}
                    updateQueryParams={updateQueryParams}
                />
            </ModalComponent>
            <ModalComponent isOpen={statusModal} setOpen={setStatusModal}>
                <Status data={statusData} startLoader={startLoader} stopLoader={stopLoader} setOpen={setStatusModal} />
            </ModalComponent>
            <AlertModal title={alertModal} isOpen={alertModal} content={'No Data Found!'} setOpen={setAlertModal} />
        </div>
    );
};

export default Bids;
