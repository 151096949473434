import React, { useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import Details from './Details/Details';
import classes from './Economics.module.css';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';

const Economics = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        date: queryParams.get('date') ? queryParams.get('date') : '',
        customer: queryParams.get('customer') ? queryParams.get('customer') : '',
    });
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    const tabs = [
        {
            name: 'Overview',
            component: <Details queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 1,
        },
    ];
    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Economics;
