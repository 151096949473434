import React, { useState } from 'react';
import TabsComponent from '../../../components/Tabs/Tabs';
import ErrorLogsTable from './ErrorLogs/ErrorLogs';
import TriggerLogsTable from './TriggerLogs/TriggerLogs';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';
import AccessLogsTable from './AccessLogs/AccessLogs';
import ExternalAPILogsTable from './ExternalAPILogs/ExternalAPILogs';
import EdgeAPILogsTable from './EdgeAPILogs/EdgeAPILogs';
const SystemLogs = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        type: queryParams.get('type') ? queryParams.get('type') : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        startTime: queryParams.get('startTime') ? queryParams.get('startTime') : '',
        endTime: queryParams.get('endTime') ? queryParams.get('endTime') : '',
        status: queryParams.get('status') ? Number(queryParams.get('status')) : '',
        responseTime: queryParams.get('responseTime') ? Number(queryParams.get('responseTime')) : '',
    });

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);

    let tabs = [
        {
            name: 'Error Logs',
            component: (
                <>
                    <ErrorLogsTable />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Trigger Logs',
            component: (
                <>
                    <TriggerLogsTable queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Access Logs',
            component: (
                <>
                    <AccessLogsTable queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 3,
        },
        {
            name: 'External API Logs',
            component: (
                <>
                    <ExternalAPILogsTable queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 4,
        },
        {
            name: 'Edge API Logs',
            component: (
                <>
                    <EdgeAPILogsTable queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 5,
        },
    ];
    return (
        <div>
            <div className=" ventilation">
                <TabsComponent
                    selectedIndex={selectedIndex}
                    tabs={tabs}
                    onChangeTab={(index) => {
                        setSelectedIndex(index);
                        updateQueryParams('tab', index);
                    }}
                />
            </div>
        </div>
    );
};

export default SystemLogs;
