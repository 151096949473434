import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/admin/activation-profile',
    create: '/admin/activation-profile',
    update: '/admin/activation-profile',
    delete: '/admin/activation-profile',
    activation: '/admin/activation-profile/activation-log',
    getInstance: '/admin/activation-profile/instance',
    activationSummaryByProfile: '/admin/summary/activation-summary',
    GetOptionsFilters: '/admin/activation-profile/filters',
    getSelectedProfile: '/admin/activation-profile/selected',
    event: '/admin/activation-profile/event',
    intMeta: '/admin/activation-profile/int-meta',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const GetActivation = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.activation, { params }).then(callback).catch(error).finally(next);
};

const GetInstance = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getInstance, { params }).then(callback).catch(error).finally(next);
};

const GetActivationSummaryByProfile = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.activationSummaryByProfile}/${params.edgeId}`, {
        params,
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

const GetOptionsFilters = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.GetOptionsFilters).then(callback).catch(error).finally(next);
};

const GetSelectedProfile = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getSelectedProfile, { params }).then(callback).catch(error).finally(next);
};

export const getAllEvents = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.event, { params }).then(callback).catch(error).finally(next);
};

export const getDataLog = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.event}/${id}/data-log`, { params }).then(callback).catch(error).finally(next);
};

export const getAllIntMeta = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.intMeta, { params }).then(callback).catch(error).finally(next);
};

export const activationProfileService = {
    ReadAll,
    Create,
    Update,
    Delete,
    GetActivation,
    GetInstance,
    GetActivationSummaryByProfile,
    GetOptionsFilters,
    GetSelectedProfile,
    getAllEvents,
    getDataLog,
    getAllIntMeta,
};
