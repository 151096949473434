import { HttpClient } from '../utils/httpClient';

const PATH = {
    run: '/root/cron/',
    create: '/root/cron/create',
    readAll: '/root/cron',
    addRule: '/root/cron/rule',
    getRule: '/root/cron/rule',
    deleteRule: '/root/cron/rule',
    deleteCron: '/root/cron',
    update: '/root/cron',
    apply: '/root/cron/apply',
    run: '/root/cron/run',
    getInstance: '/root/cron/instance',
};

const RunCronByRoot = (params, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.run, {}, { params }).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const DeleteCron = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteCron}/${id}`).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const AddRule = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(PATH.addRule, payload).then(callback).catch(error).finally(next);
};

const GetRule = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getRule}/${params.id}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteRule = (id, ruleId, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteRule}/${id}`, {
        params: { ruleId: ruleId },
    })
        .then(callback)
        .catch(error)
        .finally(next);
};

const ApplyChanges = (start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.apply).then(callback).catch(error).finally(next);
};

const RunCron = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.run, payload).then(callback).catch(error).finally(next);
};

const GetInstance = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getInstance}/${id}`).then(callback).catch(error).finally(next);
};
export const CronService = {
    RunCronByRoot,
    ReadAll,
    Create,
    AddRule,
    GetRule,
    DeleteRule,
    DeleteCron,
    Update,
    ApplyChanges,
    RunCron,
    GetInstance,
};
