import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/excludedDates',
    read_all: '/admin/excludedDates',
    read: '/admin/excludedDates',
    update: '/admin/excludedDates',
    delete: '/admin/excludedDates',
};

//Post call for creating a version history
const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

//Hit for getting all version history info from API
const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

//Patch request for updating a version history (id as a param)
const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

//Delete request for deleting a version history based on id
const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

export const excludedDatesService = { Create, ReadAll, Update, Delete };
