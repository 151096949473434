//Standard library imports
import React from 'react';
//Internal module imports
import classes from '../../../styles/CapacityContent.module.css';
import Table from '../../../components/Table/Table';
import bidClasses from '../index.module.css';
import Typography from '../../../components/Typography/Typography';

const Status = ({ data, setOpen }) => {
    return (
        <div className={`${classes.CapacityContent} ${bidClasses.InfoModalContainer}`}>
            <div className={bidClasses.InfoModalHeading}>
                <Typography content={'Status'} size={14} />
            </div>
            <div className={classes.Content}>
                <div className={classes.Container}>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Response Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['name', 'value']} data={data?.bidResponse || []}></Table>
                    </div>
                    <div>
                        <div className={classes.Title} style={{ textAlign: 'center' }}>
                            Bid Status
                        </div>
                        <Table head={['Product Name', 'Status']} keys={['productName', 'status']} data={data?.bidStatus || []}></Table>
                    </div>
                </div>
            </div>
            <div className={classes.ButtonContainer}>
                <div className="btn-primary" onClick={() => setOpen({ status: false, data: {} })} style={{ minWidth: 'auto' }}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default Status;
