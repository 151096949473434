// Standard library imports
import React from 'react';
// Internal module imports
import CreateBid from '../../../Bidding/ModalContent/CreateBid';

export const CreateBidPrimary = ({ market, bidDate, customerId, fetchBids, group, setOpen }) => {
    const renderComponent = () => {
        return <CreateBid fetchBids={fetchBids} setOpen={setOpen} customerId={customerId} bidDate={bidDate} instance={group} market={market} />;
    };
    return <div>{renderComponent()}</div>;
};
