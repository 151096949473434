// Standard library imports
import React from 'react';
// Internal module imports
import classes from '../../../styles/AllDevices.module.css';
import Typography from '../../../components/Typography/Typography';

const RedirectToSignalViwerOrEdgeDashboard = ({ data, setRedirectTo, redirectToSignalviewer, selectedCustomer, setDashboard, setSelectedIndex }) => {

    const redirectToDashboard = () => {
        const edge = {
            label: data.edgeAlias,
            value: data.deviceId,
            _id: data._id,
            profile: data?.properties?.profile || {},
            frequency: data.properties?.frequency || {},
        };
        const url = `/edge?tab=4&edge=${JSON.stringify(edge)}&customer=${JSON.stringify(selectedCustomer)}&isDashboard=true`;
        window.open(url, '_blank');
    };

    return (
        <div>
            <Typography content={`Redirect to`} size="16" />

            <div className={classes.ButtonContainer} style={{ marginTop: '0' }}>
                <div>
                    <button type="button" className="btn-primary" onClick={() => redirectToSignalviewer(data)}>
                        Signal Viewer
                    </button>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn-primary"
                        onClick={() => {
                            redirectToDashboard();
                        }}
                    >
                        Edge Dashboard
                    </button>
                </div>
            </div>

            <div className={classes.ButtonContainer} style={{ marginTop: '0' }}>
                <div>
                    <button
                        type="button"
                        className="btn-secondary"
                        onClick={() => {
                            setRedirectTo({ status: false, data: {} });
                        }}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RedirectToSignalViwerOrEdgeDashboard;
