// Standard library imports
import React, { useEffect, useState } from 'react';
// Internal module imports
import TabsComponent from '../../../components/Tabs/Tabs';
import BidRequests from './BidRequests';
import MaintenanceHours from './MaintenanceHours';
import { CustomerService } from '../../../services/CustomerService';
import { useLoader } from '../../../hooks';

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const BidRequestIndex = ({ market, updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [customers, setCustomers] = useState([{}]);

    useEffect(() => {
        fetchCustomers();
    }, []);

    const handleError = (error) => {
        console.log(error);
    };

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, handleFetchSucess, handleError, stopLoader);
    };

    const handleFetchSucess = ({ data }) => {
        const customerOptions = getCustomerOptions(data.data);
        setCustomers(customerOptions);
    };

    const tabs = [
        {
            name: 'Bid requests',
            component: <BidRequests market={market} customers={customers} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
        {
            name: 'Maintenance Hours',
            component: <MaintenanceHours market={market} customers={customers} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={queryParamsData.subTab ? queryParamsData.subTab : 0}
                onChangeTab={(index) => {
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default BidRequestIndex;
