// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { toast } from 'react-toastify';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
import { UilExclamationOctagon, UilImport } from '@iconscout/react-unicons';
import { flatten } from 'flat';
import { Menu, MenuItem, SubMenu } from '@szhsin/react-menu';

// Internal module imports
import ChartComponent from '../../../../components/ApexCharts/Chart';
import { useLoader } from '../../../../hooks';
import { DownloadAsExcel, DownloadAsExcelWithBuffer } from '../../../../utils/downloadAsExcel';
import { CamelCaseToTextCase, Capitalize } from '../../../../utils/stringHelper';
// import downloadIcon from "../../../../assets/download.svg";
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import { momentTimeFormater, timeOnlyWithms } from '../../../../utils/dateHelper';
import { usToEuCurrencyFormat } from '../../../../utils/currencyHelper';
import Typography from '../../../../components/Typography/Typography';
import { DropdownComponent, InputWithOutState, ToggleButtonWithState } from '../../../../components/Inputs/Input';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import { truncateNumber } from '../../../../utils/numberHelper';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import SignalReportTable from '../ModalComponent/SignalReportTable';
import SignalError from '../SignalError/SignalError';
import PQDownload from '../ModalComponent/PQDownload';

// Css imports
import classes from '../FCRFrequency/Frequency.module.css';
import SignalViewerClasses from '../index.module.css';

const SignalViewer = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [graphData, setGraphData] = useState([]);
    const [powerData, setPowerData] = useState([]);
    const [open, setOpen] = useState(false);
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [signalType, setSignalType] = useState(queryParamsData.signalType ? JSON.parse(queryParamsData.signalType) : { label: 'Frequency', value: 'Frequency' });
    const [signalTypeOption, setSignalTypeOption] = useState([
        { label: 'Frequency', value: 'Frequency' },
        { label: 'Power Cost', value: 'Power Cost' },
        { label: 'Gas Price', value: 'Gas Price' },
        { label: 'Carbon', value: 'Carbon' },
        { label: 'Average Settlement', value: 'Average Settlement' },
        { label: 'Solar Forecast', value: 'Solar Forecast' },
    ]);
    const [signal, setSignal] = useState(queryParamsData.signal ? JSON.parse(queryParamsData.signal) : {});
    const [signalOptions, setSignalOptions] = useState([]);
    const [signalTypeMapping, setSignalTypeMapping] = useState({});
    const [lastStatus, setLastStatus] = useState('--');
    const [counterInterval, setCounterInterval] = useState(null);
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const [seconds, setSeconds] = useState(30);
    const [PQModal, setPQModal] = useState(false);
    const [market, setMarket] = useState(queryParamsData.market ? JSON.parse(queryParamsData.market) : { label: 'None', value: 'none' });
    const [frequencyAnnotations, setFrequencyAnnotations] = useState({});
    // const [yaxisVisible, setYaxisVisible] = useState({});
    const [reportOpen, setReportOpen] = useState(false);
    const [isParamsData, setIsParamsData] = useState(queryParamsData.signal ? true : false);
    // const frequencyMarketProperties = {
    //   fcr: {
    //     lineMin: 49.98,
    //     lineMax: 50.02,
    //     lowVisible: 49.8,
    //     highVisible: 50.2,
    //   },
    //   fcrD: {
    //     lineMin: 50.1,
    //     lineMax: 50.14,
    //     lowVisible: 49.8,
    //     highVisible: 50.7,
    //   },
    // };
    const marketOption = [
        {
            label: 'None',
            value: 'none',
        },
        {
            label: 'FCR',
            value: 'fcr',
        },
        {
            label: 'FCR-D-INC',
            value: 'fcrD',
        },
    ];

    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));

    const [customers, setCustomers] = useState([
        {
            label: 'Global',
            value: 'global',
        },
    ]);

    const [selectedCustomer, setSelectedCustomer] = useState({});

    const [customSignal, setCustomSiganl] = useState(queryParamsData.customSignal ? queryParamsData.customSignal : '');

    const readSignal = (params, successHandler) => {
        signalsServiceForRoot.ReadSignalDataFromSource(
            params,
            () => startLoader('read-data'),
            successHandler,
            handleError,
            () => stopLoader('read-data')
        );
    };

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        const filteredOptions = signalTypeMapping?.[signalType?.value]?.map((item) => ({
            label: item?.name,
            value: item?._id,
            dataSource: item?.dataSource,
            type: item?.type,
        }));
        setSignalOptions(filteredOptions);
    }, [signalType]);

    useEffect(() => {
        getCustomers();
    }, []);

    useEffect(() => {
        if (selectedCustomer?.value && selectedCustomer?.value !== 'CUSTOM~') {
            getSignals();
        } else if (selectedCustomer?.value == 'CUSTOM~' && customSignal) {
            let params = {
                ...(signalType?.value === 'Frequency' && {
                    start: startTime,
                    end: endTime,
                }),
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
                signalId: `CUSTOM~${customSignal}`,
                prefillData: false,
                dataDecimation: true,
            };
            readSignal(params, handleSignalDataSucess);
        }
    }, [selectedCustomer]);

    const getCustomers = () => {
        signalsServiceForRoot.GetCustomers(startLoader, handleGetCustomersSuccess, handleError, stopLoader);
    };

    const handleGetCustomersSuccess = ({ data }) => {
        let temp = [
            {
                label: 'Global',
                value: 'global',
            },
            {
                label: 'Custom',
                value: 'CUSTOM~',
            },
        ];
        data?.data?.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setCustomers(temp);
        setSelectedCustomer(queryParamsData.customer ? JSON.parse(queryParamsData.customer) : temp[0]);
    };

    const getSignals = () => {
        signalsServiceForRoot.ReadSignalByType(
            { id: selectedCustomer?.value },
            () => startLoader('getSignals'),
            handleSignalSucess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const getLastReportedData = (signalId) => {
        if (signalId) {
            signalsServiceForRoot.getLastReportedData({ signalId: signalId }, startLoader, handleLastReportedSuccess, handleError, stopLoader);
        }
    };

    const handleLastReportedSuccess = ({ data }) => {
        if (data.data) {
            setLastStatus(moment(data?.data).tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss'));
        } else {
            setLastStatus('--');
        }
    };

    const readSignalData = () => {
        let params = {
            ...(signalType?.label === 'Frequency' && {
                start: startTime,
                end: endTime,
            }),
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: selectedCustomer?.value === 'CUSTOM~' ? `CUSTOM~${customSignal}` : signal.value,
            prefillData: false,
            dataDecimation: true,
        };
        readSignal(params, handleSignalDataSucess);
        getLastReportedData(signal.value);
    };

    const handleSignalDataSucess = ({ data }) => {
        setGraphData(data.data?.data);
        setPowerData(data.data?.power);
    };

    const handleSignalSucess = ({ data }) => {
        const list = data.data.reduce((acc, x) => {
            if (!acc[x.type]) acc[x.type] = [x];
            else acc[x.type] = [...acc[x.type], x];
            return acc;
        }, {});

        if (Object.keys(list).length) {
            setSignalTypeMapping(list);
            const signalTypeOptions = Object.keys(list).map((item) => ({
                label: item,
                value: item,
            }));
            const signalOption = list[Object.keys(signalType).length ? signalType.value : signalTypeOptions[0].value].map((item) => ({
                label: item.name,
                value: item._id,
                dataSource: item.dataSource,
                type: item.type,
            }));
            setSignalOptions(signalOption);
            if (!isParamsData) {
                setSignal(signalOption[0]);
            }
            setIsParamsData(false);
        } else {
            setSignalOptions([]);
            setSignal({});
        }
    };

    useEffect(() => {
        if (signal?.value && !customSignal) {
            let params = {
                ...(signalType?.value === 'Frequency' && {
                    start: startTime,
                    end: endTime,
                }),
                date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
                signalId: signal?.value,
                prefillData: false,
                dataDecimation: true,
            };
            readSignal(params, handleSignalDataSucess);
            getLastReportedData(signal?.value);
        }
    }, [signal]);

    const handleAdvanceDownloadExcel = (rawData = []) => {
        const downloadData = rawData?.downloadable;
        let columnIndex = 0;
        let columnLength = 0;
        const flattenedData = downloadData
            .map((item) => flatten(item, { delimiter: '_' }))
            .map((item, index) => {
                const timestampCET = item.timestampCET;
                delete item.timestampCET;
                const objectLength = Object.keys(item).length;
                if (objectLength > columnLength) {
                    columnLength = objectLength;
                    columnIndex = index;
                }
                return {
                    timestampCET,
                    ...item,
                };
            });

        const columnNames = ['TIMESTAMP (CET)'];
        for (let key in flattenedData[columnIndex]) {
            if (key !== 'timestampCET') {
                columnNames.push(key.toUpperCase());
            }
        }

        flattenedData?.length > 0 &&
            DownloadAsExcel(
                flattenedData,
                `${Capitalize(signal?.dataSource)}-${Capitalize(selectedCustomer?.value === 'CUSTOM~' ? `${customSignal}` : signal?.label)}`,
                columnNames,
                columnIndex
            );

        setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const formatYAxisValue = (value) => {
        return usToEuCurrencyFormat(value, 3);
    };

    const downLoadRawData = (prefillData = true) => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: selectedCustomer?.value === 'CUSTOM~' ? `CUSTOM~${customSignal}` : signal.value,
            prefillData: prefillData,
            dataDecimation: false,
        };
        readSignal(params, handleRawSignalDataSucess);
    };
    const handleRawSignalDataSucess = ({ data }) => {
        handleAdvanceDownloadExcel(data?.data);
    };

    const autoRefreshLastReportedData = (signalId) => {
        if (signalId) {
            signalsServiceForRoot.autoRefreshLastReportedData({ signalId: signalId }, handleLastReportedSuccess, handleError);
        }
    };

    const autoRefeshData = () => {
        autoRefreshLastReportedData(signal?.value);
        setEndTime(moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'));
        const params = {
            ...(signalType?.label === 'Frequency' && {
                start: startTime,
                end: moment().tz('Europe/Berlin').add(1, 'minutes').format('HH:mm:ss'),
            }),
            date: moment(date).format('YYYY-MM-DD'),
            signalId: selectedCustomer?.value === 'CUSTOM~' ? `CUSTOM~${customSignal}` : signal.value,
            prefillData: false,
            dataDecimation: true,
        };
        signalsServiceForRoot.AutoRefreshData(params, handleSignalDataSucess, handleError);
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    autoRefeshData();
                    setSeconds(30);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    useEffect(() => {
        if (isAutoRefresh) {
            const currentTime = moment().tz('Europe/Berlin').format('HH:mm:ss');
            const curCetDate = moment().tz('Europe/Berlin');
            setDate(new Date(curCetDate.format()));
            setEndTime(currentTime);
        }
    }, [isAutoRefresh]);

    // const handleMarketChange = (value) => {
    //   if (value.value === "fcr") {
    //     setYaxisVisible({
    //       lowVisible: frequencyMarketProperties.fcr.lowVisible,
    //       highVisible: frequencyMarketProperties.fcr.highVisible,
    //     });
    //     setFrequencyAnnotations({
    //       yaxis: [
    //         {
    //           y: frequencyMarketProperties.fcr.lineMin,
    //           borderColor: "#219e4d",
    //           label: {
    //             borderColor: "#219e4d",
    //             style: {
    //               color: "#fff",
    //               background: "#219e4d",
    //               fontSize: "0.8vw",
    //             },
    //             text: String(frequencyMarketProperties.fcr.lineMin),
    //           },
    //         },
    //         {
    //           y: frequencyMarketProperties.fcr.lineMax,
    //           borderColor: "#775DD0",
    //           label: {
    //             borderColor: "#775DD0",
    //             style: {
    //               color: "#fff",
    //               background: "#775DD0",
    //               fontSize: "0.8vw",
    //             },
    //             text: String(frequencyMarketProperties.fcr.lineMax),
    //           },
    //         },
    //       ],
    //     });
    //   } else if (value.value === "fcrD") {
    //     setYaxisVisible({
    //       lowVisible: frequencyMarketProperties.fcrD.lowVisible,
    //       highVisible: frequencyMarketProperties.fcrD.highVisible,
    //     });
    //     setFrequencyAnnotations({
    //       yaxis: [
    //         {
    //           y: frequencyMarketProperties.fcrD.lineMin,
    //           borderColor: "#219e4d",
    //           label: {
    //             borderColor: "#219e4d",
    //             style: {
    //               color: "#fff",
    //               background: "#219e4d",
    //               fontSize: "0.8vw",
    //             },
    //             text: String(frequencyMarketProperties.fcrD.lineMin),
    //           },
    //         },
    //         {
    //           y: frequencyMarketProperties.fcrD.lineMax,
    //           borderColor: "#775DD0",
    //           label: {
    //             borderColor: "#775DD0",
    //             style: {
    //               color: "#fff",
    //               background: "#775DD0",
    //               fontSize: "0.8vw",
    //             },
    //             text: String(frequencyMarketProperties.fcrD.lineMax),
    //           },
    //         },
    //       ],
    //     });
    //   } else {
    //     setYaxisVisible({});
    //     setFrequencyAnnotations({
    //       yaxis: [{}],
    //     });
    //   }
    // };

    const downLoadEnergynetAudit = (type, downloadHandler) => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: signal.value,
            market: market.value,
            type: type,
        };

        signalsServiceForRoot.downLoadEnergynetAudit(
            params,
            () => startLoader('read-data'),
            downloadHandler,
            handleError,
            () => stopLoader('read-data')
        );
    };

    const handleDownloadEnergynetAudit = ({ data }) => {
        let formatedData = [];
        let formatedSimulatedData = [];
        data.data.data.map((e) => {
            let temp = {
                date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                time: e.time,
                frequency: e.frequency,
                capacitySold: e.soldCapacity,
                targetCapacity: e.targetCapacity,
                '-5%': e.minusFivePercent,
                load: e.load,
                '+20%': e.plusTwentyPercent,
                ...(!data.data?.basePower && {
                    deltaLoad: e.deltaLoad,
                    status: e.status,
                }),
            };
            if (data.data?.basePower) {
                temp.basePower = e.basePower;
                temp.deltaLoad = e.deltaLoad;
                temp.status = e.status;
            }
            formatedData.push(temp);

            if (data.data?.isSimulatedPower) {
                let simulatedTemp = {
                    date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                    time: e.time,
                    frequency: e.frequency,
                    capacitySold: e.soldCapacity,
                    targetCapacity: e.simulatedTargetCapacity,
                    '-5%': e.simulatedMinusFivePercent,
                    load: e.simulatedLoad,
                    '+20%': e.simulatedPlusTwentyPercent,
                    deltaLoad: e.deltaLoad,
                    status: e.simulatedStatus,
                };
                formatedSimulatedData.push(simulatedTemp);
            }
        });

        let headers = ['Date (CET)', 'Time (CET)', 'Frequency', 'Capacity Sold', 'Target Capacity', '-5%', 'Load', '+20% '];

        if (data.data?.basePower) {
            headers.push(...['Base Power', 'Delta Load', 'Status']);
        } else {
            headers.push(...['Delta Load', 'Status']);
        }

        data.data.data.length && DownloadAsExcel(formatedData, `${data.data?.customerName}_${data.data?.instanceName}`, headers);
        if (formatedSimulatedData.length) {
            DownloadAsExcel(formatedSimulatedData, `${data.data?.instanceName} Simulated Power`, [
                'Date (CET)',
                'Time (CET)',
                'Frequency',
                'Capacity Sold',
                'Target Capacity',
                '-5%',
                'Simulated Power',
                '+20% ',
                'Delta Load',
                'Status',
            ]);
        }
    };

    const downloadEnerginetFormatData = () => {
        const params = {
            id: signal?.value,
            start: startTime,
            end: endTime,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
        };
        signalsServiceForRoot.getEnerginetFormatData(params, startLoader, handleEnerginetFormatDataSuccess, handleError, stopLoader);
    };

    const handleEnerginetFormatDataSuccess = ({ data }) => {
        const base64String = data.data.base64String;
        const templateBase64String = data.data.templateBase64String;
        DownloadAsExcelWithBuffer(base64String, `Energinet Format - ${signal.label}`);
    };

    const handlePQFequencyDownload = () => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: signal?.value,
        };

        signalsServiceForRoot.pqFrequencyDownload(params, startLoader, handlePQFreqencySuccess, handleError, stopLoader);
    };

    const handlePQFreqencySuccess = ({ data }) => {
        let headers = ['Date', 'Time', 'VALUE_FREQUENCY'];

        data?.data?.length && DownloadAsExcel(data.data, `PQ Frequency - ${signal.label}`, headers);
    };

    return (
        <>
            <div>
                <div className={SignalViewerClasses.ParentWrapper}>
                    <ModalComponent isOpen={PQModal} setOpen={setPQModal} style={{ overflow: 'initial' }}>
                        <PQDownload setPQModal={setPQModal} startTime={startTime} endTime={endTime} signalId={signal?.value} date={date} />
                    </ModalComponent>
                    <ModalComponent isOpen={reportOpen} setOpen={setReportOpen}>
                        <SignalReportTable
                            date={date}
                            startTime={startTime}
                            endTime={endTime}
                            signal={
                                selectedCustomer?.value === 'CUSTOM~'
                                    ? {
                                        label: `${customSignal}`,
                                        value: `CUSTOM~${customSignal}`,
                                    }
                                    : signal
                            }
                        />
                    </ModalComponent>
                    <div style={{ backgroundColor: '#f4f7fc' }}>
                        <div className={SignalViewerClasses.HeaderContainer}>
                            <div className={classes.ComponentHeader}>
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '0.5vw',
                                        alignItems: 'center',
                                    }}
                                >
                                    {/* <Typography content="Signal Viewer" /> */}
                                    <div className={classes.HeaderLeft}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>Signal Type</label>
                                            <DropdownComponent
                                                defaultValue={signalType}
                                                options={signalTypeOption}
                                                onChange={(e) => {
                                                    setSignalType(e);
                                                    updateQueryParams('signalType', JSON.stringify(e));
                                                }}
                                                width={'9vw'}
                                            />
                                        </div>
                                    </div>

                                    {/* {signalType?.value === "Frequency" && (
                    <div className={classes.HeaderLeft}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label className={SignalViewerClasses.DatePickerLabel}>
                          Market
                        </label>
                        <DropdownComponent
                          defaultValue={market}
                          options={marketOption}
                          onChange={(e) => {
                            setMarket(e);
                            // handleMarketChange(e);
                            updateQueryParams("market", JSON.stringify(e));
                          }}
                        />
                      </div>
                    </div>
                  )} */}

                                    {signalType?.value === 'Frequency' && (
                                        <div className={classes.HeaderLeft}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label className={SignalViewerClasses.DatePickerLabel}>Customer</label>
                                                <DropdownComponent
                                                    defaultValue={selectedCustomer}
                                                    options={customers}
                                                    onChange={(e) => {
                                                        if (e.value !== selectedCustomer?.value) {
                                                            setSelectedCustomer(e);
                                                            setCustomSiganl('');
                                                            updateQueryParams('customSignal', '');
                                                        }
                                                        updateQueryParams('customer', JSON.stringify(e));
                                                    }}
                                                    width={'9vw'}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {selectedCustomer?.value !== 'CUSTOM~' ? (
                                        <div className={classes.HeaderLeft}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label className={SignalViewerClasses.DatePickerLabel}>Signal</label>
                                                <DropdownComponent
                                                    defaultValue={signal}
                                                    options={signalOptions}
                                                    onChange={(e) => {
                                                        if (e.value !== signal?.value) {
                                                            setSignal(e);
                                                        }
                                                        updateQueryParams('signal', JSON.stringify(e));
                                                    }}
                                                    disabled={signalOptions?.length === 0}
                                                    width={'8vw'}
                                                />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={classes.HeaderLeft}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label className={SignalViewerClasses.DatePickerLabel}>Signal Code</label>
                                                <InputWithOutState
                                                    label="Signal Code"
                                                    value={customSignal}
                                                    onChange={(e) => {
                                                        setCustomSiganl(e.target.value);
                                                        updateQueryParams('customSignal', e.target.value);
                                                    }}
                                                    style={{ marginTop: '0' }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'end',
                                        justifyContent: 'center',
                                        gap: '0.5vw',
                                    }}
                                >
                                    {signalType?.value !== 'Frequency' ? (
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <label className={SignalViewerClasses.DatePickerLabel}>Date</label>
                                            <DatePicker date={date} setDate={setDate} showArrow={true} />
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '0.5vw',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label className={SignalViewerClasses.DatePickerLabel}>Date</label>
                                                <DatePicker date={date} setDate={setDate} showArrow={true} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label className={SignalViewerClasses.DatePickerLabel}>Start Time</label>
                                                <CustomTimePicker
                                                    startTime={startTime}
                                                    setStartTime={(e) => {
                                                        setStartTime(e);
                                                        updateQueryParams('startTime', e);
                                                    }}
                                                    type="start"
                                                    maxDetail={'second'}
                                                />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <label className={SignalViewerClasses.DatePickerLabel}>End Time</label>
                                                <CustomTimePicker
                                                    endTime={endTime}
                                                    setEndTime={(e) => {
                                                        setEndTime(e);
                                                        updateQueryParams('endTime', e);
                                                    }}
                                                    type="end"
                                                    disabled={isAutoRefresh}
                                                    maxDetail={'second'}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    <div style={{ display: 'flex', gap: '0.5vw' }}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'baseline',
                                                gap: '0.5vw',
                                            }}
                                        >
                                            <button
                                                type="submit"
                                                className="btn-primary"
                                                style={{
                                                    marginTop: '0',
                                                    width: 'auto',
                                                    marginLeft: '0.5vw',
                                                }}
                                                onClick={readSignalData}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '0.5vw',
                                            }}
                                        >
                                            <UilExclamationOctagon
                                                size="1.5vw"
                                                style={{
                                                    color: 'var(--color-primary)',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => setReportOpen(true)}
                                            />
                                            <Menu
                                                menuButton={
                                                    <div>
                                                        <UilImport
                                                            size="1.5vw"
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop: '0.4vw',
                                                                color: 'var(--color-primary)',
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                direction={'bottom'}
                                                arrow={true}
                                                menuClassName={SignalViewerClasses.Menu}
                                            >
                                                <MenuItem onClick={() => downLoadRawData(false)} className={SignalViewerClasses.MenuOptions}>
                                                    <div>Raw Download</div>
                                                </MenuItem>
                                                <MenuItem onClick={() => downLoadRawData(true)} className={SignalViewerClasses.MenuOptions}>
                                                    <div>Advance Download</div>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => downLoadEnergynetAudit('energinetAudit', handleDownloadEnergynetAudit)}
                                                    className={SignalViewerClasses.MenuOptions}
                                                >
                                                    <div>Energinet Audit Download</div>
                                                </MenuItem>
                                                <MenuItem onClick={() => setPQModal(true)} className={SignalViewerClasses.MenuOptions}>
                                                    <div>PQ Download</div>
                                                </MenuItem>
                                                <MenuItem onClick={() => handlePQFequencyDownload()} className={SignalViewerClasses.MenuOptions}>
                                                    <div>PQ Frequency Download</div>
                                                </MenuItem>
                                                <MenuItem onClick={downloadEnerginetFormatData} className={SignalViewerClasses.MenuOptions}>
                                                    <div>Energinet Format</div>
                                                </MenuItem>

                                                <SubMenu className={SignalViewerClasses.MenuOptions} label={<div>Energinet Format - Template</div>}>
                                                    <div>
                                                        <MenuItem className={SignalViewerClasses.MenuOptions}>
                                                            <a target="_blank" href="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/newEnerginetTemplate.xlsx">
                                                                FCR/FFR/FCR-D
                                                            </a>
                                                        </MenuItem>
                                                    </div>
                                                    <div>
                                                        <MenuItem className={SignalViewerClasses.MenuOptions}>
                                                            <a target="_blank" href="https://truegreen-uploads.s3.eu-central-1.amazonaws.com/energinetTemplateAfrr.xlsx">
                                                                aFRR/mFRR
                                                            </a>
                                                        </MenuItem>
                                                    </div>
                                                </SubMenu>
                                            </Menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.SignalViewerClasses}>
                            <div style={{ marginBottom: '1.5vh' }}>
                                <div
                                    style={
                                        (signalType?.value === 'Frequency' && market?.value == 'none') || signalType?.value !== 'Frequency'
                                            ? { display: 'block' }
                                            : { display: 'none' }
                                    }
                                >
                                    <ChartComponent
                                        series={graphData}
                                        title={signalType?.label && signal?.label ? `${signalType?.label} - ${signal?.label}` : 'Select Signal'}
                                        type="line"
                                        curve={signalType?.value === 'Average Settlement' ? 'stepline' : "smooth"}
                                        xFormatter={timeOnlyWithms}
                                        lastReported={lastStatus}
                                        yFormatter={formatYAxisValue}
                                        chartProps={{
                                            group: 'signalLog',
                                        }}
                                        rightSideElement={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '0.5vw',
                                                }}
                                            >
                                                <ToggleButtonWithState
                                                    label={'Auto Refresh'}
                                                    value={isAutoRefresh}
                                                    style={{ marginTop: '0' }}
                                                    onChange={(e) => {
                                                        setIsAutorefresh(e.target.checked);
                                                        clearInterval(counterInterval);
                                                        setSeconds(30);
                                                    }}
                                                />
                                                {isAutoRefresh && (
                                                    <label
                                                        style={{
                                                            fontSize: '0.72vw',
                                                            fontWeight: '500',
                                                            marginLeft: '-4px',
                                                        }}
                                                    >
                                                        in {seconds} seconds
                                                    </label>
                                                )}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <div style={{ marginBottom: '1.5vh' }}>
                                <div style={signalType?.value === 'Frequency' ? { display: 'block' } : { display: 'none' }}>
                                    {
                                        <ChartComponent
                                            series={powerData}
                                            title={'Power'}
                                            type="line"
                                            xFormatter={timeOnlyWithms}
                                            lastReported={lastStatus}
                                            yFormatter={formatYAxisValue}
                                            chartProps={{
                                                group: 'signalLog',
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                            <div style={{ marginBottom: '1.5vh' }}>
                                <div style={signalType?.value === 'Frequency' && market?.value !== 'none' ? { display: 'block', height: '100%' } : { display: 'none' }}>
                                    <ChartComponent
                                        // yProps={{
                                        //   min: yaxisVisible.lowVisible && yaxisVisible.lowVisible,
                                        //   max: yaxisVisible.highVisible && yaxisVisible.highVisible,
                                        //   tickAmount: 4,
                                        // }}
                                        // annotations={frequencyAnnotations}
                                        series={graphData}
                                        title={signalType?.label && signal?.label ? `${signalType?.label} - ${signal?.label}` : 'Select Signal'}
                                        type="line"
                                        xFormatter={timeOnlyWithms}
                                        lastReported={lastStatus}
                                        yFormatter={formatYAxisValue}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignalViewer;
