import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/signals',
    readByType: '/root/signals/type',
    update: '/root/signals',
    delete: '/root/signals',
    read_dataSourceList: '/root/dataSourceList',
    readAllAdmin: '/root/signals',
    readSignalDataFromSource: '/root/signals/data',
    lastReportedData: '/root/signals/lastReported',
    signalError: '/root/signals/error',
    activationProfile: '/root/signals/activation-profile',
    getCustomers: '/root/signals/customers',
    readSignalByType: '/root/signals/read-signal-type',
    report: 'root/signals/report',
    event: 'root/signals/event',
    energynetAudit: 'root/signals/energynet-audit',
    energinetFormat: '/root/signals/energinet-format',
    pqFrequencyDownload: '/root/signals/pq-frequency',
};

const createType = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

const ReadAllByType = (type, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readByType}/${type}`).then(callback).catch(error).finally(next);
};

// deleting a signal accepting id as param
const deleteType = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};
const updateType = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const readDataSourceList = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_dataSourceList).then(callback).catch(error).finally(next);
};

const ReadAllAdmin = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAllAdmin).then(callback).catch(error).finally(next);
};

const ReadSignalDataFromSource = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readSignalDataFromSource, { params }).then(callback).catch(error).finally(next);
};

const getLastReportedData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.lastReportedData, { params }).then(callback).catch(error).finally(next);
};

const getSignalError = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.signalError, { params }).then(callback).catch(error).finally(next);
};

const getActivationProfile = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.activationProfile, { params }).then(callback).catch(error).finally(next);
};
const AutoRefreshData = (params, callback, error, next) => {
    return HttpClient.get(PATH.readSignalDataFromSource, { params }).then(callback).catch(error).finally(next);
};

const AutoRefreshActivationProfile = (params, callback, error, next) => {
    return HttpClient.get(PATH.activationProfile, { params }).then(callback).catch(error).finally(next);
};

const autoRefreshLastReportedData = (params, callback, error, next) => {
    return HttpClient.get(PATH.lastReportedData, { params }).then(callback).catch(error).finally(next);
};

const GetCustomers = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getCustomers}`).then(callback).catch(error).finally(next);
};

const ReadSignalByType = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readSignalByType, { params }).then(callback).catch(error).finally(next);
};

const getReport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.report, { params }).then(callback).catch(error).finally(next);
};

const getEvents = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.report, { params }).then(callback).catch(error).finally(next);
};

const downLoadEnergynetAudit = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.energynetAudit, { params }).then(callback).catch(error).finally(next);
};
export const getEnerginetFormatData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.energinetFormat}`, { params }).then(callback).catch(error).finally(next);
};
const pqFrequencyDownload = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.pqFrequencyDownload, { params }).then(callback).catch(error).finally(next);
};
export const signalsServiceForRoot = {
    ReadAll,
    ReadAllByType,
    deleteType,
    createType,
    readDataSourceList,
    updateType,
    ReadSignalDataFromSource,
    getLastReportedData,
    getSignalError,
    getActivationProfile,
    AutoRefreshData,
    AutoRefreshActivationProfile,
    autoRefreshLastReportedData,
    GetCustomers,
    ReadSignalByType,
    getReport,
    getEvents,
    downLoadEnergynetAudit,
    getEnerginetFormatData,
    pqFrequencyDownload,
};

export const signalsServiceAdmin = {
    RealAll: ReadAllAdmin,
};
