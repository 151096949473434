// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilPen, UilTrash } from '@iconscout/react-unicons';
// Internal module imports
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { useLoader } from '../../../../hooks';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import CreateEdit from './ModalComponent/CreateEdit';
import { RootEdgeProfileService } from '../../../../services/RootEdgeProfileService';
import { CamelCaseToTextCase } from '../../../../utils/stringHelper';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

const EdgeProfile = () => {
    const [startLoader, stopLoader] = useLoader();
    const [tableData, setTableData] = useState([]);
    const [createEditModal, setCreateEditModal] = useState({
        status: false,
        data: '',
    });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: '',
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        RootEdgeProfileService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const temp = data.data.map((item, index) => ({
            's.no': index + 1,
            name: item.name,
            type: CamelCaseToTextCase(item.type),
            action: (
                <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)', marginRight: '0.5vw' }}
                            onClick={() => {
                                setCreateEditModal({ status: true, data: item });
                            }}
                        />
                    </CustomTooltip>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: item._id })} />
                    </CustomTooltip>
                </div>
            ),
        }));

        setTableData(temp);
    };

    const handleDelete = () => {
        if (deleteModal.id) {
            RootEdgeProfileService.Delete(deleteModal.id, startLoader, handleDeleteSuccess, handleError, stopLoader);
        }
    };

    const handleDeleteSuccess = () => {
        toast.success('Profle deleted successfully');
        getData();
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={createEditModal.status} setOpen={() => {}}>
                <CreateEdit data={createEditModal.data} setCreateEditModal={setCreateEditModal} getData={getData} />
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                <div>
                    <Typography content="Edge Profiles" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{tableData.length}</span>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn-primary"
                            style={{ marginTop: '0' }}
                            onClick={() =>
                                setCreateEditModal({
                                    status: true,
                                    data: '',
                                })
                            }
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Name', 'Type', 'Action']} keys={['s.no', 'name', 'type', 'action']} data={tableData} />
            </div>
        </div>
    );
};

export default EdgeProfile;
