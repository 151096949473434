import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/enabler',
    create: '/root/enabler',
    update: '/root/enabler',
    delete: '/root/enabler',
    getCustomers: '/root/enabler/customers',
    loginAs: '/root/enabler/login-as',
    getEnabler: '/admin/enabler/',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (data, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${data.id}`, { params: data }).then(callback).catch(error).finally(next);
};

const GetCustomers = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getCustomers}`).then(callback).catch(error).finally(next);
};

const GetEnabler = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getEnabler}/${id}`).then(callback).catch(error).finally(next);
};

const LoginAs = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.loginAs}/${id}`).then(callback).catch(error).finally(next);
};

export const EnablerService = {
    ReadAll,
    Create,
    Update,
    Delete,
    GetCustomers,
    LoginAs,
};

export const AdminEnablerService = {
    GetEnabler,
};
