// Standard library imports
import React, { useContext, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { get as lodashGet } from 'lodash';

// Internal module imports
import { Input, InputFile, ToggleButton } from '../../../components/Inputs/Input';
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/CreateDevice.module.css';
import { customizationValidation } from '../../../validations/Root/customizationValidation';
import { AuthContext } from '../../../context/AuthContext';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';
import { SettingsService } from '../../../services/SettingsService';
import { UserService } from '../../../services/UserService';
import { useLoader } from '../../../hooks';
import { REMOVE_USER, SET_USER } from '../../../constants';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { GrCircleInformation } from 'react-icons/gr';
import FileUpload from '../../../components/Inputs/FileUpload/FileUpload';

const Customization = ({ setOpen }) => {
    const { state, dispatch: authDispatch } = useContext(AuthContext);
    const user = lodashGet(state, 'user');
    const [logo, setLogo] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const initialValues = {
        domain: user?.properties?.domain,
        theme: user.properties?.theme || { primaryColor: '#098074' },
        removeLogo: false,
    };
    const dispatchSetUser = (payload) => authDispatch({ type: SET_USER, payload });
    const removeUser = (payload) => authDispatch({ type: REMOVE_USER });

    const handleSubmit = (values) => {
        const formData = new FormData();
        const isLogoValid = /(jpg|png|peg|svg)$/.test(logo?.name?.slice(-4));
        if (logo.name && !isLogoValid) {
            toast.error('Please select a valid image format');
            return;
        }

        formData.append('logo', logo);
        formData.append('properties', JSON.stringify(values));
        formData.append('removeLogo', values.removeLogo);
        SettingsService.Update(formData, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = () => {
        verifyUser();
        toast.success('Settings Updated!');
    };

    const verifyUser = () => {
        UserService.Verify(startLoader, handleVerifySuccess, handleVerifyError, stopLoader);
    };

    const handleVerifySuccess = ({ data }) => {
        if (data.success) {
            dispatchSetUser({ user: data.data });
        }
    };

    const handleVerifyError = (err) => {
        removeUser();
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setLogo(file);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <div className={classes.FormContent}>
                <Typography content={`Customize Admin Portal`} />

                <Formik initialValues={initialValues} onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)} validationSchema={customizationValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div className={classes.InputContainer} style={{ gap: '1vw' }}>
                                <div className={classes.FieldControl2}>
                                    <label for="domain">
                                        Domain <span className="required">*</span>
                                    </label>
                                    <Input name="domain" id="domain" />
                                </div>
                                <div className={classes.FieldControl2}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label for="profileImage" style={{ marginRight: '0.4rem', marginLeft: '0.2rem' }}>
                                            Logo
                                        </label>
                                        <CustomTooltip content={'Try uploading Logo of size 468 x 280 - 2560 x 1440'}>
                                            <GrCircleInformation size={13} />
                                        </CustomTooltip>
                                    </div>
                                    <FileUpload name="profileImage" id="profileImage" accept="image/*" onChange={handleUploadClick} />
                                </div>
                            </div>
                            <div className={classes.InputContainer}>
                                <div className={classes.FieldControl2}>
                                    <ColorPicker name={'theme.primaryColor'} title={'Primary Color'} />
                                </div>
                            </div>
                            <div className={classes.RMLogoWrapper}>
                                {user.properties?.logo && (
                                    <>
                                        <ToggleButton values={values} label={'Remove Logo'} name={`removeLogo`} />
                                        <div className={classes.LogoWrapper}>
                                            <img src={user.properties?.logo} height={50} />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Close
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default Customization;
