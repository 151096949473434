// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/admin/documents',
    saveDocument: '/admin/documents',
    getDocument: '/admin/documents',
    uploadFiles: '/admin/documents/upload-files',
    removeFile: '/admin/documents/remove-file',
    getDetails: '/admin/documents/details',
};

const GetData = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const SaveDocument = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.saveDocument, payload).then(callback).catch(error).finally(next);
};

const GetDocument = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getDocument}/${id}`).then(callback).catch(error).finally(next);
};

const UploadFiles = (payload, callback, error) => {
    return HttpClient.post(PATH.uploadFiles, payload).then(callback).catch(error);
};
const RemoveFile = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(PATH.removeFile, payload).then(callback).catch(error).finally(next);
};

const GetFiles = (id, callback, error) => {
    return HttpClient.get(`${PATH.getDocument}/${id}`).then(callback).catch(error);
};

const GetDetails = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getDetails, { params: params }).then(callback).catch(error).finally(next);
};

export const DocumentsService = {
    GetData,
    SaveDocument,
    GetDocument,
    UploadFiles,
    RemoveFile,
    GetFiles,
    GetDetails,
};
