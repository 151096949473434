import classes from '../../../../../styles/AllDevices.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { useLoader } from '../../../../../hooks';
import { toast } from 'react-toastify';
import { SubscriptionService } from '../../../../../services/SubscriptionService';
import DeleteIcon from '../../../../../assets/bids/Delete.svg';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import { CustomerService } from '../../../../../services/CustomerService';
import { Form, Formik } from 'formik';
import { DropdownComponent, Input } from '../../../../../components/Inputs/Input';
import { CreateSubscriptionValiodator } from '../../../../../validations/Notification/addSubscriptionValidation';

function EmailSubscriptions() {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState({});
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        subscriptionId: '',
    });
    const [customers, setCustomers] = useState([]);
    const [selectCustomer, setSelectCustomer] = useState({
        value: '',
        label: '',
    });
    const [addSubModal, setAddSubModal] = useState(false);

    useEffect(() => {
        fetchCustomers();
        getData();
    }, []);

    const getData = () => {
        SubscriptionService.ReadAll(startLoader, handleFetchSuccess, handleError, stopLoader);
    };

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomers(temp);
    };

    const handleFetchSuccess = (res) => {
        let data = res.data?.data?.map((item, i) => ({
            ...item,
            's.no': i + 1,
            action: (
                <div className={classes.ActionContainer}>
                    <div className={classes.Action} onClick={() => setDeleteModal({ status: true, subscriptionId: item._id })}>
                        <div>
                            <img src={DeleteIcon} alt="" />
                        </div>
                    </div>
                </div>
            ),
        }));
        setData(data);
    };

    const handleDelete = () => {
        SubscriptionService.Delete(deleteModal.subscriptionId, startLoader, deleteSuccessfull, handleError, stopLoader);
    };

    const deleteSuccessfull = (res) => {
        toast.success('Subscription deleted successfully');
        getData();
    };

    const handleSubmit = (values, resetForm) => {
        SubscriptionService.Create(values, startLoader, (res) => handleCreateSuccess(res, resetForm), handleError, stopLoader);
    };

    const handleCreateSuccess = (res, restFrom) => {
        toast.success('Subscription created successfully');
        restFrom();
        getData();
        setAddSubModal(false);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };
    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <ModalComponent setOpen={setAddSubModal} isOpen={addSubModal}>
                <div style={{ minWidth: '25vw' }}>
                    <Typography size="14" content={'Add Subscription'} />
                    <div className={classes.FormContent}>
                        <Formik
                            initialValues={{ customerId: '', email: '', eventType: '' }}
                            validationSchema={CreateSubscriptionValiodator}
                            onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
                        >
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="profileImage">Email</label>
                                            <Input name="email" id="email" type="text" />
                                        </div>
                                    </div>

                                    {customers.length > 0 && (
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="profileImage">Customer</label>
                                                <DropdownComponent name="customerId" label="Customer ID" options={customers} />
                                            </div>
                                        </div>
                                    )}

                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label for="profileImage">Event Type</label>
                                            <DropdownComponent name="eventType" label="Event Type" options={[{ label: 'FCR', value: 'FCR' }]} />
                                        </div>
                                    </div>

                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Email Subscriptions" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={0} />
                        </span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddSubModal(true)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Email', 'Event Type', 'Customer Name', 'Action']} keys={['s.no', 'email', 'eventType', 'customerName', 'action']} data={data} />
            </div>
        </div>
    );
}

export default EmailSubscriptions;
