// External library imports
import xlsx from 'xlsx';
import moment from 'moment/moment';

export const auditDataAppender = (workbook) => {
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = xlsx.utils.sheet_to_json(sheet);
    const startTime = jsonData[0]['Time (CET)'];

    jsonData.forEach((row, index) => {
        const frequency = row.Frequency;
        const date = row['Date (CET)'];
        const time = row['Time (CET)'];
        const load = row['Load'] || row['Load'] === 0 ? row['Load'] : row['Simulated Power'] || row['Simulated Power'] === 0 ? row['Simulated Power'] : 0;
        const targetLoad = isNaN(row['Target Capacity']) ? 0 : row['Target Capacity'];
        const minusFive = isNaN(row['-5%']) ? 0 : row['-5%'];
        const plusTwenty = isNaN(row['+20%']) ? 0 : row['+20%'];
        const timeCheck = moment(startTime, 'HH:mm:ss').add(index, 'second').format('HH:mm:ss');
        const activation = frequency > 50.12 ? 1 : 0;
        const frequencyDeviation = (frequency - 50) * 1000;
        let status = '--';

        if (targetLoad) {
            if (load < minusFive) status = 'Under Delivered';
            if (load > plusTwenty) status = 'Over Delivered';
            if (minusFive <= load && load <= plusTwenty) status = 'OK';
        }

        setCellValueAndRange(sheet, `O1`, {
            t: 's',
            v: 'Timecheck',
            w: 'Timecheck',
        });
        setCellValueAndRange(sheet, `P1`, {
            t: 's',
            v: 'Activation',
            w: 'Activation',
        });
        setCellValueAndRange(sheet, `Q1`, { t: 's', v: 'Dato', w: 'Dato' });
        setCellValueAndRange(sheet, `R1`, { t: 's', v: 'Tid' });
        setCellValueAndRange(sheet, `S1`, {
            t: 's',
            v: 'ENDK frekvens (Sand) [Hz]',
        });
        setCellValueAndRange(sheet, `T1`, {
            t: 's',
            v: 'ENDK Frekvensafvigelse [mHz]',
        });

        setCellValueAndRange(sheet, `U1`, {
            t: 's',
            v: 'Frekvens afvigelse [mHz]',
        });

        setCellValueAndRange(sheet, `V1`, { t: 's', v: 'Blok effekt [MW]' });
        setCellValueAndRange(sheet, `W1`, { t: 's', v: 'Planlagt effekt [MW]' });
        setCellValueAndRange(sheet, `X1`, {
            t: 's',
            v: 'Anlægs solgte mængde [MW]',
        });
        setCellValueAndRange(sheet, `Y1`, {
            t: 's',
            v: '',
        });

        sheet[`N${index + 2}`] = { t: 's', v: status };
        sheet[`O${index + 2}`] = { t: 's', v: timeCheck };
        sheet[`P${index + 2}`] = { t: 'n', v: activation, w: activation };
        sheet[`Q${index + 2}`] = { t: 's', v: date, w: date };
        sheet[`R${index + 2}`] = { t: 's', v: time };
        sheet[`S${index + 2}`] = { t: 'n', v: frequency };
        sheet[`T${index + 2}`] = { t: 'n', v: '' };

        sheet[`U${index + 2}`] = { t: 'n', v: frequencyDeviation };
        sheet[`V${index + 2}`] = { t: 'n', v: load };
        sheet[`W${index + 2}`] = {
            t: 'n',
            v: isNaN(row['Base Power']) ? 0 : row['Base Power'],
        };
        sheet[`X${index + 2}`] = { t: 'n', v: row['Capacity Sold'] };
        sheet[`Y${index + 2}`] = { t: 'n', v: '' };
    });

    return workbook;
};

const setCellValueAndRange = (sheet, cellId, cellObject) => {
    sheet[cellId] = cellObject;

    const range = xlsx.utils.decode_range(sheet['!ref']);
    if (!range) {
        sheet['!ref'] = cellId;
    } else {
        if (range.s.r > range.e.r) {
            range.s.r = range.e.r;
        }
        if (range.s.c > range.e.c) {
            range.s.c = range.e.c;
        }
        if (range.e.r < xlsx.utils.decode_row(cellId)) {
            range.e.r = xlsx.utils.decode_row(cellId);
        }
        if (range.e.c < xlsx.utils.decode_col(cellId)) {
            range.e.c = xlsx.utils.decode_col(cellId);
        }
        sheet['!ref'] = xlsx.utils.encode_range(range);
    }
};
