// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import Dashboard from './Dashboard';
import CommandLogs from './CommandLogs';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';

const Afrr = ({ queryParamsData, updateQueryParams }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);

    let tabs = [
        {
            name: 'Dashboard',
            component: (
                <>
                    <Dashboard queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Command Logs',
            component: (
                <>
                    <CommandLogs queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 2,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default Afrr;
