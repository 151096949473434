import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';
import moment from 'moment';
import { generateHourlyTimestamps } from '../../utils/dateHelper';

const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;
const validateNumber = () => yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required);

const validateUrl = () => yup.string().matches(re, 'URL is not valid').required();

export const CreateBidValidationDynamically = (date) => {
    const timestampsForWholeDay = generateHourlyTimestamps(date, moment(date).add(1, 'day'));
    const hourlyDataValidation = {};
    timestampsForWholeDay.forEach(
        (hour) =>
            (hourlyDataValidation[hour] = yup.object().shape({
                capacity: validateNumber(),
                price: validateNumber(),
            }))
    );
    return yup.object().shape({
        hourlyData: yup.object().shape(hourlyDataValidation),
    });
};

export const CreateBidValidation = yup.object().shape({
    slot1: validateNumber(),
    slot2: validateNumber(),
    slot3: validateNumber(),
    slot4: validateNumber(),
    slot5: validateNumber(),
    slot6: validateNumber(),
    slot1Price: validateNumber(),
    slot2Price: validateNumber(),
    slot3Price: validateNumber(),
    slot4Price: validateNumber(),
    slot5Price: validateNumber(),
    slot6Price: validateNumber(),
});

const ffrSlotAndPrice = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = validateNumber();
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = validateNumber();
    });
    return slotAndPrice;
};

export const CreateFfrBidValidation = yup.object().shape({
    slotAndPrice: yup.object().shape(ffrSlotAndPrice()),
    reserveBidIdentification: yup.string().required(ERRORS.required),
});

const fcrdSlotAndPrice = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = validateNumber();
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = validateNumber();
    });
    return slotAndPrice;
};

export const CreateFcrDBidValidation = yup.object().shape({
    slotAndPrice: yup.object().shape(fcrdSlotAndPrice()),
    reserveBidIdentification: yup.string().required(ERRORS.required),
});

export const CreateFcrDMinusBidValidation = yup.object().shape({
    slotAndPrice: yup.object().shape(fcrdSlotAndPrice()),
    reserveBidIdentification: yup.string().required(ERRORS.required),
});

export const FfrResultMessageValidator = yup.object().shape({
    resultMessageId: validateNumber(),
    reserveBidIdentification: yup.string().required(ERRORS.required),
});

export const FcrDResultMessageValidator = (dMinus2BidNotAvailable) => {
    const wrapper = {
        reserveBidIdentification: yup.string().required(ERRORS.required),
        resultMessageIdDMinus1: yup.number().typeError(ERRORS.number),
    };
    wrapper.resultMessageId = dMinus2BidNotAvailable ? yup.number().typeError(ERRORS.number) : validateNumber();

    return yup.object().shape(wrapper);
};

export const FcrDMinusResultMessageValidator = (dMinus2BidNotAvailable) => {
    const wrapper = {
        reserveBidIdentification: yup.string().required(ERRORS.required),
        resultMessageIdDMinus1: yup.number().typeError(ERRORS.number),
    };
    wrapper.resultMessageId = dMinus2BidNotAvailable ? yup.number().typeError(ERRORS.number) : validateNumber();

    return yup.object().shape(wrapper);
};

export const fcrD_PercentageValidator = yup.object().shape({
    percentage: yup.number().typeError(ERRORS.number).min(1).max(100).required(),
});

export const fcrDMinus_PercentageValidator = yup.object().shape({
    percentage: yup.number().typeError(ERRORS.number).min(1).max(100).required(),
});

const dcPlannerSlotAndPrice = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = validateNumber();
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = validateNumber();
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`regulation${index + 1}Price`] = validateNumber();
    });
    return slotAndPrice;
};

export const CreateDCPlannerBidValidation = yup.object().shape({
    slotAndPrice: yup.object().shape(dcPlannerSlotAndPrice()),
    unitId: yup.number().required(),
    plantId: yup.number().required(),
});

export const FcrDDMinusOneBidValidation = (feilds) => {
    const keys = Object.keys(feilds);
    const wrapper = {};
    keys.forEach((key) => {
        wrapper[key] = validateNumber();
    });
    return yup.object().shape(wrapper);
};

export const ForceFactorValidation = yup.object().shape({
    status: yup.boolean().required(),
    value: yup.number().when('status', { is: true, then: yup.number().required() }),
});
