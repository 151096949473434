import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useLoader } from '../../../../hooks';
import { RootBidRequestService } from '../../../../services/bidRequestService';
import { toast } from 'react-toastify';
import moment from 'moment';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import Table from '../../../../components/Table/Table';
import { Capitalize } from '../../../../utils/stringHelper';
import FilterBarClasses from '../../../../styles/Filterbar.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import bidsClasses from '../../../../styles/Bids.module.css';
import { UilTrash, UilNotes } from '@iconscout/react-unicons';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import DeleteModalComponent from '../../../../components/DeleteModal/DeleteModal';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import classes from '../../../../styles/Bids.module.css';
import Typography from '../../../../components/Typography/Typography';
import { Note } from './modalComponent/Note';

export const MaintenanceHours = ({
    date,
    setDate,
    admins,
    selectedAdmin,
    setSelectedAdmin,
    customers,
    selectedCustomer,
    setSelectedCustomer,
    regulations,
    selectedRegulation,
    setSelectedRegulation,
    updateQueryParams,
}) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [noteModal, setNoteModal] = useState({ status: false, note: '' });

    useEffect(() => {
        fetchData();
    }, [selectedCustomer, selectedRegulation]);

    const fetchData = () => {
        if (selectedCustomer?.value) {
            const params = {
                market: selectedRegulation.value,
                date: moment(date).format('YYYY-MM-DD'),
                adminId: selectedAdmin.value,
                customerId: selectedCustomer.value,
            };
            RootBidRequestService.GetMaintenanceHours(params, startLoader, handleFetchSuccess, handleError, stopLoader);
        }
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data?.data)) {
            const processed = data.data.data.map((i) => ({
                ...i,
                date: momentTimeFormater(i.date).format('YYYY-MM-DD'),
                instance: i.groupId?.name,
                license: _.get(i, ['licenseId', 'name'], ''),
                customer: i.customerName,
                hoursT: i.hourSlots?.toString(),
                action: (
                    <div className={classes.ActionContainer}>
                        <CustomTooltip content="Delete">
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: i._id })} />
                        </CustomTooltip>
                        <CustomTooltip content="Note">
                            <UilNotes size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setNoteModal({ status: true, note: i?.note || '' })} />
                        </CustomTooltip>
                    </div>
                ),
            }));
            setData(processed);
        } else {
            setData([]);
        }
    };

    const handleDelete = (id) => {
        RootBidRequestService.DeleteMaintenanceHours(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Maintenance record deleted successfully!');
        fetchData();
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    return (
        <div>
            <div>
                <div className={FilterBarClasses.ParentWrapper}>
                    <div className={FilterBarClasses.Left}>
                        <div className={FilterBarClasses.Filter}>
                            <label>Admin</label>
                            <div style={{ width: '10vw' }}>
                                <DropdownComponent
                                    defaultValue={selectedAdmin}
                                    options={admins}
                                    onChange={(e) => {
                                        setSelectedAdmin(e);
                                        updateQueryParams('admin', e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={FilterBarClasses.Filter}>
                            <label>Customer</label>
                            <div style={{ width: '10vw' }}>
                                <DropdownComponent
                                    defaultValue={selectedCustomer}
                                    options={customers}
                                    onChange={(e) => {
                                        setSelectedCustomer(e);
                                        updateQueryParams('customer', e.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className={FilterBarClasses.Filter}>
                            <label>Market</label>
                            <div style={{ width: '10vw' }}>
                                <DropdownComponent
                                    defaultValue={selectedRegulation}
                                    options={regulations}
                                    onChange={(e) => {
                                        setSelectedRegulation(e);
                                        updateQueryParams('regulation', e.value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={FilterBarClasses.Right}>
                        <div className={FilterBarClasses.Filter}>
                            <label>Date</label>
                            <DatePicker
                                date={date}
                                setDate={(d) => {
                                    setDate(d);
                                    updateQueryParams('bidDate', d);
                                }}
                                showArrow={true}
                            />
                        </div>
                        <button
                            className="btn-primary"
                            style={{
                                marginTop: '0',
                                width: 'auto',
                            }}
                            onClick={fetchData}
                        >
                            Submit
                        </button>
                    </div>
                </div>
                <div className={classes.Bids}>
                    <div className={classes.Header}>
                        <div>
                            <Typography content="Maintenance hours" />
                            <div className={classes.TableCount}>
                                Total Count :
                                <span>
                                    <Typography size="14" content={data?.length || 0} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <Table
                        head={['Customer', 'License', 'Instance', 'Date', 'Hour Slots', 'Action']}
                        keys={[, 'customer', 'license', 'instance', 'date', 'hoursT', 'action']}
                        data={data}
                    />
                </div>
            </div>

            <ModalComponent
                isOpen={noteModal.status}
                setOpen={() => {
                    setNoteModal({ status: false, note: '' });
                }}
                style={{ overflowY: 'initial' }}
            >
                <Note setOpen={setNoteModal} note={noteModal.note} />
            </ModalComponent>
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};
