// Standard library imports
import React, { useState, useEffect } from 'react';

// External module imports
import { toast } from 'react-toastify';

// Internal module imports
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { useLoader } from '../../../../../hooks';
import { RootSummaryService } from '../../../../../services/RootSummaryService';
import TabsComponent from '../../../../../components/Tabs/Tabs';
import CustomerMonitoringSummary from './Customer';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import monitoringClasses from '../../Monitoring.module.css';
import { AdminService } from '../../../../../services/AdminService';

const LIMIT = 15;

const MonitoringSummary = ({ queryParamsData, updateQueryParams, admins = [] }) => {
    const [startLoader, stopLoader] = useLoader();
    const [proccessedData, setProccessedData] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : {});
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.nestedTab ? queryParamsData.nestedTab : 0);

    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState(queryParamsData.customer ? JSON.parse(queryParamsData.customer) : {});
    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [isParams, setIsParams] = useState(queryParamsData.customer ? true : false);

    useEffect(() => {
        if (!selectedAdmin?.value) {
            setSelectedAdmin(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : admins[0]);
        }
    }, [admins]);

    useEffect(() => {
        if (selectedAdmin?.value) {
            getCustomers();
        }
    }, [selectedAdmin]);

    useEffect(() => {
        if (selectedCustomer?.value && selectedAdmin?.value) {
            getData();
        }
    }, [selectedCustomer, skip]);

    const getCustomers = () => {
        AdminService.GetCustomers(selectedAdmin?.value, startLoader, handleGetCustomerSuccess, handleError, stopLoader);
    };

    const handleGetCustomerSuccess = ({ data }) => {
        let temp = [
            {
                label: 'All',
                value: 'all',
            },
        ];
        data.data.map((c) => {
            temp.push({
                label: c.name,
                value: c._id,
            });
        });
        setCustomers(temp);
        if (!isParams) {
            setSelectedCustomer(temp[0]);
        }
        setIsParams(false);
    };

    const getData = () => {
        RootSummaryService.GetToggleMonitoring(
            {
                type: 'edge',
                adminId: selectedAdmin?.value,
                customerId: selectedCustomer?.value,
                limit: LIMIT,
                skip: skip,
            },
            startLoader,
            handleSuccess,
            handleError,
            stopLoader
        );
    };
    const handleSuccess = ({ data }) => {
        const edge = data?.data.data.map((item, index) => ({
            edgeName: item.edgeAlias,
            customerName: item?.customer || '--',
            forceStatus: item?.instance?.[0]?.forceAcivationStatus?.[item.market] || '--',
            forceFactor: `${item?.instance?.[0]?.forceFactorStatus?.[item.market]?.status || '--'}/${item?.instance?.[0]?.forceFactorStatus?.[item.market]?.value || '--'}/${item?.instance?.[0]?.forceFactorStatus?.[item.market]?.adjustmentFactor || item?.instance?.[0]?.forceFactorStatus?.[item.market]?.adjustmentFactor === 0 ? item?.instance?.[0]?.forceFactorStatus?.[item.market]?.adjustmentFactor : '--'}`,
            bidStatus: item?.bidStatus,
            status: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.properties?.notificationConfig?.master ? (
                        <div className={monitoringClasses.AccessNotBlocked}></div>
                    ) : (
                        <div className={monitoringClasses.AccessBlocked}></div>
                    )}
                </div>
            ),
            heartbeat: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.properties?.notificationConfig?.heartbeat ? (
                        <div className={monitoringClasses.AccessNotBlocked}></div>
                    ) : (
                        <div className={monitoringClasses.AccessBlocked}></div>
                    )}
                </div>
            ),
            signalReporting: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.properties?.notificationConfig?.signalReporting ? (
                        <div className={monitoringClasses.AccessNotBlocked}></div>
                    ) : (
                        <div className={monitoringClasses.AccessBlocked}></div>
                    )}
                </div>
            ),
            instanceReporting: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.properties?.notificationConfig?.instanceReporting ? (
                        <div className={monitoringClasses.AccessNotBlocked}></div>
                    ) : (
                        <div className={monitoringClasses.AccessBlocked}></div>
                    )}
                </div>
            ),
            constantReporting: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.properties?.notificationConfig?.constantReporting ? (
                        <div className={monitoringClasses.AccessNotBlocked}></div>
                    ) : (
                        <div className={monitoringClasses.AccessBlocked}></div>
                    )}
                </div>
            ),
            apiCalls: (
                <div className={monitoringClasses.StatusContainer}>
                    {item.properties?.notificationConfig?.apiCalls ? (
                        <div className={monitoringClasses.AccessNotBlocked}></div>
                    ) : (
                        <div className={monitoringClasses.AccessBlocked}></div>
                    )}
                </div>
            ),
        }));

        setProccessedData(edge);
        setTotalCount(data.data.count);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    let tabs = [
        {
            name: 'Edge Monitoring',
            component: (
                <>
                    <div className={monitoringClasses.Filter}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className={monitoringClasses.FilterContainer}>
                                <div>
                                    <div className={classes.FieldControl}>
                                        <label>Select Admin</label>
                                        <DropdownComponent
                                            name="adminId"
                                            options={admins}
                                            defaultValue={selectedAdmin}
                                            onChange={(e) => {
                                                if (e.value !== selectedAdmin?.value) {
                                                    setSelectedAdmin(e);
                                                }
                                                updateQueryParams('admin', JSON.stringify(e));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label>Select Customer</label>
                                            <DropdownComponent
                                                name="customerId"
                                                options={customers}
                                                defaultValue={selectedCustomer}
                                                onChange={(e) => {
                                                    if (e.value !== selectedCustomer?.value) {
                                                        setSelectedCustomer(e);
                                                    }
                                                    updateQueryParams('customer', JSON.stringify(e));
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.AllDevices} style={{ marginBottom: '1.3vh' }}>
                        <div className={classes.Header}>
                            <div>
                                <Typography content={'Edge Monitoring'} />
                                <div className={classes.TableCount}>
                                    Total Count :
                                    <span>
                                        <Typography size="14" content={totalCount || 0} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Table
                                    head={[
                                        'Edge Alias',
                                        'Customer Name',
                                        'Monitoring',
                                        'Heartbeat',
                                        'Signal',
                                        'Instance',
                                        'Constant',
                                        'API Calls',
                                        'Bid Status',
                                        'Force Status',
                                        'Force Factor(Status/Capacity/Adjustment)',
                                    ]}
                                    keys={[
                                        'edgeName',
                                        'customerName',
                                        'status',
                                        'heartbeat',
                                        'signalReporting',
                                        'instanceReporting',
                                        'constantReporting',
                                        'apiCalls',
                                        'bidStatus',
                                        'forceStatus',
                                        'forceFactor',
                                    ]}
                                    data={proccessedData}
                                    page={page}
                                    Pagination={true}
                                    limit={LIMIT}
                                    handlePageChange={handlePageChange}
                                    totalCount={totalCount}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Customer Monitoring',
            component: (
                <>
                    <CustomerMonitoringSummary admins={admins} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
    ];

    return (
        <div>
            <div className={'ventilation'}>
                <TabsComponent
                    selectedIndex={selectedIndex}
                    tabs={tabs}
                    onChangeTab={(index) => {
                        setSelectedIndex(index);
                        updateQueryParams('nestedTab', index);
                    }}
                />
            </div>
        </div>
    );
};

export default MonitoringSummary;
