import * as yup from 'yup';

export const DataHubValidation = yup.object().shape({
    name: yup.string().required().min(5).max(64),
    priceSignal: yup.string().required('PriceSignal is required'),
    taxValue: yup
        .number()
        .typeError('only decimal or integer allowed')
        .test('is-decimal', 'only decimal or integer allowed', (value) => (value + '').match(/^[0-9]\d*(\.\d+)?$/))
        .required(),
    address: yup.string().nullable(),
});

export const AddDataHubKeyValidation = yup.object().shape({
    key: yup.string().required(),
});
