import { HttpClient } from '../utils/httpClient';

const PATH = {
    login: '/user/login',
    get_profile: '/user/profile',
    logout: '/user/logout',
    create: '/user/create',
    read: '/user',
    update: '/user',
    delete: '/user',
    verify: '/user/profile',
    requestPassword: '/user/requestPassword',
    resetPassword: '/user/reset-password',
    resendOtp: '/user/resend-otp',
    resetWithOtp: '/user/reset-with-otp',
    updatePassword: '/user/update-password',
};

const Login = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.login, payload).then(callback).catch(error).finally(next);
};
const requestPassword = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.requestPassword}`, payload).then(callback).catch(error).finally(next);
};

const Profile = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.get_profile).then(callback).catch(error).finally(next);
};

const logout = (start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.logout).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Read = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}/${id}`).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const Verify = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.verify}`).then(callback).catch(error).finally(next);
};

const resetPassword = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resetPassword}`, payload).then(callback).catch(error).finally(next);
};

const resendOtp = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resendOtp}`, payload).then(callback).catch(error).finally(next);
};

const resetWithOtp = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.resetWithOtp}`, payload).then(callback).catch(error).finally(next);
};

const updatePasswordInfo = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updatePassword}`, { ...payload })
        .then(callback)
        .catch(error)
        .finally(next);
};

export const UserService = {
    Login,
    Profile,
    logout,
    Create,
    Read,
    Update,
    Delete,
    Verify,
    requestPassword,
    resetPassword,
    resendOtp,
    resetWithOtp,
    updatePasswordInfo,
};
