import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).min(0).required(ERRORS.required);

export const BidRequestValidation = yup.object().shape({
    slot1: validateNumber(),
    slot2: validateNumber(),
    slot3: validateNumber(),
    slot4: validateNumber(),
    slot5: validateNumber(),
    slot6: validateNumber(),
    slot1Price: validateNumber(),
    slot2Price: validateNumber(),
    slot3Price: validateNumber(),
    slot4Price: validateNumber(),
    slot5Price: validateNumber(),
    slot6Price: validateNumber(),
    groupId: yup.string().required(ERRORS.required),
    biddingMarket: yup.string().required(ERRORS.required),
    startDate: yup.date().required(ERRORS.required),
    endDate: yup.date().min(yup.ref('startDate'), "end date can't be before start date").required(ERRORS.required),
    excludedDays: yup
        .array()
        .of(
            yup.object({
                date: yup.string().required(ERRORS.required),
                reason: yup.string().required(ERRORS.required),
            })
        )
        .min(0),
});
