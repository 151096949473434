import { HttpClient } from '../utils/httpClient';
import { formatStartDateStringMoment, formatEndDateStringMoment } from '../utils/timeHelper';
const PATH = {
    data: '/admin/data/fcr-d',
    getFcrBid: '/fcr-d',
    getLastFcrBid: '/admin/data/fcr-d/last',
    postBid: '/admin/data/fcr-d/createBid',
    checkBidStatus: '/admin/data/fcr-d/checkbid',
    addResultId: '/admin/data/fcr-d/addResultId',
    biddingTool: 'admin/data/fcr-d/bidding-tool',
    createReverseBid: '/admin/data/fcr-d/reverse',
    UpdatePercentage: 'admin/data/fcr-d/update-percentage',
    UpdateDMinusOne: 'admin/data/fcr-d/d-one',
    marketEnabledGroups: 'admin/data/FCR-D-INC/market-enabled-groups',
    bidRequest: '/admin/data/bid-request/FCR-D-INC',
    deleteBidRequest: '/admin/data/bid-request',
    forceActivation: '/admin/data/force-activation',
    instanceDetails: '/admin/data/intance-details',
    maintenanceHours: '/admin/data/maintenance-hours/FCR-D-INC',
    deleteMaintenanceHours: '/admin/data/maintenance-hours',
    bidsExport: '/admin/data/bids/export',
    totalCapacity: '/admin/data/bids/total-capacity',
    updateForceFactor: '/admin/data/force-factor',
};
export const formatedEndDate = (date) => {
    return `${formatEndDateStringMoment(date)}`;
};
export const formatedStartDate = (date) => {
    return `${formatStartDateStringMoment(date)}`;
};

const Create = (params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.data}`, payload, { params }).then(callback).catch(error).finally(next);
};

const PostBid = (id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBid}/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};
const fcrBidData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.data, { params }).then(callback).catch(error).finally(next);
};

const GetLastBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastFcrBid}`, { params }).then(callback).catch(error).finally(next);
};
const CheckBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.checkBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (_id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.data}?_id=${_id}`).then(callback).catch(error).finally(next);
};

const AddResultId = (id, data, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.addResultId}/${id}`, data).then(callback).catch(error).finally(next);
};

const GetBiddingToolData = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.biddingTool}`).then(callback).catch(error).finally(next);
};

const UpdatePercentage = (data, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.UpdatePercentage}`, data).then(callback).catch(error).finally(next);
};

const UpdateDMinusOne = (id, data, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.UpdateDMinusOne}/${id}`, data).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}`).then(callback).catch(error).finally(next);
};

const ForceActivation = (params, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.forceActivation}`, params).then(callback).catch(error).finally(next);
};

const GetInstanceDetails = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceDetails}/${id}`).then(callback).catch(error).finally(next);
};

const GetBidRequestForADate = (params, groupId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const GetBidRequests = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteBidRequest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBidRequest}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHours = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteMaintenanceHours = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteMaintenanceHours}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHoursForADate = (params, groupId, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}/${groupId}`, { params }).then(callback).catch(error).finally(next);
};

const BidsExport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidsExport}`, { params }).then(callback).catch(error).finally(next);
};

const BidCapacity = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.totalCapacity}`, { params }).then(callback).catch(error).finally(next);
};

const CreateReverseBid = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createReverseBid}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateForceFactor = (payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.updateForceFactor}`, payload).then(callback).catch(error).finally(next);
};

export const FcrDBidService = {
    fcrBidData,
    Create,
    Delete,
    GetLastBid,
    PostBid,
    CheckBidStatus,
    AddResultId,
    GetBiddingToolData,
    UpdatePercentage,
    UpdateDMinusOne,
    GetMarketEnabledGroups,
    GetBidRequestForADate,
    GetBidRequests,
    DeleteBidRequest,
    ForceActivation,
    GetInstanceDetails,
    GetMaintenanceHours,
    DeleteMaintenanceHours,
    GetMaintenanceHoursForADate,
    BidsExport,
    BidCapacity,
    CreateReverseBid,
    UpdateForceFactor,
};
