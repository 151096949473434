// Internal Module Imports
import { dkCurrencyFormat } from '../../../../../utils/currencyHelper';

// CSS Imports
import classes from '../../../../../styles/CapacityContent.module.css';

const PriceContent = ({ data, setOpen }) => {
    const price = data?.price || [];
    const regulationPrice = data?.regulationPrice || [];
    return (
        <div className={classes.CapacityContent} style={{ height: 'auto', width: 'auto' }}>
            <div className={classes.Title}></div>
            <div className={classes.Content}>
                <div className={classes.ContainerStart}>
                    {[0, 1, 2].map((n) => (
                        <table>
                            <tr>
                                <th>Hour</th>
                                <th>Cost</th>
                                <th>Energy</th>
                            </tr>
                            {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                key <= 23 ? (
                                    <tr>
                                        <td>{key}</td>
                                        <td>{dkCurrencyFormat(price[key])}</td>
                                        <td>{dkCurrencyFormat(regulationPrice[key])}</td>
                                    </tr>
                                ) : null
                            )}
                        </table>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PriceContent;
