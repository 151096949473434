import React, { useEffect, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import activationClasses from './ActivationProfiler.module.css';
import classes from '../../../../styles/AllDevices.module.css';
import { Form, Formik, FieldArray } from 'formik';
import { Input, ToggleButton } from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../hooks';
import { RootActivationProfileService } from '../../../../services/RootActivationProfileService';
import { activationProfileValidator } from '../../../../validations/Root/activationProfileValidator';
import { regulationsService } from '../../../../services/regulationsService';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import AddIcon from '../../../../assets/add.svg';
import RemoveIcon from '../../../../assets/remove.svg';

const getInitialValues = (editProfile) => {
    if (editProfile?._id) {
        return {
            name: editProfile.name,
            signalId: editProfile.signalId,
            instanceId: editProfile.instanceId ? editProfile.instanceId : '',
            customerId: editProfile.customerId ? editProfile.customerId : '',
            market: editProfile.market,
            totalCapacity: editProfile.totalCapacity,
            upperLimit: editProfile.upperLimit ? editProfile.upperLimit : 0,
            lowerLimit: editProfile.lowerLimit ? editProfile.lowerLimit : 0,
            ctFactor: editProfile.ctFactor ? editProfile.ctFactor : '',
            emails: editProfile.emails ? editProfile.emails : [''],
            basePower: editProfile.basePower ? editProfile.basePower : false,
        };
    } else {
        return {
            name: '',
            signalId: '',
            market: '',
            totalCapacity: '',
            upperLimit: 0,
            lowerLimit: 0,
            ctFactor: 0,
            emails: [''],
            instanceId: '',
            customerId: '',
            basePower: false,
        };
    }
};
const AddProfile = ({ setOpen, setRefresh, editProfile }) => {
    const [startLoader, stopLoader] = useLoader();
    const initialValues = getInitialValues(editProfile);
    const [deleteModal, setDeleteModal] = useState({ id: '', status: false });
    const [signal, setSignal] = useState([
        {
            label: '',
            value: '',
        },
    ]);
    const [market, setMarket] = useState([
        {
            label: '',
            value: '',
        },
    ]);
    const [selectedMarket, setSelectedMarket] = useState({});
    const [selectedSignal, setSelectedSignal] = useState({});
    const [instanceOption, setInstanceOption] = useState([]);
    const [customerOption, setCustomerOption] = useState([]);
    const [selectedInstance, setSelectedInstance] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState({});

    useEffect(() => {
        getSignals();
        getMarket();
        getCustomers();
    }, []);

    useEffect(() => {
        if (initialValues.market) {
            setSelectedMarket({
                label: initialValues.market,
                value: initialValues.market,
            });

            setSelectedSignal({
                label: initialValues.signalId.name,
                value: initialValues.signalId._id,
            });
        } else {
            setSelectedMarket({});
            setSelectedSignal({});
        }
    }, []);
    useEffect(() => {
        if (initialValues?.instanceId) {
            const filterInstance = instanceOption.find((item) => item.value === initialValues?.instanceId);
            setSelectedInstance(filterInstance);
        } else {
            setSelectedInstance({});
        }
    }, [instanceOption]);

    useEffect(() => {
        if (initialValues?.customerId) {
            const filterCustomer = customerOption.find((item) => item.value === initialValues?.customerId);
            setSelectedCustomer(filterCustomer);
        } else {
            setSelectedCustomer({});
        }
    }, [customerOption]);

    const getSignals = () => {
        signalsServiceForRoot.ReadSignalByType(
            {},
            () => startLoader('getSignals'),
            handleSignalSucess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const getMarket = () => {
        regulationsService.ReadAll(
            () => startLoader('getRegulation'),
            handleRegulationSucess,
            handleError,
            () => stopLoader('getRegulation')
        );
    };

    const getCustomers = () => {
        RootActivationProfileService.GetCustomers(
            () => startLoader('getCustomer'),
            handleCustomerSuccess,
            handleError,
            () => stopLoader('getCustomer')
        );
    };

    const handleCustomerSuccess = ({ data }) => {
        const temp = [];
        data.data.map((item) => {
            let license = [];
            for (const key in item.license) {
                if (item.license[key].enabled) {
                    license.push(item.license[key].id);
                }
            }
            temp.push({
                label: `${item.admin} | ${item.customer}`,
                value: item._id,
                license,
            });
        });
        setCustomerOption(temp);
    };

    const handleRegulationSucess = ({ data }) => {
        const temp = [];
        data?.data.map((item) => {
            temp.push({
                label: item.name,
                value: item.name,
            });
        });
        setMarket(temp);
    };

    const handleSignalSucess = ({ data }) => {
        const temp = [];
        data?.data.map((item) => {
            if (item.type === 'Frequency') {
                temp.push({
                    label: item.name,
                    value: item._id,
                });
            }
        });
        setSignal(temp);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleSubmit = (values) => {
        if (editProfile._id) {
            RootActivationProfileService.Update(
                editProfile._id,
                values,
                () => startLoader('updateProfile'),
                handleUpdateProfileSucess,
                handleError,
                () => stopLoader('updateProfile')
            );
        } else {
            RootActivationProfileService.Create(
                values,
                () => startLoader('addProfile'),
                handleAddProfileSucess,
                handleError,
                () => stopLoader('addProfile')
            );
        }
    };

    const handleAddProfileSucess = () => {
        toast.success('Profile created!');
        setRefresh(true);
        setOpen(false);
    };

    const handleUpdateProfileSucess = () => {
        toast.success('Profile updated!');
        setRefresh(true);
        setOpen(false);
    };

    const handleDelete = () => {
        RootActivationProfileService.Delete(deleteModal.id, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        setRefresh(true);
        setOpen(false);
        toast.success('Profile Deleted');
    };

    useEffect(() => {
        if (selectedCustomer?.value) {
            RootActivationProfileService.GetInstance({ license: selectedCustomer.license }, startLoader, handleGetInstanceSuccess, handleError, stopLoader);
        }
    }, [selectedCustomer]);

    const handleGetInstanceSuccess = ({ data }) => {
        let temp = [];
        data?.data.map((item) => {
            temp.push({
                label: item.name,
                value: item._id,
            });
        });
        setInstanceOption(temp);
    };

    return (
        <div>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <div style={{ height: 'auto' }}>
                <div className={activationClasses.ModalWrapper}>
                    <Typography content={editProfile._id ? 'Edit Profile' : 'Add Profile'} />
                </div>
                <div className={classes.FormContent}>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        validationSchema={activationProfileValidator}
                        onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={activationClasses.InputContainer}>
                                        <div className={activationClasses.FieldControl}>
                                            <div className={activationClasses.container}>
                                                <label className={activationClasses.label}>
                                                    Name <span className="required">*</span>
                                                </label>
                                                <Input type={'text'} name="name" />
                                            </div>
                                        </div>

                                        <div className={activationClasses.FieldControl}>
                                            <div className={activationClasses.container}>
                                                <label className={activationClasses.label}>
                                                    Select Frequency Signal
                                                    <span className="required">*</span>
                                                </label>
                                                <Dropdown name="signalId" options={signal} defaultValue={selectedSignal} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={activationClasses.InputContainer}>
                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label className={activationClasses.label}>
                                                    Select Customer
                                                    <span className="required">*</span>
                                                </label>
                                                <Dropdown name="customerId" options={customerOption} defaultValue={selectedCustomer} onChange={setSelectedCustomer} />
                                            </div>
                                        </div>

                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label className={activationClasses.label}>
                                                    Select Instance
                                                    <span className="required">*</span>
                                                </label>
                                                <Dropdown name="instanceId" options={instanceOption} defaultValue={selectedInstance} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={activationClasses.InputContainer}>
                                        <div className={activationClasses.FieldControl}>
                                            <label className={activationClasses.label}>
                                                Select Market
                                                <span className="required">*</span>
                                            </label>
                                            <Dropdown name="market" options={market} defaultValue={selectedMarket} />
                                        </div>

                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label className={activationClasses.label}>
                                                    Total Capacity <span className="required">*</span>
                                                </label>
                                                <Input type={'number'} name="totalCapacity" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={activationClasses.InputContainer}>
                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label className={activationClasses.label}>
                                                    Upper Limit <span className="required">*</span>
                                                </label>
                                                <Input type={'number'} name="upperLimit" />
                                            </div>
                                        </div>

                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label className={activationClasses.label}>
                                                    Lower Limit <span className="required">*</span>
                                                </label>
                                                <Input type={'number'} name="lowerLimit" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={activationClasses.InputContainer}>
                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label className={activationClasses.label}>
                                                    CT Factor<span className="required">*</span>
                                                </label>
                                                <Input type={'number'} name="ctFactor" />
                                            </div>
                                        </div>

                                        <div className={activationClasses.FieldControl}>
                                            <div style={{ marginTop: '2vw' }}>
                                                <ToggleButton values={values} label={'Base Power'} name="basePower" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className={activationClasses.InputContainer}>
                                        <div className={activationClasses.FieldControl}>
                                            <div>
                                                <label for="depotEmail">
                                                    Emails <span className="required">*</span>
                                                </label>
                                                <FieldArray name="emails">
                                                    {({ push, remove, form }) => {
                                                        const emails = form.values.emails;
                                                        return (
                                                            <div>
                                                                <div>
                                                                    {emails.map((email, index) => (
                                                                        <div key={index} className={activationClasses.FormContentNoSpace}>
                                                                            <Input name={`emails[${index}]`} type="email" />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className={classes.addInputActionWrapper} style={{ display: 'flex', gap: '0.5vw' }}>
                                                                    {emails.length > 1 && (
                                                                        <span onClick={() => remove(emails.length - 1)}>
                                                                            <img src={RemoveIcon} className={classes.addInputActionbutton} />
                                                                        </span>
                                                                    )}
                                                                    <span onClick={() => push('')}>
                                                                        <img src={AddIcon} className={classes.addInputActionbutton} />
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </FieldArray>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div style={{ display: 'flex', gap: '0.4vw' }}>
                                            {editProfile._id && (
                                                <button
                                                    className="btn-secondary btn-delete"
                                                    onClick={() =>
                                                        setDeleteModal({
                                                            id: editProfile._id,
                                                            status: true,
                                                        })
                                                    }
                                                    type="button"
                                                >
                                                    Delete
                                                </button>
                                            )}
                                            <button className="btn-secondary" onClick={() => setOpen(false)} type="button">
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default AddProfile;
