import React, { useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../hooks';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { Capitalize } from '../../../../utils/stringHelper';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';

export const Logs = ({ data }) => {
    const [logs, setLogs] = useState([]);
    const [startLoader, stopLoader] = useLoader();

    useEffect(() => {
        if (data?._id) {
            getLogs();
        }
    }, []);

    const getLogs = () => {
        BundleBiddingService.GetLogs(data._id, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const proccessedData = data?.data?.logs?.map((e) => ({
            timestamp: momentTimeFormater(e.timestamp).format('YYYY-MM-DD HH:mm'),
            user: e.source,
            action: Capitalize(e.action),
        }));
        setLogs(proccessedData || []);
    };
    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div style={{ maxHeight: '70vh', width: '50vw', overflow: 'auto' }}>
            <Table head={['Timestamp (CET)', 'User', 'Action']} keys={['timestamp', 'user', 'action']} data={logs} />
        </div>
    );
};
