// Standard library imports
import React from 'react';

// Internal module imports
import Typography from '../../../../../components/Typography/Typography';
import NewJSONEditor from '../../../../../components/JSONEditor/NewJSONEditor';
import { Form, Formik } from 'formik';

// Css imports
import activationClasses from '../ActivationProfiler.module.css';

const MetaLogJSONModal = ({ setOpen, log }) => {
    return (
        <div className={activationClasses.ModalContainer}>
            <div className={activationClasses.TableHeadingPaddingBottom}>
                <Typography content={'INT META Log '} />
            </div>
            <div>
                <Formik initialValues={{ log }}>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <NewJSONEditor name="log" defaultValue={log} height="30rem" />
                            </Form>
                        );
                    }}
                </Formik>
            </div>
            <div className={activationClasses.ButtonRight}>
                <div className="btn-secondary" onClick={() => setOpen({ status: false, data: '' })}>
                    Close
                </div>
            </div>
        </div>
    );
};

export default MetaLogJSONModal;
