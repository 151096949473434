import React from 'react';
import TabsComponent from '../../components/Tabs/Tabs';
import Customization from './Customization';
import classes from './index.module.css';

const Settings = ({ setOpen }) => {
    const tabs = [
        {
            name: 'Customization',
            component: <Customization setOpen={setOpen} />,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent selectedIndex={0} tabs={tabs} />
        </div>
    );
};

export default Settings;
