// Standard library imports
import React, { useState, useEffect } from 'react';

// External library import
import { UilFileDownload, UilInfoCircle } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';

// Internal module imports
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks';
import Table from '../../../components/Table/Table';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import ShippingAssetModal from '../ModalComponent/ShippingAssetModal';
import { EdgeShipmentService } from '../../../services/EdgeShipmentService';
import { CustomerService } from '../../../services/CustomerService';
import { DropdownComponent } from '../../../components/Inputs/Input';
import ShipmentInfo from './ShipmentInfo';
import { momentTimeFormater } from '../../../utils/timeHelper';

// CSS Imports
import classes from '../EdgeError/EdgeError.module.css';
import bidClasses from '../../../styles/Bids.module.css';
import EdgeClasses from '../Edge.module.css';

const LIMIT = 15;

const Shipments = ({ updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [pageTotalCount, setPageTotalCount] = useState(0);

    const [tableData, setTableData] = useState([]);
    const [assetModal, setAssetModal] = useState(queryParamsData.assetModal || false);
    const [assetOptions, setAssetOptions] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({
        value: '',
        label: '',
    });
    const [page, setPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [infoModal, setInfoModal] = useState({ status: false, data: {} });

    const packageTypeCodeOptions = [
        { label: 'Parcel', value: 'PC' },
        { label: 'EUR Pallet', value: 'PE' },
        { label: 'Half Pallet', value: 'AF' },
        { label: 'Quearter Pallet', value: 'OA' },
        { label: 'Special Pallet', value: 'OF' },
        { label: 'Cage Roll', value: 'CW' },
        { label: 'Box', value: 'BX' },
        { label: 'Envelope', value: 'EN' },
    ];

    useEffect(() => {
        fetchCustomers();
        getAssets();
    }, []);

    useEffect(() => {
        if (assetOptions.length) {
            fetchShipments();
        }
    }, [assetOptions.length, selectedCustomer.value, skip]);

    useEffect(() => {
        updateQueryParams('assetModal', assetModal);
    }, [assetModal]);

    const fetchCustomers = () => {
        CustomerService.ReadAll({ isActive: true }, startLoader, customerSuccessHandler, handleError, stopLoader);
    };

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return item;
            }
        }
        return null;
    };

    const customerSuccessHandler = ({ data }) => {
        let customers = data.data;
        let temp = [];
        customers.forEach((element) => {
            temp.push({
                value: element._id,
                label: element.name,
            });
        });
        setCustomers(temp);
        if (temp.length) {
            const queryMatched = matchQueryParams(temp, { value: queryParamsData.customer });
            setSelectedCustomer(queryMatched ? queryMatched : temp[0]);
            !queryMatched && updateQueryParams('customer', temp[0].value);
        } else {
            setSelectedCustomer({
                value: '',
                label: '',
            });
        }
    };

    const getAssets = () => {
        EdgeShipmentService.GetShipmentAssets(
            () => startLoader('edgeShipmentAsset'),
            (data) => handleAssetsSuccess(data),
            handleError,
            () => stopLoader('edgeShipmentAsset')
        );
    };

    const handleAssetsSuccess = ({ data }) => {
        const temp = [];
        data.data.forEach((item) => {
            temp.push({ label: item.name, value: item._id });
        });
        setAssetOptions(temp);
    };

    const fetchShipments = () => {
        if (selectedCustomer.value) {
            EdgeShipmentService.GetShipments(
                {
                    customerId: selectedCustomer.value,
                    limit: LIMIT,
                    skip: skip,
                },
                () => startLoader('edgeShipmentGet'),
                (data) => handleSuccess(data),
                handleError,
                () => stopLoader('edgeShipmentGet')
            );
        }
    };

    const handleLabelDownload = (base64Data, filename) => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${base64Data}`;
        link.download = filename;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleSuccess = ({ data }) => {
        if (Array.isArray(data.data?.data)) {
            const processedData = data.data?.data.map((item) => {
                const { consigneeName, consigneeCity, consigneeCountryCode } = item.consignee;
                return {
                    bookingId: item.bookingId,
                    consigneeName: consigneeName,
                    consigneeCity: consigneeCity,
                    consigneeCountryCode: consigneeCountryCode,
                    packageTypeCode: packageTypeCodeOptions.find((option) => option.value === item.packageTypeCode).label,
                    notes:
                        item.notes.length > 40 ? (
                            <CustomTooltip content={item.notes}>
                                <div>{item.notes.substring(0, 40) + '...'}</div>
                            </CustomTooltip>
                        ) : item.notes ? (
                            <div>{item.notes}</div>
                        ) : (
                            '--'
                        ),
                    bookedDate: momentTimeFormater(item.date).format('YYYY-MM-DD HH:mm:ss'),
                    action: (
                        <div className={EdgeClasses.EdgeIconContainer}>
                            <div>
                                <CustomTooltip content={'Info'}>
                                    <UilInfoCircle
                                        style={{
                                            color: 'var(--color-primary)',
                                        }}
                                        size={'1.3vw'}
                                        onClick={() => setInfoModal({ status: true, data: item })}
                                    />
                                </CustomTooltip>
                            </div>
                            <div>
                                <CustomTooltip content={'Download Label'}>
                                    <UilFileDownload
                                        style={{
                                            color: 'var(--color-primary)',
                                        }}
                                        size={'1.3vw'}
                                        onClick={() => handleLabelDownload(item.label?.labelData, `${consigneeName}_${item.bookingId}_edge_shipment`)}
                                    />
                                </CustomTooltip>
                            </div>
                        </div>
                    ),
                };
            });
            setTableData(processedData);
            setPageTotalCount(processedData.length);
        } else {
            setTableData([]);
            setPageTotalCount(0);
        }
        setTotalCount(data.data?.count ? data.data.count : 0);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={bidClasses.Bids}>
            <ModalComponent isOpen={assetModal} setOpen={setAssetModal}>
                <ShippingAssetModal
                    customerId={selectedCustomer?.value}
                    setOpen={(e, isFetchShipments = false) => {
                        setAssetModal(e);
                        isFetchShipments && fetchShipments();
                    }}
                    assetOptions={assetOptions}
                    packageTypeCodeOptions={packageTypeCodeOptions}
                />
            </ModalComponent>
            <ModalComponent isOpen={infoModal.status} setOpen={() => setInfoModal({ status: false, data: {} })}>
                <ShipmentInfo data={infoModal.data} setInfoModal={setInfoModal} assetOptions={assetOptions} packageTypeCodeOptions={packageTypeCodeOptions} />
            </ModalComponent>
            <div className={classes.Header}>
                <div className={classes.ErrorDropdownContainer}>
                    <Typography content={'Shipments'} />
                    <div className={bidClasses.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={pageTotalCount || 0} />
                        </span>
                    </div>
                    {customers.length > 0 && (
                        <div className={EdgeClasses.DropDnContainer}>
                            <DropdownComponent
                                name="customerId"
                                options={customers}
                                onChange={(e) => {
                                    setSelectedCustomer(e);
                                    updateQueryParams('customer', e.value);
                                }}
                                defaultValue={selectedCustomer}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <button onClick={() => setAssetModal(true)} className="btn-primary" style={{ marginTop: '0' }}>
                        Create Shipment
                    </button>
                </div>
            </div>
            <div>
                <Table
                    head={['Booking Id', 'Consignee Name', 'Consignee City', 'Consignee Country Code', 'Package Type', 'Notes', 'Booked Date', 'Action']}
                    keys={['bookingId', 'consigneeName', 'consigneeCity', 'consigneeCountryCode', 'packageTypeCode', 'notes', 'bookedDate', 'action']}
                    data={tableData}
                    page={page}
                    Pagination={true}
                    limit={LIMIT}
                    handlePageChange={handlePageChange}
                    totalCount={totalCount}
                />
            </div>
        </div>
    );
};

export default Shipments;
