// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/instance',
    read_all: '/admin/instance',
    update: '/admin/instance',
    delete: '/admin/instance',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const ReadAll = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read_all}/${id}`).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

export const InstanceService = {
    Create,
    ReadAll,
    Update,
    Delete,
};
