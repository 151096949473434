// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { UilInfoCircle } from '@iconscout/react-unicons';

// Internal module imports
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import { LogsService } from '../../../../services/LogsService';
import { useLoader } from '../../../../hooks/use-loader.hook';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import NewJSONEditor from '../../../../components/JSONEditor/NewJSONEditor';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import { momentTimeFormater } from '../../../../utils/timeHelper';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';

const ExternalAPILogsTable = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = momentTimeFormater();
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [limit] = useState(15);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [infoModal, setInfoModal] = useState({ status: false, data: '' });
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date());
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm'));

    const RESPONSE_TIME_OPTIONS = [
        {
            label: '>= 1ms',
            value: 1,
        },
        {
            label: '>= 500ms',
            value: 500,
        },
        {
            label: '>= 1000ms',
            value: 1000,
        },
        {
            label: '>= 2000ms',
            value: 2000,
        },
        {
            label: '>= 5000ms',
            value: 5000,
        },
    ];

    const STATUS_TYPE_OPTIONS = [
        {
            label: '>= 100',
            value: 100,
        },
        {
            label: '>= 200',
            value: 200,
        },
        {
            label: '>= 300',
            value: 300,
        },
        {
            label: '>= 400',
            value: 400,
        },
        {
            label: '>= 500',
            value: 500,
        },
    ];

    const [selectedStatusCode, setSelectedStatusCode] = useState(
        queryParamsData.status ? STATUS_TYPE_OPTIONS.filter((options) => options.value === queryParamsData.status)[0] :
            {
                label: '>= 100',
                value: 100,
            });

    const [selectedResponseTime, setSelectedResponseTime] = useState(
        queryParamsData.responseTime ? RESPONSE_TIME_OPTIONS.filter((options) => options.value === queryParamsData.responseTime)[0] :
            {
                label: '>= 1ms',
                value: 1
            });

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        updateQueryParams('startTime', startTime);
    }, [startTime]);

    useEffect(() => {
        updateQueryParams('endTime', endTime);
    }, [endTime]);

    useEffect(() => {
        updateQueryParams('status', selectedStatusCode.value);
    }, [selectedStatusCode.value]);

    useEffect(() => {
        updateQueryParams('responseTime', selectedResponseTime.value);
    }, [selectedResponseTime.value]);

    useEffect(() => {
        fetchData();
    }, [skip, limit, selectedStatusCode.value, selectedResponseTime.value]);

    const fetchData = () => {
        let params = {
            limit,
            skip,
            date: moment(date).format('YYYY-MM-DD'),
            startTime,
            endTime,
            statusCode: selectedStatusCode.value,
            responseTime: selectedResponseTime.value
        };

        LogsService.EdgeAPI(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const processedData = data.data.data.map((item, index) => {
            const path = item.request?.url;
            return {
                path: path.length > 35 ? <CustomTooltip content={path}>{path.substring(0, 35) + '...'}</CustomTooltip> : path || '--',
                responseTime: item?.response?.responseTime || '--',
                statusCode: item?.response?.status || '--',
                host: item?.request?.headers?.host || '--',
                userName: item?.user || '--',
                timestamp: item?.timestamp && moment(item?.timestamp).format('MMMM Do YYYY, h:mm:ss a'),
                action: (<div
                    style={{
                        display: 'flex',
                        gridColumnGap: '1vw',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CustomTooltip content={'Info'}>
                        <UilInfoCircle
                            size={'1.2vw'}
                            style={{ color: 'var(--color-primary)' }}
                            onClick={() => setInfoModal({ status: true, data: { request: item?.request, response: item?.response } })}
                        />
                    </CustomTooltip>
                </div>)
            };
        });
        setTotalCount(data.data.count);
        setData(processedData);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handlePageChange = (pageno) => {
        setSkip(limit * pageno);
        setPage(pageno);
    };

    return (
        <div >
            <div className={classes.AllDevices} style={{ marginBottom: '2vh' }}>
                <div className={classes.Header} >
                    <div>
                        <div className={classes.FieldControl}>
                            <label>Status Code</label>
                            <DropdownComponent name="status" options={STATUS_TYPE_OPTIONS} onChange={(e) => setSelectedStatusCode(e)} defaultValue={queryParamsData.status ? STATUS_TYPE_OPTIONS.filter((options) => options.value === queryParamsData.status)[0] : STATUS_TYPE_OPTIONS[0]} />
                        </div>
                        <div className={classes.FieldControl}>
                            <label>Response Time</label>
                            <DropdownComponent name="resposeTime" options={RESPONSE_TIME_OPTIONS} onChange={(e) => setSelectedResponseTime(e)} defaultValue={queryParamsData.responseTime ? RESPONSE_TIME_OPTIONS.filter((options) => options.value === queryParamsData.responseTime)[0] : RESPONSE_TIME_OPTIONS[0]} />
                        </div>
                    </div>
                    <div className={classes.InputContainer2}>
                        <div className={classes.FieldControl2}>
                            <div className={classes.BadgeContainer}>
                                <div>
                                    <label>Date</label>
                                    <DatePicker date={date} setDate={setDate} showArrow={true} />
                                </div>
                                <div>
                                    <label>Start Time</label>
                                    <CustomTimePicker startTime={startTime} setStartTime={setStartTime} type="start" />
                                </div>
                                <div>
                                    <label>End Time</label>
                                    <CustomTimePicker endTime={endTime} setEndTime={setEndTime} type="end" />
                                </div>
                            </div>
                        </div>

                        <button
                            type="submit"
                            className="btn-primary"
                            style={{
                                marginTop: '3vh',
                                width: 'auto',
                            }}
                            onClick={fetchData}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>

            <div className={classes.AllDevices}>
                <div className={classes.Header} >
                    <div>
                        <Typography content={'Edge API Logs'} />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount} />
                            </span>
                        </div>
                    </div>

                </div>

                <div>
                    <Table
                        head={['Path', 'Response time', 'Status Code', 'Host', 'User name', 'Timestamp', 'Action']}
                        keys={['path', 'responseTime', 'statusCode', 'host', 'userName', 'timestamp', 'action']}
                        data={data}
                        page={page}
                        Pagination={true}
                        limit={limit}
                        handlePageChange={handlePageChange}
                        totalCount={totalCount}
                    />
                </div>

                <ModalComponent isOpen={infoModal.status} setOpen={(status) => setInfoModal({ status, data: {} })}>
                    <div style={{ width: '50vw' }}>
                        <div>
                            <Typography content={'Error Info'}></Typography>
                            <div>
                                <div>
                                    <Typography content={'Request Info'}></Typography>
                                    <Formik initialValues={{ ...infoModal.data?.request }}>
                                        {() => {
                                            return (
                                                <Form>
                                                    <NewJSONEditor name="request" defaultValue={infoModal.data?.request} height="15rem" />
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                                <div style={{ marginTop: '0.5vw' }}>
                                    <Typography content={'Response Info'}></Typography>
                                    <Formik initialValues={{ ...infoModal.data?.response }}>
                                        {() => {
                                            return (
                                                <Form>
                                                    <NewJSONEditor name="response" defaultValue={infoModal.data?.response} height="15rem" />
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                                <div className={classes.ButtonRight}>
                                    <div className="btn-secondary" onClick={() => setInfoModal({ status: false, data: {} })}>
                                        Close
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalComponent>
            </div>

        </div>
    );
};

export default ExternalAPILogsTable;
