// Standard module imports
import React, { useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import classes from './index.module.css';
import TabsComponent from '../../../components/Tabs/Tabs';
import SignalViewer from './SignalViewer';
import SignalError from './SignalError/SignalError';
import ActivationProfiler from './ActivationProfiler/ActivationProfiler';
import DataExporter from './DataExporter/DataExporter';
import AuditPQTool from './AuditPQTools';
import Afrr from './AfrrDashboard';
import EdgeOTAUpdate from './EdgeOTAUpdate';

const SystemTools = () => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        nestedTab: queryParams.get('nestedTab') ? Number(queryParams.get('nestedTab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        signalErrorDate: queryParams.get('signalErrorDate') ? new Date(queryParams.get('signalErrorDate')) : '',
        startTime: queryParams.get('startTime') ? queryParams.get('startTime') : '',
        endTime: queryParams.get('endTime') ? queryParams.get('endTime') : '',
        signalType: queryParams.get('signalType') ? queryParams.get('signalType') : '',
        market: queryParams.get('market') ? queryParams.get('market') : '',
        signal: queryParams.get('signal') ? queryParams.get('signal') : '',
        customer: queryParams.get('customer') ? queryParams.get('customer') : '',
        admin: queryParams.get('admin') ? queryParams.get('admin') : '',
        profile: queryParams.get('profile') ? queryParams.get('profile') : '',
        direction: queryParams.get('direction') ? queryParams.get('direction') : '',
        deltaload: queryParams.get('deltaload') ? queryParams.get('deltaload') : '',
        customSignal: queryParams.get('customSignal') ? queryParams.get('customSignal') : '',
    });
    const updateQueryParams = (type, value) => {
        if (type == 'tab') {
            queryParams.delete('date');
            queryParams.delete('customer');
            queryParams.delete('admin');
            queryParams.delete('profile');
            queryParams.delete('startTime');
            queryParams.delete('endTime');
            queryParams.delete('signalType');
            queryParams.delete('signal');
            queryParams.delete('market');
            queryParams.delete('direction');
            queryParams.delete('deltaload');
            queryParams.delete('signalErrorDate');
            setQueryParams({});
        }
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    let tabs = [
        {
            name: 'Signal Viewer',
            component: (
                <>
                    <SignalViewer queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Activation Profiler',
            component: (
                <>
                    <ActivationProfiler queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Data Exporter',
            component: (
                <>
                    <DataExporter setSelectedIndex={setSelectedIndex} />
                </>
            ),
            tabId: 3,
        },
        {
            name: 'Audit/PQ Tools',
            component: (
                <>
                    <AuditPQTool setSelectedIndex={setSelectedIndex} />
                </>
            ),
            tabId: 4,
        },
        {
            name: 'AFRR Dashboard',
            component: (
                <>
                    <Afrr setSelectedIndex={setSelectedIndex} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 4,
        },
        {
            name: 'Edge OTA Console',
            component: (
                <>
                    <EdgeOTAUpdate setSelectedIndex={setSelectedIndex} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 5,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(e) => {
                    setSelectedIndex(e);
                    updateQueryParams('tab', e);
                }}
            />
        </div>
    );
};

export default SystemTools;
