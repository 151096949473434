// Standard library imports
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// External library imports
import { toast } from 'react-toastify';
import { UilAngleRight } from '@iconscout/react-unicons';

// Internal module import
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import TreeChart from '../../../../components/TreeChart/TreeChart';
import { useLoader } from '../../../../hooks';
import { RootDocumentsService } from '../../../../services/RootDocumentsService';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import DocumentEditor from './ModalComponent/DocumentEditor';
import DocumentsMenuView from './DocumentsMenuView';
import { ToggleButtonWithState } from '../../../../components/Inputs/Input';
import DynamicTables from '../../../../components/DynamicTables.js/DynamicTables';

import documentClasses from './Documents.module.css';

const Documents = () => {
    const { hash } = useLocation();

    const [chartData, setChartData] = useState({});
    const [startLoader, stopLoader] = useLoader();
    const [nodeData, setNodeData] = useState({});
    const [isOpen, setOpen] = useState(false);
    const [switchView, setSwitchView] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [filteredData, setFilteredData] = useState({
        admin: [],
        customer: [],
        license: [],
    });
    const [isSearch, setIsSearch] = useState({
        admin: '',
        customer: '',
        license: '',
    });
    const [infoModal, setInfoModal] = useState(false);
    const [infoModalData, setInfoModalData] = useState({ type: '', data: [] });

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (nodeData?._id) {
            setOpen(true);
        }
    }, [nodeData]);

    const getData = () => {
        RootDocumentsService.GetData(startLoader, handleGetDataSuccess, handleError, stopLoader);
    };

    const handleGetDataSuccess = ({ data }) => {
        setChartData(data?.data?.data);
        setFilterData(data?.data?.data);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const filterAdminName = (value) => {
        setIsSearch({ admin: value, customer: '', license: '' });
        if (value !== '') {
            const searchValue = value?.toLowerCase();
            const filterAdmin = chartData.children.filter((item) => item.name.toLowerCase().includes(searchValue));
            if (searchValue !== '') {
                setFilterData({ ...filterData, children: filterAdmin });
                setFilteredData({
                    ...filteredData,
                    admin: filterAdmin,
                });
            } else {
                setFilterData(chartData);
            }
        } else {
            setFilterData(chartData);
        }
    };

    const filterCustomerName = (value) => {
        setIsSearch({ ...isSearch, customer: value, license: '' });
        const searchValue = value?.toLowerCase();
        const admin = filteredData?.admin?.map((admin) => {
            const filterCustomer = admin?.children.filter((item) => item.name.toLowerCase().includes(searchValue));
            return {
                ...admin,
                children: filterCustomer,
            };
        });
        setFilterData({ ...filterData, children: admin });
        setFilteredData({
            ...filteredData,
            customer: admin,
        });
    };

    const filterLicense = (value) => {
        setIsSearch({ ...isSearch, license: value });
        const searchValue = value?.toLowerCase();
        const filterLicense = filteredData.customer?.map((admin) => {
            const customer = admin.children?.map((customer) => {
                const filter = customer?.children?.filter((item) => item.name.toLowerCase().includes(searchValue));
                return {
                    ...customer,
                    children: filter,
                };
            });
            return {
                ...admin,
                children: customer,
            };
        });
        setFilteredData({ ...filteredData, license: filterLicense });
        setFilterData({ ...filterData, children: filterLicense });
    };

    const filterInstance = (value) => {
        const searchValue = value?.toLowerCase();
        const filterInstances = filteredData.license?.map((admin) => {
            const customer = admin.children.map((customer) => {
                const license = customer?.children?.map((license) => {
                    const filter = license?.children?.filter((item) => item.name.toLowerCase().includes(searchValue));
                    return {
                        ...license,
                        children: filter,
                    };
                });
                return {
                    ...customer,
                    children: license,
                };
            });
            return {
                ...admin,
                children: customer,
            };
        });
        setFilterData({ ...filterData, children: filterInstances });
    };

    const handleDetailsSuccess = (type, res) => {
        const capital = type.charAt(0).toUpperCase() + type.slice(1);
        setInfoModalData({ type: capital, data: res.data.data.details });
        setInfoModal(true);
    };

    useEffect(() => {
        let params = new URLSearchParams(hash);
        const id = params.get('id');
        const modalType = params.get('modalType');
        const type = params.get('type');

        if (modalType === 'document') {
            setOpen(true);
            setNodeData({ _id: id, name: '' });
        } else if (modalType === 'details') {
            getInfoDetails({ type, _id: id });
        }
    }, []);

    const clearQueryParams = () => {
        if (hash) {
            window.location.href = window.location.href.replace(window.location.hash, '#');
        }
    };

    const updateQueryParams = (id, modalType, type = null) => {
        let query = `#&modalType=${modalType}&id=${id}`;
        if (type) {
            query += `&type=${type}`;
        }
        const url = window.location;
        if (url.hash) {
            const splited = url.href.split('#');
            window.location.href = splited[0] + query;
        } else {
            window.location.href = url.href + query;
        }
    };

    const getInfoDetails = (data) => {
        const params = {
            type: data.type,
            id: data._id,
        };
        RootDocumentsService.GetDetails(params, startLoader, (res) => handleDetailsSuccess(data.type, res), handleError, stopLoader);
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={isOpen} setOpen={() => {}}>
                <div>
                    <DocumentEditor
                        nodeData={nodeData}
                        setOpen={(e) => {
                            setOpen(e);
                            !e && clearQueryParams();
                        }}
                        setNodeData={setNodeData}
                    />
                </div>
            </ModalComponent>
            <ModalComponent
                isOpen={infoModal}
                setOpen={(e) => {
                    setInfoModal(e);
                    !e && clearQueryParams();
                }}
                onClose={(e) => {
                    setInfoModal(false);
                    !e && clearQueryParams();
                }}
            >
                <div
                    style={{
                        width: '80vw',
                        height: '70vh',
                    }}
                >
                    <h3>{infoModalData.type} Info:</h3>
                    <div
                        style={{
                            maxHeight: '56vh',
                            overflowY: 'scroll',
                        }}
                    >
                        <DynamicTables tables={infoModalData.data} />
                    </div>
                    <button
                        type="button"
                        className="btn-secondary"
                        onClick={() => {
                            setInfoModal(false);
                            clearQueryParams();
                        }}
                        style={{
                            maxWidth: '5vw',
                            position: 'absolute',
                            right: '1vw',
                            bottom: '1vw',
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div className={documentClasses.HeaderContainer}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1vw' }}>
                        <Typography content="Documents" />
                        {switchView && (
                            <div>
                                <div className={`${documentClasses.Breadcrumb}`}>
                                    <input placeholder="Search by admin name" style={{ paddingLeft: '1.2vw' }} type="text" onChange={(e) => filterAdminName(e.target.value)} />

                                    {isSearch.admin ? (
                                        <>
                                            <UilAngleRight size={'2vw'} />
                                            <input placeholder="Search by customer name" type="text" onChange={(e) => filterCustomerName(e.target.value)} />
                                            {isSearch.customer ? (
                                                <>
                                                    <UilAngleRight size={'2vw'} />
                                                    <input placeholder="Search by license name" style={{}} type="text" onChange={(e) => filterLicense(e.target.value)} />
                                                    {isSearch.license ? (
                                                        <>
                                                            <UilAngleRight size={'2vw'} />
                                                            <input placeholder="Search by instance name" style={{}} type="text" onChange={(e) => filterInstance(e.target.value)} />
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={documentClasses.SwitchView}>
                        <label>Flow View</label>
                        <ToggleButtonWithState
                            value={switchView}
                            onChange={(e) => setSwitchView(e.target.checked)}
                            label=""
                            defaultStyle={true}
                            sliderStyle={{ backgroundColor: 'var(--color-primary)' }}
                        />
                        <label>List View</label>
                    </div>
                </div>
            </div>
            <div>
                {!switchView ? (
                    <TreeChart chartData={chartData} orientation="horizontal" onDoubleClick={setNodeData} getInfoDetails={getInfoDetails} updateQueryParams={updateQueryParams} />
                ) : (
                    <DocumentsMenuView data={filterData} onDoubleClick={setNodeData} getInfoDetails={getInfoDetails} updateQueryParams={updateQueryParams} />
                )}
            </div>
        </div>
    );
};

export default Documents;
