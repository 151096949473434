// Standard library imports
import React, { useState } from 'react';
// External library imports
import _ from 'lodash';
// CSS Imports
import classes from '../../../styles/CapacityContent.module.css';

const HourlyCost = ({ data, setOpen }) => {
    const length = Math.floor(_.get(data, ['bidStatus'], []).length / 3);
    return (
        <div className={`${classes.CapacityContent} ${classes.BidInfoModal}`}>
            <div className={classes.Content}>
                <div className={classes.ContainerStart}>
                    {[0, 1, 2].map((n) => (
                        <table>
                            <tr>
                                <th>Product Name</th>
                                <th>Price</th>
                            </tr>
                            {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                key <= 23 ? (
                                    <tr>
                                        <td style={{ display: 'flex', gap: '0.5vw' }}>{_.get(data, ['bidStatus', key, 'productName'], '--')}</td>
                                        <td>{_.get(data, ['bidStatus', key, 'price'], '--')}</td>
                                    </tr>
                                ) : null
                            )}
                        </table>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HourlyCost;
