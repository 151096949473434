// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import XLSX from 'xlsx';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';
import xlsx from 'xlsx';
import { UilImport, UilBoltAlt } from '@iconscout/react-unicons';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
// Css imports
import Classes from '../../../../styles/AllDevices.module.css';
import AuditClasses from './index.module.css';
// Intenal module imports
import { useLoader } from '../../../../hooks';
import { auditDataAppender } from '../../../../utils/AuditHelper';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import { DownloadFileFromBuffer } from '../../../../utils/downloadHelper';
import { setCellData } from '../../../../utils/exceljsHelper';
import MultipleFilesUpload from '../../../../components/Inputs/FileUpload/MultipleFileUpload';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import Table from '../../../../components/Table/Table';

const fileNamePrefix = 'Audit Data Appended';

const AuditDataAppender = () => {
    const [startLoader, stopLoader] = useLoader();
    const [fileData, setFileData] = useState(null);
    const [energinetFileData, setEnerginetFileData] = useState(null);
    const [proccessedFile, setProccessedFiles] = useState([]);

    const constructEnerginetFormat = async (data, fileName) => {
        const colors = {
            peachOrange: 'FFFFCC99',
            pureOrange: 'FFFF7D00',
            lightYellow: 'FFFFFFCC',
            lightGray: 'FFF2F2F2',
            blue: 'FF1F497D',
            darkGreen: 'FF006100',
            lightGreen: 'FFC6EFCE',
        };
        const boldFont = { bold: true };
        const borderStyle = {
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            },
        };
        const fontSize = (size = 11) => ({ size });
        const backgroundColor = (color) => ({
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: colors[color] },
            },
        });
        const fontColor = (color) => ({ color: { argb: colors[color] } });
        const alignMiddle = {
            alignment: { horizontal: 'center', vertical: 'middle' },
        };

        const inputSheetName = data.SheetNames[0];
        const inputSheet = data.Sheets[inputSheetName];
        const jsonData = xlsx.utils.sheet_to_json(inputSheet);

        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(fileName);

        // Increase the height of row
        const row1 = sheet.getRow(1);
        row1.height = 40;
        const row15 = sheet.getRow(15);
        row15.height = 60;
        const row16 = sheet.getRow(16);
        row16.height = 50;

        const AColumn = sheet.getColumn('A');
        AColumn.width = 10;
        const CColumn = sheet.getColumn('C');
        CColumn.width = 30;
        const DColumn = sheet.getColumn('D');
        DColumn.width = 25;
        const EColumn = sheet.getColumn('E');
        EColumn.width = 25;
        const FColumn = sheet.getColumn('F');
        FColumn.width = 25;
        const GColumn = sheet.getColumn('G');
        GColumn.width = 25;
        const Hcolumn = sheet.getColumn('H');
        Hcolumn.width = 30;

        sheet.mergeCells('A1:E1');
        const cell = sheet.getCell('A1');
        cell.value = {
            richText: [
                {
                    text: 'Dataark til automatisk kontrol af ',
                    font: { ...fontSize(18), ...fontColor('blue') },
                },
                {
                    text: 'FCR',
                    font: { bold: true, ...fontSize(18), ...fontColor('blue') },
                },
                {
                    text: ' leverancer',
                    font: { ...fontSize(18), ...fontColor('blue') },
                },
            ],
        };
        cell.alignment = alignMiddle.alignment;

        sheet.mergeCells('F2:H2');
        setCellData(sheet, 'F2', 'ENDK beder om data fra tidspunktet der er leveret den sande ', { font: { ...boldFont } });
        sheet.mergeCells('F3:H3');
        setCellData(sheet, 'F3', 'frekvens for. Det fremgår i kolonne A,B og C længere nede.', { font: { ...boldFont } });
        sheet.mergeCells('A4:C4');
        setCellData(sheet, 'A4', 'Solgt FCR I perioden [MW]:', {
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'D4', '', { ...backgroundColor('peachOrange') });
        sheet.mergeCells('F5:H5');
        setCellData(sheet, 'F5', 'Felter markeret med denne farve bedes udfyldes.', {
            ...backgroundColor('peachOrange'),
            font: { ...boldFont },
            ...alignMiddle,
        });

        sheet.mergeCells('E8:F8');
        setCellData(sheet, 'E8', 'Anlægsnavn, type:', {
            font: { ...boldFont },
            ...alignMiddle,
        });
        sheet.mergeCells('E9:F9');
        setCellData(sheet, 'E9', 'Anlægs maksimaleffekt [MW]:', {
            font: { ...boldFont },
            ...alignMiddle,
        });
        sheet.mergeCells('E10:F10');
        setCellData(sheet, 'E10', 'Anlægs minimumseffekt [MW]:', {
            font: { ...boldFont },
            ...alignMiddle,
        });

        setCellData(sheet, 'G8', '', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
        });
        setCellData(sheet, 'G9', '', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
        });
        setCellData(sheet, 'G10', '', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
        });

        setCellData(sheet, 'A14', 'Fx');
        setCellData(sheet, 'B14', 'Tiden', {
            ...borderStyle,
            ...backgroundColor('lightGray'),
            font: { ...fontColor('pureOrange') },
        });
        sheet.mergeCells('F14:I14');
        setCellData(sheet, 'F14', 'Vær opmærksom på timeskift. Derfor er "Planlagt Effekt" en kolonne, og ikke en enkelt celle. ');

        setCellData(sheet, 'A15', '', {
            ...borderStyle,
            ...backgroundColor('lightGreen'),
            font: { ...fontColor('darkGreen') },
        });
        setCellData(sheet, 'B15', '', {
            ...borderStyle,
            ...backgroundColor('lightGreen'),
            font: { ...fontColor('darkGreen') },
        });
        sheet.mergeCells('C15:D15');
        setCellData(sheet, 'C15', 'Indsættes af ENDK', {
            ...borderStyle,
            ...backgroundColor('lightGreen'),
            ...alignMiddle,
            font: { ...fontColor('darkGreen') },
        });
        sheet.mergeCells('E15:H15');
        setCellData(sheet, 'E15', 'Indsættes af leverandør', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
            ...alignMiddle,
            font: { ...boldFont, ...fontSize(35), ...fontColor('blue') },
        });

        setCellData(sheet, 'A16', 'Dato', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'B16', 'Tid', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'C16', 'ENDK frekvens (Sand) [Hz]', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'D16', 'ENDK Frekvensafvigelse [mHz]', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'E16', 'Frekvens afvigelse [mHz]', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'F16', 'Blok effekt [MW]', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'G16', 'Planlagt effekt [MW]', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'H16', 'Anlægs solgte mængde [MW]', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });

        const startRow = 17;
        jsonData.forEach((row, idx) => {
            setCellData(sheet, `A${startRow + idx}`, row['Dato'], {
                ...borderStyle,
                ...backgroundColor('lightGreen'),
                font: { ...fontColor('darkGreen') },
            });
            setCellData(sheet, `B${startRow + idx}`, row['Tid'], {
                ...borderStyle,
                ...backgroundColor('lightGreen'),
                font: { ...fontColor('darkGreen') },
            });
            setCellData(sheet, `C${startRow + idx}`, row['ENDK frekvens (Sand) [Hz]']);
            setCellData(sheet, `D${startRow + idx}`, row['ENDK Frekvensafvigelse [mHz]']);
            setCellData(sheet, `E${startRow + idx}`, row['Frekvens afvigelse [mHz]'], { ...borderStyle, ...backgroundColor('peachOrange') });
            setCellData(sheet, `F${startRow + idx}`, row['Blok effekt [MW]'], {
                ...borderStyle,
                ...backgroundColor('peachOrange'),
            });
            setCellData(sheet, `G${startRow + idx}`, row['Planlagt effekt [MW]'], {
                ...borderStyle,
                ...backgroundColor('peachOrange'),
            });
            setCellData(sheet, `H${startRow + idx}`, row['Anlægs solgte mængde [MW]'], { ...borderStyle, ...backgroundColor('peachOrange') });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        return buffer;
    };

    const handleUploadClick = async (e) => {
        try {
            startLoader();
            const files = Array.from(e.target.files);
            const temp = [];
            const promises = files.map((el) => {
                const file = el;
                return new Promise((resolve, reject) => {
                    const energinetFileName = file.name.split('.').join(' Energinet.xlsx.');
                    const reader = new FileReader();
                    reader.onload = async (event) => {
                        try {
                            const arrayBuffer = event.target.result;
                            const binaryString = new Uint8Array(arrayBuffer).reduce((data, byte) => {
                                return data + String.fromCharCode(byte);
                            }, '');
                            const workbook = XLSX.read(binaryString, { type: 'binary' });
                            const updatedWorkbook = auditDataAppender(workbook);
                            const energinetDataWorkbook = await constructEnerginetFormat(workbook, energinetFileName);
                            temp.push({
                                fileName: file.name,
                                energinetFileName: energinetFileName,
                                standard: updatedWorkbook,
                                energinet: energinetDataWorkbook,
                                action: (
                                    <div className={AuditClasses.ActionButton}>
                                        <div>
                                            <CustomTooltip content={'Standard Format'}>
                                                <div onClick={() => handleDownload(updatedWorkbook, 'standard', file.name)}>
                                                    <UilImport
                                                        size="1.2vw"
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: 'var(--color-primary)',
                                                        }}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        </div>
                                        <div>
                                            <CustomTooltip content={'Energinet Format'}>
                                                <div onClick={() => handleDownload(energinetDataWorkbook, 'energinet', energinetFileName)}>
                                                    <UilBoltAlt
                                                        size="1.2vw"
                                                        style={{
                                                            cursor: 'pointer',
                                                            color: 'var(--color-primary)',
                                                        }}
                                                    />
                                                </div>
                                            </CustomTooltip>
                                        </div>
                                    </div>
                                ),
                            });
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    };
                    reader.readAsArrayBuffer(file);
                });
            });
            await Promise.all(promises);
            stopLoader();
            toast.success('Audit Data Appended Successfully');
            setProccessedFiles(temp);
        } catch (err) {
            stopLoader();
            toast.error(`Error while processing file`);
        }
    };

    const handleDownload = (data, type = 'standard', fileName = '') => {
        if (type === 'standard') {
            XLSX.writeFile(data, `${fileNamePrefix} ${fileName}.xlsx`);
        } else {
            DownloadFileFromBuffer(data, `${fileNamePrefix} ${fileName}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        }
    };

    const downloadZipFile = async (type) => {
        startLoader();
        const zip = new JSZip();
        proccessedFile.map((item) => {
            if (type == 'standard') {
                const buffer = XLSX.write(item.standard, {
                    bookType: 'xlsx',
                    type: 'array',
                });
                const excelBlob = new Blob([new Uint8Array(buffer)], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                zip.file(item.fileName, excelBlob);
            } else {
                const excelBlob = new Blob([item.energinet], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                zip.file(item.energinetFileName, excelBlob);
            }
        });
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, `${fileNamePrefix} ${type} format.zip`);
        stopLoader();
    };

    return (
        <div>
            <div className={Classes.AllDevices}>
                <div className={AuditClasses.UploadFileContainer}>
                    <Formik initialValues={{}} enableReinitialize>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={AuditClasses.FileContainer}>
                                        <div className={Classes.InputContainer}>
                                            <div className={AuditClasses.DatePickerLabel} style={{ width: '35vw' }}>
                                                <label>Upload file</label>
                                                <MultipleFilesUpload
                                                    name="file"
                                                    id="file"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    onChange={handleUploadClick}
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    {proccessedFile.length ? (
                        <div>
                            <div className={`${AuditClasses.FileContainer} ${AuditClasses.DownloadButtonControl}`}>
                                <DownloadButton size={'medium'} text={'All files - Standard Format'} onClick={() => downloadZipFile('standard')} />
                                <DownloadButton size={'medium'} text={'All files - Energinet Format'} onClick={() => downloadZipFile('energinet')} />
                            </div>
                            <div style={{ minWidth: '35vw' }}>
                                <Table head={['File', 'Action']} keys={['fileName', 'action']} data={proccessedFile} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default AuditDataAppender;
