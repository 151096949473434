import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { Input, ToggleButton } from '../../../components/Inputs/Input';
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks';
import { excludedDatesService } from '../../../services/excludedDatesService';
import classes from '../../../styles/AllDevices.module.css';
import { Capitalize } from '../../../utils/stringHelper';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';

const getInitialValues = (editData, regulations) => {
    if (editData?._id) {
        const markets = {};
        regulations.forEach((item) => (markets[item] = editData?.markets?.includes(item)));
        return {
            date: moment(editData.date).format('YYYY-MM-DD'),
            customers: editData.customers,
            markets,
        };
    } else {
        const markets = {};
        regulations.forEach((item) => (markets[item] = false));
        return {
            date: moment().format('YYYY-MM-DD'),
            customers: [],
            markets,
        };
    }
};

const CreateExcludeDays = ({ editData, customers, setmodalControl, refreshData, regulations }) => {
    const [startLoader, stopLoader] = useLoader();
    const [initialValues, setInitialValues] = useState(getInitialValues(editData, regulations));
    const [state, setState] = useState({});

    useEffect(() => {
        if (editData?._id) {
            const data = {};
            customers.forEach((element) => {
                data[element.value] = editData.customers.includes(element.value);
            });
            setState(data);
        } else {
            const data = {};
            customers.forEach((element) => {
                data[element.value] = false;
            });
            setState(data);
        }
    }, []);

    const handleFcrSubmit = async (values, resetForm) => {
        let newPayLoad = {
            date: values.date,
        };

        let tempArray = [];
        Object.keys(state).filter((item) => {
            if (state[item]) {
                tempArray.push(item);
            }
        });
        newPayLoad.customers = tempArray;
        newPayLoad.markets = Object.keys(values?.markets || {}).filter((key) => values?.markets[key]);

        if (editData?._id) {
            await excludedDatesService.Update(editData?._id, newPayLoad, startLoader, (res) => handleSuccessFcrAggrement(res, resetForm), handleError, stopLoader);
        } else {
            await excludedDatesService.Create(newPayLoad, startLoader, (res) => handleSuccessFcrAggrement(res, resetForm), handleError, stopLoader);
        }
    };

    const handleSuccessFcrAggrement = (res, resetForm) => {
        if (res) {
            toast.success(editData?._id ? 'Excluded Dates Updated successfully!' : 'Excluded Dates Created successfully!');
            setmodalControl(false);
            resetForm();
            refreshData();
        }
    };

    const handleChange = (type, checked) => {
        setState({ ...state, [type.value]: checked });
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.FormContainer}>
            <Typography content={editData?._id ? `Edit Excluded Customers` : `Create Excluded Customers`} />
            <div className={classes.FormContent}>
                <Formik initialValues={initialValues} onSubmit={(val, { resetForm }) => handleFcrSubmit(val, resetForm)}>
                    {({ errors, touched, values, isValidating, ...props }) => {
                        console.log({ initialValues });
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label for="Start Date">Start Date</label>
                                        <DatePicker
                                            name="date"
                                            date={values.date ? new Date(values.date) : new Date()}
                                            setDate={(date) => {
                                                values.date = moment(date).format('YYYY-MM-DD');
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className={classes.FieldControl}>
                                    <label for="markets">Markets</label>
                                </div>
                                <div className={classes.InputContainer} style={{ marginBottom: '1vh' }}>
                                    <div className="flex-box">
                                        {regulations.map((signal, key) => (
                                            <ToggleButton values={values} label={signal} name={`markets.${signal}`} />
                                        ))}
                                    </div>
                                </div>
                                <div className={classes.FieldControl} style={{ marginTop: '2vh' }}>
                                    <label for="customers">Customers</label>
                                </div>
                                <div className="flex-box">
                                    {customers.map((route, i) => (
                                        <Checkbox
                                            name={route.value}
                                            label={Capitalize(route.label)}
                                            checked={state[route.value]}
                                            id={`checkbox-${i}`}
                                            key={i}
                                            onChange={(checked) => {
                                                handleChange(route, checked);
                                            }}
                                        />
                                    ))}
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button type="submit" className="btn-primary">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateExcludeDays;
