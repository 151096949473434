import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/dataInjest',
    create: '/root/dataInjest',
    update: '/root/dataInjest',
    delete: '/root/dataInjest',
    modelTypes: '/root/dataInjest/model-types',
    apply: '/root/dataInjest/apply',
    MQTTCredCount: '/root/dataInjest/MQTT-cred-count',
    generateMQTTCredential: '/root/dataInjest/create-mqtt-credentials',
    updateMQTTCredential: '/root/dataInjest/update-mqtt-credentials',
};

// create a dataInjest document
const createDataInjest = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.create}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

// read all dataInjest documents
const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

// read all model types
const ReadAllModelTyes = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.modelTypes).then(callback).catch(error).finally(next);
};

// apply changes
const ApplyChanges = (start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.apply).then(callback).catch(error).finally(next);
};

// deleting a dataInjest accepting id as param
const deleteDataInjest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};
// updating a dataInjest accepting id as param
const updateDataInjest = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

// get available credentials count
const GetAvailabelMQTTCredCount = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.MQTTCredCount).then(callback).catch(error).finally(next);
};

// create credentials
const GenerateMQTTCredentials = (start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.generateMQTTCredential).then(callback).catch(error).finally(next);
};

// update credentials on MQTT
const UpdateMQTTCredentials = (start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.updateMQTTCredential).then(callback).catch(error).finally(next);
};

export const dataInjestService = {
    ReadAll,
    deleteDataInjest,
    createDataInjest,
    updateDataInjest,
    ReadAllModelTyes,
    ApplyChanges,
    GetAvailabelMQTTCredCount,
    GenerateMQTTCredentials,
    UpdateMQTTCredentials,
};
