import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/root',
    create: '/root/root',
    delete: '/root/root',
    update: '/root/root',
    awsDetails: '/root/root/aws-details',
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAll).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (data, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${data.id}`, { params: data }).then(callback).catch(error).finally(next);
};

const GetAwsDetails = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.awsDetails}/${id}`).then(callback).catch(error).finally(next);
};
export const RootService = {
    ReadAll,
    Create,
    Update,
    Delete,
    GetAwsDetails,
};
