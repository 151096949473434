import { HttpClient } from '../utils/httpClient';

const PATH = {
    createRequest: '/root/data-export',
};

const createRequest = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.createRequest, payload).then(callback).catch(error).finally(next);
};

const getAllRequest = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.createRequest).then(callback).catch(error).finally(next);
};

const deleteRequest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.createRequest}/${id}`).then(callback).catch(error).finally(next);
};

export const dataExportServiceForRoot = {
    createRequest,
    getAllRequest,
    deleteRequest,
};
