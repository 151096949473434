// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/system',
    getAll: '/admin/system',
    update: '/admin/system',
    delete: '/admin/system',
    getSystems: '/admin/system/host',
    getSystemInfo: '/admin/system',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const GetAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getAll, { params }).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const getSystems = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getSystems}/${id}`).then(callback).catch(error).finally(next);
};

const getSystemInfo = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getSystemInfo}/${id}`).then(callback).catch(error).finally(next);
};

export const SystemServices = {
    Create,
    GetAll,
    Update,
    Delete,
    getSystems,
    getSystemInfo,
};
