import React, { useContext, useEffect, useState } from 'react';
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import ModalComponent from '../../../../../components/ModalComponent/ModalComponent';
import cronClasses from '../CronJobs.module.css';
import { toast } from 'react-toastify';
import { START_LOADER, STOP_LOADER } from '../../../../../constants';
import { regulationsService } from '../../../../../services/regulationsService';
import { LoaderContext } from '../../../../../context/LoaderContext';
import { Input } from '../../../../../components/Inputs/Input';
import { Form, Formik } from 'formik';
import DropDown from '../../../../../components/Inputs/Dropdown';
import { cronJobsRulesValidation } from '../../../../../validations/Root/cronJobsValidation';
import { CronService } from '../../../../../services/cronServices';
import { UilTrash } from '@iconscout/react-unicons';
import DeleteModal from '../../../../../components/DeleteModal/DeleteModal';
import CustomTooltip from '../../../../../components/CustomToolTip/CustomTooltip';

const Rule = ({ config }) => {
    const [addFormModal, setAddFormModal] = useState(false);
    const [dataSourceList, setDataSourceList] = useState([]);
    const [ruleData, setRuleData] = useState(config);
    const [tableData, setTableData] = useState([]);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const PRESERVE_DAYS_OPTION = [
        {
            label: 'Days',
            value: 'days',
        },
        {
            label: 'Weeks',
            value: 'weeks',
        },
        {
            label: 'Months',
            value: 'months',
        },
    ];
    const initialValues = {
        collection: '',
        preserveDays: '',
        value: '',
    };
    useEffect(() => {
        fetchDataSourceList();
    }, []);

    useEffect(() => {
        formatedTableData();
    }, [ruleData]);

    const formatedTableData = () => {
        const processedData =
            ruleData.properties?.rules[0] &&
            ruleData.properties?.rules.map((item, index) => ({
                's.no': index + 1,
                collection: item?.collection,
                preserveDays: `${item?.value} ${item?.preserveDays}`,
                action: (
                    <div className="cronActionConatiner">
                        <CustomTooltip content={'Delete'}>
                            <UilTrash onClick={() => setDeleteModal({ status: true, id: item.id })} style={{ color: 'var(--color-primary)' }} />
                        </CustomTooltip>
                    </div>
                ),
            }));
        processedData ? setTableData(processedData) : setTableData([]);
    };

    const getRules = () => {
        CronService.GetRule(
            { id: ruleData?._id, type: 'cleanUpRules' },
            () => startLoader('getRule'),
            handleGetRuleSuccess,
            handleError,
            () => stopLoader('getRule')
        );
    };

    const handleGetRuleSuccess = ({ data }) => {
        setRuleData(data.data[0]);
    };

    const DeleteRule = () => {
        CronService.DeleteRule(
            ruleData._id,
            deleteModal.id,
            () => startLoader('deleteRule'),
            handleDeleteRuleSuccess,
            handleError,
            () => stopLoader('deleteRule')
        );
    };

    const handleDeleteRuleSuccess = () => {
        toast.success('Rule deleted!');
        getRules();
    };

    const fetchDataSourceList = () => {
        regulationsService.readDataSourceList(
            () => startLoader('fetchDataSourceList'),
            handleDataSourceListSuccess,
            handleError,
            () => stopLoader('fetchDataSourceList')
        );
    };

    const handleDataSourceListSuccess = ({ data }) => {
        setDataSourceList(
            data.data.map((item) => ({
                label: item,
                value: item,
            }))
        );
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSubmit = (value) => {
        value['_id'] = config._id;
        CronService.AddRule(
            value,
            () => startLoader('addRule'),
            handleAddRuleSuccess,
            handleError,
            () => stopLoader('addRule')
        );
    };

    const handleAddRuleSuccess = () => {
        toast.success('Rule added!');
        setAddFormModal(false);
        if (ruleData?._id) {
            getRules();
        }
    };

    return (
        <div>
            <DeleteModal deletefunction={DeleteRule} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}></DeleteModal>
            <div className={classes.AllDevices}>
                <ModalComponent isOpen={addFormModal} setOpen={(status) => setAddFormModal(status)} style={{ overflow: 'initial' }}>
                    <div style={{ height: 'auto', width: '20vw' }}>
                        <div className={cronClasses.ModalWrapper}>
                            <Typography content={'Add Rule'} />
                        </div>

                        <div className={classes.FormContent}>
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                validationSchema={cronJobsRulesValidation}
                                onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                            >
                                {({ errors, touched, values, isValidating, ...props }) => {
                                    return (
                                        <Form>
                                            <div>
                                                <label className={cronClasses.label}>
                                                    Select Collection <span className="required">*</span>
                                                </label>
                                                <DropDown name="collection" options={dataSourceList} />
                                            </div>
                                            <div>
                                                <label className={cronClasses.label}>
                                                    Preserve Days <span className="required">*</span>
                                                </label>
                                                <DropDown name="preserveDays" options={PRESERVE_DAYS_OPTION} />
                                            </div>
                                            {values.preserveDays !== '' && (
                                                <div>
                                                    <label className={cronClasses.label}>
                                                        Value <span className="required">*</span>
                                                    </label>
                                                    <Input type={'number'} name="value" style={{ marginTop: '0' }} />
                                                </div>
                                            )}

                                            <div>
                                                <div style={{ display: 'flex', gap: '0.4vw' }}>
                                                    <button className="btn-secondary" onClick={() => setAddFormModal(false)}>
                                                        Cancel
                                                    </button>
                                                    <button type="submit" className="btn-primary">
                                                        Add
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </ModalComponent>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Clean Up Rules" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={tableData.length} />
                            </span>
                        </div>
                        <div>
                            <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddFormModal(true)}>
                                Add Rule
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <Table head={['S.No', 'Collection', 'Preserve Days', 'Action']} keys={['s.no', 'collection', 'preserveDays', 'action']} data={tableData} />
                </div>
            </div>
        </div>
    );
};

export default Rule;
