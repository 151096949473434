import classes from './Home.module.css';
import React, { useContext, useEffect, useState } from 'react';
import Customer from './Customers';
import TabsComponent from '../../components/Tabs/Tabs';
import { LoaderContext } from '../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../constants';
import CreateCustomer from './Add Customer';
import CreateUser from './AddUser';
import SupportNotes from './SupportNotes/SupportNotes';
import ExternalApis from './ExternalApi';
import { CustomerService } from '../../services/CustomerService';
import FCRAggrements from '../RevenueSplit/FcrAggrements';
import CreateFCRAggrement from '../RevenueSplit/FcrAggrements/CreateFCRAggrement';
import { regulationsServiceAdmin } from '../../services/regulationsService';
import ExcludeDates from '../RevenueSplit/ExcludeDates';
import Instances from './Instances/index';
import LicensesV2 from './CreateLicense/createLicense-v2';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

const Home = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const getQueryData = () => {
        return {
            tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
            customerForm: queryParams.get('customerForm') && queryParams.get('customerForm') === 'true' ? Boolean(queryParams.get('customerForm')) : false,
            licenseForm: queryParams.get('licenseForm') ? JSON.parse(queryParams.get('licenseForm')) : '',
            showRevenueSplit: queryParams.get('showRevenueSplit') ? JSON.parse(queryParams.get('showRevenueSplit')) : '',
            showExternalApi: queryParams.get('showExternalApi') ? JSON.parse(queryParams.get('showExternalApi')) : '',
            customerType: queryParams.get('customerType') ? JSON.parse(queryParams.get('customerType')) : '',
            instanceFormModal: queryParams.get('instanceFormModal') ? JSON.parse(queryParams.get('instanceFormModal')) : '',
            licenseFormModal: queryParams.get('licenseFormModal') ? JSON.parse(queryParams.get('licenseFormModal')) : '',
            customer: queryParams.get('customer') ? JSON.parse(queryParams.get('customer')) : '',
            noteModal: queryParams.get('noteModal') && queryParams.get('noteModal') === 'true' ? Boolean(queryParams.get('noteModal')) : false,
            excludeModal: queryParams.get('excludeModal') && queryParams.get('excludeModal') === 'true' ? Boolean(queryParams.get('excludeModal')) : false,
        };
    };

    const [queryParamsData, setQueryParamsData] = useState(getQueryData());

    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [refresh, setRefresh] = useState('');
    const [prefillGroup, setPrefillGroup] = useState('');
    const [device, setDevice] = useState({
        name: '',
        uId: '',
    });
    const [customer, setCustomer] = useState({});
    const [customersData, setCustomersData] = useState([]);
    const [user, setUser] = useState({});
    const [showCustomerForm, setShowCustomerForm] = useState(queryParamsData.customerForm ? queryParamsData.customerForm : false);
    const [showUserForm, setShowUserForm] = useState(false);
    const [showExternalApi, setShowExternalApi] = useState(
        queryParamsData.showExternalApi
            ? queryParamsData.showExternalApi
            : {
                  status: false,
                  customerId: '',
              }
    );
    const [selectedTab, setSelectedTab] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    const [showRevenueSplit, setShowRevenueSplit] = useState(
        queryParamsData.showRevenueSplit
            ? queryParamsData.showRevenueSplit
            : {
                  status: false,
                  data: {},
              }
    );
    const [aggrement, setAggrement] = useState({});
    const [regulations, setRegulations] = useState([]);
    const [showInstances, setShowInstances] = useState({
        status: false,
        customer: '',
    });
    const [showLicenses, setShowLicenses] = useState(
        queryParamsData.licenseForm
            ? queryParamsData.licenseForm
            : {
                  status: false,
                  data: '',
              }
    );

    useEffect(() => {
        if (selectedTab !== 3 && showCustomerForm) {
            setShowCustomerForm(false);
            setCustomer({});
        }
        if (selectedTab !== 3 && showUserForm.status) {
            setShowUserForm({ status: false, customerId: '' });
        }
        if (selectedTab !== 3 && showExternalApi.status) {
            setShowExternalApi(false);
        }
        if (selectedTab !== 3 && showInstances.status) {
            setShowInstances({ status: false, customer: '' });
        }
        if (selectedTab !== 3 && showLicenses.status) {
            setShowLicenses({ status: false, data: '' });
        }
        updateQueryParams('tab', selectedTab);
        if (selectedTab === 3) {
            setQueryParamsData(getQueryData);
        }
    }, [selectedTab]);

    useEffect(() => {
        updateQueryParams('customerForm', showCustomerForm);
    }, [showCustomerForm]);

    useEffect(() => {
        updateQueryParams('licenseForm', JSON.stringify(showLicenses));
    }, [showLicenses]);

    useEffect(() => {
        updateQueryParams('showRevenueSplit', JSON.stringify(showRevenueSplit));
    }, [showRevenueSplit]);

    useEffect(() => {
        updateQueryParams('showExternalApi', JSON.stringify(showExternalApi));
    }, [showExternalApi]);

    const handleError = (err) => {
        console.log(err);
    };

    useEffect(() => {
        CustomerService.ReadAll({ isActive: true }, startLoader, handleFetchSucess, handleError, stopLoader);
    }, [refresh]);

    const handleFetchSucess = ({ data }) => {
        setCustomersData(data.data);
    };

    useEffect(() => {
        fetchRegulations();
    }, []);

    const fetchRegulations = () => {
        regulationsServiceAdmin.RealAll(startLoader, handleRegulationsSuccess, handleError, stopLoader);
    };

    const handleRegulationsSuccess = ({ data }) => {
        const rgs = [];
        data?.data?.forEach((reg) => {
            rgs.push(reg.name);
        });
        setRegulations(rgs);
    };

    const updateQueryParams = (type, value) => {
        let queryParamsNew = queryParams;
        if (type === 'tab') {
            queryParamsNew = new URLSearchParams();
        }
        queryParamsNew.set(type, value);
        history.push({ search: queryParamsNew.toString() });
    };

    let tabs = [
        {
            name: 'Customers',
            component: (
                <>
                    <Customer
                        setCustomer={(e) => {
                            setCustomer(e);
                            setShowCustomerForm(true);
                        }}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        setUser={(e) => {
                            setUser(e);
                            setShowUserForm({ status: true, customerId: '' });
                        }}
                        customersData={customersData}
                        setShowCustomerForm={setShowCustomerForm}
                        setShowUserForm={setShowUserForm}
                        setShowRevenueSplit={setShowRevenueSplit}
                        setSelectedTab={setSelectedTab}
                        setShowExternalApi={setShowExternalApi}
                        setShowInstances={setShowInstances}
                        setShowLicenses={setShowLicenses}
                        updateQueryParams={updateQueryParams}
                        queryParamsData={queryParamsData}
                    />
                </>
            ),
        },
        {
            name: 'Support Notes',
            component: (
                <>
                    <SupportNotes customersData={customersData} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        {
            name: 'Excluded Dates',
            component: (
                <>
                    <ExcludeDates regulations={regulations} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                </>
            ),
        },
        ...(showCustomerForm
            ? [
                  {
                      name: !customer?._id ? 'Create Customer' : 'Edit Customer',
                      component: (
                          <>
                              <CreateCustomer customerdata={customer} setRefresh={setRefresh} setSelectedTab={setSelectedTab} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(showUserForm.status
            ? [
                  {
                      name: !user?._id ? 'Create User' : 'Edit User',
                      component: (
                          <>
                              <CreateUser editUser={user} autoFillCustomerId={showUserForm.customerId} setRefresh={setRefresh} customersData={customersData} />
                          </>
                      ),
                  },
              ]
            : []),

        ...(showRevenueSplit.status && (selectedTab === 3 || selectedTab === 4)
            ? [
                  {
                      name: 'Revenue Split',
                      component: (
                          <>
                              <FCRAggrements setAggrement={setAggrement} customer={showRevenueSplit.data} setSelectedTab={setSelectedTab} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(showRevenueSplit.status && selectedTab === 4
            ? [
                  {
                      name: Object.keys(aggrement).length ? 'Edit Aggrement' : 'CreateAggrement',
                      component: (
                          <>
                              <CreateFCRAggrement
                                  regulations={regulations}
                                  editAggrement={aggrement}
                                  customerId={showRevenueSplit?.data?._id}
                                  biddingMarkets={showRevenueSplit.data?.biddingMarkets}
                              />
                          </>
                      ),
                  },
              ]
            : []),
        ...(showExternalApi.status
            ? [
                  {
                      name: 'External Api',
                      component: (
                          <>
                              <ExternalApis customersData={customersData} customerId={showExternalApi.customerId} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(showLicenses.status
            ? [
                  {
                      name: 'Licenses',
                      component: (
                          <>
                              <LicensesV2 customer={showLicenses.data} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                          </>
                      ),
                  },
              ]
            : []),
        ...(showInstances.status
            ? [
                  {
                      name: 'Instances',
                      component: (
                          <>
                              <Instances customer={showInstances.customer} updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />
                          </>
                      ),
                  },
              ]
            : []),
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={queryParamsData.tab ? queryParamsData.tab : user?._id || showUserForm.status ? 3 : selectedTab}
                resetIndex={device.name}
                setEditDevice={
                    customer._id
                        ? (e) => {
                              setCustomer(e);
                              setShowCustomerForm(showCustomerForm === false ? true : false);
                          }
                        : user?._id
                          ? (e) => {
                                setUser(e);
                                setShowUserForm({ status: showUserForm.status === false ? true : false, customerId: '' });
                            }
                          : () => {}
                }
                setPrefillGroup={setPrefillGroup}
                tabs={tabs}
                onChangeTab={(e) => {
                    setSelectedTab(e);
                }}
            />
        </div>
    );
};

export default Home;
