// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import _ from 'lodash';
// Internal module imports
import { useLoader } from '../../../../hooks/use-loader.hook';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
// css module imports
import classes from '../../../../styles/AllDevices.module.css';
import forceActivationClasses from './forceActivation.module.css';
import { UpdateStatus } from './ModalComponent/UpdateStatus';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { RootGlobalActivationService } from '../../../../services/RootGlobalActivationService';
import { momentTimeFormater } from '../../../../utils/timeHelper';

export const ForceActivation = () => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [updateModal, setUpdateModal] = useState({
        status: false,
    });

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        RootGlobalActivationService.GetForceActivation(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const proccessed = _.get(data, ['data', 'properties'], null);
        proccessed &&
            setData([
                {
                    bidDate: proccessed.bidDate ? momentTimeFormater(proccessed.bidDate).format('YYYY-MM-DD') : '--',
                    startDate: proccessed.startDate ? momentTimeFormater(proccessed.startDate).format('YYYY-MM-DD') : '--',
                    endDate: proccessed.endDate ? momentTimeFormater(proccessed.endDate).format('YYYY-MM-DD') : '--',
                    status: proccessed.status,
                    updatedAt: data.data.updatedAt,
                },
            ]);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent
                isOpen={updateModal.status}
                setOpen={() => {
                    setUpdateModal({ status: false });
                }}
                style={{ overflow: 'initial' }}
            >
                <UpdateStatus setOpen={setUpdateModal} getData={getData} />
            </ModalComponent>
            <div className={forceActivationClasses.ForceActivationContainer}>
                <Typography content="Global Force activation" />
                <div>
                    <button
                        type="submit"
                        className="btn-primary"
                        style={{ marginTop: '0' }}
                        onClick={() =>
                            setUpdateModal({
                                status: true,
                            })
                        }
                    >
                        Update Status
                    </button>
                </div>
            </div>
            <div>
                <Table head={['Status', 'Bid Date', 'Start Date', 'End Date', 'Updated At']} keys={['status', 'bidDate', 'startDate', 'endDate', 'updatedAt']} data={data} />
            </div>
        </div>
    );
};
