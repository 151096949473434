// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilSync } from '@iconscout/react-unicons';
// Internal module imports
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/AllDevices.module.css';
import { useLoader } from '../../../../hooks';
import { RootAfrrService } from '../../../../services/RootAfrrService';
import afrrClasses from './index.module.css';
import { ToggleButtonWithState } from '../../../../components/Inputs/Input';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

const Dashboard = () => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [isAutoRefresh, setIsAutorefresh] = useState(false);
    const [seconds, setSeconds] = useState(10);
    const [counterInterval, setCounterInterval] = useState(null);

    useEffect(() => {
        getRedisData();
    }, []);

    const getRedisData = (showLoader = true) => {
        RootAfrrService.GetRedisData(showLoader ? startLoader : () => {}, handleSuccess, handleError, showLoader ? stopLoader : () => {});
    };

    const handleSuccess = ({ data }) => {
        const processedData = data.data.map((item, index) => ({
            sno: index + 1,
            key: item.key,
            value: item.value,
        }));

        setData(processedData);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    useEffect(() => {
        if (isAutoRefresh) {
            const interval = setInterval(() => {
                if (seconds > 0) {
                    setSeconds(seconds - 1);
                } else {
                    getRedisData(false);
                    setSeconds(10);
                }
            }, 1000);
            setCounterInterval(interval);
            return () => clearInterval(interval);
        }
    }, [isAutoRefresh, seconds]);

    return (
        <div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Redis Console" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={data.length || 0} />
                            </span>
                        </div>
                    </div>
                    <div className={afrrClasses.RefreshContainer}>
                        <div>
                            <CustomTooltip content={'Refresh'}>
                                <div>
                                    <UilSync
                                        size={'1.5vw'}
                                        style={{
                                            cursor: 'pointer',
                                            marginTop: '0.4vw',
                                            color: 'var(--color-primary)',
                                        }}
                                        onClick={getRedisData}
                                    />
                                </div>
                            </CustomTooltip>
                        </div>
                        <div className={afrrClasses.TimerContainer}>
                            <ToggleButtonWithState
                                label={'Auto Refresh'}
                                value={isAutoRefresh}
                                style={{ marginTop: '0' }}
                                onChange={(e) => {
                                    setIsAutorefresh(e.target.checked);
                                    clearInterval(counterInterval);
                                    setSeconds(10);
                                }}
                            />
                            {isAutoRefresh && <label className={afrrClasses.TimerLabel}>in {seconds} seconds</label>}
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table head={['S.No', 'Label', 'Value']} keys={['sno', 'key', 'value']} data={data} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
