// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { UilCopy } from '@iconscout/react-unicons';

// Internal module imports
import Table from '../Table/Table';
import CustomTooltip from '../CustomToolTip/CustomTooltip';

const DefaultStringLength = 30;

const DynamicTables = ({ tables }) => {
    const [tableData, setTableData] = useState([]);

    const dynamicTable = (data, title = '', tables = []) => {
        const currentTable = { name: title ? title : 'General', table: [] };
        const obj = [];
        for (let key in data) {
            const value = data[key];
            const isArray = Array.isArray(value);
            const isBoolean = typeof value === 'boolean';
            if (typeof value === 'object' && !isArray) {
                obj.push({ key: key, value });
            } else {
                const val = isBoolean ? value.toString() : isArray && value.length ? value.join(', ') : value.length === 0 ? '--' : value;
                if (currentTable.name === 'General' && key === '_id') {
                    currentTable.table.unshift({
                        key,
                        value: <div>{val}</div>,
                        action: (
                            <div>
                                <CopyToClipboard text={val} onCopy={() => toast.success('Copied!')}>
                                    <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CopyToClipboard>
                            </div>
                        ),
                    });
                } else {
                    currentTable.table.push({
                        key,
                        value: (
                            <div>
                                {val.length > DefaultStringLength ? <CustomTooltip content={val}>{val.slice(0, DefaultStringLength) + '...'}</CustomTooltip> : <div>{val} </div>}
                            </div>
                        ),
                        action: (
                            <div>
                                <CopyToClipboard text={val} onCopy={() => toast.success('Copied!')}>
                                    <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </CopyToClipboard>
                            </div>
                        ),
                    });
                }
            }
        }
        tables.push(currentTable);
        for (let nestedObj of obj) {
            const tableTitle = title ? `${title} > ${nestedObj.key}` : nestedObj.key;
            dynamicTable(nestedObj.value, tableTitle, tables);
        }
        return tables;
    };

    useEffect(() => {
        const tableData = dynamicTable(tables);
        setTableData(tableData);
    }, [tables]);

    return (
        <div>
            {tableData.map((tableObj) => {
                const title = tableObj.name.charAt(0).toUpperCase() + tableObj.name.slice(1);
                return (
                    <div>
                        <h3>{title}:</h3>
                        <div style={{ maxHeight: '55vh', overflowY: 'scroll' }}>
                            <Table head={['S.No', 'Properties', 'properties-Value', 'Action']} keys={['key', 'value', 'action']} data={tableObj.table} addIndex={true} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default DynamicTables;
