// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import moment from 'moment/moment';
// Internal module imports
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import classes from '../../../../styles/AllDevices.module.css';
import { RootAfrrService } from '../../../../services/RootAfrrService';

const LIMIT = 15;

const PowerLogs = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [tableData, setTableData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        getLogs();
    }, [date, skip]);

    const getLogs = () => {
        const params = {
            limit: LIMIT,
            skip,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
        };
        RootAfrrService.GetPowerLogs(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data?.data?.map((item) => ({
            edge: item?.edge || '--',
            power: item?.value?.power || item?.value?.power === 0 ? item?.value?.power : '--',
            area: item?.value?.area || '--',
            market: item?.value?.market || '--',
            activationDirection: item?.value?.activationDirection || '--',
            mode: item?.value?.mode || '--',
            timestamp: item?.timestamp ? momentTimeFormater(item?.timestamp).format('YYYY-MM-DD HH:mm') : '--',
        }));
        setTableData(processedData);
        setTotalCount(data?.data?.count || 0);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Power Logs" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <DatePicker date={date} setDate={setDate} showArrow={true} />
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={['Edge', 'Timestamp (CET)', 'Power', 'Market', 'Area', 'Activation Direction', 'Mode']}
                            keys={['edge', 'timestamp', 'power', 'market', 'area', 'activationDirection', 'mode']}
                            data={tableData}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PowerLogs;
