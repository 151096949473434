// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    bidStatusUpdate: '/admin/data/bid-status',
    bidRequest: '/root/bid-request',
    deleteBidRequest: '/root/bid-request',
    maintenanceHours: '/root/bid-request/maintenance-hours',
    deleteMaintenanceHours: '/root/bid-request/maintenance-hours',
};

const UpdateBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.bidStatusUpdate}/${id}`).then(callback).catch(error).finally(next);
};

const GetBidRequest = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidRequest}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteBidRequest = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBidRequest}/${id}`).then(callback).catch(error).finally(next);
};

const GetMaintenanceHours = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.maintenanceHours}`, { params }).then(callback).catch(error).finally(next);
};

const DeleteMaintenanceHours = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteMaintenanceHours}/${id}`).then(callback).catch(error).finally(next);
};

export const BidService = {
    UpdateBidStatus,
};

export const RootBidRequestService = {
    GetBidRequest,
    DeleteBidRequest,
    UpdateBidStatus,
    GetMaintenanceHours,
    DeleteMaintenanceHours,
};
