export const usToEuCurrencyFormat = (input, toFixed = 2) => {
    input = Number(input).toFixed(toFixed);
    return String(input).replace(/[,.]/g, function (x) {
        return x == ',' ? '.' : ',';
    });
};

export const dkCurrencyFormat = (input) => {
    return input.toLocaleString('da-DK');
};

export const euToUsCurrencyFormat = (input) => {
    return String(input).replace(/[,.]/g, function (x) {
        return x == '.' ? '' : '.';
    });
};

export const toDkFormatter = (input) => {
    return Number(input).toLocaleString('da-DK', { minimumFractionDigits: 2 });
};