import classes from '../../../../styles/AllDevices.module.css';
import React, { useContext, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { CRONJOBS_NAMES, START_LOADER, STOP_LOADER } from '../../../../constants';
import { CronService } from '../../../../services/cronServices';
import { LoaderContext } from '../../../../context/LoaderContext';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import cronClasses from './CronJobs.module.css';
import { Input, ToggleButton, ToggleButtonWithState } from '../../../../components/Inputs/Input';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { cronJobsValidation } from '../../../../validations/Root/cronJobsValidation';
import { useEffect } from 'react';
import { UilSetting } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import { UilPen } from '@iconscout/react-unicons';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import Rule from './Rule/Rule';
import InstancePowerCheckRule from './Rule/InstancePowerCheckRule';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

function CronJobs({ setSelectedIndex, setCronConfigPage }) {
    const [cron, setCron] = useState([]);
    const [allCrons, setAllCrons] = useState([]);
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const [addCronModal, setAddCronModal] = useState(false);
    const [count, setCount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });

    const [initialValues, setInitialValues] = useState({
        name: '',
        pattern: '',
        enable: true,
        lastRun: '',
    });

    const cronsIdMap = {
        '649a86889a4dfc85a0184f96': '',
        '649a86e19a4dfc85a0184f97': '',
        '649a883f9a4dfc85a0184f9b': '',
        '649a88059a4dfc85a0184f99': '',
        '649a881d9a4dfc85a0184f9a': '',
        '649a88729a4dfc85a0184f9c': '',
        '649a88949a4dfc85a0184f9d': '',
        '649a88bf9a4dfc85a0184f9e': '',
        '649a890b9a4dfc85a0184f9f': '',
        '649a892d9a4dfc85a0184fa0': '',
        '645dfe4b4e6fd034dcb29569': Rule,
        '649a89ae9a4dfc85a0184fa1': '',
        '649a89f09a4dfc85a0184fa2': '',
        '649a8a0c9a4dfc85a0184fa3': '',
        '649a8a3c9a4dfc85a0184fa4': '',
        '649a8a6a9a4dfc85a0184fa5': '',
        '649a8a929a4dfc85a0184fa6': '',
        '649a8aa99a4dfc85a0184fa7': '',
        '649a8adf9a4dfc85a0184fa8': '',
        '649a8b2b9a4dfc85a0184fa9': '',
        '649a8b849a4dfc85a0184faa': '',
        '649a8bc59a4dfc85a0184fab': '',
        '649a8be19a4dfc85a0184fac': '',
        '649a8bfd9a4dfc85a0184fad': '',
        '649a8c169a4dfc85a0184fae': '',
        '649a8c319a4dfc85a0184faf': '',
        '649a8c459a4dfc85a0184fb0': '',
        '649a87029a4dfc85a0184f98': '',
        '649a8c6e9a4dfc85a0184fb1': '',
        '649a8c8a9a4dfc85a0184fb2': '',
        '649a8ca99a4dfc85a0184fb3': InstancePowerCheckRule,
        '649a8cc99a4dfc85a0184fb4': '',
        '649a8cf09a4dfc85a0184fb5': '',
        '649a8d5a9a4dfc85a0184fb6': '',
        '649a8d789a4dfc85a0184fb7': '',
        '649a8da29a4dfc85a0184fb8': '',
        '649a8dbf9a4dfc85a0184fb9': '',
        '64a2a312af287ea9bcd4503d': '',
    };

    useEffect(() => {
        getCrons();
    }, []);

    const getCrons = () => {
        CronService.ReadAll(
            () => startLoader(),
            handleFetchCronSuccess,
            handleError,
            () => stopLoader()
        );
    };

    const handleSetting = (cron) => {
        if (cronsIdMap[cron._id]) {
            const ConfigComponent = cronsIdMap[cron._id];
            setCronConfigPage(<ConfigComponent config={cron} />);
            setSelectedIndex(1);
        } else {
            toast.error('Config not found.');
        }
    };

    const handleDelete = (id) => {
        CronService.DeleteCron(
            id,
            () => startLoader(),
            handleDeleteSuccess,
            handleError,
            () => stopLoader()
        );
    };

    const handleDeleteSuccess = () => {
        toast.success('Cron Deleted!');
        getCrons();
    };

    const editCron = (cron) => {
        delete cron.properties;
        setInitialValues(cron);
        setAddCronModal(true);
    };

    const updateStatus = (value, cron) => {
        const params = {
            ...cron,
            enable: value,
        };

        if (cron._id) {
            CronService.Update(
                cron._id,
                params,
                () => startLoader(),
                handleUpdateSuccess,
                handleError,
                () => stopLoader()
            );
        }
    };

    const handleFetchCronSuccess = ({ data }) => {
        const processedData = data?.data.map((cron, index) => ({
            ...cron,
            's.no': index + 1,
            cronJobName: cron?.name,
            pattern: cron?.pattern,
            enable: (
                <div>
                    <ToggleButtonWithState
                        value={cron?.enable || false}
                        onChange={(e) => updateStatus(e.target.checked, cron)}
                        label=""
                        style={{
                            marginTop: '0',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    />
                </div>
            ),
            lastRun: cron.lastRun ? momentTimeFormater(cron.lastRun).format('YYYY-MM-DD HH:mm') : '--',
            action: (
                <div className={cronClasses.cronActionConatiner}>
                    <CustomTooltip content={'Config'}>
                        <UilSetting
                            onClick={() => {
                                handleSetting(cron);
                            }}
                            style={{ color: 'var(--color-primary)' }}
                        />
                    </CustomTooltip>
                    <CustomTooltip content={'Edit'}>
                        <UilPen
                            onClick={() => {
                                editCron(cron);
                            }}
                            style={{ color: 'var(--color-primary)' }}
                        />
                    </CustomTooltip>
                    {/* <UilTrash
            onClick={() => {
              handleDelete(cron._id);
            }}
            style={{ color: "var(--color-primary)" }}
          /> */}
                </div>
            ),
            run: (
                <div className="cronActionConatiner">
                    <button
                        className="btn-primary"
                        mb={2}
                        onClick={() => {
                            HandleRunCron(cron._id);
                        }}
                    >
                        Run
                    </button>
                </div>
            ),
        }));
        setCount(processedData.length);
        setCron(processedData);
        setAllCrons(processedData);
    };

    useEffect(() => {
        if (searchValue !== '') {
            filterCronByName(searchValue);
        }
    }, [allCrons]);

    const HandleRunCron = (cronId) => {
        let params = { cronId };
        CronService.RunCron(
            params,
            () => startLoader(),
            handleSuccess,
            handleError,
            () => stopLoader()
        );
    };

    const handleApplyChanges = () => {
        CronService.ApplyChanges(startLoader, handleApplySuccess, handleError, stopLoader);
    };

    const handleApplySuccess = ({ data }) => {
        toast.success(data?.data?.message);
    };

    const handleSuccess = ({ data }) => {
        toast.success('Cron ran successfully!');
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSubmit = (value) => {
        const params = {
            name: value.name,
            pattern: value.pattern,
            enable: value.enable,
        };

        if (initialValues._id) {
            CronService.Update(
                initialValues._id,
                params,
                () => startLoader(),
                handleUpdateSuccess,
                handleError,
                () => stopLoader()
            );
        } else {
            CronService.Create(
                params,
                () => startLoader(),
                handleSubmitSuccess,
                handleError,
                () => stopLoader()
            );
        }
    };

    const handleSubmitSuccess = ({ data }) => {
        toast.success('Cron Created!');
        getCrons();
        setAddCronModal(false);
        setInitialValues({
            name: '',
            pattern: '',
            enable: true,
            lastRun: '',
        });
    };

    const handleUpdateSuccess = ({ data }) => {
        toast.success('Cron Updated!');
        getCrons();
        setAddCronModal(false);
        setInitialValues({
            name: '',
            pattern: '',
            enable: true,
            lastRun: '',
        });
    };

    const filterCronByName = (value) => {
        setSearchValue(value);
        const searchValue = value.toLowerCase().split(' ');
        const filterData = allCrons.filter((item) => {
            let name = item.cronJobName.toLowerCase();
            return searchValue.every((word) => name.includes(word));
        });
        setCron(filterData);
    };

    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={addCronModal} setOpen={(status) => setAddCronModal(status)} style={{ overflow: 'initial' }}>
                <div style={{ height: 'auto', width: '15vw' }}>
                    <div className={cronClasses.ModalWrapper}>
                        <Typography content={'Add Cron Job'} />
                    </div>
                    <div className={classes.FormContent}>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={cronJobsValidation}
                            onSubmit={(val, { resetForm }) => handleSubmit(val, resetForm)}
                        >
                            {({ errors, touched, values, isValidating, ...props }) => {
                                return (
                                    <Form>
                                        <div>
                                            <label className={cronClasses.label} style={{ position: 'relative', top: '1vw' }}>
                                                Name <span className="required">*</span>
                                            </label>
                                            <Input type={'text'} name="name" />
                                        </div>
                                        <div>
                                            <label className={cronClasses.label} style={{ position: 'relative', top: '1vw' }}>
                                                Pattern <span className="required">*</span>
                                            </label>
                                            <Input type={'text'} name="pattern" />
                                        </div>

                                        <div>
                                            <ToggleButton values={values} label={'Enable'} name={'enable'} />
                                        </div>

                                        <div>
                                            <div style={{ display: 'flex', gap: '0.4vw' }}>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setAddCronModal(false);
                                                        setInitialValues({
                                                            name: '',
                                                            pattern: '',
                                                            enable: true,
                                                            lastRun: '',
                                                        });
                                                    }}
                                                    type="button"
                                                >
                                                    Cancel
                                                </button>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <div className={classes.Header}>
                <div>
                    <Typography content="Cron Jobs" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={cron.length} />
                        </span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={handleApplyChanges}>
                            Apply Changes
                        </button>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddCronModal(true)}>
                            Add Cron Jobs
                        </button>
                    </div>
                </div>
                <div>
                    <div className={'inputStyle2'}>
                        <input
                            placeholder="Search by cron name"
                            style={{ width: '10vw !important', paddingRight: '1.4vw' }}
                            type="text"
                            name="cron"
                            id=""
                            onChange={(e) => {
                                filterCronByName(e.target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Table
                    head={['S.No', 'Cron ID', 'Cron Job Name', 'Cron Pattern', 'Status', 'Last Run', 'Action', 'Run']}
                    keys={['s.no', '_id', 'cronJobName', 'pattern', 'enable', 'lastRun', 'action', 'run']}
                    data={cron}
                />
            </div>
        </div>
    );
}

export default CronJobs;
