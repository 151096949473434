import React, { useEffect, useState } from 'react';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { AdminService } from '../../../../services/AdminService';
import { useLoader } from '../../../../hooks';
import { toast } from 'react-toastify';
import classes from './deleteAdmin.module.css';
import Typography from '../../../../components/Typography/Typography';

const DeleteAdmin = ({ admins, customers, currentAdmin, setOpen, refresh }) => {
    const [startLoader, stopLoader] = useLoader();
    const [adminOption, setAdminOption] = useState([
        {
            label: '',
            value: '',
        },
    ]);
    const [selectedAdmin, setSelectedAdmin] = useState({});

    useEffect(() => {
        let temp = [];
        admins.map((item) => {
            if (item._id !== currentAdmin._id) {
                temp.push({
                    label: item.name,
                    value: item._id,
                });
            }
            setAdminOption(temp);
        });
    }, [admins]);

    const deleteHandle = () => {
        const data = {
            id: currentAdmin._id,
            isCustomers: true,
            newAdminId: selectedAdmin.value,
        };
        AdminService.Delete(data, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };
    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Admin deleted');
            setOpen(false);
            refresh(true);
        }
    };
    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div>
            <Typography content={'Transfer Customers'} />
            <p className={classes.Text}>The following customers are linked to {currentAdmin?.name}</p>
            <div style={{ maxHeight: '13.021vw', overflowY: 'auto' }}>
                {customers &&
                    customers.map((item, index) => (
                        <li key={index} style={{ fontSize: '13px' }}>
                            {item.name}
                        </li>
                    ))}
            </div>
            <p className={classes.Text}>Select an admin to move the customers</p>
            <div className={classes.FieldControl}>
                <DropdownComponent name="admin" options={adminOption} onChange={(e) => setSelectedAdmin(e)} />
                {!selectedAdmin.value ? <div className="error-msg">Admin is required</div> : null}
            </div>
            <div>
                <div>
                    <div className={classes.ButtonContainer}>
                        <div type="submit" className="btn-secondary" onClick={() => setOpen(false)}>
                            Cancel
                        </div>
                        <div type="submit" className="btn-primary" onClick={selectedAdmin.value && deleteHandle}>
                            Submit
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteAdmin;
