// External library imports
import * as yup from 'yup';
// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

export const EdgeProfileValidation = () => {
    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        type: yup.string().required(ERRORS.required),
        profile: yup.mixed().test('Profile must be a non-empty object or array', ERRORS.required, (value) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            } else if (typeof value === 'object' && value !== null) {
                return Object.keys(value).length > 0;
            }
            return false;
        }),
    });
};
