// Standard library imports
import React from 'react';

// External module imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

// Internal module imports
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/AllDevices.module.css';
import { user } from '../../../../validations/Root/accessManagerValidator';
import { Input } from '../../../../components/Inputs/Input';
import { AccessManagerService } from '../../../../services/AccessManagerService';

const CreateUserModal = ({ setOpen, setRefresh, customer = {} }) => {
    const [startLoader, stopLoader] = useLoader();

    const initialValues = {
        customerId: customer?.value,
        customerName: '',
    };

    const handleSubmit = (values) => {
        AccessManagerService.Create(values, startLoader, handleCreateSuccess, handleError, stopLoader);
    };

    const handleCreateSuccess = () => {
        toast.success('User created!');
        setOpen(false);
        setRefresh(true);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    return (
        <div>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={user}>
                {({ errors, touched, values, isValidating, ...props }) => {
                    return (
                        <Form>
                            <div>
                                <Typography content={'Create IAM User'} />
                            </div>
                            <div className={classes.FieldControl2}>
                                <label for="customerName">Edge Alias Name</label>
                                <Input name="customerName" id="customerName" style={{ marginTop: '0' }} />
                            </div>
                            <div className={classes.FieldControl2}>
                                <label>Final Alias Name</label>
                                <div style={{ fontSize: '0.8vw' }}>
                                    {customer?.label && customer?.label.replace(/ /g, '-')}-{values.customerName}
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button className="btn-secondary" onClick={() => setOpen(false)} type="button">
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default CreateUserModal;
