import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const addAdminSignalsValidation = yup.object().shape({
    type: yup.string().required(ERRORS.required),
    area: yup.string().required(ERRORS.required),
    // queryString: yup.mixed().required(ERRORS.required),
    dataSource: yup.mixed().required(ERRORS.required),
    loggingType: yup.string().when('type', {
        is: 'Frequency',
        then: yup.string().required(ERRORS.required),
    }),
});
