// Standard library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { UilCopy } from '@iconscout/react-unicons';

// Internal module imports
import { useLoader } from '../../../../hooks';
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/Bids.module.css';
import customerClasses from '../index.module.css';
import { CustomerService } from '../../../../services/CustomerService';

const Info = ({ infoModal, setInfoModal }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [licenseCount, setLicenseCount] = useState(0);
    const [systemInfo, setSystemInfo] = useState({});

    useEffect(() => {
        let tempLicenseCount = 0;
        for (let l in infoModal.enabledLicense) {
            if (infoModal.enabledLicense[l].enabled) {
                tempLicenseCount += 1;
            }
        }
        setLicenseCount(tempLicenseCount);
    }, []);

    useEffect(() => {
        const tempData = [
            {
                name: 'Customer Name',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.name || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.name} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Company Name',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.companyName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.companyName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Customer Code',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.customerCode || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.customerCode} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Contact',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address?.contact || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address?.contact} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Primary Contact Name',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.primaryContactName || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.primaryContactName} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Primary Contact Email',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.primaryContactEmail || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.primaryContactEmail} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Billing Email',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.billingEmail || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.billingEmail} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Addess 1',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.address1 || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.address1} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Address 2',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.address2 || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.address2} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'City',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.city || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.city} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Zip',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.address.zip || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.zip} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
            {
                name: 'Vat',
                value: (
                    <div className={customerClasses.TableValueParent}>
                        <div className={customerClasses.TableValue}>{infoModal.vat || '--'}</div>
                    </div>
                ),
                action: (
                    <div>
                        <CopyToClipboard text={infoModal.address.vat} onCopy={() => toast.success('Copied!')}>
                            <UilCopy size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </CopyToClipboard>
                    </div>
                ),
            },
        ];
        setData(tempData);
        if (infoModal?._id) {
            getSystemStatus();
        }
    }, []);

    const getSystemStatus = () => {
        CustomerService.getSystemStatus(infoModal?._id, startLoader, handleSystemStatusSuccess, handleError, stopLoader);
    };

    const handleSystemStatusSuccess = (systemData) => {
        setSystemInfo(systemData.data.data);
    };

    const handleError = (error) => {
        let message = error?.response?.data?.message;
        toast.error(message);
    };

    return (
        <div>
            <div>
                <div>
                    <div>
                        <Typography content={'Customer Info'} />
                    </div>
                    <div className={customerClasses.CardContainer}>
                        <div className={customerClasses.Card}>
                            <p>Licenses</p>
                            <span>{licenseCount}</span>
                        </div>
                        <div className={customerClasses.Card}>
                            <p>Markets</p>
                            <span>{infoModal?.biddingMarkets?.length}</span>
                        </div>
                        <div className={customerClasses.Card}>
                            <p>Instances</p>
                            <span>{systemInfo.instanceCount}</span>
                        </div>
                        <div className={customerClasses.Card}>
                            <p>Storage</p>
                            <span>{systemInfo.storage} Mb</span>
                        </div>
                    </div>
                    <div className={customerClasses.TableContainer}>
                        <Table head={['Label', 'Value', 'Action']} keys={['name', 'value', 'action']} data={data} style={{ marginTop: '0' }} />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <div>
                            <button type="button" className="btn-primary" onClick={() => setInfoModal({ customer: {}, status: false })}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
