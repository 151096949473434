import { Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import { Input, TextArea, ToggleButton } from '../../../../components/Inputs/Input';
import Typography from '../../../../components/Typography/Typography';
import classes from './index.module.css';
import { toast } from 'react-toastify';
import { addVersionValidator } from '../../../../validations/SystemTools/addVersionValidation';
import { VersionHistoryService } from '../../../../services/VersionHistoryService';
import Dropdown from '../../../../components/Inputs/Dropdown';

function AddVersion({ setOpen, data, startLoader, stopLoader, setEditData, fetchNotes, setSendNotificationModal }) {
    const [initialValues, setinitialValues] = useState({
        title: '',
        description: '',
        released: new Date().toISOString().split('T')[0],
        enable: false,
        application: '',
        type: '',
        releaseLink: '',
    });

    useEffect(() => {
        if (data?._id) {
            setinitialValues({
                ...data,
                released: data?.released.split('T')[0],
            });
        }
    }, [data]);

    const applicationDropdownOptions = [
        { label: 'Web', value: 'web' },
        { label: 'IOT', value: 'iot' },
    ];
    const typeDropdownOptions = [
        { label: 'Internal', value: 'internal' },
        { label: 'External', value: 'external' },
    ];
    const handleSubmit = (val) => {
        let payload = {
            version: {
                ...val,
                released: new Date(val?.released),
            },
        };
        if (data?._id) {
            VersionHistoryService.Update(
                data?._id,
                payload,
                () => startLoader('getVersions'),
                handleSuccess,
                handleError,
                () => stopLoader('getVersions')
            );
        } else {
            VersionHistoryService.Create(
                payload,
                () => startLoader('getVersions'),
                handleSuccess,
                handleError,
                () => stopLoader('getVersions')
            );
        }
    };
    const handleSuccess = ({ data }) => {
        console.log('datat', data, data?.data?._id);
        setOpen(false);
        setEditData({});
        fetchNotes();
        if (data?.data?._id) {
            setSendNotificationModal({ status: true, id: data?.data?._id });
        }
    };
    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div className={classes.SupportNoteForm}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={addVersionValidator}
                onSubmit={(val) => {
                    handleSubmit(val);
                }}
            >
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div>
                            <Typography content="Version History" />
                            <div>
                                <div>
                                    <div className={classes.FieldControl}>
                                        <label for="title">
                                            Title <span className="required">*</span>
                                        </label>
                                        <Input
                                            name="title"
                                            id="title"
                                            setFieldValue={props.setFieldValue}
                                            setFieldTouched={props.setFieldTouched}
                                            getFieldMeta={props.getFieldMeta}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.FieldControl}>
                                        <label for="enable">Enabled</label>
                                        <ToggleButton name="enable" label="Enable" values={values} />
                                    </div>
                                </div>
                                <div className={classes.FieldControl}>
                                    <label for="released">
                                        Released <span className="required">*</span>
                                    </label>
                                    <Input
                                        type="date"
                                        name="released"
                                        id="released"
                                        setFieldValue={props.setFieldValue}
                                        setFieldTouched={props.setFieldTouched}
                                        getFieldMeta={props.getFieldMeta}
                                    />
                                </div>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl2}>
                                        <label for="application">
                                            Application <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="application"
                                            options={applicationDropdownOptions}
                                            defaultValue={
                                                initialValues.application
                                                    ? applicationDropdownOptions.filter((item) => item.value === initialValues.application)[0]
                                                    : applicationDropdownOptions[0]
                                            }
                                            setFieldValue={props.setFieldValue}
                                        />
                                    </div>
                                    <div className={classes.FieldControl2}>
                                        <label for="type">
                                            Type <span className="required">*</span>
                                        </label>
                                        <Dropdown
                                            name="type"
                                            options={typeDropdownOptions}
                                            defaultValue={initialValues.type ? typeDropdownOptions.filter((item) => item.value === initialValues.type)[0] : typeDropdownOptions[0]}
                                            setFieldValue={props.setFieldValue}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.FieldControl}>
                                        <label for="description">
                                            Description <span className="required">*</span>
                                        </label>
                                        <TextArea
                                            name="description"
                                            label="description"
                                            setFieldValue={props.setFieldValue}
                                            setFieldTouched={props.setFieldTouched}
                                            getFieldMeta={props.getFieldMeta}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className={classes.FieldControl}>
                                        <label for="releaseLink">
                                            JIRA Release Link <span className="required">*</span>
                                        </label>
                                        <Input name="releaseLink" id="releaseLink" setFieldValue={props.setFieldValue} />
                                    </div>
                                </div>
                                <div className={classes.ButtonContainer}>
                                    <div>
                                        <button
                                            className="btn-secondary"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpen(false);
                                                setEditData({});
                                            }}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div>
                                        <button className="btn-primary" type="submit">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default AddVersion;
