import classes from '../../../../styles/AllDevices.module.css';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { LoaderContext } from '../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';
import { Input } from '../../../../components/Inputs/Input';
import { addAreasValidation } from '../../../../validations/SystemTools/addAreasValidation';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { areaService } from '../../../../services/areaService';
import { UilTrash } from '@iconscout/react-unicons';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

function Areas() {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [areaTableData, setAreaTableData] = useState([]);
    const [addAreaModal, setAddAreaModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ status: false, areaId: '' });

    useEffect(() => {
        fetchAreas();
    }, []);

    const fetchAreas = () => {
        areaService.ReadAll(
            () => startLoader('getAreasSignals'),
            handleSuccess,
            handleError,
            () => stopLoader('getAreasSignals')
        );
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            title: key.name,
            action: (
                <div>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, areaId: key._id })} />
                    </CustomTooltip>
                </div>
            ),
        }));
        setAreaTableData(processedData);
    };

    const handleSubmit = (values) => {
        const payload = { ...values };
        let params = {};
        areaService.createArea(
            payload,
            params,
            () => startLoader('createdArea'),
            handleCreateSuccess,
            handleError,
            () => stopLoader('createdArea')
        );
    };

    const handleCreateSuccess = (res) => {
        fetchAreas();
        toast.success('area created sucessfully');
        setAddAreaModal(false);
    };

    const handleDelete = () => {
        if (deleteModal.areaId) {
            areaService.deleteArea(
                deleteModal.areaId,
                startLoader,
                () => {
                    fetchAreas();
                    toast.success('area deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={addAreaModal} setOpen={setAddAreaModal}>
                <Typography content="Add Areas" />
                <div className={classes.SupportNoteForm}>
                    <Formik
                        initialValues={{ name: '' }}
                        enableReinitialize
                        validationSchema={addAreasValidation}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div>
                                    <div>
                                        <div>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Name <span className="required">*</span>
                                                </label>
                                                <Input
                                                    name="name"
                                                    id="name"
                                                    setFieldValue={props.setFieldValue}
                                                    setFieldTouched={props.setFieldTouched}
                                                    getFieldMeta={props.getFieldMeta}
                                                />
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button
                                                    className="btn-secondary"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setAddAreaModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                {/* <div> */}
                <div>
                    <Typography content="Areas" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{areaTableData.length}</span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddAreaModal(true)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Title', 'Action']} keys={['s.no', 'title', 'action']} data={areaTableData} />
            </div>
        </div>
    );
}

export default Areas;
