// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getAccounts: '/root/credits/accounts',
    create: '/root/credits',
    get: '/root/credits',
};

const GetAccounts = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getAccounts, { params }).then(callback).catch(error).finally(next);
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const GetLogs = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.get}/${id}`, { params }).then(callback).catch(error).finally(next);
};

export const CreditsServiceRoot = { GetAccounts, Create, GetLogs };
