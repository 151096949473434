// Standard library imports
import React from 'react';
// Internal module imports
import CreateSecondaryBid from '../../../Bidding/ModalContent/CreateSecondaryBid';

export const CreateD1Bid = ({ market, bid, fetchBids, setOpen }) => {
    const renderComponent = () => {
        return <CreateSecondaryBid bid={bid} market={market} setOpen={setOpen} fetchBids={fetchBids} />;
    };
    return <div>{renderComponent()}</div>;
};
