import React from 'react';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import classes from './index.module.css';

const VersionDetails = ({ version, setOpen }) => {
    return (
        <div className={classes.VersionDetailsWrapper}>
            <div>
                <div className={classes.TitleWrapper}>
                    <div className={classes.Title}> {version.title}</div>
                </div>
                <div className={classes.SubTitleWrapper}>
                    <div className={classes.SubTitle}>
                        {' '}
                        {version?.application} - {version?.type}
                    </div>
                    <div className={classes.SubTitle}>{version.released ? momentTimeFormater(version.released).format('YYYY-MM-DD') : '--'}</div>
                </div>
            </div>
            <div className={classes.Content}>
                <ul>
                    {version.description
                        ?.split('\n')
                        .filter((e) => !!e.replace(/\s/g, ''))
                        .map((e) => (
                            <li>{e}</li>
                        ))}
                </ul>
            </div>
            <div className={classes.ButtonContainer}>
                <div>
                    <button className="btn-secondary" onClick={setOpen}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default VersionDetails;
