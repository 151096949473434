// External library import
import * as yup from 'yup';

// Internal module imports
import { ERRORS } from '../../utils/validationHelper';

export const EdgeSystem = (customerCode = null) => {
    return yup.object().shape({
        properties: yup.object().shape({
            teamviewer: yup.object().shape({
                id: yup.string().min(6, 'Please enter valid id').max(10, 'Please enter valid id').required(ERRORS.required),
                password: yup.string().required(ERRORS.required),
            }),
            anydesk: yup.object().shape({
                id: yup.string().required(ERRORS.required),
                password: yup.string().required(ERRORS.required),
            }),
        }),
        alias: yup.string().notOneOf([customerCode], 'Alias and Customer code cannot be same, please modify Alias'),
    });
};
