import React from 'react';
import TimePicker from 'react-time-picker';
import classes from './TimePicker.module.css';
function CustomTimePicker({ disableClock = true, setStartTime, setEndTime, startTime, endTime, type, disabled, style = {}, maxDetail = 'minute', ...props }) {
    return (
        <div>
            <div className={classes.TimeContainer} style={style}>
                {type == 'start' ? (
                    <div className={classes.Time} style={disabled ? { opacity: '0.5' } : {}}>
                        <TimePicker disableClock={disableClock} onChange={setStartTime} value={startTime} disabled={disabled} maxDetail={maxDetail} />
                    </div>
                ) : (
                    <div className={classes.Time} style={disabled ? { opacity: '0.5' } : {}}>
                        <TimePicker disableClock={disableClock} onChange={setEndTime} value={endTime} disabled={disabled} maxDetail={maxDetail} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default CustomTimePicker;
