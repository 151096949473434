// Standard Library Imports
import React from 'react';

// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// // Internal library imports
import { useLoader } from '../../../../hooks';
import { Input, TextArea } from '../../../../components/Inputs/Input';
import Typography from '../../../../components/Typography/Typography';
import { createAssetValidation } from '../../../../validations/Root/shipmentAssetValidator';
import { RootShipmentAssetService } from '../../../../services/RootShipmentAssetService';

// Css imports
import classes from '../Content.module.css';

function ShipmentAssetModal({ setOpen }) {
    const [startLoader, stopLoader] = useLoader();

    const initialValues = {
        name: '',
        description: '',
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSuccess = (toastData) => {
        toast.success(toastData);
        setOpen(false, true);
    };

    const handleSubmit = (values) => {
        RootShipmentAssetService.Create(
            values,
            () => startLoader('shipmentAssetCreate'),
            () => handleSuccess('Shipment asset created sucessfully'),
            handleError,
            () => stopLoader('shipmentAssetCreate')
        );
    };

    return (
        <div className={classes.ModalContainer}>
            <Typography content={'Add Asset'} />
            <div className={classes.SupportNoteForm}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={createAssetValidation}
                    onSubmit={(val) => {
                        handleSubmit(val);
                    }}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <div>
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Name
                                                    <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Input name="name" id="name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="description">Description</label>
                                                <div>
                                                    <TextArea
                                                        name="description"
                                                        label="description"
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button
                                                className="btn-secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpen(false);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button className="btn-primary" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
}

export default ShipmentAssetModal;
