// Standard library imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getSignalError: '/root/signal-error',
    getSignalErrorEmails: '/root/signal-error/emails',
    updateSignalErrorEmails: '/root/signal-error/emails',
    deleteSignalErrorEmail: '/root/signal-error/emails',
};

const GetSignalErrors = (adminId, customerId, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getSignalError}/${adminId}/${customerId}`, { params }).then(callback).catch(error).finally(next);
};

const GetEmails = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getSignalErrorEmails).then(callback).catch(error).finally(next);
};

const UpdateEmails = (data, start, callback, error, next) => {
    start();
    return HttpClient.patch(PATH.updateSignalErrorEmails, data).then(callback).catch(error).finally(next);
};

const DeleteEmail = (data, start, callback, error, next) => {
    start();
    return HttpClient.delete(PATH.deleteSignalErrorEmail, { params: data }).then(callback).catch(error).finally(next);
};

export const RootSignalErrorService = {
    GetSignalErrors,
    GetEmails,
    UpdateEmails,
    DeleteEmail,
};
