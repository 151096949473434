// Standard Library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { toast } from 'react-toastify';

// Internal module imports
import classes from '../../../../styles/AllDevices.module.css';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { useLoader } from '../../../../hooks';
import monitoringClasses from '../Monitoring.module.css';
import { RootMonitoringService } from '../../../../services/RootMonitoringService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { AdminService } from '../../../../services/AdminService';

const LIMIT = 15;
const FILTER_OPTIONS = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Reporting',
        value: 'reporting',
    },
    {
        label: 'Not Reporting',
        value: 'notReporting',
    },
];

const SignalMonitoring = ({ queryParamsData, updateQueryParams, admins }) => {
    const [signalData, setSignalData] = useState([]);
    const [startLoader, stopLoader] = useLoader();
    const [selectedAdmin, setSelectedAdmin] = useState({});
    const [selectedCustomer, setSelectedCustomer] = useState(
        queryParamsData.customer
            ? JSON.parse(queryParamsData.customer)
            : {
                label: 'All',
                value: 'all',
            }
    );
    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
        {
            label: 'Global',
            value: 'global',
        },
    ]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [filterData, setFilterData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(
        queryParamsData.filter
            ? JSON.parse(queryParamsData.filter)
            : {
                label: 'Not Reporting',
                value: 'notReporting',
            }
    );

    useEffect(() => {
        setSelectedAdmin(queryParamsData.admin ? JSON.parse(queryParamsData.admin) : admins[0]);
    }, [admins]);

    useEffect(() => {
        if (selectedAdmin?.value && selectedAdmin?.value !== 'all') {
            getCustomers();
        }
    }, [selectedAdmin]);

    useEffect(() => {
        if (selectedCustomer?.value) {
            getData();
        }
    }, [selectedCustomer]);

    const getCustomers = () => {
        AdminService.GetCustomers(selectedAdmin?.value, startLoader, handleGetCustomerSuccess, handleError, stopLoader);
    };

    const handleGetCustomerSuccess = ({ data }) => {
        let temp = [
            {
                label: 'All',
                value: 'all',
            },
            {
                label: 'Global',
                value: 'global',
            },
        ];
        data.data.map((c) => {
            temp.push({
                label: c.name,
                value: c._id,
            });
        });
        setCustomers(temp);
    };

    const getData = () => {
        RootMonitoringService.getSignal(
            {
                customerId: selectedCustomer.value,
            },
            startLoader,
            handleGetDataSuccess,
            handleError,
            stopLoader
        );
    };

    const handleGetDataSuccess = ({ data }) => {
        const proccessedDataSignal = data?.data?.signalData.map((signal, index) => ({
            ...signal,
            lastReportedTimestampFormated: signal.lastReportedTimestamp ? momentTimeFormater(signal.lastReportedTimestamp).format('YYYY-MM-DD HH:mm') : '-',
            style: !signal.isReported ? { background: '#FFE3E3', color: '#E25657' } : {},
        }));
        setSignalData(proccessedDataSignal);
        setTotalCount(proccessedDataSignal?.length);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    useEffect(() => {
        getFilterData();
    }, [signalData, skip, selectedFilter]);

    const getFilterData = () => {
        let temp = [];
        const filterByType = signalData.filter((e) => {
            if (selectedFilter.value === 'all') {
                return e;
            } else if (selectedFilter.value === 'reporting') {
                return e.isReported === true;
            } else if (selectedFilter.value === 'notReporting') {
                return e.isReported === false;
            }
        });
        const slicedData = filterByType.slice(skip);
        slicedData.map((item, index) => {
            if (temp.length < LIMIT) {
                temp.push(item);
            }
        });
        setFilterData(temp);
        setTotalCount(filterByType.length);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div>
            <div className={monitoringClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={monitoringClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select Admin</label>
                                <DropdownComponent
                                    name="adminId"
                                    options={admins}
                                    defaultValue={selectedAdmin}
                                    onChange={(e) => {
                                        setSelectedAdmin(e);
                                        updateQueryParams('admin', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    defaultValue={selectedCustomer}
                                    onChange={(e) => {
                                        setSelectedCustomer(e);
                                        updateQueryParams('customer', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={monitoringClasses.FilterContainer}>
                        <div className={classes.FieldControl} style={{ minWidth: '10vw' }}>
                            <label>Filter</label>
                            <DropdownComponent
                                name="filter"
                                options={FILTER_OPTIONS}
                                defaultValue={selectedFilter}
                                onChange={(e) => {
                                    setSelectedFilter(e);
                                    updateQueryParams('filter', JSON.stringify(e));
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Signal Monitoring" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={monitoringClasses.TableContainer}>
                    <div style={{ width: '100%' }}>
                        <Table
                            head={['Customer Name', 'Edge', 'Signal', 'Last Reported (CET)']}
                            keys={['customerName', 'edge', 'name', 'lastReportedTimestampFormated']}
                            data={filterData}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignalMonitoring;
