// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilBolt, UilImport } from '@iconscout/react-unicons';
import moment from 'moment';
// Internal module imports
import classes from '../../../../../styles/AllDevices.module.css';
import Typography from '../../../../../components/Typography/Typography';
import Table from '../../../../../components/Table/Table';
import { useLoader } from '../../../../../hooks';
import { RootSummaryService } from '../../../../../services/RootSummaryService';
import DateRangePicker from '../../../../../components/Inputs/DateRangePicker/DateRangePicker';
import { AdminService } from '../../../../../services/AdminService';
import adminClasses from '../../Monitoring.module.css';
import { DropdownComponent } from '../../../../../components/Inputs/Input';
import CustomTooltip from '../../../../../components/CustomToolTip/CustomTooltip';
import { DownloadAsExcel } from '../../../../../utils/downloadAsExcel';

const Overview = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(moment()),
    });

    const [customers, setCustomers] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [admins, setAdmins] = useState([
        {
            label: 'All',
            value: 'all',
        },
    ]);
    const [selectedAdmin, setSelectedAdmin] = useState(
        queryParamsData.admin
            ? JSON.parse(queryParamsData.admin)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    const [selectedCustomer, setSelectedCustomer] = useState(
        queryParamsData.customer
            ? JSON.parse(queryParamsData.customer)
            : {
                  label: 'All',
                  value: 'all',
              }
    );
    useEffect(() => {
        getAdmins();
    }, []);

    useEffect(() => {
        getCustomers();
    }, [selectedAdmin]);

    const getAdmins = () => {
        AdminService.ReadAll(startLoader, handleAdminSuccess, handleError, stopLoader);
    };

    const handleAdminSuccess = ({ data }) => {
        const admin = data.data.map((item) => ({
            label: item.name,
            value: item._id,
        }));
        setAdmins([{ label: 'All', value: 'all' }, ...admin]);
    };

    const getCustomers = () => {
        RootSummaryService.GetCustomer(selectedAdmin.value, startLoader, handleCustomerSuccess, handleError, stopLoader);
    };

    const handleCustomerSuccess = ({ data }) => {
        const customer = data.data.customers.map((customer) => ({
            label: customer.name,
            value: customer._id,
        }));
        setCustomers([{ label: 'All', value: 'all' }, ...customer]);
    };

    useEffect(() => {
        getData();
    }, [selectedCustomer]);

    const getData = () => {
        const params = {
            startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            customerId: selectedCustomer?.value,
            adminId: selectedAdmin?.value,
        };
        RootSummaryService.Overview(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const redirectToSignalviewer = (data) => {
        const url = `/systemtool?tab=1&admin=${JSON.stringify({
            value: data.adminId,
        })}&customer=${JSON.stringify({
            value: data.customerId,
        })}&profile=${JSON.stringify(data.profile)}`;
        window.open(url, '_blank');
    };

    const handleSuccess = ({ data }) => {
        const temp = data.data.map((item, index) => ({
            's.no': index + 1,
            customer: item.customerName,
            instance: item.instance,
            score: item.activation,
            activation: `${item.activationIssue}/${item.activationTotal}`,
            edgeError: item.edgeError,
            frequencyQA: `${item.frequencyIssue.missingFrequency}/${item.frequencyIssue.consecutiveOneMinute}`,
            activationTime: item.activationTime,
            resourseError: item.resourceError,
            action: (
                <div
                    onClick={() => {
                        redirectToSignalviewer(item);
                    }}
                >
                    <CustomTooltip content={'Activation Profiler'}>
                        <UilBolt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                    </CustomTooltip>
                </div>
            ),
        }));
        setData(temp);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const downloadFile = () => {
        const proccessedData = data.map((item) => ({
            customer: item.customer,
            instance: item.instance,
            score: item.score,
            activation: item.activation,
            edgeError: item.edgeError,
            frequencyQA: item.frequencyQA,
            activationTime: item.activationTime,
            resourseError: item.resourceError || 0,
        }));

        proccessedData.length &&
            DownloadAsExcel(proccessedData, `Activation Overview`, [
                'Customer',
                'Instance',
                'Score',
                'Act (Issue/Total)',
                'Edge Errors',
                'Freq QA (Missing/Constant)',
                'Avg Activation Time (Sec)',
                'Resource Error',
            ]);
    };

    return (
        <div>
            <div className={adminClasses.Filter}>
                <div className={adminClasses.OverviewFilterContainer}>
                    <div className={adminClasses.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Admin</label>
                                <DropdownComponent
                                    name="admin"
                                    options={admins}
                                    onChange={(e) => {
                                        if (e.value !== selectedAdmin?.value) {
                                            setSelectedAdmin(e);
                                        }
                                        updateQueryParams('admin', JSON.stringify(e));
                                    }}
                                    defaultValue={selectedAdmin}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Customer</label>
                                <DropdownComponent
                                    name="customerId"
                                    options={customers}
                                    onChange={(e) => {
                                        if (e.value !== selectedCustomer?.value) {
                                            setSelectedCustomer(e);
                                        }
                                        updateQueryParams('customer', JSON.stringify(e));
                                    }}
                                    defaultValue={selectedCustomer}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={adminClasses.FilterContainer}>
                        <div>
                            <div className={classes.DatePickers} style={{ marginTop: '0' }}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div className={classes.DateLabels} style={{ marginBottom: '0.5vh' }}>
                                            Start Date
                                        </div>
                                        <div className={classes.DateLabels} style={{ marginRight: '2.604vw', marginBottom: '0.5vh' }}>
                                            End Date
                                        </div>
                                    </div>
                                    <DateRangePicker
                                        startDate={dateRange.startDate}
                                        endDate={dateRange.endDate}
                                        onChange={(date) => {
                                            setDateRange({
                                                startDate: new Date(date.startDate),
                                                endDate: new Date(date.endDate),
                                            });
                                        }}
                                    />
                                </div>
                                <div className={classes.SubmitButton} onClick={getData}>
                                    <button className="btn-primary">Submit</button>
                                </div>
                                <div onClick={() => downloadFile()} style={{ marginTop: '4vh' }}>
                                    <CustomTooltip content={'Download'}>
                                        <UilImport
                                            size="1.5vw"
                                            style={{
                                                cursor: 'pointer',
                                                color: 'var(--color-primary)',
                                            }}
                                        />
                                    </CustomTooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Activation Overview" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={data.length || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={[
                                'S.no',
                                'Customer',
                                'Instance',
                                'Score',
                                'Act (Issue/Total)',
                                'Edge Errors',
                                'Freq QA (Missing/Constant)',
                                'Avg Act Time (Sec)',
                                'Resource Error',
                                'Action',
                            ]}
                            keys={['s.no', 'customer', 'instance', 'score', 'activation', 'edgeError', 'frequencyQA', 'activationTime', 'resourseError', 'action']}
                            data={data}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overview;
