// Standard library imports
import React, { useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

import TabsComponent from '../../components/Tabs/Tabs';
import Details from './Details/Details';
import classes from './Economics.module.css';

const Economics = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);

    const [queryParamsData] = useState({
        customer: queryParams.get('customer') ? JSON.parse(queryParams.get('customer')) : '',
        dateRange: queryParams.get('dateRange') ? JSON.parse(queryParams.get('dateRange')) : '',

        // subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        // tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        // limit: queryParams.get('limit') ? Number(queryParams.get('limit')) : '',
        // date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        // createAlertModal: queryParams.get('createAlertModal') ? Boolean(queryParams.get('createAlertModal')) : false
    });

    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };

    const tabs = [
        {
            name: 'Overview',
            component: <Details updateQueryParams={updateQueryParams} queryParamsData={queryParamsData} />,
        },
    ];
    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent tabs={tabs} />
        </div>
    );
};

export default Economics;
