import classes from './Chart.module.css';
import React from 'react';
import Chart from 'react-apexcharts';
import { useHistory } from 'react-router-dom';
import Typography from '../Typography/Typography';
import { usToEuCurrencyFormat } from '../../utils/currencyHelper';
const ChartComponent = ({
    title,
    series,
    curve = 'smooth',
    colors,
    strokeWidth,
    markerSize,
    xFormatter = null,
    yFormatter = null,
    type,
    dataLabels = { enabled: false },
    disableZoom,
    annotations = null,
    xType = null,
    yProps = null,
    linkTitle,
    legend = false,
    lastReported = null,
    height = '90%',
    chartProps = null,
    width = '99%',
    rightSideElement = null,
    showLastReported = true,
}) => {
    const history = useHistory();

    const state = {
        options: {
            annotations: { position: 'front', ...annotations },
            chart: {
                id: 'basic-bar',
                zoom: {
                    enabled: !disableZoom,
                },
                ...chartProps,
            },
            colors,
            dataLabels,

            stroke: {
                show: true,
                curve: curve,
                lineCap: 'butt',
                width: strokeWidth >= 0 ? strokeWidth : 2,
                dashArray: 0,
                colors: colors,
            },
            dropShadow: {
                enabled: true,
                top: 0,
                left: 0,
                blur: 3,
                opacity: 0.5,
                color: 'yellow',
            },
            markers: {
                size: markerSize || 0,
                colors: colors || ['#40D3E6'],
                strokeColors: colors || ['#40D3E6'],
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: 'circle',
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: undefined,
                    sizeOffset: 3,
                },
            },
            xaxis: {
                type: xType || 'datetime',
                labels: {
                    formatter: xFormatter ? (value, timestamp, opts) => xFormatter(value) : (value, timestamp, opts) => value,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    style: {
                        fontSize: '0.825vw',
                    },
                },
            },
            yaxis: {
                labels: {
                    formatter: yFormatter ? (value, timestamp, opts) => yFormatter(value) : (value, timestamp, opts) => usToEuCurrencyFormat(parseFloat(value).toFixed(2)),
                    style: {
                        fontSize: '0.825vw',
                    },
                },
                ...yProps,
            },
            tooltip: {
                style: {
                    fontSize: '1vw',
                },
            },
            legend: {
                show: legend ? true : false,
            },
        },
        series,
    };
    return (
        <div className={classes.ChartContainer}>
            <div className={classes.Head}>
                {!linkTitle ? (
                    <div>
                        <div className={classes.Title}>{title}</div>
                        {lastReported ? (
                            <div className={classes.lastReported}>
                                <Typography subtext content={showLastReported ? 'Last Reported: ' + (lastReported || ' - ') : lastReported || ' - '} />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <div className={classes.LinkTitle} onClick={() => history.push(linkTitle.link)}>
                        {linkTitle.name}
                    </div>
                )}
                {rightSideElement && rightSideElement}
            </div>
            <Chart options={state.options} series={state.series} type={type || 'area'} height={height} width={width} />
        </div>
    );
};

export default ChartComponent;
