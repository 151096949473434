import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).required(ERRORS.required);

export const editBidValidation = yup.object().shape({
    capacity: yup.array().of(validateNumber()),
    price: yup.array().of(validateNumber()),
    reserveBidIdentification: yup.string().required(ERRORS.required),
});
