// External library imports
import { get as lodashGet } from 'lodash';
// Internal library imports
import { PROTECTED_ROUTES_ROOT } from '../routes/Routes';

export const tabCount = (route, state) => {
    let tabCount = 0;
    const userRole = lodashGet(state, 'user.userRole');
    const accesableTabs = state?.user?.allowed || null;
    const compId = PROTECTED_ROUTES_ROOT.find((comp) => comp.url === route);
    const filteredComp = accesableTabs?.properties?.[userRole]?.find((item) => item.componentId === compId.id);
    if (filteredComp) {
        tabCount = filteredComp?.tabsId?.length;
    }
    return tabCount;
};
