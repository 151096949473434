import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

const phoneRegExp = /^\+\d{1,50}$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const edgeShipmentValidation = yup.object().shape({
    consignee: yup
        .object()
        .shape({
            consigneeName: yup.string().required(ERRORS.required),
            consigneeContactName: yup.string().required(ERRORS.required),
            consigneeStreet1: yup.string().required(ERRORS.required),
            consigneeStreet2: yup.string().optional(),
            consigneePostalCode: yup.string().required(ERRORS.required),
            consigneeCountryCode: yup.string().required(ERRORS.required),
            consigneeCity: yup.string().required(ERRORS.required),
            consigneePhone: yup.string().matches(phoneRegExp, 'invalid contact number'),
            consigneeEmail: yup.string().matches(emailRegex, 'invalid email'),
        })
        .required(),
    packageTypeCode: yup.string().required(ERRORS.required),
    assetWeight: yup.number().required(ERRORS.required),
    assetUnit: yup.string().required(ERRORS.required),
    assets: yup
        .array()
        .of(
            yup.object().shape({
                assetId: yup.string().required(ERRORS.required),
                quantity: yup.number().required(ERRORS.required).positive().integer(),
            })
        )
        .required(),
    notes: yup.string().optional(),
});
