// Standard Library Imports
import React, { useEffect, useState } from 'react';

// External library imports
import { Formik, Form, FieldArray } from 'formik';
import { UilMinusCircle, UilPlusCircle, UilTrash } from '@iconscout/react-unicons';
import { toast } from 'react-toastify';

// Internal library imports
import Table from '../../../../../components/Table/Table';
import { Input } from '../../../../../components/Inputs/Input';
import TabsComponent from '../../../../../components/Tabs/Tabs';
import { emailValidation } from '../../../../../validations/Root/signalErrorEmailValidator';
import { RootSignalErrorService } from '../../../../../services/RootSignalErrorService';
import { useLoader } from '../../../../../hooks';

// Css imports
import classes from '../../../../../styles/AllDevices.module.css';

function SignalErrorEmailModal({ setEmailModal }) {
    const [startLoader, stopLoader] = useLoader();

    const [emails, setEmails] = useState([]);
    const [emailTable, setEmailTable] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        handleGetEmail();
    }, []);

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const handleEmailUpdateSuccess = () => {
        toast.success('Email Added Successfully!');
        handleGetEmail();
        setSelectedIndex(0);
    };

    const handleEmailDeleteSuccess = () => {
        toast.success('Email Deleted Successfully!');
        handleGetEmail();
    };

    const handleDeleteEmail = (email) => {
        RootSignalErrorService.DeleteEmail({ email }, startLoader, handleEmailDeleteSuccess, handleError, stopLoader);
    };

    const handleEmailSuccess = ({ data }) => {
        const gotEmails = data?.data?.properties?.emails;
        if (gotEmails) {
            const processedData = gotEmails.map((email, idx) => {
                return {
                    slNo: idx + 1,
                    email,
                    action: (
                        <div onClick={() => handleDeleteEmail(email)}>
                            <UilTrash size={'1.2vw'} />
                        </div>
                    ),
                };
            });
            setEmails(gotEmails);
            setEmailTable(processedData);
        } else {
            setEmails([]);
            setEmailTable([]);
        }
    };

    const handleGetEmail = () => {
        RootSignalErrorService.GetEmails(startLoader, handleEmailSuccess, handleError, stopLoader);
    };

    const handleEmailSubmit = (data) => {
        RootSignalErrorService.UpdateEmails(data, startLoader, handleEmailUpdateSuccess, handleError, stopLoader);
    };

    const tabs = [
        {
            name: 'Emails',
            component: (
                <div>
                    <Table head={['Sl.No', 'Email', 'Action']} keys={['slNo', 'email', 'action']} data={emailTable} />
                </div>
            ),
        },
        {
            name: 'Add Email',
            component: (
                <div>
                    <Formik
                        initialValues={{ emails: [''] }}
                        onSubmit={(val) => {
                            handleEmailSubmit(val);
                        }}
                        validationSchema={emailValidation}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div>
                                        <div>
                                            <div>
                                                <FieldArray name="emails">
                                                    {({ push, remove, form }) => {
                                                        const notificationTypeMails = form.values.emails;
                                                        return (
                                                            <div>
                                                                <div>
                                                                    <div className={classes.InputContainer}>
                                                                        <div className={classes.FieldControl2}>
                                                                            <label>E-Mail</label>
                                                                            {notificationTypeMails.map((notification, idx) => (
                                                                                <div className={classes.InputContainer} style={{ marginBottom: '0.5vh' }}>
                                                                                    <div className={classes.FieldControl2}>
                                                                                        <Input name={`emails[${idx}]`} label={'Enter Email'} style={{ marginTop: 0 }} />
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={classes.addInputActionWrapper}
                                                                    style={{ display: 'flex', flexDirection: 'row-reverse', gap: '0.5vw', paddingTop: '1%' }}
                                                                >
                                                                    <span
                                                                        onClick={() => {
                                                                            push('');
                                                                        }}
                                                                    >
                                                                        <UilPlusCircle
                                                                            size={'1.5vw'}
                                                                            style={{
                                                                                color: 'var(--color-primary)',
                                                                                cursor: 'pointer',
                                                                                zIndex: '1',
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    {notificationTypeMails.length > 1 && (
                                                                        <span onClick={() => remove(notificationTypeMails.length - 1)}>
                                                                            <UilMinusCircle
                                                                                size={'1.5vw'}
                                                                                style={{
                                                                                    color: 'var(--color-primary)',
                                                                                    cursor: 'pointer',
                                                                                    zIndex: '1',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }}
                                                </FieldArray>
                                            </div>
                                            <div className={classes.ButtonContainer}>
                                                <div>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setEmailModal(false);
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            ),
        },
    ];
    return (
        <div>
            <TabsComponent tabs={tabs} selectedIndex={selectedIndex} onChangeTab={setSelectedIndex} />
        </div>
    );
}

export default SignalErrorEmailModal;
