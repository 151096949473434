// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import classes from './index.module.css';
import TabsComponent from '../../../components/Tabs/Tabs';
import Areas from './Areas';
import SignalTypes from './SignalTypes';
import Signals from './Signals';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';

const SystemConfig = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
    });
    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    let tabs = [
        {
            name: 'Areas',
            component: (
                <>
                    <Areas />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Signal Types',
            component: (
                <>
                    <SignalTypes />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Signals',
            component: (
                <>
                    <Signals />
                </>
            ),
            tabId: 3,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default SystemConfig;
