// Standard library imports
import React, { useState } from 'react';
//Css imports
import documentClasses from '../Documents.module.css';

const InputComponent = ({ pageValue, setDocumentData, setActivePageId, setDocumentsPage, documentsPage, activePageId }) => {
    const [width, setWidth] = useState(pageValue.name?.length);
    const [currentValue, setCurrentValue] = useState(pageValue.name);
    const changeHandler = (evt) => {
        setWidth(evt.target.value.length);
        setCurrentValue(evt.target.value);
    };

    return (
        <input
            style={{ width: width + 'ch' }}
            type="text"
            onChange={changeHandler}
            value={currentValue}
            className={`${documentClasses.PageInput} ${pageValue.pageId === activePageId ? documentClasses.ActivePage : ''}`}
            onClick={() => {
                setDocumentData(pageValue.data);
                setActivePageId(pageValue.pageId);
            }}
            onBlur={() => {
                const temp = documentsPage.map((item) => {
                    if (item.pageId === activePageId) {
                        return { ...item, name: currentValue };
                    } else {
                        return item;
                    }
                });
                setDocumentsPage(temp);
            }}
        />
    );
};

export default InputComponent;
