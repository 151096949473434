// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { UilImport } from '@iconscout/react-unicons';

// Internal module imports
import { Input } from '../../../../components/Inputs/Input';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { useLoader } from '../../../../hooks';
import MonthPicker from '../../../../components/Inputs/MonthPicker/MonthPicker';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import ChartComponent from '../../../../components/ApexCharts/Chart';
import Typography from '../../../../components/Typography/Typography';
import { RootRevenueCalculatorService } from '../../../../services/RootRevenueCalculatorService';
import { toDkFormatter } from '../../../../utils/currencyHelper';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';

// Css imports
import RevenueClasses from './index.module.css';

const MARKET_OPTIONS = [
    {
        label: 'FCR',
        value: 'FCR',
    },
    {
        label: 'FCR-D-INC',
        value: 'FCR_D_INCREASED',
    },
    {
        label: 'FCR-D-DEC',
        value: 'FCR_D_DECREASED',
    },
    {
        label: 'FCR-D (LER)',
        value: 'FCR-D (LER)',
    },
];

const AFRR_MARKET_OPTIONS = [
    {
        label: 'AFRR-INC',
        value: 'AFRR_INCREASED',
    },
    {
        label: 'AFRR-DEC',
        value: 'AFRR_DECREASED',
    }
];

const CURRENCY_OPTIONS = [
    {
        label: 'DKK',
        value: 'DKK',
    },
    {
        label: 'Euro',
        value: 'Euro',
    },
];

const AREA_OPTIONS = [
    {
        label: 'DK1',
        value: 'DK1',
    },
    {
        label: 'DK2',
        value: 'DK2',
    },
    {
        label: 'SE1',
        value: 'SE1',
    },
    {
        label: 'SE2',
        value: 'SE2',
    },
    {
        label: 'SE3',
        value: 'SE3',
    },
    {
        label: 'SE4',
        value: 'SE4',
    },
];

const today = new Date();
const sixMonthsAgo = new Date();
sixMonthsAgo.setMonth(today.getMonth() - 6);

const RevenueCalulator = ({ updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [dateRange, setDateRange] = useState({
        startDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.startDate) : sixMonthsAgo,
        endDate: queryParamsData.dateRange ? new Date(queryParamsData.dateRange.endDate) : today,
    });
    const [data, setData] = useState({});
    const [graphData, setGraphData] = useState([]);
    const [chartData, setChartData] = useState({});
    const [downloadData, setDownloadData] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(queryParamsData.currency ? queryParamsData.currency : CURRENCY_OPTIONS[0]);
    const [areaOption, setAreaOption] = useState(AREA_OPTIONS);
    const [selectedArea, setSelectedArea] = useState(queryParamsData.area ? queryParamsData.area : areaOption[0]);
    const [marketOption, setMarketOption] = useState(selectedArea?.value === 'DK1' ? [MARKET_OPTIONS[0], ...AFRR_MARKET_OPTIONS] : MARKET_OPTIONS);
    const [selectedMarket, setSelectedMarket] = useState(queryParamsData.market ? queryParamsData.market : MARKET_OPTIONS[0]);

    const initialValues = {
        market: queryParamsData.market ? queryParamsData.market : '',
        area: queryParamsData.area ? queryParamsData.area : '',
        capacity: queryParamsData.capacity ? queryParamsData.capacity : 1,
    };

    useEffect(() => {
        getRevenue({
            signal: queryParamsData?.market?.value ? queryParamsData?.market?.value : marketOption[0].value,
            capacity: queryParamsData?.capacity ? queryParamsData?.capacity : 1,
            startDate: momentTimeFormater(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: momentTimeFormater(dateRange.endDate).format('YYYY-MM-DD'),
            area: queryParamsData?.area?.value ? queryParamsData?.area?.value : areaOption[0].value,
        });
    }, []);

    const handleSubmit = (values) => {
        getRevenue({
            signal: values.market,
            capacity: values.capacity,
            startDate: momentTimeFormater(dateRange.startDate).format('YYYY-MM-DD'),
            endDate: momentTimeFormater(dateRange.endDate).format('YYYY-MM-DD'),
            area: values.area,
        });
        updateQueryParams('capacity', values.capacity);
        const data = { startDate: new Date(dateRange.startDate), endDate: new Date(dateRange.endDate) };
        updateQueryParams('dateRange', JSON.stringify(data));
    };

    const getRevenue = (params) => {
        RootRevenueCalculatorService.getRevenue(params, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        setData(data.data);
        setGraphData(data.data.graphData || []);
        setDownloadData(data.data.downloadData || []);
        if (selectedCurrency.value === 'DKK') {
            setChartData(data.data.graphData[0] || {});
        } else {
            setChartData(data.data.graphData[1] || {});
        }
    };

    const handleError = (error) => {
        let message = error?.response?.data?.message;
        toast.error(message);
    };

    const handleDownLoad = () => {
        DownloadAsExcel(downloadData, `Potential Revenue - ${momentTimeFormater(dateRange.startDate).format('YYYY-MM-DD')} - ${momentTimeFormater(dateRange.endDate).format('YYYY-MM-DD')}`, [
            'Month',
            'Revenue DKK',
            'Revenue Euro'
        ]);
    };

    return (
        <div>
            <div className={RevenueClasses.Events + ` ${RevenueClasses.FilterWarapper}`}>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className={RevenueClasses.InputContainer}>
                                    <div>
                                        <div className={RevenueClasses.FieldControl} style={{ width: 'auto' }}>
                                            <label for="area">Select Area</label>
                                            <Dropdown
                                                name="area"
                                                label="area"
                                                options={areaOption}
                                                defaultValue={selectedArea}
                                                onChange={(value) => {
                                                    updateQueryParams('area', JSON.stringify(value));
                                                    if (value.value === 'DK1') {
                                                        setMarketOption([MARKET_OPTIONS[0], ...AFRR_MARKET_OPTIONS]);
                                                        setSelectedMarket(MARKET_OPTIONS[0]);
                                                    } else if (value.value === 'DK2') {
                                                        let temp = MARKET_OPTIONS.filter((item) => item.value !== 'FCR');
                                                        temp.push(...AFRR_MARKET_OPTIONS);
                                                        setMarketOption(temp);
                                                        setSelectedMarket(temp[0]);
                                                    } else {
                                                        let temp = MARKET_OPTIONS.filter((item) => item.value !== 'FCR');
                                                        setMarketOption(temp);
                                                        setSelectedMarket(temp[0]);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className={RevenueClasses.FieldControl} style={{ width: 'auto', minWidth: '10vw' }}>
                                            <label for="market">Select Market</label>
                                            <Dropdown
                                                name="market"
                                                label="market"
                                                options={marketOption}
                                                defaultValue={selectedMarket}
                                                onChange={(value) => {
                                                    updateQueryParams('market', JSON.stringify(value));
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <div className={RevenueClasses.FieldControl}>
                                            <label for="capacity">Capacity (MW)</label>
                                            <Input name="capacity" />
                                        </div>
                                    </div>
                                    <div className={RevenueClasses.FieldControl} style={{ width: 'auto' }}>
                                        <label for="currency">Select Currency</label>
                                        <Dropdown
                                            name="currency"
                                            label="Currency"
                                            options={CURRENCY_OPTIONS}
                                            defaultValue={selectedCurrency}
                                            onChange={(value) => {
                                                if (value.value === 'DKK') {
                                                    setChartData(graphData[0] || {});
                                                } else {
                                                    setChartData(graphData[1] || {});
                                                }
                                                setSelectedCurrency(value);
                                                updateQueryParams('currency', JSON.stringify(value));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '0.5vw' }}>
                                        <div className={RevenueClasses.DateLabels} style={{ marginBottom: '0' }}>
                                            Start Month
                                            <MonthPicker date={dateRange.startDate} setDate={(e) => setDateRange({ ...dateRange, startDate: e })} />
                                        </div>
                                        <div className={RevenueClasses.DateLabels} style={{ marginBottom: '0' }}>
                                            End Month
                                            <MonthPicker date={dateRange.endDate} setDate={(e) => setDateRange({ ...dateRange, endDate: e })} />
                                        </div>
                                        <div className={RevenueClasses.SubmitButton} >
                                            <button className="btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>

                <div className={RevenueClasses.Icon}>
                    <UilImport
                        onClick={handleDownLoad}
                    />
                </div>
            </div>
            <div className={RevenueClasses.ChartContainer}>
                <div>
                    <div className={RevenueClasses.CardContainer}>
                        <div className={RevenueClasses.Card}>
                            Revenue In DKK :
                            <span>
                                <Typography size="14" content={(data?.totalRevenueInDkk && toDkFormatter(data?.totalRevenueInDkk)) || 0} />
                            </span>
                        </div>
                        <div className={RevenueClasses.Card}>
                            Revenue In Euro :
                            <span>
                                <Typography size="14" content={(data?.totalRevenueInEuro && toDkFormatter(data?.totalRevenueInEuro)) || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: '1vw', height: '40vh' }}>
                    <ChartComponent title={`Revenue in ${selectedCurrency.value}`} xType={'category'} type="bar" series={[chartData]} yFormatter={toDkFormatter} />
                </div>
            </div>
        </div>
    );
};

export default RevenueCalulator;
