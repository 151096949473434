// Standard library imports
import React, { useState, useEffect } from 'react';
// Internal module imports
import { DropdownComponent } from '../../../../components/Inputs/Input';
import DateRange from '../../../../components/Inputs/DateRangePicker/DateRangePicker';
import { CreditsServiceRoot } from '../../../../services/RootCreditsService';
import { useLoader } from '../../../../hooks';
import Typography from '../../../../components/Typography/Typography';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

// Css imports
import classes from '../../../../styles/AllDevices.module.css';
import creditsClassess from './index.module.css';
// External library imports
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import Create from './modalComponents/Create';
import Table from '../../../../components/Table/Table';
import { UilImport } from '@iconscout/react-unicons';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import moment from 'moment/moment';

const ACCOUNT_TYPE = [
    {
        label: 'Partners',
        value: 'admin',
    },
    {
        label: 'Enablers',
        value: 'enabler',
    },
    {
        label: 'Customers',
        value: 'customer',
    },
];

const Credits = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const date = new Date();
    const [dateRange, setDateRange] = useState(
        queryParamsData.date
            ? {
                  startDate: new Date(JSON.parse(queryParamsData.date).startDate),
                  endDate: new Date(JSON.parse(queryParamsData.date).endDate),
              }
            : {
                  startDate: new Date(date.getFullYear(), date.getMonth(), 1),
                  endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
              }
    );
    const [accountOptions, setAccountOptions] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedAccountType, setSelectedAccountType] = useState({});
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [logsData, setLogsData] = useState([]);
    const [availableBalance, setAvailableBalance] = useState(0);

    useEffect(() => {
        updateQueryParams('date', JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        if (selectedAccountType?.value) {
            getAccountsData();
        }
    }, [selectedAccountType]);

    useEffect(() => {
        if (selectedAccount?.value) {
            getLogs();
        }
    }, [selectedAccount]);

    useEffect(() => {
        if (selectedAccount?.value && refresh) {
            getLogs();
        }
    }, [refresh]);

    const getAccountsData = () => {
        CreditsServiceRoot.GetAccounts({ type: selectedAccountType.value }, startLoader, handleGetAccountsSuccess, handleError, stopLoader);
    };

    const handleGetAccountsSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            label: item.name,
            value: item._id,
        }));
        setAccountOptions(temp);
    };

    const getLogs = () => {
        CreditsServiceRoot.GetLogs(
            selectedAccount.value,
            {
                startDate: moment(dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(dateRange.endDate).format('YYYY-MM-DD'),
            },
            startLoader,
            handleGetLogsSuccess,
            handleError,
            stopLoader
        );
    };

    const handleGetLogsSuccess = ({ data }) => {
        const processedData = data?.data?.data.map((item) => ({
            date: momentTimeFormater(item.createdAt).format('YYYY-MM-DD'),
            type: item.type,
            amountValue: item.type === 'in' ? `+${item.amount}` : `-${item.amount}`,
            amount: (
                <div className={creditsClassess.AmountContainer}>
                    {item.type === 'in' ? (
                        <span className={creditsClassess.BoldFont + ' ' + creditsClassess.GreenColor}>{`+${item.amount}`}</span>
                    ) : (
                        <span className={creditsClassess.BoldFont + ' ' + creditsClassess.RedColor}>{`-${item.amount}`}</span>
                    )}
                </div>
            ),
            description: <CustomTooltip content={item.description}>{item.description.substring(0, 40)}</CustomTooltip>,
        }));
        setLogsData(processedData);
        setAvailableBalance(Math.round(data.data?.availableBalance));
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    const handleDownLoad = () => {
        let temp =
            logsData?.length > 0 &&
            logsData.map((item) => ({
                date: item.date,
                description: item.description.props.content,
                amount: item.amountValue,
                availableBalance: '',
            }));

        temp.push({
            date: '',
            description: '',
            amount: '',
            availableBalance: availableBalance,
        });

        DownloadAsExcel(temp, `${selectedAccount?.label} credits`, ['Date', 'Description', 'Amount', 'Available Balance']);
    };

    return (
        <div>
            <ModalComponent isOpen={open} setOpen={setOpen}>
                <div style={{ width: '25vw' }}>
                    <Create accountType={selectedAccountType?.value} accountId={selectedAccount?.value} setOpen={setOpen} setRefresh={setRefresh} />
                </div>
            </ModalComponent>
            <div className={creditsClassess.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={creditsClassess.FilterContainer}>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Account Type</label>
                                <DropdownComponent
                                    name="accountType"
                                    options={ACCOUNT_TYPE}
                                    defaultValue={queryParamsData.accountType ? JSON.parse(queryParamsData.accountType) : ACCOUNT_TYPE[0]}
                                    onChange={(e) => {
                                        setSelectedAccountType(e);
                                        updateQueryParams('accountType', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Value</label>
                                <DropdownComponent
                                    name="accountId"
                                    options={accountOptions}
                                    defaultValue={queryParamsData.account ? JSON.parse(queryParamsData.account) : accountOptions[0]}
                                    onChange={(e) => {
                                        setSelectedAccount(e);
                                        updateQueryParams('account', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Credits" />
                        <div className={classes.TableCount}>
                            Payable :
                            <span>
                                <Typography size="14" content={availableBalance ? ` ${availableBalance} €` : ` ${availableBalance} €`} />
                            </span>
                        </div>

                        <div>
                            <button
                                type="submit"
                                className="btn-primary"
                                style={{ marginTop: '0' }}
                                onClick={() => {
                                    setRefresh(false);
                                    setOpen(true);
                                }}
                            >
                                Add Transactions
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className={creditsClassess.DatePickers}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className={creditsClassess.DateLabels}>Start Date</div>
                                    <div className={creditsClassess.DateLabels} style={{ marginRight: '2.604vw' }}>
                                        End Date
                                    </div>
                                </div>
                                <DateRange
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    onChange={(date) =>
                                        setDateRange({
                                            startDate: new Date(date.startDate),
                                            endDate: new Date(date.endDate),
                                        })
                                    }
                                />
                            </div>
                            <div className={classes.SubmitButton} style={{ marginBottom: '0.3vh' }}>
                                <button className="btn-primary" onClick={getLogs}>
                                    Submit
                                </button>
                            </div>
                            <div>
                                <UilImport
                                    size="1.5vw"
                                    style={{
                                        cursor: 'pointer',
                                        marginTop: '0.4vw',
                                    }}
                                    onClick={handleDownLoad}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <Table head={['Date', 'Description', 'Amount (€)']} keys={['date', 'description', 'amount']} data={logsData} />
                </div>
            </div>
        </div>
    );
};

export default Credits;
