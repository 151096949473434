import classes from '../../../../styles/AllDevices.module.css';
import React, { useEffect, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Form, Formik, FieldArray } from 'formik';
import { Input } from '../../../../components/Inputs/Input';
import { create } from '../../../../validations/SystemTools/licenseTypesValidation';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { LicenseTypeService } from '../../../../services/licenseTypeService';
import { useLoader } from '../../../../hooks/use-loader.hook';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import { UilTrash } from '@iconscout/react-unicons';
import FileUpload from '../../../../components/Inputs/FileUpload/FileUpload';
import { UilMinusCircle } from '@iconscout/react-unicons';
import { UilPlusCircle, UilPen } from '@iconscout/react-unicons';
import LicenseClasses from '../index.module.css';
const LicenseTypes = () => {
    const [startLoader, stopLoader] = useLoader();
    const [tableData, setTableData] = useState([]);
    const [addModal, setAddModal] = useState({
        status: false,
        data: {},
    });
    const [deleteModal, setDeleteModal] = useState({ status: false, id: null });
    const [image, setImage] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        LicenseTypeService.ReadAll(
            () => startLoader('getLicenseTypes'),
            handleSuccess,
            handleError,
            () => stopLoader('getLicenseTypes')
        );
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            name: key.name,
            licenseCode: key.licenseCode ? key.licenseCode : '--',
            icon: key?.icon ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img style={{ height: '1.5vw' }} src={key.icon} />
                </div>
            ) : (
                '--'
            ),
            action: (
                <div className={LicenseClasses.ActionContainer} style={{ justifyContent: 'center' }}>
                    <CustomTooltip content={'Edit License Type'}>
                        <div onClick={() => setAddModal({ status: true, data: key })}>
                            <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </CustomTooltip>
                    <CustomTooltip content={'Delete License Type'}>
                        <div onClick={() => setDeleteModal({ status: true, id: key._id })}>
                            <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                        </div>
                    </CustomTooltip>
                </div>
            ),
        }));
        setTableData(processedData);
    };

    const handleSubmit = (values) => {
        const formData = new FormData();
        const isImageValid = /(jpg|png|peg|svg)$/.test(image?.name?.slice(-4));
        if (image.length && !isImageValid) {
            toast.error('Please select a valid image format');
            return;
        }
        formData.append('image', image);
        Object.keys(values).forEach((key) => {
            key !== 'defaultMetaKeys' && formData.append(key, values[key]);
        });
        values?.defaultMetaKeys?.forEach((k) => formData.append('defaultMetaKeys[]', k));
        if (addModal.data?._id) {
            LicenseTypeService.Update(
                addModal.data?._id,
                formData,
                () => startLoader('updateType'),
                handleUpdateSuccess,
                handleError,
                () => stopLoader('updateType')
            );
        } else {
            LicenseTypeService.Create(
                formData,
                () => startLoader('createdType'),
                handleCreateSuccess,
                handleError,
                () => stopLoader('createdType')
            );
        }
    };

    const handleCreateSuccess = (res) => {
        fetchData();
        toast.success('License type created sucessfully');
        setAddModal({ status: false, data: {} });
    };

    const handleUpdateSuccess = (res) => {
        fetchData();
        toast.success('License type updated sucessfully');
        setAddModal({ status: false, data: {} });
    };

    const handleDelete = () => {
        if (deleteModal.id) {
            LicenseTypeService.Delete(
                deleteModal.id,
                startLoader,
                () => {
                    fetchData();
                    toast.success('License type deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleUploadClick = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };
    return (
        <div className={classes.AllDevices}>
            <ModalComponent
                isOpen={addModal.status}
                setOpen={(e) => {
                    setAddModal({ status: false, data: {} });
                }}
            >
                <Typography content={addModal.data?._id ? 'Edit License Types' : 'Add License Types'} />
                <div className={classes.SupportNoteForm} style={{ width: '35vw' }}>
                    <Formik
                        initialValues={{
                            name: addModal.data?.name ? addModal.data?.name : '',
                            licenseCode: addModal.data?.licenseCode ? addModal.data?.licenseCode : '',
                            defaultMetaKeys: addModal.data?.defaultMetaKeys ? addModal.data?.defaultMetaKeys : [],
                        }}
                        enableReinitialize
                        validationSchema={create}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => (
                            <Form>
                                <div>
                                    <div>
                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="name">
                                                    Name (Camel Case) <span className="required">*</span>
                                                </label>
                                                <Input name="name" id="name" values={values} label={'type in camel case'} style={{ marginTop: '0' }} />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="licenseCode">
                                                    License Code <span className="required">*</span>
                                                </label>
                                                <Input name="licenseCode" id="licenseCode" values={values} label={'Enter License Code'} style={{ marginTop: '0' }} />
                                            </div>
                                        </div>

                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <label for="image">
                                                    Image <span className="required">*</span>
                                                </label>
                                                <FileUpload name="image" id="image" accept="image/jpg, image/jpeg, image/png, image/svg+xml" onChange={handleUploadClick} />
                                            </div>
                                        </div>

                                        <div className={classes.InputContainer}>
                                            <div className={classes.FieldControl2}>
                                                <div>
                                                    <label for="defaultMetaKeys">Default Meta Keys</label>
                                                    <FieldArray name="defaultMetaKeys">
                                                        {({ push, remove, form }) => {
                                                            const defaultMetaKeys = form.values.defaultMetaKeys;
                                                            return (
                                                                <div>
                                                                    <div className={LicenseClasses.LicenseMetaKeysContainer}>
                                                                        {defaultMetaKeys.map((email, index) => (
                                                                            <div key={index} style={{ width: '48.5%' }}>
                                                                                <Input name={`defaultMetaKeys[${index}]`} type="text" style={{ marginTop: '0' }} />
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className={LicenseClasses.IconContainer}>
                                                                        {defaultMetaKeys.length > 0 && (
                                                                            <span onClick={() => remove(defaultMetaKeys.length - 1)}>
                                                                                <UilMinusCircle
                                                                                    size={'1.5vw'}
                                                                                    style={{
                                                                                        color: 'var(--color-primary)',
                                                                                        cursor: 'pointer',
                                                                                        zIndex: '1',
                                                                                    }}
                                                                                />
                                                                            </span>
                                                                        )}
                                                                        <span onClick={() => push('')}>
                                                                            <UilPlusCircle
                                                                                size={'1.5vw'}
                                                                                style={{
                                                                                    color: 'var(--color-primary)',
                                                                                    cursor: 'pointer',
                                                                                    zIndex: '1',
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </FieldArray>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={classes.ButtonContainer}>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={(e) => {
                                                        setAddModal({ status: false, data: {} });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button className="btn-primary" type="submit">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                {/* <div> */}
                <div>
                    <Typography content="License Types" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{tableData.length}</span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddModal({ status: true, data: {} })}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Name', 'License Code', 'Icon', 'Action']} keys={['s.no', 'name', 'licenseCode', 'icon', 'action']} data={tableData} />
            </div>
        </div>
    );
};

export default LicenseTypes;
