import classes from '../../../../../styles/BidModal.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Input } from '../../../../../components/Inputs/Input';
import Typography from '../../../../../components/Typography/Typography';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import { CreateDCPlannerBidValidation } from '../../../../../validations/Frequency/CreateBidValidation';
import { DCPlannerBidService } from '../../../../../services/DCPlannerBidService';
import DatePicker from '../../../../../components/Inputs/DatePicker/DatePicker';

const getSlotAndPriceInitials = () => {
    const slotAndPrice = {};
    const dummyArray = Array(24).fill(null);
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}`] = '0.9';
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`slot${index + 1}Price`] = '14';
    });
    dummyArray.forEach((item, index) => {
        slotAndPrice[`regulation${index + 1}Price`] = '14';
    });
    return slotAndPrice;
};

const BidContent = ({ setOpen, startLoader, stopLoader, fetchBids, customerId, bidDate, plantId, unitId, updateQueryParams }) => {
    const [initialValues, setInitialValues] = useState({
        slotAndPrice: getSlotAndPriceInitials(),
        plantId,
        unitId,
    });
    const [date, setDate] = useState(bidDate ? new Date(moment(bidDate).format('YYYY-MM-DD')) : new Date());

    useEffect(() => {
        updateQueryParams('bidDate', date);
    }, [date]);

    useEffect(() => {
        if (customerId) {
            DCPlannerBidService.GetLastBid({ customerId, unitId, plantId }, startLoader, handleGetLastBidSuccess, handleError, stopLoader);
        }
    }, []);

    const handleGetLastBidSuccess = ({ data }) => {
        const { capacity, date, price, regulationPrice } = data.data;
        if (capacity?.length && price?.length) {
            const slotAndPrice = {};
            capacity.forEach((item, index) => {
                slotAndPrice[`slot${index + 1}`] = item;
            });
            price.forEach((item, index) => {
                slotAndPrice[`slot${index + 1}Price`] = item;
            });
            regulationPrice?.forEach((item, index) => {
                slotAndPrice[`regulation${index + 1}Price`] = item;
            });
            setInitialValues({
                ...initialValues,
                slotAndPrice,
            });
        } else {
            setInitialValues({
                slotAndPrice: getSlotAndPriceInitials(),
                plantId,
                unitId,
            });
        }
        const tempDate = new Date(date);
        tempDate.setHours(tempDate.getHours() + 25);
        if (!bidDate) {
            setDate(tempDate);
        }
    };

    const handleSubmit = (values) => {
        let params = {
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            customerId,
        };
        DCPlannerBidService.Create(params, values, startLoader, (res) => handleCreateSuccess(res), handleError, stopLoader);
    };

    const handleCreateSuccess = (res) => {
        toast.success('Bid Created!');
        fetchBids();
        setOpen(false);
    };
    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <div>
            <Typography content="dcPlanner Bidding" size="16" />
            <div className={classes.FormContainer2}>
                <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateDCPlannerBidValidation}>
                    {({ errors, touched, values, isValidating, ...props }) => (
                        <Form>
                            <div>
                                <div className={classes.Subtitle}>Capacity(Mw)</div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`slot${index * 4 + subIndex + 1}`}>
                                                                Slot {index * 4 + subIndex + 1} <span className="required">*</span>
                                                            </label>
                                                            <Input name={`slotAndPrice.slot${index * 4 + subIndex + 1}`} id={`slot${index * 4 + subIndex + 1}`} />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Regulation Price(€)</div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`regulation${index * 4 + subIndex + 1}`}>
                                                                Regulation Price Slot {index * 4 + subIndex + 1} <span className="required">*</span>
                                                            </label>
                                                            <Input name={`slotAndPrice.regulation${index * 4 + subIndex + 1}Price`} id={`regulation${index * 4 + subIndex + 1}`} />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <div className={classes.Subtitle}>Price(€)</div>
                                {Array(6)
                                    .fill(null)
                                    .map((item, index) => (
                                        <div className={classes.InputContainer}>
                                            {Array(4)
                                                .fill(null)
                                                .map((subItem, subIndex) => (
                                                    <div>
                                                        <div className={classes.FieldControl}>
                                                            <label for={`slot${index * 4 + subIndex + 1}Price`}>
                                                                Slot {index * 4 + subIndex + 1} Price <span className="required">*</span>
                                                            </label>
                                                            <Input name={`slotAndPrice.slot${index * 4 + subIndex + 1}Price`} id={`slot${index * 4 + subIndex + 1}Price`} />
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    ))}

                                <div className={classes.InputContainer}>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label>
                                                Date <span className="required">*</span>
                                            </label>
                                            <div className="modal-date-picker">
                                                <DatePicker date={date} setDate={setDate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="plantId">
                                                Plant Id <span className="required">*</span>
                                            </label>
                                            <Input name="plantId" id="plantId" disabled />
                                        </div>
                                    </div>
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label for="unitId">
                                                Unit Id <span className="required">*</span>
                                            </label>
                                            <Input name="unitId" id="unitId" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.ButtonContainer}>
                                <div>
                                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                        Cancel
                                    </button>
                                </div>
                                <div>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default BidContent;
