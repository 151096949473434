// Internal Module Imports
import { HttpClient } from '../utils/httpClient';
import { formatStartDateStringMoment, formatEndDateStringMoment } from '../utils/timeHelper';

const PATH = {
    data: '/admin/data/dc-planner',
    getFcrBid: '/dc-planner',
    getLastFcrBid: '/admin/data/dc-planner/last',
    postBid: '/admin/data/dc-planner/createBid',
    checkBidStatus: '/admin/data/dc-planner/checkbid',
    createReverseBid: '/admin/data/dc-planner/reverse',
    addResultId: '/admin/data/dc-planner/addResultId',
    marketEnabledGroups: 'admin/data/DC-PLANNER/market-enabled-groups',
    updateIsPaid: 'admin/data/DC-PLANNER/paid',
};

export const formatedEndDate = (date) => {
    return `${formatEndDateStringMoment(date)}`;
};

export const formatedStartDate = (date) => {
    return `${formatStartDateStringMoment(date)}`;
};

const Create = (params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.data}`, payload, { params }).then(callback).catch(error).finally(next);
};

const PostBid = (id, params, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.postBid}/${id}`, payload, { params }).then(callback).catch(error).finally(next);
};
const BidData = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.data, { params }).then(callback).catch(error).finally(next);
};

const GetLastBid = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getLastFcrBid}`, { params }).then(callback).catch(error).finally(next);
};
const CheckBidStatus = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.checkBidStatus}/${id}`, {}, { params }).then(callback).catch(error).finally(next);
};

const Delete = (_id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.data}?_id=${_id}`).then(callback).catch(error).finally(next);
};

const AddResultId = (id, data, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.addResultId}/${id}`, data).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}`).then(callback).catch(error).finally(next);
};

const CreateReverseBid = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.createReverseBid}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateIsPaid = (id, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updateIsPaid}/${id}`).then(callback).catch(error).finally(next);
};

export const DCPlannerBidService = {
    BidData,
    Create,
    Delete,
    GetLastBid,
    PostBid,
    CheckBidStatus,
    AddResultId,
    GetMarketEnabledGroups,
    CreateReverseBid,
    UpdateIsPaid,
};
