// Standard Library imports
import React, { useEffect, useState } from 'react';

// Css imports
import classes from '../../../../../styles/AllDevices.module.css';

// External library imports
import { Form, Formik, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';

// Internal module imports
import Typography from '../../../../../components/Typography/Typography';
import { EdgeOTAService } from '../../../../../services/edgeOtaService';
import { useLoader } from '../../../../../hooks';
import { Input } from '../../../../../components/Inputs/Input';
import Dropdown from '../../../../../components/Inputs/Dropdown';
import MultiSelectDropdown from '../../../../../components/Inputs/MultiSelectDropdown';
import Table from '../../../../../components/Table/Table';
import { CreateBatchValidation } from '../../../../../validations/EdgeOTA/Batch';

const CreateBatch = ({ setOpen, fetchBatches }) => {
    const [startLoader, stopLoader] = useLoader();
    const [admins, setAdmins] = useState([]);
    const [adminCustomerHashMap, setAdminCustomerHashMap] = useState({});
    const [customerEdgeHashMap, setCustomerEdgehashMap] = useState({});

    useEffect(() => {
        fetchAdminCustomerEdge();
    }, []);

    const handleSubmit = (values) => {
        const payload = {
            version: values.version,
            tagId: values.tagId,
            edges: Object.values(values.edges)
                .flat()
                .map((val) => val.value),
        };
        EdgeOTAService.CreateBatch(payload, startLoader, handleCreateSuccess, handleError, stopLoader);
    };

    const handleCreateSuccess = () => {
        setOpen(false);
        fetchBatches();
        toast.success('Batch created successfully!');
    };

    const fetchAdminCustomerEdge = () => {
        EdgeOTAService.ReadAdminCustomerEdge(startLoader, handleAdminCustomerEdgeSuccess, handleError, stopLoader);
    };

    const handleAdminCustomerEdgeSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const curHash1 = {};
            const curHash2 = {};
            const admins = data.data.map((a) => {
                curHash1[a._id] = {
                    customers: a.customers.map((c) => {
                        curHash2[c._id] = {
                            edges: c.edges,
                            name: c.name,
                            adminId: a._id,
                        };
                        return { label: c.name, value: c._id };
                    }),
                    name: a.name,
                };
                return {
                    label: a.name,
                    value: a._id,
                };
            });
            Object.keys(curHash2).forEach((c) => {
                curHash2[c].edges = curHash2[c].edges.map((e) => ({ label: e.edgeAlias, value: e._id, customerId: c }));
            });
            setAdminCustomerHashMap(curHash1);
            setCustomerEdgehashMap(curHash2);
            setAdmins(admins);
        }
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const getTableData = (edges) => {
        const flatted = Object.values(edges)
            .flat()
            .map((edge) => {
                return {
                    admin: adminCustomerHashMap[customerEdgeHashMap[edge.customerId].adminId].name,
                    customer: customerEdgeHashMap[edge.customerId].name,
                    edgeAlias: edge.label,
                };
            });
        return flatted;
    };

    return (
        <div style={{ width: '45vw' }}>
            <Typography content="Create Batch" />
            <Formik initialValues={{ tagId: 'latest', version: '', admin: '', customer: '', edges: {} }} validationSchema={CreateBatchValidation} onSubmit={handleSubmit}>
                {({ values, errors }) => (
                    <Form>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl2}>
                                <label for="name">
                                    TAG ID <span className="required">*</span>
                                </label>
                                <Input name="tagId" style={{ marginTop: 0 }} placeHolder="nishant" />
                            </div>
                            <div className={classes.FieldControl2}>
                                <label for="version">
                                    Version <span className="required">*</span>
                                </label>
                                <Input name="version" style={{ marginTop: 0 }} />
                            </div>
                        </div>
                        <div className={classes.InputContainer}>
                            <div className={classes.FieldControl2}>
                                <label for="Admin">
                                    Admin <span className="required">*</span>
                                </label>
                                <Dropdown name="admin" options={admins} />
                            </div>
                            <div className={classes.FieldControl2}>
                                <label for="Customer">
                                    Customer <span className="required">*</span>
                                </label>
                                <Dropdown name="customer" options={adminCustomerHashMap[values.admin]?.customers} />
                            </div>
                            <div className={classes.FieldControl2}>
                                <label for="Edge">
                                    Edge <span className="required">*</span>
                                </label>
                                <MultiSelectDropdown
                                    name={`edges.${values.admin}~${values.customer}`}
                                    options={customerEdgeHashMap[values.customer]?.edges}
                                    defaultValue={values.edges[`${values.admin}~${values.customer}`]}
                                />
                            </div>
                        </div>
                        <ErrorMessage name="edges" component="div" className="error-msg" />
                        <div style={{ height: '50vh', overflowY: 'scroll', marginTop: '1vw' }}>
                            <Table data={getTableData(values.edges)} keys={['admin', 'customer', 'edgeAlias']} head={['Admin', 'Customer', 'Edge Alias']} />
                        </div>
                        <div className={classes.ButtonContainer}>
                            <div>
                                <button type="submit" className="btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateBatch;
