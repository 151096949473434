export const getThemeFromLC = () => {
    const storageItem = localStorage.getItem('THEME');
    if (storageItem && storageItem !== 'undefined') {
        return JSON.parse(storageItem);
    } else {
        return null;
    }
};

export const saveUser = ({ id, email, profileImage, token, userRole }) => {
    const storageItem = localStorage.getItem('GM_USERS');
    let existingUsers = [];
    if (storageItem && storageItem !== 'undefined') existingUsers = JSON.parse(storageItem);
    const extUserIndex = existingUsers.findIndex((u) => u.id === id && u.userRole === userRole);
    const user = { id, email, profileImage, token, userRole };
    if (extUserIndex >= 0) {
        if (existingUsers[extUserIndex].recentApps) {
            user.recentApps = existingUsers[extUserIndex].recentApps;
        }
        existingUsers[extUserIndex] = user;
    } else {
        existingUsers.push(user);
    }
    localStorage.setItem('GM_USERS', JSON.stringify(existingUsers));
};

export const getUsers = () => {
    const storageItem = localStorage.getItem('GM_USERS');
    let existingUsers = [];
    if (storageItem && storageItem !== 'undefined') existingUsers = JSON.parse(storageItem);
    return existingUsers;
};

export const removeUser = (id, userRole) => {
    const storageItem = localStorage.getItem('GM_USERS');
    let existingUsers = [];
    if (storageItem && storageItem !== 'undefined') existingUsers = JSON.parse(storageItem);
    const extUserIndex = existingUsers.findIndex((u) => u.id === id && u.userRole === userRole);
    if (extUserIndex >= 0) {
        existingUsers.splice(extUserIndex, 1);
        localStorage.setItem('GM_USERS', JSON.stringify(existingUsers));
    }
};

export const removeAllUsers = () => {
    let existingUsers = [];
    localStorage.setItem('GM_USERS', JSON.stringify(existingUsers));
};

const getActiveUser = (state) => {
    const {
        user: { _id, userRole },
    } = state;

    const storageItem = localStorage.getItem('GM_USERS');
    let users = [];
    if (storageItem && storageItem !== 'undefined') users = JSON.parse(storageItem);
    const activeUserIndex = users.findIndex((u) => u.id === _id && u.userRole === userRole);

    return {
        activeUser: users[activeUserIndex],
        users,
    };
};

export const saveRecentApps = (data, state) => {
    const { activeUser, users } = getActiveUser(state);

    if (activeUser && !activeUser.recentApps) activeUser.recentApps = [];
    const filtered = activeUser.recentApps.filter((app) => app.id !== data.id);
    filtered.unshift(data);
    filtered.splice(5, 1);
    activeUser.recentApps = filtered;

    localStorage.setItem('GM_USERS', JSON.stringify(users));
};

export const getRecentApps = (state) => {
    const { activeUser } = getActiveUser(state);

    return activeUser?.recentApps ? activeUser.recentApps : [];
};

export const updateRecentTabIndex = (urlPath, tabIndex, state) => {
    const { activeUser, users } = getActiveUser(state);

    const updated = activeUser?.recentApps?.map((app) => {
        if (app.url === urlPath) {
            return {
                ...app,
                tabIndex,
            };
        } else return app;
    });
    activeUser.recentApps = updated;

    localStorage.setItem('GM_USERS', JSON.stringify(users));
};
