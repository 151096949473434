// Standard library imports
import React, { useEffect, useRef, useState } from 'react';

// External library imports
import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import _ from 'lodash';
// Internal module imports
import { Input, InputWithOutState, TextArea } from '../../../components/Inputs/Input';
import Typography from '../../../components/Typography/Typography';
import { createBidValidationDynamically, createFcrBidValidation } from '../../../validations/Bidding/Bids';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import { momentTimeFormater } from '../../../utils/timeHelper';
import { generateHourlyTimestamps } from '../../../utils/dateHelper';
import { useLoader } from '../../../hooks';
import { AdminBiddingService } from '../../../services/AdminBiddingService';
import Dropdown from '../../../components/Inputs/Dropdown';
import { AFRR_MARKET_TYPES } from '../../../constants';

// Css imports
import classes from '../../../styles/BidModal.module.css';
import { arrayConverter24 } from '../../../utils/arrayHelper';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const fcrInitialValues = {
    slot1: { capacity: 0.9, price: 14 },
    slot2: { capacity: 0.9, price: 14 },
    slot3: { capacity: 0.9, price: 14 },
    slot4: { capacity: 0.9, price: 14 },
    slot5: { capacity: 0.9, price: 14 },
    slot6: { capacity: 0.9, price: 14 },
};

const marketType2 = ['FCR', 'FCR-N'];

const bidTypes = [
    {
        label: 'Primary',
        value: 'primary',
    },
    {
        label: 'Secondary',
        value: 'secondary',
    },
];

const getInitialValues = (date, capacity = [], price = [], market, regulationPrice = []) => {
    date = momentTimeFormater(momentTimeFormater(date).format('YYYY-MM-DD'));
    const timestampsForWholeDay = generateHourlyTimestamps(date, moment(date).add(1, 'day'));
    const hourlyData = {};
    timestampsForWholeDay.forEach(
        (hour, index) =>
            (hourlyData[hour] = {
                capacity: capacity.length ? capacity[index] : 0.9,
                price: price.length ? price[index] : 14,
                ...(AFRR_MARKET_TYPES.includes(market) ? { regulationPrice: _.get(regulationPrice, [index], 14) } : {}),
            })
    );
    return { hourlyData };
};

const CreateBid = ({ market, setOpen, fetchBids, customerId, bidDate, instance }) => {
    const defaultDateValue = bidDate ? new Date(bidDate) : new Date();
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(defaultDateValue);
    const [capacity, setCapacity] = useState([]);
    const [price, setPrice] = useState([]);
    const [regulationPrice, setRegulationPrice] = useState([]);
    const [initialValues, setInitialValues] = useState(
        !marketType2.includes(market)
            ? { ...getInitialValues(date, capacity, price, market, regulationPrice), reserveBidIdentification: '', bidType: bidTypes[0].value }
            : { ...fcrInitialValues, bidType: bidTypes[0].value }
    );

    const formikRef = useRef();
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);
    const [selectedBidType, setSelectedBidType] = useState(bidTypes[0]);

    const [alreadyBidCreatedHours, setAlreadyBidCreatedHours] = useState([]);
    const [lastBidCapacityAndPower, setlastBidCapacityAndPower] = useState({
        lastBidCapacity: [],
        instancePower: [],
    });

    const [requestNote, setRequestNote] = useState({
        bidRequest: null,
        maintenanceHours: null,
    });

    useEffect(() => {
        if (customerId && selectedBidType?.value) {
            AdminBiddingService.GetLastBid(
                { market, customerId, instanceId: instance.value, bidType: selectedBidType.value },
                startLoader,
                handleGetLastBidSuccess,
                handleError,
                stopLoader
            );
        }
    }, [selectedBidType]);

    const handleGetLastBidSuccess = ({ data }) => {
        const { date: lastBidDate, reserveBidIdentification } = data.data;
        const capacity = _.get(data.data, [selectedBidType.value, 'capacity']);
        const price = _.get(data.data, [selectedBidType.value, 'price']);
        const regulationPrice = _.get(data.data, [selectedBidType.value, 'regulationPrice'], []);
        if (capacity?.length && price?.length) {
            if (!marketType2.includes(market)) {
                const slotAndPrice = getInitialValues(date, capacity, price, market, regulationPrice);
                const { hourlyData } = slotAndPrice;
                setInitialValues({
                    ...initialValues,
                    hourlyData,
                    reserveBidIdentification: reserveBidIdentification,
                });
                setCapacity(capacity);
                setPrice(price);
                setRegulationPrice(regulationPrice);
            } else {
                setInitialValues({
                    ...initialValues,
                    slot1: { capacity: capacity[0], price: price[0] },
                    slot2: { capacity: capacity[4], price: price[4] },
                    slot3: { capacity: capacity[8], price: price[8] },
                    slot4: { capacity: capacity[12], price: price[12] },
                    slot5: { capacity: capacity[16], price: price[16] },
                    slot6: { capacity: capacity[20], price: price[20] },
                });
            }
        }
        const tempDate = new Date(lastBidDate);
        tempDate.setDate(tempDate.getDate() + 1);
        if (!bidDate) {
            setDate(tempDate);
        }
    };

    useEffect(() => {
        if (customerId) {
            fetchMaintenanceHourForADate();
        }
    }, [date, selectedBidType]);

    useEffect(() => {
        if (alreadyBidCreatedHours.length) {
            alreadyBidCreatedHours.map((h) => {
                const timestamp = Object.keys(initialValues?.hourlyData)[h];
                _.set(initialValues, ['hourlyData', timestamp], { capacity: 0, price: 0, regulationPrice: 0 });
            });
        } else {
            const slotAndPrice = getInitialValues(date, capacity, price, market, regulationPrice);
            const { hourlyData } = slotAndPrice;
            _.set(initialValues, 'hourlyData', hourlyData);
        }
    }, [alreadyBidCreatedHours, capacity]);

    const fetchMaintenanceHourForADate = () => {
        AdminBiddingService.GetMaintenanceHoursForADate(
            {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                market,
            },
            instance.value,
            startLoader,
            handleMaintenanceSuccess,
            handleError,
            stopLoader
        );
    };

    const handleMaintenanceSuccess = ({ data }) => {
        setMaintenanceSlots(_.get(data, ['data', 'requests', 'hourSlots'], []));
        setRequestNote({
            maintenanceHours: _.get(data, ['data', 'requests', 'note'], null),
            bidRequest: _.get(data, ['data', 'bidRequest', 'note'], null),
        });
        let temp = [];
        if (data?.data?.bid?.length) {
            _.get(data, ['data', 'bid'], []).map((bid) => {
                const capacity = _.get(bid, [selectedBidType.value, 'capacity'], []);
                capacity.map((c, i) => {
                    if (c > 0) {
                        temp.push(i);
                    }
                });
            });
        }
        setAlreadyBidCreatedHours(temp);
    };

    const GetBidRequestForADate = () => {
        AdminBiddingService.GetBidRequestForADate(
            {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                market,
            },
            instance.value,
            startLoader,
            handleBidRequestSuccess,
            handleError,
            stopLoader
        );
    };

    const handleBidRequestSuccess = ({ data }) => {
        if (data.data) {
            const capacity = _.get(data.data, ['capacity'], []);
            const price = _.get(data.data, ['price'], []);
            const regulationPrice = _.get(data.data, ['regulationPrice'], []);
            if (!marketType2.includes(market)) {
                const slotAndPrice = getInitialValues(date, capacity, price, market, regulationPrice);
                const { hourlyData } = slotAndPrice;
                setInitialValues({
                    ...initialValues,
                    hourlyData,
                });
                setCapacity(capacity);
                setPrice(price);
                setRegulationPrice(regulationPrice);
            } else {
                setInitialValues({
                    ...initialValues,
                    slot1: { capacity: capacity[0], price: price[0] },
                    slot2: { capacity: capacity[4], price: price[4] },
                    slot3: { capacity: capacity[8], price: price[8] },
                    slot4: { capacity: capacity[12], price: price[12] },
                    slot5: { capacity: capacity[16], price: price[16] },
                    slot6: { capacity: capacity[20], price: price[20] },
                });
            }
        } else {
            toast.error('No request found');
        }
    };

    const handleSubmit = (values) => {
        let capacity = [];
        let price = [];
        let payload = { instanceId: instance.value, customerId, date: momentTimeFormater(date).format('YYYY-MM-DD'), market: market };

        if (marketType2.includes(market)) {
            capacity = arrayConverter24(
                values.slot1.capacity,
                values.slot2.capacity,
                values.slot3.capacity,
                values.slot4.capacity,
                values.slot5.capacity,
                values.slot6.capacity,
                capacity
            );
            price = arrayConverter24(values.slot1.price, values.slot2.price, values.slot3.price, values.slot4.price, values.slot5.price, values.slot6.price, price);
            payload = { ...payload, price, capacity, bidType: selectedBidType.value };
        } else {
            payload = { ...payload, ...values, bidType: selectedBidType.value };
        }
        AdminBiddingService.Create(payload, startLoader, createSuccess, handleError, stopLoader);
    };

    const createSuccess = () => {
        toast.success('Bid Created!');
        const currentDate = moment();
        if (instance?.approvalValidity?.endDate) {
            if (moment(instance?.approvalValidity?.endDate).diff(currentDate, 'days') <= 30) {
                toast.warn(`Approval expires on ${momentTimeFormater(instance.approvalValidity.endDate).format('YYYY-MM-DD')}`);
            }
        }
        fetchBids();
        setOpen(false);
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    useEffect(() => {
        setInitialValues({ ...initialValues, ...(!marketType2.includes(market) ? getInitialValues(date, capacity, price, market, regulationPrice) : {}) });
    }, [date]);

    const getSolcastData = () => {
        AdminBiddingService.GetSolcastData(
            {
                date: momentTimeFormater(date).format('YYYY-MM-DD'),
                instanceId: instance.value,
                market: market,
            },
            startLoader,
            handleSolecastSuccess,
            handleError,
            stopLoader
        );
    };

    const handleSolecastSuccess = ({ data }) => {
        const { capacity = [], lastBidCapacity = [], instancePower = [] } = data?.data;
        setlastBidCapacityAndPower({
            lastBidCapacity: lastBidCapacity,
            instancePower: instancePower,
        });
        setCapacity(capacity);
        const slotAndPrice = getInitialValues(date, capacity, price, market, []);
        const { hourlyData } = slotAndPrice;
        setInitialValues({
            ...initialValues,
            hourlyData,
        });
    };

    return (
        <div>
            <Typography content={`${market} Bidding`} size="16" />
            <div className={classes.FormWrapper}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={!marketType2.includes(market) ? createBidValidationDynamically(date, market) : createFcrBidValidation}
                    onSubmit={handleSubmit}
                    innerRef={formikRef}
                >
                    {({ values, errors }) => {
                        return (
                            <Form>
                                <div className={classes.InputContainer}>
                                    <div className={classes.FieldControl}>
                                        <label>
                                            Date <span className="required">*</span>
                                        </label>
                                        <div className="modal-date-picker">
                                            <DatePicker date={date} setDate={setDate} />
                                        </div>
                                    </div>
                                    {['FCR-D-INC', 'FCR-D-DEC'].includes(market) && (
                                        <div className={classes.FieldControl}>
                                            <label>
                                                Bid Type <span className="required">*</span>
                                            </label>
                                            <div>
                                                <Dropdown name="bidType" options={bidTypes} defaultValue={bidTypes[0]} onChange={setSelectedBidType} />
                                            </div>
                                        </div>
                                    )}

                                    {['FCR-D-INC', 'FCR-D-DEC', 'FFR'].includes(market) && (
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label>
                                                    Reserve Bid Identification
                                                    <span className="required">*</span>
                                                </label>
                                                <Input name="reserveBidIdentification" id="reserveBidIdentification" />
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <div className={classes.FieldControl}>
                                            <label>Instance</label>
                                            <InputWithOutState name="instanceId" id="instanceId" value={instance.label} />
                                        </div>
                                    </div>
                                </div>
                                {requestNote.bidRequest || requestNote.maintenanceHours ? (
                                    <div className={classes.FieldControl2}>
                                        <label>Note</label>
                                        <TextArea
                                            name={`note`}
                                            label="Note"
                                            disabled={true}
                                            value={`${requestNote.bidRequest ? `Bid Request note - ${requestNote.bidRequest}\n` : ''}${requestNote.maintenanceHours ? `Maintenance hours note - ${requestNote.maintenanceHours}` : ''}`}
                                            style={{ fontSize: '0.8vw' }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                                {[
                                    {
                                        label: 'Capacity (Mw)',
                                        value: 'capacity',
                                    },
                                    {
                                        label: 'Price (€)',
                                        value: 'price',
                                    },
                                    ...(AFRR_MARKET_TYPES.includes(market)
                                        ? [
                                              {
                                                  label: 'Regulation Price (€)',
                                                  value: 'regulationPrice',
                                              },
                                          ]
                                        : []),
                                ].map((type) => (
                                    <div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div className={classes.Subtitle}>{type.label}</div>
                                            {type.value === 'capacity' && (
                                                <div style={{ display: 'flex', gap: '0.5vw' }}>
                                                    <button type="button" className="btn-primary" onClick={GetBidRequestForADate}>
                                                        Fill ( Bid Request )
                                                    </button>
                                                    <button type="button" className="btn-primary" onClick={getSolcastData}>
                                                        Fill ( Solecast )
                                                    </button>
                                                </div>
                                            )}
                                        </div>

                                        {!marketType2.includes(market)
                                            ? _.chunk(Object.keys(values.hourlyData), 6).map((chunk, chunkIndex) => (
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          flexWrap: 'wrap',
                                                          gap: '1vw',
                                                      }}
                                                  >
                                                      {chunk.map((key, index) => (
                                                          <div>
                                                              <div className={classes.FieldControl}>
                                                                  <label for={`hourlyData.${key}.${type.value}`}>
                                                                      Slot {6 * chunkIndex + index + 1} {' / Hour '}
                                                                      {6 * chunkIndex + index}-{6 * chunkIndex + index + 1}
                                                                      {type.value === 'price' ? ' Price' : ' '}
                                                                      <span className="required">*</span>
                                                                  </label>
                                                                  <Input
                                                                      name={`hourlyData.${key}.${type.value}`}
                                                                      id={`hourlyData.${key}.${type.value}`}
                                                                      type={'number'}
                                                                      style={maintenanceSlots?.includes(6 * chunkIndex + index + 1) ? maintenanceSlotCss : {}}
                                                                      disabled={alreadyBidCreatedHours.includes(6 * chunkIndex + index) ? true : false}
                                                                  />
                                                                  {lastBidCapacityAndPower?.lastBidCapacity.length && type.value === 'capacity' ? (
                                                                      <label for={`hourlyData.${key}.${type.value}`}>
                                                                          Capacity - {_.get(lastBidCapacityAndPower, ['lastBidCapacity', 6 * chunkIndex + index])}/ Power -{' '}
                                                                          {_.get(lastBidCapacityAndPower, ['instancePower', 6 * chunkIndex + index])}
                                                                      </label>
                                                                  ) : (
                                                                      ''
                                                                  )}
                                                              </div>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ))
                                            : _.chunk(Object.keys(fcrInitialValues), 3).map((chunk, chunkIndex) => (
                                                  <div
                                                      style={{
                                                          display: 'flex',
                                                          flexWrap: 'wrap',
                                                          gap: '1vw',
                                                      }}
                                                  >
                                                      {chunk.map((key, index) => (
                                                          <div>
                                                              <div className={classes.FieldControl}>
                                                                  <label for={`${key}.${type.value}`}>
                                                                      Slot {3 * chunkIndex + index + 1} / Hour {(3 * chunkIndex + index) * 4}-{(3 * chunkIndex + index + 1) * 4}
                                                                      {type.value === 'price' ? ' Price' : ' '}
                                                                      <span className="required">*</span>
                                                                  </label>
                                                                  <Input
                                                                      name={`${key}.${type.value}`}
                                                                      id={`${key}.${type.value}`}
                                                                      type={'number'}
                                                                      style={maintenanceSlots?.includes(3 * chunkIndex + index + 1) ? maintenanceSlotCss : {}}
                                                                      disabled={alreadyBidCreatedHours.includes(6 * chunkIndex + index) ? true : false}
                                                                  />
                                                              </div>
                                                          </div>
                                                      ))}
                                                  </div>
                                              ))}
                                    </div>
                                ))}
                            </Form>
                        );
                    }}
                </Formik>
            </div>

            <div className={classes.ButtonContainer}>
                <div>
                    <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                        Cancel
                    </button>
                </div>
                <div>
                    <button type="submit" className="btn-primary" onClick={() => formikRef.current.submitForm()}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateBid;
