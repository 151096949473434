// Standard Library Imports
import React, { useEffect, useState } from 'react';

// Internal library imports
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { useLoader } from '../../../../hooks';
import classes from '../../../../styles/AllDevices.module.css';
import { toast } from 'react-toastify';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import { signalsService } from '../../../../services/signalsService';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';

// Css imports
import signalClasses from '../../../Root/GlobalConfig/index.module.css';

function SignalTable({ customerId, setSignalFormModal, setSelectedIndex }) {
    const [startLoader, stopLoader] = useLoader();
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        userId: '',
    });

    const [signalsLocal, setSignalsLocal] = useState([]);
    const [signalsGlobal, setSignalsGlobal] = useState([]);

    useEffect(() => {
        fetchSignals();
    }, []);

    const handleSignalSuccess = ({ data }) => {
        const processedDataLocal = [];
        const processedDataGlobal = [];

        data?.data.forEach((key, i) => {
            const data = {
                's.no': i + 1,
                title: key.name,
                area: key.area,
                type: key.type,
                queryString: JSON.stringify(key.queryString),
                dataSource: key.dataSource,
                action: (
                    <div className={signalClasses.ActionContainer} style={{ justifyContent: 'center', display: 'flex', marginTop: '12%' }}>
                        {key?.signalType === 'local' ? (
                            <div>
                                <UilPen
                                    size={'1.2vw'}
                                    style={{ color: 'var(--color-primary)' }}
                                    onClick={() => {
                                        setSignalFormModal({
                                            status: true,
                                            type: 'edit',
                                            data: {
                                                name: key.name,
                                                area: key.area,
                                                type: key.type,
                                                queryString: key.queryString,
                                                dataSource: key.dataSource,
                                                monitoringToggle: key.monitoringToggle || false,
                                                loggingType: key.loggingType,
                                                constantMonitoring: key?.constantMonitoring || false,
                                                signalId: key._id,
                                            },
                                        });
                                        setSelectedIndex(1);
                                    }}
                                />
                                <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, signalId: key._id })} />
                            </div>
                        ) : (
                            <p>--</p>
                        )}
                    </div>
                ),
            };

            if (key.signalType === 'local') {
                processedDataLocal.push(data);
            } else {
                processedDataGlobal.push(data);
            }
        });
        setSignalsLocal(processedDataLocal);
        setSignalsGlobal(processedDataGlobal);
    };

    const fetchSignals = () => {
        signalsService.ReadAllById(
            customerId,
            () => startLoader('getSignals'),
            handleSignalSuccess,
            handleError,
            () => stopLoader('getSignals')
        );
    };

    const handleDelete = () => {
        signalsService.deleteType(deleteModal.signalId, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = (res) => {
        if (res) {
            toast.success('Signal deleted successfully!');
            setDeleteModal({ status: false, signalId: '' });
            fetchSignals();
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div className={classes.AllDevices}>
            <DeleteModal
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })}
            ></DeleteModal>
            <div className={classes.Header} style={{ marginBottom: '1%' }}>
                <div>
                    <Typography content={'Local Signals'} />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={signalsLocal.length} />
                        </span>
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="btn-primary"
                            style={{ marginTop: '0' }}
                            onClick={() => {
                                setSignalFormModal({ status: true, type: 'create', data: {} });
                                setSelectedIndex(1);
                            }}
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ height: 'auto', width: 'auto', maxHeight: '30vh', overflowY: 'scroll', minHeight: '20vh' }}>
                <Table
                    head={['S.No', 'Title', 'Area', 'Signal Type', 'Query String', 'Data Source', 'Action']}
                    keys={['s.no', 'title', 'area', 'type', 'queryString', 'dataSource', 'action']}
                    data={signalsLocal}
                />
            </div>
            <hr />
            <div className={classes.Header} style={{ marginBottom: '1%' }}>
                <div>
                    <Typography content={'Global Signals'} />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={signalsGlobal.length} />
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ height: 'auto', width: 'auto', maxHeight: '30vh', overflowY: 'scroll', minHeight: '20vh' }}>
                <Table
                    head={['S.No', 'Title', 'Area', 'Signal Type', 'Query String', 'Data Source']}
                    keys={['s.no', 'title', 'area', 'type', 'queryString', 'dataSource']}
                    data={signalsGlobal}
                />
            </div>
        </div>
    );
}

export default SignalTable;
