import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const CreateBatchValidation = yup.object().shape({
    tagId: yup.string().required(ERRORS.required),
    version: yup.string().required(ERRORS.required),
    edges: yup.object().test('edges', 'Select atleast one edge device!', (val) => {
        const flatted = Object.values(val).flat();
        return Boolean(flatted.length);
    }),
});
