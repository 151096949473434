import React from 'react';
import { useHistory } from 'react-router-dom';
import classes from './ErrorPage.module.css';
import LostIcon from '../../assets/lost.svg';

const NotFound = () => {
    const history = useHistory();
    return (
        <div>
            <div className={classes.Container}>
                <div>
                    <img src={LostIcon} alt="" />
                </div>
                <div className={classes.Title}>Oops! Page Not Found</div>
                <div className={classes.Subtitle}>
                    Contact the Administrator <span className={classes.Email}>admin@scnordic.com</span>
                </div>
                <div className={classes.Button}>
                    <button className="btn-primary" onClick={() => history.push('/')}>
                        Home
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
