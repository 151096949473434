// Standard library imports
import React, { useState } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import AuditDownloader from './AuditDownloader';
import TimestampAnalyzer from './TimestampAnalyzer';
import PQAnalyser from './PQAnalyser';
import AuditDataAppender from './AuditDataAppender';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

const AuditPQTool = () => {
    const history = useHistory();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        startTime: queryParams.get('startTime') ? queryParams.get('startTime') : '',
        endTime: queryParams.get('endTime') ? queryParams.get('endTime') : '',
        market: queryParams.get('market') ? queryParams.get('market') : '',
    });
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const updateQueryParams = (type, value) => {
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    let tabs = [
        {
            name: 'Audit Downloader',
            component: (
                <>
                    <AuditDownloader queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Timestamp Analyzer',
            component: (
                <>
                    <TimestampAnalyzer />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Audit Data Appender',
            component: (
                <>
                    <AuditDataAppender queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 3,
        },
        {
            name: 'PQ Analyser',
            component: (
                <>
                    <PQAnalyser queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 4,
        },
    ];

    return (
        <div className=" ventilation">
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default AuditPQTool;
