import * as yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const createAdminUserValidation = yup.object({
    email: yup.string().email().max(100).typeError('seh').required(),
    name: yup.string().min(3).max(70).required(),
    password: yup.string().required().min(8).max(40),
    userRole: yup.mixed().required().oneOf(['sub-admin']),
    contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(),
    adminId: yup.string().required(),
});

export const updateAdminUserValidation = yup.object({
    email: yup.string().email().max(100).typeError('seh').required(),
    name: yup.string().min(3).max(70).required(),
    password: yup.string().optional(),
    userRole: yup.mixed().required().oneOf(['sub-admin']),
    contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(),
    adminId: yup.string().required(),
});
