// Standard library import
import React from 'react';

// External library import
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { UilExpandArrows, UilTimesCircle, UilFileDownload } from '@iconscout/react-unicons';

// Internal module import
import Typography from '../../../components/Typography/Typography';
import classes from '../../../styles/BidModal.module.css';
import { TextArea, ToggleButton, Input } from '../../../components/Inputs/Input';
import { useEffect } from 'react';
import { useState } from 'react';
import Dropdown from '../../../components/Inputs/Dropdown';
import { SystemServices } from '../../../services/SystemServices';
import NewJSONEditor from '../../../components/JSONEditor/NewJSONEditor';
import edgeClasses from '../Edge.module.css';
import MultipleFilesUpload from '../../../components/Inputs/FileUpload/MultipleFileUpload';
import { EdgeGatewayService } from '../../../services/EdgeGatewayService';
import { EdgeGatewayCreate } from '../../../validations/EdgeGateway/edgeGateway';

const CreateEdgeGateway = ({ setModal, startLoader, stopLoader, fetchBids, deviceDetails, customerId, customerCode, selectCustomer }) => {
    const [edges, setEdges] = useState([]);
    const [systems, setSystems] = useState([]);
    const [isFullScreen, setFullScreen] = useState({
        status: false,
        editor: 0,
    });

    const [edgeProfileOptions, setEdgeProfileOptions] = useState({
        controlProfile: [],
        meterProfile: [],
        controllerProfile: [],
    });
    const [updateDefaultValueEditor, setUpdateDefaultValueEditor] = useState(false);

    const defaultValue = {
        edgeGatewayAlias: deviceDetails.edgeGatewayAlias || '',
        edgeId: deviceDetails.edgeId || '',
        systemId: deviceDetails.systemId || '',
        disabled: deviceDetails.disabled || false,
        properties: {
            notificationConfig: {
                master: deviceDetails.properties?.notificationConfig?.master === false ? false : true,
            },
            gatewayConfiguration: deviceDetails.properties?.gatewayConfiguration || {},
            codeVersion: deviceDetails.properties?.codeVersion,
            notes: deviceDetails.properties?.notes || '',
        },
    };

    const [initialValues, setInitialValues] = useState(defaultValue);
    const [files, setFiles] = useState(deviceDetails?.files || []);

    useEffect(() => {
        getEdgeProfiles();
        getCustomerEdges();
    }, []);

    const getCustomerEdges = () => {
        EdgeGatewayService.CustomerEdges(selectCustomer.value, startLoader, handleEdgeSuccess, handleError, stopLoader);
    };

    const getEdgeProfiles = () => {
        EdgeGatewayService.EdgeProfiles(startLoader, handleEdgeProfileSuccess, handleError, stopLoader);
    };

    const handleEdgeSuccess = ({ data }) => {
        const processedData = data.data.map((e) => {
            return {
                label: e.edgeAlias,
                value: e._id
            }
        });
        setEdges(processedData);
    };

    const handleEdgeProfileSuccess = ({ data }) => {
        const controlProfile = [];
        const meterProfile = [];
        const controllerProfile = [];
        data.data.map((e) => {
            if (e.type === 'controlProfile') {
                controlProfile.push({
                    label: e.name,
                    value: e.profile,
                });
            } else if (e.type === 'meterProfile') {
                meterProfile.push({
                    label: e.name,
                    value: e.profile,
                });
            } else if (e.type === 'controllerProfile') {
                controllerProfile.push({
                    label: e.name,
                    value: e.profile,
                });
            }
        });
        setEdgeProfileOptions({
            controlProfile: controlProfile,
            meterProfile: meterProfile,
            controllerProfile: controllerProfile,
        });
    };

    useEffect(() => {
        getSystems();
    }, [customerId]);

    const handleSubmit = (values) => {
        delete values.controlProfile;
        delete values.meterProfile;
        delete values.controllerProfile;
        values.files = files;

        if (deviceDetails?._id) {
            EdgeGatewayService.Update(deviceDetails._id, values, startLoader, handleUpdateSucess, handleError, stopLoader);
        } else {
            const payload = {
                ...values,
                customerId,
                customerCode,
            };
            EdgeGatewayService.Create(payload, startLoader, handleSubmitSucess, handleError, stopLoader);
        }
    };

    const handleUpdateSucess = ({ res }) => {
        fetchBids();
        setModal(false);
        toast.success('Gateway updated successfully');
    };

    const handleSubmitSucess = ({ res }) => {
        fetchBids();
        setModal(false);
        toast.success('Gateway created successfully');
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleSystemSuccess = (res) => {
        const systems = res?.data?.data.map((host) => {
            return {
                label: host.hostName,
                value: host._id,
            };
        });
        setSystems(systems);
    };

    const getSystems = () => {
        SystemServices.getSystems(customerId, startLoader, (data) => handleSystemSuccess(data), handleError, stopLoader);
    };

    const handleUploadClick = (e) => {
        const files = e.target.files;
        uploadFiles(files);
    };

    const uploadFiles = (files) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        EdgeGatewayService.UploadFiles(formData, startLoader, handleUploadFilesSuccess, handleError, stopLoader);
    };

    const handleUploadFilesSuccess = ({ data }) => {
        setFiles((prev) => [...prev, ...data.data]);
    };

    const removeFile = (key) => {
        const temp = files.filter((e) => e.key !== key);
        setFiles(temp);
    };

    const handleGetSignedUrl = (key) => {
        EdgeGatewayService.GetUploadedFileSignedURL({ key, type: 'edgeFile' }, startLoader, handleFileSignedURLSuccess, handleError, stopLoader);
    };

    const handleFileSignedURLSuccess = ({ data }) => {
        if (data.data?.signedUrl) {
            window.open(data.data.signedUrl, '_blank');
        }
    };

    return (
        <div className={isFullScreen.status ? edgeClasses.AddModalFullScreen : edgeClasses.AddModal}>
            {!isFullScreen.status && <Typography content={`${deviceDetails?._id ? 'Edit' : 'Create'} Gateway`} size="16" />}

            <div className={classes.FormContainer}>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={EdgeGatewayCreate}
                >
                    {({ values, setFieldValue, ...props }) => {
                        return (
                            <Form>
                                {!isFullScreen.status ? (
                                    <>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="edgeGatewayAlias">
                                                    Edge Gateway Alias <span className="required">*</span>
                                                </label>
                                                <Input name="edgeGatewayAlias" id="edgeGatewayAlias" type="text" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="edgeId">
                                                    Edge
                                                    <span className="required"> * </span>
                                                </label>
                                                <Dropdown name="edgeId" options={edges} defaultValue={{ value: initialValues.edgeId }} />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="systemId">
                                                    System
                                                    <span className="required"> * </span>
                                                </label>
                                                <Dropdown name="systemId" options={systems} defaultValue={{ value: initialValues.systemId }} />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="controlProfile">Control Profile</label>
                                                <Dropdown
                                                    name="controlProfile"
                                                    options={edgeProfileOptions.controlProfile}
                                                    defaultValue={{}}
                                                    onChange={(e) => {
                                                        setUpdateDefaultValueEditor(true);
                                                        const temp = {
                                                            ...values.properties.gatewayConfiguration,
                                                            controlProfile: e.value,
                                                        };
                                                        setFieldValue('properties.gatewayConfiguration', temp);
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="meterProfile">Meter Profile</label>
                                                <Dropdown
                                                    name="meterProfile"
                                                    options={edgeProfileOptions.meterProfile}
                                                    defaultValue={{}}
                                                    onChange={(e) => {
                                                        setUpdateDefaultValueEditor(true);
                                                        const temp = {
                                                            ...values.properties.gatewayConfiguration,
                                                            meterProfile: e.value,
                                                        };
                                                        setFieldValue('properties.gatewayConfiguration', temp);
                                                    }}
                                                />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <label for="controllerProfile">Controller Profile</label>
                                                <Dropdown
                                                    name="controllerProfile"
                                                    options={edgeProfileOptions.controllerProfile}
                                                    defaultValue={{}}
                                                    onChange={(e) => {
                                                        setUpdateDefaultValueEditor(true);
                                                        const temp = {
                                                            ...values.properties.gatewayConfiguration,
                                                            controllerProfile: e.value,
                                                        };
                                                        setFieldValue('properties.gatewayConfiguration', temp);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2} style={{ marginTop: '1vh' }}>
                                                <label
                                                    for="gatewayConfiguration"
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <span>Gateway Configuration</span>

                                                    <UilExpandArrows
                                                        size={'1vw'}
                                                        style={{
                                                            color: 'var(--color-primary)',
                                                            cursor: 'pointer',
                                                            zIndex: '1',
                                                        }}
                                                        onClick={() => {
                                                            setFullScreen({
                                                                status: true,
                                                                editor: 0,
                                                            });
                                                        }}
                                                    />
                                                </label>
                                                <NewJSONEditor
                                                    name="properties.gatewayConfiguration"
                                                    defaultValue={values.properties.gatewayConfiguration}
                                                    height="15rem"
                                                    manualDefaultValueUpdate={updateDefaultValueEditor}
                                                    setManualDefaultValueUpdate={setUpdateDefaultValueEditor}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="codeVersion">Code Version</label>
                                                <Input name={`properties.codeVersion`} id="properties.codeVersion" />
                                            </div>
                                            <div className={classes.FieldControl2}>
                                                <div style={{ display: 'flex', marginTop: '2vh' }}>
                                                    <div className={classes.FieldControl}>
                                                        <ToggleButton values={values} label={'Monitoring'} name={`properties.notificationConfig.master`} />
                                                    </div>
                                                    <div className={classes.FieldControl}>
                                                        <ToggleButton values={values} label={'Disable (Gateway)'} name={`disabled`} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2}>
                                                <label for="notes">Notes</label>
                                                <TextArea name={`properties.notes`} id="notes" type="text" />
                                            </div>
                                        </div>
                                        <div className={classes.InputContainer2}>
                                            <div className={classes.FieldControl2} style={{ width: '99.7%' }}>
                                                <label for="uploadFiles">Files</label>
                                                <div>
                                                    <MultipleFilesUpload name="uploadFiles" id="uploadFiles" accept="*" multiple onChange={handleUploadClick} />
                                                </div>
                                            </div>
                                        </div>
                                        {files?.length ? (
                                            <div className={edgeClasses.UploadedEdgeFiles}>
                                                {files.map((item, index) => (
                                                    <div>
                                                        <div className={edgeClasses.SelectedFile}>
                                                            <div key={index}>
                                                                <p>{item.name}</p>
                                                            </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className={edgeClasses.CloseIcon} onClick={() => handleGetSignedUrl(item.key)}>
                                                                    <UilFileDownload
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                        }}
                                                                        size={'1.3vw'}
                                                                    />
                                                                </div>
                                                                <div onClick={() => removeFile(item.key)} className={edgeClasses.CloseIcon}>
                                                                    <UilTimesCircle
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                        }}
                                                                        size={'1.3vw'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        <div className={classes.ButtonContainer} style={{ marginBottom: '2vh' }}>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setModal(false);
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                            <div>
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {isFullScreen.status && (
                                            <div className={classes.InputContainer2}>
                                                <div className={classes.FieldControl2}>
                                                    <label for="gatewayConfiguration">Gateway Configuration</label>
                                                    <NewJSONEditor name="properties.gatewayConfiguration" defaultValue={values.properties.gatewayConfiguration} height="80vh" />
                                                </div>
                                            </div>
                                        )}

                                        <div className={classes.ButtonContainer} style={{ marginBottom: '2vh' }}>
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setFullScreen({
                                                            status: false,
                                                            editor: 0,
                                                        });
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default CreateEdgeGateway;
