import classes from './CollapsibleWithRename.module.css';
import React, { useContext } from 'react';
import Typography from '../Typography/Typography';
import Collapsible from 'react-collapsible';
import DeviceIcon from '../../assets/device.png';

const CollapsibleWithRename = ({ group, setDevice, open, setOpen, setCreateGroupModal, setEditGroup, deviceIcon, disableDoubleClick, source, addDevice, tempName }) => {
    let timeout = null;

    const { name, devices, _id: groupId } = group;

    const onClick = (e, name) => {
        e.preventDefault();
        if (timeout === null) {
            timeout = window.setTimeout(() => {
                timeout = null;
                open === name ? setOpen('') : setOpen(name);
            }, 300);
        }
    };

    const onDoubleClick = (e) => {
        e.preventDefault();
        window.clearTimeout(timeout);
        console.log(group);
        setEditGroup({ id: group?._id, name: group.name });
        timeout = null;
        setCreateGroupModal(true);
    };

    return (
        <div className={classes.Container}>
            <div className={classes.GroupsContainer}>
                <div role="button" onClick={(e) => onClick(e, name)} onDoubleClickCapture={!disableDoubleClick ? onDoubleClick : ''} className={classes.Label}>
                    <div className="img-container">
                        <img src={DeviceIcon} alt="" />
                    </div>
                    <div>
                        <Typography size="14" content={name} />
                    </div>
                    <div></div>
                </div>
                <Collapsible open={open === name}>
                    <ul className={classes.DeviceList}>
                        {/* {addDevice && userRole === USER_ROLE.SUDOER ? (
              <li onClick={() => addDevice(group)}>
                <div className={classes.Label} role='button'>
                  <div className='img-container'>
                    <img src={AddIcon} alt='' style={{ height: '1vw' }} />
                  </div>
                  <div>
                    <Typography size='14' content='Add Device' />
                  </div>
                  <div></div>
                </div>
              </li>
            ) : null} */}
                        {devices.map((device) => {
                            if (source === 'dataHub' && device.enabled === false) return;
                            return (
                                <li onClick={() => setDevice({ ...device, group: groupId })}>
                                    <div className={classes.Label} role="button">
                                        <div className="img-container">
                                            <img src={deviceIcon || DeviceIcon} alt="" />
                                        </div>
                                        <div>
                                            <Typography size="14" content={tempName ? device?.tempName : device.name} />
                                        </div>
                                        <div></div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </Collapsible>
            </div>
        </div>
    );
};

export default CollapsibleWithRename;
