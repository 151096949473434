// Standard library imports
import React from 'react';
// Internal module imports
import classes from '../../styles/BidModal.module.css';
import Typography from '../../components/Typography/Typography';

const CreateAgreement = ({ link, instance = [], setOpen }) => {
    return (
        <div>
            <div>
                <Typography content={'No Agreement Found'} />
                <Typography subtext={true} content={instance.length ? `${instance.join(', ')}` : ''} />
            </div>

            <div className={classes.ButtonContainer}>
                <div>
                    <button type="button" className="btn-secondary" onClick={() => setOpen({ modalStatus: false, link: '' })}>
                        Cancel
                    </button>
                </div>
                <div>
                    <button className="btn-primary">
                        <a href={link} target="_blank" style={{ textDecoration: 'none', color: 'white' }}>
                            Create agreement
                        </a>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateAgreement;
