// Standard Library imports
import React, { useEffect, useState } from 'react';
// Internal module imports
import ActivationSummary from './';
import Overview from './Overview';
import TabsComponent from '../../../../../components/Tabs/Tabs';

const ActivationIndex = ({ queryParamsData, updateQueryParams }) => {
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);

    let tabs = [
        {
            name: 'Details',
            component: (
                <>
                    <ActivationSummary queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
        {
            name: 'Overview',
            component: (
                <>
                    <Overview queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 1,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('nestedTab', index);
                }}
            />
        </div>
    );
};

export default ActivationIndex;
