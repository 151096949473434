import { HttpClient } from '../utils/httpClient';

const PATH = {
    customerEdges: '/admin/iot-gateway/edges',
    create: '/admin/iot-gateway',
    read_all: '/admin/iot-gateway',
    update: '/admin/iot-gateway',
    delete: '/admin/iot-gateway',
    rotate_credentials: '/admin/iot-gateway/mqtt-credential',
    command: '/admin/iot-gateway/command',
    edgeError: '/admin/iot-gateway/error',
    stats: '/admin/iot-gateway/stats',
    statsExport: '/admin/iot-gateway/stats-export',
    create_alert: '/admin/iot-gateway/create-alert',
    get_alerts: '/admin/iot-gateway/get-alert',
    delete_alert: '/admin/iot-gateway/delete-alert',
    updateNotificationStates: '/admin/iot-gateway/notification',
    getUploadedFiles: '/admin/iot-gateway/log-files',
    getUploadedFileSignedURL: '/admin/iot-gateway/log-file',
    edgeProfiles: '/admin/iot-gateway/edge-profile',
    uploadFiles: '/admin/iot-gateway/upload-files',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const RotateCredentials = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.rotate_credentials}/${id}`).then(callback).catch(error).finally(next);
};

const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const SendCommand = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.command, payload).then(callback).catch(error).finally(next);
};

const GetEdgeError = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.edgeError, { params }).then(callback).catch(error).finally(next);
};

const GetEdgeStats = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.stats, { params }).then(callback).catch(error).finally(next);
};

const GetEdgeStatsForExport = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.statsExport, { params }).then(callback).catch(error).finally(next);
};

const CreateAlert = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create_alert, payload).then(callback).catch(error).finally(next);
};

const GetAlerts = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.get_alerts}/${id}`).then(callback).catch(error).finally(next);
};

const DeleteAlert = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete_alert}/${id}`).then(callback).catch(error).finally(next);
};

const UpdateNotificationStates = (payload, start, callback, error, next) => {
    start();
    return HttpClient.put(PATH.updateNotificationStates, payload).then(callback).catch(error).finally(next);
};

const GetUploadedFiles = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getUploadedFiles}`, { params }).then(callback).catch(error).finally(next);
};
const GetUploadedFileSignedURL = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getUploadedFileSignedURL}`, { params }).then(callback).catch(error).finally(next);
};

const EdgeProfiles = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.edgeProfiles).then(callback).catch(error).finally(next);
};

const UploadFiles = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.uploadFiles, payload).then(callback).catch(error).finally(next);
};

const CustomerEdges = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.customerEdges}/${id}`).then(callback).catch(error).finally(next);
};

export const EdgeGatewayService = {
    Create,
    ReadAll,
    Update,
    Delete,
    CustomerEdges,
    RotateCredentials,
    SendCommand,
    GetEdgeError,
    GetEdgeStats,
    GetEdgeStatsForExport,
    CreateAlert,
    GetAlerts,
    DeleteAlert,
    UpdateNotificationStates,
    GetUploadedFiles,
    GetUploadedFileSignedURL,
    EdgeProfiles,
    UploadFiles,
};
