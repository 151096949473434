// External library import
import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';

export const PQDownloadValidation = yup.object().shape({
    market: yup.string().test('market', ERRORS.required, (value, c) => {
        if (c.parent.capacity && !value) {
            return false;
        } else {
            return true;
        }
    }),
    capacity: yup.string().test('capacity', ERRORS.required, (value, c) => {
        if (c.parent.market && !value) {
            return false;
        } else {
            return true;
        }
    }),
});
