import * as yup from 'yup';
import { ERRORS } from '../../utils/validationHelper';
const validateDay = (day) => {
    return yup.object().shape({
        startHour: yup
            .number()
            .min(0)
            .max(24)
            .nullable()
            .when('enabled', {
                is: true,
                then: yup.number().required('Start Hour is required'),
            }),
        endHour: yup
            .number()
            .min(0)
            .max(24)
            .nullable()
            .when('enabled', {
                is: true,
                then: yup.number().required('End Hour is required'),
            }),
        enabled: yup.bool(),
        startHour1: yup
            .number()
            .min(0)
            .max(24)
            .nullable()
            .when('enabled1', {
                is: true,
                then: yup.number().required('Start Hour1 is required'),
            }),
        endHour1: yup
            .number()
            .min(0)
            .max(24)
            .nullable()
            .when('enabled1', {
                is: true,
                then: yup.number().required('End Hour1 is required'),
            }),
        enabled1: yup.bool(),
    });
};

const validateRevenueSplit = () => {
    return yup
        .mixed()
        .test('total should be hundred', 'Total of revenue split should be 100', (value, context) => {
            const scSplit = Number(context.parent.revenueSplit);
            const partnerSplit = Number(context.parent.revenueSplitPartner);
            const customerSplit = Number(context.parent.revenueSplitCustomer);
            let total = scSplit + partnerSplit + customerSplit;
            let enablerSplit = 0;
            for (const key in context.parent.enablers) {
                enablerSplit = enablerSplit + context.parent.enablers[key];
            }
            total = total + enablerSplit;
            if (total === 100) return true;
            else return false;
        })
        .optional();
};

const validateInstance = yup.object().shape({
    activeDays: yup.object({
        monday: validateDay('monday'),
        tuesday: validateDay('tuesday'),
        wednesday: validateDay('wednesday'),
        thursday: validateDay('thursday'),
        friday: validateDay('friday'),
        saturday: validateDay('saturday'),
        sunday: validateDay('sunday'),
    }),
    revenueSplit: validateRevenueSplit(),
    revenueSplitPartner: validateRevenueSplit(),
    revenueSplitCustomer: validateRevenueSplit(),
    enablers: validateRevenueSplit(),
    licenseId: yup.string().required(ERRORS.required),
});

export const CreateAggrementValidator = (instnaceValue = {}) => {
    const instancesValidation = {};
    Object.keys(instnaceValue).forEach((key) => (instancesValidation[key] = validateInstance));
    return yup.object().shape({
        startDate: yup.string().required(ERRORS.required),
        endDate: yup.date().nullable(),
        instances: yup.object().shape(instancesValidation),
        enableEndDate: yup.bool(),
        customer: yup.string().required(ERRORS.required),
        market: yup.string().required(ERRORS.required),
    });
};
