import classes from './AlertModal.module.css';
import React from 'react';
import Modal from 'react-modal';
import Typography from '../Typography/Typography';
import AlertIcon from '../../assets/alert.png';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const AlertModal = ({ isOpen, setOpen, title, content }) => {
    return (
        <div>
            <Modal isOpen={isOpen} style={customStyles} onRequestClose={() => setOpen('')}>
                <div className={classes.Modal}>
                    <div className={classes.ModalHead}>
                        <Typography content={title} />
                        <div className={classes.AlertIcon}>
                            <img src={AlertIcon} alt="" />
                        </div>
                    </div>
                    <div className={classes.ModalContent}>{content}</div>
                    <div className={classes.Footer}>
                        <div className="btn-primary" onClick={() => setOpen('')}>
                            Okay
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default AlertModal;
