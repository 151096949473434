// Standard library imports
import React, { useState, useContext } from 'react';
// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal module imports
import TabsComponent from '../../../components/Tabs/Tabs';
import classes from './Monitoring.module.css';
import Montiroting from './Monitoring/index';
import Summary from './Summary';
import Notifications from './Notifications';

const Monitoring = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        subTab: queryParams.get('subTab') ? Number(queryParams.get('subTab')) : '',
        nestedTab: queryParams.get('nestedTab') ? Number(queryParams.get('nestedTab')) : '',
        date: queryParams.get('date') ? new Date(queryParams.get('date')) : '',
        customer: queryParams.get('customer') ? queryParams.get('customer') : '',
        filter: queryParams.get('filter') ? queryParams.get('filter') : '',
        admin: queryParams.get('admin') ? queryParams.get('admin') : '',
        profile: queryParams.get('profile') ? queryParams.get('profile') : '',
        status: queryParams.get('status') ? queryParams.get('status') : '',
    });

    const removeQueryParams = () => {
        queryParams.delete('date');
        queryParams.delete('customer');
        queryParams.delete('admin');
        queryParams.delete('profile');
        queryParams.delete('status');
        queryParams.delete('nestedTab');
    };
    const updateQueryParams = (type, value) => {
        if (type == 'tab') {
            queryParams.delete('subTab');
            setQueryParams({});
            removeQueryParams();
        }
        if (type == 'subTab' || type == 'nestedTab') {
            removeQueryParams();
            setQueryParams({});
        }
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);

    const tabs = [
        {
            name: 'Summary',
            component: <Summary queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 1,
        },
        {
            name: 'Monitoring',
            component: <Montiroting queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 2,
        },
        {
            name: 'Global Notification Control',
            component: <Notifications queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 3,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Monitoring;
