// Standard library imports
import React, { useEffect, useState } from 'react';
// Internal module imports
import TabsComponent from '../../../../components/Tabs/Tabs';
import { RequestBid } from './RequestBid';
import { MaintenanceHours } from './MaintenanceHours';
import { regulationsService } from '../../../../services/regulationsService';
import { AdminService } from '../../../../services/AdminService';
import { toast } from 'react-toastify';
import { useLoader } from '../../../../hooks';
import moment from 'moment';

const getAdminOptions = (admins = []) => {
    const options = [{ label: 'All', value: 'All' }];
    admins.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const getCustomerOptions = (customers = []) => {
    const options = [{ label: 'All', value: 'All' }];
    customers.forEach((c) => options.push({ label: c.name, value: c._id }));
    return options;
};

const BidRequestIndex = ({ updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [regulations, setRegulations] = useState([]);
    const [selectedRegulation, setSelectedRegulation] = useState({
        value: queryParamsData.regulation,
    });
    const [date, setDate] = useState(moment().toDate());
    const [admins, setAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState({
        value: queryParamsData.admin,
    });
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState({
        value: queryParamsData.customer,
    });

    useEffect(() => {
        fetchRegulations();
        fetchAdmins();
    }, []);

    useEffect(() => {
        if (!selectedAdmin.value) return;
        if (selectedAdmin.value !== 'All') fetchAdminCustomers();
        else {
            const options = getCustomerOptions([]);
            setCustomers(options);
            setSelectedCustomer(options[0]);
        }
    }, [selectedAdmin.value]);

    const fetchRegulations = () => {
        regulationsService.getRegulationByBidDate(
            { date: moment(date).format('YYYY-MM-DD') },
            () => startLoader('load regulations'),
            handleRegulationSuccess,
            handleError,
            () => stopLoader('load regulations')
        );
    };

    const fetchAdmins = () => {
        AdminService.ReadAll(
            () => startLoader('get admin'),
            handleFetchSucess,
            handleError,
            () => stopLoader('get admin')
        );
    };

    const handleFetchSucess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getAdminOptions(data.data);
            setAdmins(options);
            if (!selectedAdmin.value) setSelectedAdmin(options[0]);
        }
    };

    const fetchAdminCustomers = () => {
        AdminService.GetMarketParticipatingCustomers(
            selectedAdmin.value,
            () => startLoader('get customer'),
            handleCustomersSuccess,
            handleError,
            () => stopLoader('get customer')
        );
    };

    const handleCustomersSuccess = ({ data }) => {
        if (Array.isArray(data.data)) {
            const options = getCustomerOptions(data.data);
            setCustomers(options);
            if (!selectedCustomer.value) setSelectedCustomer(options[0]);
        }
    };

    const handleError = (err) => {
        if (err && !!err?.response?.data?.message) {
            toast.error(err.response.data.message);
        }
    };

    const handleRegulationSuccess = ({ data }) => {
        if (Array.isArray(data.data) && data.data.length) {
            const processedReguations = data.data.map((r) => ({
                label: r.name,
                value: r.name,
            }));
            setRegulations(processedReguations);
            if (!selectedRegulation.value) setSelectedRegulation(processedReguations[0]);
        }
    };

    const tabs = [
        {
            name: 'Bid requests',
            component: (
                <div>
                    <RequestBid
                        date={date}
                        setDate={setDate}
                        admins={admins}
                        selectedAdmin={selectedAdmin}
                        setSelectedAdmin={setSelectedAdmin}
                        customers={customers}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        regulations={regulations}
                        selectedRegulation={selectedRegulation}
                        setSelectedRegulation={setSelectedRegulation}
                        updateQueryParams={updateQueryParams}
                    />
                </div>
            ),
        },
        {
            name: 'Maintenance Hours',
            component: (
                <div>
                    <MaintenanceHours
                        date={date}
                        setDate={setDate}
                        admins={admins}
                        selectedAdmin={selectedAdmin}
                        setSelectedAdmin={setSelectedAdmin}
                        customers={customers}
                        selectedCustomer={selectedCustomer}
                        setSelectedCustomer={setSelectedCustomer}
                        regulations={regulations}
                        selectedRegulation={selectedRegulation}
                        setSelectedRegulation={setSelectedRegulation}
                        updateQueryParams={updateQueryParams}
                    />
                </div>
            ),
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={queryParamsData.subTab ? queryParamsData.subTab : 0}
                onChangeTab={(index) => {
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default BidRequestIndex;
