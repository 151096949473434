import * as yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const LoginValidation = yup.object({
    email: yup.string().email().required(),
    password: yup.string().min(8).max(50).required(),
});

export const CreateUserValidation = yup.object({
    email: yup.string().email().max(100).typeError('seh').required(),
    name: yup.string().min(3).max(70).required(),
    password: yup.string().required().min(8).max(40),
    // userRole: yup.mixed().required().oneOf(["expert", "auditor", "sudoer"]),
    contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(),
    customerId: yup.string().required(),
});

export const updateUserValidation = yup.object({
    email: yup.string().email().required().max(100).typeError('seh'),
    name: yup.string().min(3).max(70).required(),
    password: yup.string().min(8).max(40).nullable(),
    // userRole: yup.mixed().required().oneOf(["expert", "auditor", "sudoer"]),
    contact: yup.string().matches(phoneRegExp, 'invalid contact number').required(),
    customerId: yup.string().required(),
});
