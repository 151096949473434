// External library imports
import * as yup from "yup";
// Internal module imports
import { ERRORS } from "../../utils/validationHelper";

export const InstanceBundleValidation = yup.object().shape({
  name: yup.string().required(),
  customerId: yup.string().required(),
  market: yup.string().required(),
  pairs: yup
    .array()
    .of(
      yup.object().shape({
        licenseId: yup.string().required(ERRORS.required),
        instanceId: yup.string().required(ERRORS.required),
      })
    )
    .min(1)
    .required(ERRORS.required),
});
