// Internal module imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    getSigna: '/root/monitoring/signal',
    getEdge: '/root/monitoring/iot-device',
};

const getSignal = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getSigna, { params }).then(callback).catch(error).finally(next);
};

const getEdge = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getEdge, { params }).then(callback).catch(error).finally(next);
};

export const RootMonitoringService = { getSignal, getEdge };
