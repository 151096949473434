import { HttpClient } from '../utils/httpClient';

const PATH = {
    readAll: '/root/audit-pq-tool',
};

const readAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.readAll}`, { params }).then(callback).catch(error).finally(next);
};

export const RootAuditPQToolService = {
    readAll,
};
