import classes from '../../../../styles/AllDevices.module.css';
import React, { useContext, useEffect, useState } from 'react';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { LoaderContext } from '../../../../context/LoaderContext';
import { START_LOADER, STOP_LOADER } from '../../../../constants';
import { toast } from 'react-toastify';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { Form, Formik } from 'formik';
import { Input } from '../../../../components/Inputs/Input';
import { addSignalTypesValidation } from '../../../../validations/SystemTools/addSignalTypesValidation';
import DeleteModal from '../../../../components/DeleteModal/DeleteModal';
import { signalTypesServiceForRoot } from '../../../../services/signalTypesServiceForRoot';
import { ToggleButton } from '../../../../components/Inputs/Input';
import { UilPen } from '@iconscout/react-unicons';
import { UilTrash } from '@iconscout/react-unicons';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
function SignalTypes() {
    const { dispatch: loaderDispatch } = useContext(LoaderContext);
    const startLoader = (payload) => loaderDispatch({ type: START_LOADER, payload });
    const stopLoader = (payload) => loaderDispatch({ type: STOP_LOADER, payload });
    const [signalTypesData, setSignalTypesData] = useState([]);
    const [addTypesModal, setAddTypesModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState({ status: false, typeId: '' });
    const [editModal, setEditModal] = useState({
        status: false,
        typeId: '',
        data: { name: '', tax: false },
    });

    useEffect(() => {
        fetchTypes();
    }, []);

    const getInitialValue = ({ _id, name, tax }) => {
        setAddTypesModal(false);
        setEditModal({
            status: true,
            typeId: _id,
            data: { name, tax },
        });
    };
    const fetchTypes = () => {
        signalTypesServiceForRoot.ReadAll(
            () => startLoader('getSignalTypes'),
            handleSuccess,
            handleError,
            () => stopLoader('getSignalTypes')
        );
    };

    const handleSuccess = ({ data }) => {
        const processedData = data?.data.map((key, i) => ({
            's.no': i + 1,
            title: key.name,
            action: (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomTooltip content={'Edit'}>
                        <UilPen size={'1.2vw'} style={{ color: 'var(--color-primary)', marginRight: '0.5vw' }} onClick={() => getInitialValue(key)} />
                    </CustomTooltip>
                    <CustomTooltip content={'Delete'}>
                        <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, typeId: key._id })} />
                    </CustomTooltip>
                </div>
            ),
        }));
        setSignalTypesData(processedData);
    };

    const handleSubmit = (values) => {
        const payload = { ...values };
        let params = {};
        if (editModal.status) {
            signalTypesServiceForRoot.editSignalType(
                editModal.typeId,
                { name: values.name, tax: values.tax },
                () => startLoader('editedSignalType'),
                handleUpdateSuccess,
                handleError,
                () => stopLoader('editedSignalType')
            );
            return;
        }
        signalTypesServiceForRoot.createSignalType(
            payload,
            params,
            () => startLoader('createdSignalType'),
            handleCreateSuccess,
            handleError,
            () => stopLoader('createdSignalType')
        );
    };

    const handleCreateSuccess = (res) => {
        fetchTypes();
        toast.success('Signal Type created sucessfully');
        setAddTypesModal(false);
    };

    const handleUpdateSuccess = (res) => {
        fetchTypes();
        toast.success('Signal Type updated sucessfully');
        setEditModal({ ...editModal, status: false, data: {} });
        setAddTypesModal(false);
    };

    const handleDelete = () => {
        if (deleteModal.typeId) {
            signalTypesServiceForRoot.deleteSignalType(
                deleteModal.typeId,
                startLoader,
                () => {
                    fetchTypes();
                    toast.success('Signal Type deleted sucessfully');
                },
                handleError,
                stopLoader
            );
        }
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    return (
        <div className={classes.AllDevices}>
            <ModalComponent isOpen={addTypesModal || editModal.status} setOpen={setAddTypesModal || setEditModal}>
                <Typography content={addTypesModal ? 'Add Types' : 'Edit Types'} />
                <div className={classes.SupportNoteForm}>
                    <Formik
                        initialValues={addTypesModal ? { name: '', tax: false } : editModal.data}
                        enableReinitialize
                        validationSchema={addSignalTypesValidation}
                        onSubmit={(val) => {
                            handleSubmit(val);
                        }}
                    >
                        {({ errors, touched, values, isValidating, ...props }) => {
                            console.log({ handleSubnmit: values });
                            return (
                                <Form>
                                    <div>
                                        <div>
                                            <div>
                                                <div className={classes.FieldControl2}>
                                                    <label for="name">
                                                        Name <span className="required">*</span>
                                                    </label>
                                                    <Input
                                                        name="name"
                                                        id="name"
                                                        disabled={!addTypesModal ? true : false}
                                                        setFieldValue={props.setFieldValue}
                                                        setFieldTouched={props.setFieldTouched}
                                                        getFieldMeta={props.getFieldMeta}
                                                    />
                                                </div>
                                                <div className={classes.FieldControl2}>
                                                    <ToggleButton values={values} label={'Tax'} name={'tax'} />
                                                </div>
                                            </div>

                                            <div className={classes.ButtonContainer}>
                                                <div>
                                                    <button
                                                        className="btn-secondary"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setAddTypesModal(false);
                                                            setEditModal({ ...editModal, status: false });
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                                <div>
                                                    <button className="btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ ...deleteModal, status })} />
            <div className={classes.Header}>
                {/* <div> */}
                <div>
                    <Typography content="Types" />
                    <div className={classes.TableCount}>
                        Total Count :<span>{signalTypesData.length}</span>
                    </div>
                    <div>
                        <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setAddTypesModal(true)}>
                            Add
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <Table head={['S.No', 'Title', 'Action']} keys={['s.no', 'title', 'action']} data={signalTypesData} />
            </div>
        </div>
    );
}

export default SignalTypes;
