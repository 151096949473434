// Standard library imprors
import React, { useState, useCallback, useEffect, useContext } from 'react';

// Internal module imports
import { AuthContext } from '../../context/AuthContext';

// External library imports
import Tree from 'react-d3-tree';
import { UilInfoCircle } from '@iconscout/react-unicons';
import { get as LodashGet } from 'lodash';

// Css module imports
import classes from './TreeChart.module.css';

const TreeChart = ({ chartData = {}, orientation = 'vertical', onDoubleClick = null, getInfoDetails = null, updateQueryParams }) => {
    const { state } = useContext(AuthContext);
    const userRole = LodashGet(state, 'user.userRole');

    let timeout = null;
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const [data, setData] = useState({});
    const [nodeSize, setNodeSize] = useState({ x: 400, y: 100 });
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        setData(chartData);
    }, [chartData]);

    useEffect(() => {
        let x = Math.floor(dimensions.width / 3);
        let y = Math.floor(dimensions.height / 5);
        setNodeSize({ x: x, y: y });
    }, [dimensions]);

    const containerRef = useCallback((containerElem) => {
        if (containerElem !== null) {
            const { width, height } = containerElem.getBoundingClientRect();
            setTranslate({ x: width / 10, y: height / 2 });
            setDimensions({ width, height });
        }
    }, []);

    const onClick = (e, toggleNode) => {
        e.preventDefault();
        if (timeout === null) {
            timeout = window.setTimeout(() => {
                toggleNode();
                timeout = null;
            }, 300);
        }
    };

    const doubleClickHandler = (e, nodeDatum) => {
        e.preventDefault();
        window.clearTimeout(timeout);
        onDoubleClick && onDoubleClick(nodeDatum);
        timeout = null;
    };

    const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
        <g>
            <rect width="25%" height="18%" x="-10%" y="-6.5%" fill="var(--color-primary)" rx="0.78%" stroke="#aaa" />
            <foreignObject width="25%" height="18%" x="-10%" y="-6.5%">
                <div
                    className={classes.Text}
                    onDoubleClickCapture={(e) => {
                        doubleClickHandler(e, nodeDatum);
                        updateQueryParams && updateQueryParams(nodeDatum._id, 'document');
                    }}
                    onClick={(e) => onClick(e, toggleNode)}
                >
                    <span>{nodeDatum.name}</span>
                    {nodeDatum.name !== 'Root' && (
                        <div style={{ display: 'flex' }}>
                            <UilInfoCircle
                                stroke="#fff"
                                size={'2.5vw'}
                                style={{ strokeWidth: '0' }}
                                onClick={() => {
                                    let depth = nodeDatum.__rd3t.depth;
                                    if (userRole === 'admin' || userRole === 'sub-admin') {
                                        // NOTE: since this component is used by root and admin, this logic is required as admin does not have root usecase
                                        depth = depth + 1;
                                    }
                                    switch (depth) {
                                        case 0:
                                            getInfoDetails && getInfoDetails({ type: 'root', ...nodeDatum });
                                            updateQueryParams && updateQueryParams(nodeDatum._id, 'details', 'root');
                                            break;
                                        case 1:
                                            getInfoDetails && getInfoDetails({ type: 'admin', ...nodeDatum });
                                            updateQueryParams && updateQueryParams(nodeDatum._id, 'details', 'admin');
                                            break;
                                        case 2:
                                            getInfoDetails && getInfoDetails({ type: 'customer', ...nodeDatum });
                                            updateQueryParams && updateQueryParams(nodeDatum._id, 'details', 'customer');
                                            break;
                                        case 3:
                                            getInfoDetails && getInfoDetails({ type: 'license', ...nodeDatum });
                                            updateQueryParams && updateQueryParams(nodeDatum._id, 'details', 'license');
                                            break;
                                        case 4:
                                            getInfoDetails && getInfoDetails({ type: 'instance', ...nodeDatum });
                                            updateQueryParams && updateQueryParams(nodeDatum._id, 'details', 'instance');
                                            break;
                                        default:
                                            getInfoDetails && getInfoDetails(nodeDatum);
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            </foreignObject>
        </g>
    );

    return (
        <div>
            {chartData.name && (
                <div ref={containerRef}>
                    <Tree
                        data={data}
                        orientation={orientation}
                        translate={translate}
                        collapsible={true}
                        pathFunc={'step'}
                        zoom={0.3}
                        initialDepth={2}
                        rootNodeClassName={classes.NodeRoot}
                        branchNodeClassName={classes.NodeBranch}
                        nodeSize={nodeSize}
                        renderCustomNodeElement={renderRectSvgNode}
                        // onNodeClick={() => handleDoubleClick()}
                    />
                </div>
            )}
        </div>
    );
};

export default TreeChart;
