import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/customer/create',
    read_all: '/admin/customer',
    read: '/admin/customer',
    update: '/admin/customer',
    delete: '/admin/customer',
    loginAs: '/admin/customer/loginas',
    bidMarket: '/admin/customer/bid-market',
    updateE3DashboardConfig: '/admin/customer/update-e3-dashboard',
    getMarketEnabledGroups: '/admin/customer/groups',
    updateProperties: '/admin/customer/update-properties',
    readAllForEnabler: '/enabler/customer',
    getCustomerIntances: 'admin/customer/instances',
    getMarketParticipatingCustomer: 'admin/customer/market-participating-customer',
    systemStatus: '/admin/customer/system-status',
};

const Create = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const Read = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.read}/${id}`).then(callback).catch(error).finally(next);
};

const ReadAll = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

const ReadByBidMarket = (market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bidMarket}/${market}`).then(callback).catch(error).finally(next);
};

const loginAsCustomer = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.loginAs}/${id}`).then(callback).catch(error).finally(next);
};

const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateLicense = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/license/${id}`, payload).then(callback).catch(error).finally(next);
};
const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

const UpdateE3DashboardConfig = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updateE3DashboardConfig}/${id}`, payload).then(callback).catch(error).finally(next);
};

const UpdateProperties = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updateProperties}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetMarketEnabledgroups = (market, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getMarketEnabledGroups}/${market}`, { params }).then(callback).catch(error).finally(next);
};

const ReadAllForEnabler = (params = {}, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAllForEnabler, { params }).then(callback).catch(error).finally(next);
};

const GetCustomerInstances = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getCustomerIntances}/${id}`).then(callback).catch(error).finally(next);
};

const GetMarketParticipatingCustomer = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getMarketParticipatingCustomer).then(callback).catch(error).finally(next);
};

const getSystemStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.systemStatus}/${id}`).then(callback).catch(error).finally(next);
};

export const CustomerService = {
    Create,
    Read,
    ReadAll,
    ReadByBidMarket,
    loginAsCustomer,
    Update,
    Delete,
    UpdateLicense,
    UpdateE3DashboardConfig,
    GetMarketEnabledgroups,
    UpdateProperties,
    GetCustomerInstances,
    GetMarketParticipatingCustomer,
    getSystemStatus,
};

export const EnablerCustomerService = { ReadAll: ReadAllForEnabler };
