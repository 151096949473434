// Standard library imports
import React, { useState } from 'react';

// Internal Module Imports
import { dkCurrencyFormat } from '../../../../../utils/currencyHelper';
import Checkbox from '../../../../../components/Inputs/Checkbox/Checkbox';
import { DCPlannerBidService } from '../../../../../services/DCPlannerBidService';
import { useLoader } from '../../../../../hooks';
import { TextArea } from '../../../../../components/Inputs/Input';

// CSS Imports
import classes from '../../../../../styles/CapacityContent.module.css';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';

const HourlyCost = ({ data, setOpen, fetchBids }) => {
    const bidStatus = data?.bidStatus || [];
    const energyBidStatus = data?.energyBidStatus || [];

    const [selectReverseBid, setSelectReverseBid] = useState(false);
    const [startLoader, stopLoader] = useLoader();

    let reverseBid = {};

    const getCheckedBid = (value, productName) => {
        reverseBid = { ...reverseBid, [productName]: value };
    };

    const handleSubmit = (values) => {
        DCPlannerBidService.CreateReverseBid(
            data?.bidId,
            { reverseRequest: reverseBid, reverseReason: values.reverseReason },
            startLoader,
            handleSubmitSuccess,
            handleError,
            stopLoader
        );
    };

    const handleSubmitSuccess = () => {
        toast.success('Bid Created!');
        fetchBids();
        setOpen(false);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <Formik initialValues={{ reverseReason: data?.reverseReason || '' }} onSubmit={handleSubmit}>
            {({ errors, touched, values, isValidating, ...props }) => (
                <Form>
                    <div className={classes.CapacityContent} style={{ height: 'auto', width: 'auto' }}>
                        <div className={classes.Title}>
                            {bidStatus?.length ? (
                                <div className={classes.ReverseBidBtn}>
                                    {!selectReverseBid ? (
                                        <div>
                                            <button type="button" className="btn-primary" onClick={() => setSelectReverseBid(true)} style={{ marginTop: '0' }}>
                                                Reverse bid
                                            </button>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', gap: '0.5vw' }}>
                                            <button type="button" className="btn-primary" onClick={() => setSelectReverseBid(false)} style={{ marginTop: '0' }}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn-primary" style={{ marginTop: '0' }}>
                                                Submit
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        <div className={classes.Content}>
                            <div className={classes.ContainerStart}>
                                {Boolean(bidStatus.length) && (
                                    <>
                                        {[0, 1, 2].map((n) => (
                                            <table>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Capacity Price</th>
                                                    <th>Energy Price</th>
                                                </tr>
                                                {Array.from({ length: 9 }, (_, i) => i + n * 9).map((key) =>
                                                    key <= 23 ? (
                                                        <tr>
                                                            <td style={{ display: 'flex', gap: '0.5vw' }}>
                                                                {selectReverseBid && <Checkbox onChange={(e) => getCheckedBid(e, bidStatus[key]?.productName)} />}
                                                                {bidStatus[key]?.productName}
                                                            </td>
                                                            <td>{bidStatus[key]?.price && dkCurrencyFormat(bidStatus[key]?.price)}</td>
                                                            <td>{energyBidStatus[key]?.price && dkCurrencyFormat(energyBidStatus[key]?.price || 0)}</td>
                                                        </tr>
                                                    ) : null
                                                )}
                                            </table>
                                        ))}
                                    </>
                                )}
                            </div>
                            {data?.reverseBid || selectReverseBid ? (
                                <div className={classes.FieldControl2}>
                                    <label>Reason</label>
                                    <TextArea
                                        name={`reverseReason`}
                                        setFieldValue={props.setFieldValue}
                                        setFieldTouched={props.setFieldTouched}
                                        getFieldMeta={props.getFieldMeta}
                                        disabled={selectReverseBid ? false : true}
                                    />
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default HourlyCost;
