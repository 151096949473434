import { HttpClient } from '../utils/httpClient';

const PATH = {
    create: '/admin/supportNotes',
    read_all: '/admin/supportNotes',
    read: '/admin/supportNotes',
    update: '/admin/supportNotes',
    delete: '/admin/supportNotes',
};

//Post call for creating a version history
const Create = (params, payload, start, callback, error, next) => {
    start();
    console.log(payload);
    return HttpClient.post(PATH.create, payload, { params }).then(callback).catch(error).finally(next);
};

// //Hit for getting a particular version history based on id
// const Read = (id, start, callback, error, next) => {
//   start();
//   return HttpClient.get(`${PATH.read}/${id}`)
//     .then(callback)
//     .catch(error)
//     .finally(next);
// };

//Hit for getting all version history info from API
const ReadAll = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all, { params }).then(callback).catch(error).finally(next);
};

//Patch request for updating a version history (id as a param)
const Update = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

//Delete request for deleting a version history based on id
const Delete = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};

export const SupportNotesService = { Create, ReadAll, Update, Delete };
